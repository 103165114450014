import { Button, Grid, Paper, Typography } from "@mui/material";
import CustomSwitch from "../../../../../common/CustomSwitch/CustomSwitch";
import NumberInput from "../../../../../common/NumberInput/NumberInput";
import Dropdown from "../../../../../common/Dropdown/Dropdown";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import { Dropdowns } from "../../../../../services/dropdown.service";
import { StyledCreateEstateFormOthers, createEstateClasses as classes } from "./StyledCreateEstateFormOthers";

interface CreateEstateFormOthersProps {
    form: IForm<SellerProfileState>;
    dropdowns: Dropdowns;
    isDisabled: boolean;
}

const CreateEstateFormOthers = ({ form, dropdowns, isDisabled }: CreateEstateFormOthersProps) => {
    return (
        <StyledCreateEstateFormOthers>
            <Grid container>
                <Paper className={classes.paperContainer}>
                    <Typography variant={"h6"} color="#757C8D">
                        Други
                    </Typography>
                    <Grid container marginTop="20px" alignItems={"flex-start"}>
                        <CustomSwitch
                            label={"Наличие на трафопост"}
                            disabled={isDisabled}
                            onChange={form.handleChange}
                            className={classes.checkboxLabel}
                            name={"is_substation_present"}
                            title={"Наличие на трафопост"}
                            value={form.values.is_substation_present}
                        />
                        <NumberInput
                            label={"метри до най-близък трафопост"}
                            disabled={isDisabled}
                            handleChange={form.handleChange}
                            value={form.values.substation_distance_in_meters}
                            name={"substation_distance_in_meters"}
                            error={
                                !!(
                                    form.touched?.substation_distance_in_meters &&
                                    !!form.errors?.substation_distance_in_meters
                                )
                            }
                            helperText={form.errors.substation_distance_in_meters}
                            inputClass={classes.numberInputSubstation}
                        />
                        <CustomSwitch
                            label={"собствен"}
                            disabled={isDisabled}
                            className={classes.personalSubstation}
                            onChange={form.handleChange}
                            name={"personal_substation"}
                            title={"собствен"}
                            value={form.values.personal_substation}
                        />
                    </Grid>

                    <Grid container marginTop="20px" marginBottom="10px" alignItems={"flex-end"}>
                        <CustomSwitch
                            label={"Наличие на ел. провод"}
                            disabled={isDisabled}
                            onChange={form.handleChange}
                            className={`${classes.checkboxLabel} ${classes.customSwitch}`}
                            name={"electrical_wire"}
                            title={"Наличие на ел. провод"}
                            value={form.values.electrical_wire}
                        />

                        <Grid container width="130px" height="120px" marginRight="10px" paddingTop="8px">
                            <Dropdown
                                label={"Напрежение"}
                                disabled={isDisabled}
                                className={`${classes.dropdown} ${classes.dropdownVoltage}`}
                                options={dropdowns.voltage}
                                value={form.values.voltage_id}
                                onChange={form.handleChange}
                                name={"voltage_id"}
                                error={form.touched?.voltage_id && !!form.errors?.voltage_id}
                                helperText={form.errors.voltage_id}
                            />
                        </Grid>

                        <NumberInput
                            label={"метри до най-близък стълб"}
                            disabled={isDisabled}
                            handleChange={form.handleChange}
                            value={form.values.meters_to_nearest_electricity_pillar}
                            inputClass={classes.numberInputElectricityPillar}
                            name={"meters_to_nearest_electricity_pillar"}
                            error={
                                !!(
                                    form.touched?.meters_to_nearest_electricity_pillar &&
                                    !!form.errors?.meters_to_nearest_electricity_pillar
                                )
                            }
                            helperText={form.errors.meters_to_nearest_electricity_pillar}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </StyledCreateEstateFormOthers>
    );
};

export default CreateEstateFormOthers;
