import { Container, styled } from "@mui/material";

const ESTATE_LISTING_PREFIX = "EstateListing";

export const estateListingClasses = {
    root: `${ESTATE_LISTING_PREFIX}-root`,
    estatesWrapper: `${ESTATE_LISTING_PREFIX}-estatesWrapper`,
    filterContainer: `${ESTATE_LISTING_PREFIX}-filterContainer`,
    container: `${ESTATE_LISTING_PREFIX}-container`,
};

export const StyledEstateListing = styled(Container)(({ theme }) => ({
    [`&.${estateListingClasses.root}`]: {
        padding: "0",
        margin: "auto",
        justifyContent: "center",
        [theme.breakpoints.only("sm")]: {
            width: "850px",
        },
    },
    [`& .${estateListingClasses.estatesWrapper}`]: {
        padding: "0",
        [theme.breakpoints.only("md")]: {
            padding: "20px 20px",
        },
        [theme.breakpoints.only("lg")]: {
            padding: "20px 45px",
        },
    },
    [`& .${estateListingClasses.filterContainer}`]: {
        [theme.breakpoints.only("sm")]: {
            marginTop: "20px",
        },
    },
    [`& .${estateListingClasses.container}`]: {
        [theme.breakpoints.only("sm")]: {
            padding: "0",
        },
    },
}));
