/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { homeClasses, newFeatureModalClasses, StyledHome, StyledNewFeatureModal } from "./StyledHome";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { Filter } from "./Filter/Filter";
import { NewLocationsSection } from "./NewLocationsSection/NewLocationsSection";
import { getEstateSearchUrl } from "../../services/estate.service";
import { NavigationRoutes } from "../../routes";
import { IEstateFilters } from "../../interfaces/filters";
import { HowItWorks } from "./HowItWorks/HowItWorks";
import { Map } from "../../Seller/SellerProfile/CreateEstate/Map";
import { Box, Button, Container, Grid, Link, Paper, Typography } from "@mui/material";
import { primary } from "../../common/Theme/theme";
import { mapStyles } from "./map-styles";
import { GlobalContext } from "../../App";
import { WhatDoYouWantToBuy } from "./WhatDoYouWantToBuy/WhatDoYouWantToBuy";
import { useGA } from "../../common/hooks";
import { CookiesConsentDrawer } from "./CookiesConsentDrawer/CookiesConsentDrawer";
import { useNavigate } from "react-router-dom";

export const Home = () => {
    const { dropdowns } = useContext(GlobalContext);
    const { user } = useContext(GlobalContext);
    const [openCookiesDrawer, setOpenCookiesDrawer] = useState(false);
    const [openNewFeatureModal, setOpenNewFeatureModal] = useState(false);
    useGA();
    const navigate = useNavigate();

    const handleFiltersChange = (filters: IEstateFilters) => {
        const link = `${window.location.protocol}//${window.location.host}/${NavigationRoutes.EstateListing}`;
        window.location.href = getEstateSearchUrl(link, filters);
    };
    const handleEstateTypeChange = (type: number) => {
        const link = `${window.location.protocol}//${window.location.host}/${NavigationRoutes.EstateListing}?estate_type_id=${type}`;
        window.location.href = link;
    };
    const [zoom] = React.useState(7);
    const [center] = React.useState<google.maps.LatLngLiteral>({
        lat: 42.8264447,
        lng: 25.4963077,
    });

    const handleMapAreaClick = useCallback(
        (areaName: string) => {
            const area = dropdowns.countryAreas.find((countryArea) => countryArea.name === areaName);
            handleFiltersChange({
                country_area_id: area?.id!,
            } as any);
        },
        [dropdowns, user]
    );

    useEffect(() => {
        fetch("/pollygons.json")
            .then((res) => res.json())
            .then((areas) => {
                areas.forEach((area: any) => {
                    const bermudaTriangle = new google.maps.Polygon({
                        paths: area.Geometry,
                        strokeColor: primary,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: primary,
                        fillOpacity: 0.35,
                    });
                    bermudaTriangle.setMap((window as any).map);
                    bermudaTriangle.addListener("click", () => {
                        handleMapAreaClick(area.Name);
                    });
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdowns]);

    useEffect(() => {
        const cookiesMessage = localStorage.getItem("cookies");
        if (!cookiesMessage) {
            setOpenCookiesDrawer(true);
        }
    }, []);

    useEffect(() => {
        const newFeatureMessage = localStorage.getItem("newFeature");
        if (!newFeatureMessage) {
            setOpenNewFeatureModal(true);
        }
    }, []);

    const handleNewFeatureModalClose = () => {
        localStorage.setItem("newFeature", "true");
        setOpenNewFeatureModal(false);
    };
    return (
        <ViewContainer>
            <StyledHome className={homeClasses.root}>
                <Grid container className={homeClasses.mapSection}>
                    <Grid item xs={12} lg={6} md={6} className={homeClasses.map}>
                        <Map
                            center={center}
                            zoom={zoom}
                            mapTypeControl={false}
                            fullscreenControl={false}
                            streetViewControl={false}
                            draggable={false}
                            keyboardShortcuts={false}
                            scaleControl={false}
                            scrollwheel={false}
                            zoomControl={false}
                            disableDoubleClickZoom={true}
                            styles={mapStyles}
                            style={{ flexGrow: "1", height: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} className={homeClasses.rightPanel}>
                        <Typography className={homeClasses.heading}>
                            <span className={homeClasses.spanPrimary}> ИСКАШ ДА ИНВЕСТИРАШ </span>В СОЛАРЕН ПАРК?{" "}
                            <span className={homeClasses.spanSecondary}>ИЛИ ВЕЧЕ СИ ГО НАПРАВИЛ И ГО ПРОДАВАШ?</span>
                        </Typography>
                        <Typography className={homeClasses.text}>
                            ПЛАТФОРМАТА НА SOLAR ESTATES ПРЕДЛАГА ЦЯЛОСТНО РЕШЕНИЕ ЗА ИНВЕСТИТОРИТЕ ВЪВ ВЕИ, КАТО НА
                            ПЪРВО МЯСТО СВЪРЗВА ПРОДАВАЧИ НА ИМОТИ, ПРОЕКТИ ЗА ПАРКОВЕ, КАКТО И ДЕЙСТАВИЩИ ТАКИВА С
                            ПОТЕНЦИАЛНИ ИНВЕСТИТОРИ. В ДОПЪЛНЕНИЕ ПРЕДЛАГА ИНОВАТИВНИ НАЧИНИ ЗА ИНВЕСТИРАНЕ И
                            ФИНАНСИРАНЕ НА ПРОЕКТИ ЗА ВЕИ И ПОМАГА С НАМИРАНЕТО НА ПОДБРАНИ ИЗПЪЛНИТЕЛИ.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={homeClasses.filterContainer}>
                    <Container fixed>
                        <Filter handleChange={handleFiltersChange} />
                    </Container>
                </Grid>
                <Grid container>
                    <WhatDoYouWantToBuy handleChange={handleEstateTypeChange} />
                    <NewLocationsSection />
                    <HowItWorks />
                </Grid>
                <CookiesConsentDrawer
                    open={openCookiesDrawer}
                    onAccept={() => {
                        localStorage.setItem("cookies", "true");
                        setOpenCookiesDrawer(false);
                    }}
                />
            </StyledHome>
            <StyledNewFeatureModal
                open={openNewFeatureModal}
                onClose={() => {
                    handleNewFeatureModalClose();
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box className={newFeatureModalClasses.box}>
                    <Paper className={newFeatureModalClasses.paper}>
                        <Typography className={newFeatureModalClasses.heading}>Нова функционалност</Typography>
                        <Typography
                            id="modal-title"
                            variant="h2"
                            component="h1"
                            className={newFeatureModalClasses.message}>
                            Вече работи новата функционалност на www.vei-imot.bg, чрез която публикувайки обява може да
                            качите и съотетните документи. От своя страна търсещите имот или парк ще могат бързо и лесно
                            да преценят дали дадена обява отговаря на техните критерии. По този начин се спестява време
                            и ресурси за проучването на всяка обява. Средно времето за продажба на подобни обяви е
                            намалено с 50 %. Може да създадете Вашата обява{" "}
                            <Link
                                onClick={() => {
                                    navigate(`/${NavigationRoutes.CreateEstate}`);
                                }}>
                                тук
                            </Link>
                            .
                        </Typography>
                        <Button onClick={handleNewFeatureModalClose}>Затвори</Button>
                    </Paper>
                </Box>
            </StyledNewFeatureModal>
        </ViewContainer>
    );
};
