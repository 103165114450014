import { Button, Grid, styled, TextField } from "@mui/material";
import { greyLight, grey, greyDark } from "../../common/Theme/theme";
import loginImage from "../../assets/login-image.svg";

const LOGIN_PREFIX = "Authentication";

export const authenticationClasses = {
    root: `${LOGIN_PREFIX}-root`,
    heading: `${LOGIN_PREFIX}-heading`,
    welcome: `${LOGIN_PREFIX}-welcome`,
    forgotPassword: `${LOGIN_PREFIX}-forgotPassword`,
    link: `${LOGIN_PREFIX}-link`,
    signUpWith: `${LOGIN_PREFIX}-signUpWith`,
    socials: `${LOGIN_PREFIX}-socials`,
    leftPanel: `${LOGIN_PREFIX}-leftPanel`,
    rightPanel: { signIn: `${LOGIN_PREFIX}-rightPanelSignIn`, signUp: `${LOGIN_PREFIX}-rightPanelSignUp` },
    buttonIcon: `${LOGIN_PREFIX}-buttonIcon`,
    tabs: `${LOGIN_PREFIX}-tabs`,
    logoContainer: `${LOGIN_PREFIX}-logoContainer`,
    logoLight: `${LOGIN_PREFIX}-logoLight`,
    logoDark: `${LOGIN_PREFIX}-logoDark`,
    agreement: `${LOGIN_PREFIX}-agreement`,
};

export const StyledLogin = styled(Grid)(({ theme }) => ({
    width: "105%",
    [theme.breakpoints.only("lg")]: {
        width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
        width: "100%",
    },
    [`& .${authenticationClasses.heading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
    },
    [`& .${authenticationClasses.welcome}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: greyLight,
        marginBottom: "30px",
    },
    [`& .${authenticationClasses.forgotPassword}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        color: grey,
        marginBottom: "20px",
    },
    [`& .${authenticationClasses.link}`]: {
        textDecoration: "none",
    },
    [`& .${authenticationClasses.signUpWith}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        color: greyDark,
        marginBottom: "20px",
    },
    [`& .${authenticationClasses.socials}`]: {
        border: "1px solid",
        marginRight: "20px",
    },
    [`& .${authenticationClasses.leftPanel}`]: {
        [theme.breakpoints.up("md")]: {
            backgroundImage: `url(${loginImage})`,
            width: "100%",
            height: "110vh",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.only("sm")]: {
            backgroundImage: `url(${loginImage})`,
            width: "100%",
            height: "50vh",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        },
    },
    [`& .${authenticationClasses.rightPanel.signIn}`]: {
        background: "rgba(225, 224, 223, 0.5)",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 160px",
        },
        [theme.breakpoints.only("sm")]: {
            height: "60vh",
        },
        [theme.breakpoints.only("md")]: {
            height: "110vh",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "100px 30px",
            height: "110vh",
        },
    },
    [`& .${authenticationClasses.rightPanel.signUp}`]: {
        background: "rgba(225, 224, 223, 0.5)",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 40px",
        },
        [theme.breakpoints.only("xl")]: {
            padding: "30px 50px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "100px 30px",
            height: "1300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    [`& .${authenticationClasses.buttonIcon}`]: {
        marginRight: "10px",
    },
    [`& .${authenticationClasses.tabs}`]: {
        marginBottom: "20px",
        borderBottom: 1,
        borderColor: "divider",
    },
    [`& .${authenticationClasses.logoContainer}`]: {
        width: "10%",
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            left: "10%",
            top: "10%",
        },
        [theme.breakpoints.only("sm")]: {
            left: "5%",
            top: "5%",
        },
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            margin: "20px",
        },
    },
    [`& .${authenticationClasses.logoLight}`]: {
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        [theme.breakpoints.only("sm")]: {
            display: "block",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    [`& .${authenticationClasses.logoDark}`]: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        [theme.breakpoints.only("sm")]: {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    [`& .${authenticationClasses.agreement}`]: {
        marginBottom: "10px",
        color: grey,
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    fontFamily: "Rubik",
    borderRadius: "100px",
    height: "40px",
    textTransform: "capitalize",
    marginBottom: "30px",
    width: "fit-content",
}));

export const CssTextField = styled(TextField)(({ theme }) => ({
    color: grey,
    background: "transparent",
    borderColor: greyLight,
    boxSizing: "border-box",
    borderRadius: "8px",
    marginBottom: "40px",
    marginRight: "40px",
    width: "90%",
    "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart":
        {
            background: theme.palette.common.white,
            border: "1px solid rgba(225, 224, 223, 0.5)",
        },
    "& label.Mui-focused": {
        color: greyLight,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "rgba(225, 224, 223, 0.5)",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&:hover fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
    },
}));
