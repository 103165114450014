import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { ColorButton, CssTextField, resetPasswordClasses as classes, StyledResetPassword } from "./StyledResetPassword";
import solarEstatesLight from "./assets/solar-estates-light.svg";
import solarEstatesDark from "./assets/solar-estates-dark.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { resetPasswordService } from "../../services/reset-password.service";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { NavigationRoutes } from "../../routes";
import { useGA } from "../../common/hooks";

export const ResetPassword = () => {
    useGA();
    const { hash } = useParams();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const form = useFormik<{
        password: string;
        repeatPassword: string;
    }>({
        initialValues: {
            password: "",
            repeatPassword: "",
        },
        validationSchema: yup.object({
            password: yup.string().required("Паролата е задължителна"),
            repeatPassword: yup
                .string()
                .required("Потвърждение на паролата е задължително")
                .oneOf([yup.ref("password"), null], "Паролите трябва да съвпадат"),
        }),
        onSubmit: (values) => {
            if (hash) {
                resetPasswordService
                    .resetPassword(hash!, values.password)
                    .then(() => {
                        setIsSuccess(true);
                        setTimeout(() => {
                            navigate(`/${NavigationRoutes.Login}`);
                        }, 5000);
                    })
                    .catch(() => {
                        setIsError(true);
                    });
            }
        },
    });
    return (
        <StyledResetPassword container className={classes.root} flexDirection={"row"}>
            <Grid item lg={5} md={5} xs={12} display={"flex"} flexDirection={"column"} className={classes.leftPanel}>
                <Grid container className={classes.logoContainer}>
                    <img src={solarEstatesLight} className={classes.logoLight} alt="Solar Estates" />
                    <img src={solarEstatesDark} className={classes.logoDark} alt="Solar Estates" />
                </Grid>
            </Grid>
            <Grid
                item
                lg={7}
                md={7}
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                className={classes.rightPanel}>
                <Typography variant={"caption"} color="primary" className={classes.heading}>
                    Смени парола
                </Typography>
                <Typography className={classes.welcome}>Това ще бъде новата Ви парола</Typography>
                <Grid item>
                    <CssTextField
                        variant={"outlined"}
                        placeholder="Парола"
                        type={"password"}
                        sx={{ height: "90px" }}
                        InputProps={{
                            startAdornment: <LockOutlinedIcon htmlColor={"#BABDC3"} />,
                        }}
                        name={"password"}
                        value={form.values.password}
                        onChange={form.handleChange}
                        error={form.touched?.password && !!form.errors?.password}
                        helperText={form.errors?.password}
                    />
                </Grid>
                <Grid item>
                    <CssTextField
                        variant={"outlined"}
                        placeholder="Повтори парола"
                        type={"password"}
                        sx={{ height: "90px" }}
                        InputProps={{
                            startAdornment: <LockOutlinedIcon htmlColor={"#BABDC3"} />,
                        }}
                        name={"repeatPassword"}
                        value={form.values.repeatPassword}
                        onChange={form.handleChange}
                        error={form.touched?.repeatPassword && !!form.errors?.repeatPassword}
                        helperText={form.errors?.repeatPassword}
                    />
                </Grid>
                <ColorButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        form.submitForm();
                    }}>
                    <PersonOutlineOutlinedIcon className={classes.buttonIcon} />
                    Изпрати
                </ColorButton>
            </Grid>
            <Snackbar
                open={isSuccess}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    Успешна смяна на паролата
                </Alert>
            </Snackbar>
            <Snackbar
                open={isError}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    Грешка при смяната на паролата. Моля пробвайте отново.
                </Alert>
            </Snackbar>
        </StyledResetPassword>
    );
};
