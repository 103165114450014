import { estateDetailsClasses as classes, StyledEstateDetailsDescription } from "./StyledEstateDetailsDescription";
import { Grid, Paper, Typography } from "@mui/material";
import { existingParkId, piId, upiId } from "../../../Seller/SellerProfile/CreateEstate/schema";
import { grey, primary, secondary } from "../../../common/Theme/theme";
import solarIcon from "../../../Seller/SellerProfile/assets/solar-icon.png";
import windIcon from "../../../Seller/SellerProfile/assets/wind-icon.png";
import WaterIcon from "@mui/icons-material/Water";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";

interface EstateDetailsDescriptionProps {
    estate: IEstate;
    dropdownsMap: DropdownMap;
}

const EstateDetailsDescription = ({ estate, dropdownsMap }: EstateDetailsDescriptionProps) => {
    return (
        <StyledEstateDetailsDescription>
            <Grid container>
                <Paper className={classes.paperContainer}>
                    <Grid container flexDirection={"row"} marginBottom="20px">
                        <ErrorOutlineOutlinedIcon htmlColor={grey} />
                        <Typography marginLeft="10px" className={classes.heading}>
                            Преглед
                        </Typography>
                    </Grid>
                    <Grid container marginBottom="10px">
                        <Typography className={classes.label} marginLeft="5px">
                            Референтен номер:
                        </Typography>
                        <Typography className={classes.value}>{estate.id}</Typography>
                    </Grid>
                    <Grid container minHeight="100px">
                        <Typography variant={"h3"} className={`${classes.value} ${classes.description}`}>
                            {estate.description}
                        </Typography>
                    </Grid>
                    <Grid container marginTop="20px" height="30px">
                        <Typography variant={"body1"} className={classes.value}>
                            {dropdownsMap!.estateTypes[estate.estate_type_id]?.name} предназначен за{" "}
                            {dropdownsMap!.offerTypes[estate.offer_type_id]?.name}
                        </Typography>
                    </Grid>
                    {estate.estate_type_id === existingParkId && (
                        <Grid container marginTop="10px">
                            <Grid
                                item
                                lg={2}
                                display={estate.renewable_type_id === 3 ? "flex" : "none"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                className={classes.estateTypeContainer}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    className={classes.estateTypeContainerImageOfSolarIcon}>
                                    <img src={solarIcon} alt={"Solar"} width={"55%"} height={"50%"} />
                                </Grid>
                                <Typography variant={"h6"} component={"div"} color="#757C8D">
                                    фТЕЦ
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={2}
                                display={estate.renewable_type_id === 2 ? "flex" : "none"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                className={classes.estateTypeContainer}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    className={classes.estateTypeContainerImageOfWindIcon}>
                                    <img src={windIcon} alt={"Wind"} width={"50%"} height={"70%"} />
                                </Grid>
                                <Typography variant={"h6"} component={"div"} color="#757C8D">
                                    ВяЕЦ
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={2}
                                display={estate.renewable_type_id === 1 ? "flex" : "none"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                className={classes.estateTypeContainer}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    className={classes.estateTypeContainerImageOfWaterIcon}>
                                    <WaterIcon className={classes.WaterIcon} />
                                </Grid>
                                <Typography variant={"h6"} component={"div"} color="#757C8D">
                                    ВЕЦ
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {estate.estate_type_id === upiId && (
                        <Grid container marginTop="30px" height="60px">
                            <Typography component="div" width="100%" variant={"body1"} className={classes.value}>
                                Предназнaчение
                            </Typography>
                            <Typography component="div" variant={"body1"} className={classes.value}>
                                {estate.upi_purpose}
                            </Typography>
                        </Grid>
                    )}

                    {estate.estate_type_id === piId && (
                        <Grid container marginTop="20px">
                            <Typography variant={"body1"} className={classes.label}>
                                Категория земя:&nbsp;
                            </Typography>
                            <Typography variant={"body1"} className={classes.value}>
                                {dropdownsMap!.landCategories[estate.land_category_id]?.name}&nbsp;
                            </Typography>
                            <Typography variant={"body1"} className={classes.label}>
                                oт тип:&nbsp;
                            </Typography>
                            <Typography variant={"body1"} className={classes.value}>
                                {dropdownsMap!.landTypes[estate.land_type_id]?.name}
                            </Typography>
                        </Grid>
                    )}
                </Paper>
            </Grid>
        </StyledEstateDetailsDescription>
    );
};

export default EstateDetailsDescription;
