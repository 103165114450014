import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import { userService } from "../../../services/user.service";
import CloseIcon from "@mui/icons-material/Close";
import {
    StyledUnlockContactModal,
    unlockContactModalClasses as classes,
} from "./StyledEstateDetailsUnlockContactModal";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";
import { ILogged } from "../../../interfaces/user";
import { Cost } from "../../../common/utils";
import unlockIcon from "../assets/unlockIcon.svg";

interface EstateDetailsUnlockContactModalProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    user: ILogged | null;
    openUnlockContactModal: boolean;
    disableUnlockButton: boolean;
    setOpenUnlockContactModal: (value: boolean) => void;
    setOpenSuccessModal: (value: boolean) => void;
    setDisableUnlockButton: (value: boolean) => void;
}

const EstateDetailsUnlockContactModal = ({
    estate,
    dropdownsMap,
    openUnlockContactModal,
    user,
    setOpenUnlockContactModal,
    setOpenSuccessModal,
    setDisableUnlockButton,
    disableUnlockButton,
}: EstateDetailsUnlockContactModalProps) => {
    if (estate && dropdownsMap) {
        return (
            <StyledUnlockContactModal
                open={openUnlockContactModal}
                className={classes.root}
                onClose={() => {
                    setOpenUnlockContactModal(false);
                }}>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom="30px">
                        <img src={unlockIcon} alt="unlockIcon" className={classes.icons} />
                        <Typography className={classes.modalTitle}>Отключване на контакт</Typography>
                        <IconButton
                            className={classes.icons}
                            onClick={() => {
                                setOpenUnlockContactModal(false);
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Typography className={classes.description} marginBottom="30px">
                        За да имаш достъп до контактите, трябва да отключиш обявата за{" "}
                        <span className={classes.price}>{Cost.Contact} кредитa.</span>
                    </Typography>
                    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            className={classes.acceptButton}
                            disabled={disableUnlockButton}
                            onClick={() => {
                                if (user) {
                                    userService.unlockContact(user.id, estate.id.toString()).then(() => {
                                        setOpenUnlockContactModal(false);
                                        setOpenSuccessModal(true);
                                    });
                                    setDisableUnlockButton(true);
                                }
                            }}>
                            Отключи
                        </Button>
                    </Grid>
                </Paper>
            </StyledUnlockContactModal>
        );
    }
    return null;
};

export default EstateDetailsUnlockContactModal;
