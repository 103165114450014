import { Grid, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { estateDetailsClasses as classes, StyledEstateDetailsOthers } from "./StyledEstateDetailsOthers";
import { DropdownMap } from "../../../App";
import { IEstate } from "../../../interfaces/estate";

interface EstateDetailsOthersProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
}

const EstateDetailsOthers = ({ estate, dropdownsMap }: EstateDetailsOthersProps) => {
    if (estate.is_substation_present && estate.electrical_wire) {
        return (
            <StyledEstateDetailsOthers>
                <Grid container>
                    <Paper className={classes.paperContainer}>
                        <Typography variant={"h6"} className={classes.heading}>
                            Други
                        </Typography>
                        {estate.is_substation_present && (
                            <Grid container marginTop="20px" alignItems={"flex-start"}>
                                <Typography variant={"body1"} className={classes.value}>
                                    <CheckIcon className={`${classes.checkIcon}`} />
                                    Наличие на {estate.personal_substation ? "собствен" : ""} трафопост
                                    {" намиращ се на "}
                                    {estate.substation_distance_in_meters}
                                    {" метра от имота"}
                                </Typography>
                            </Grid>
                        )}

                        {estate.electrical_wire && (
                            <Grid container marginTop="20px" marginBottom="10px" alignItems={"flex-end"}>
                                <Typography component="div" variant={"body1"} className={classes.value}>
                                    <CheckIcon className={classes.checkIcon} />
                                    Наличие на ел. провод с напрежение от{" "}
                                    {dropdownsMap!.voltage[estate.voltage_id]?.name} намиращ се на{" "}
                                    {estate.meters_to_nearest_electricity_pillar} метра от имота
                                </Typography>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            </StyledEstateDetailsOthers>
        );
    }
    return null;
};

export default EstateDetailsOthers;
