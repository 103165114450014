import CancelIcon from "@mui/icons-material/Cancel";
import { FormikErrors } from "formik/dist/types";
import { FormHelperText, Grid, styled, SvgIcon, Typography } from "@mui/material";
import { withMemo } from "./withMemo";
import { SellerProfileState } from "../Seller/SellerProfile/CreateEstate/utils";
import { supportedFormats } from "../Seller/SellerProfile/CreateEstate/schema";
import { TextButton } from "./TextButton/TextButton";
import { primary, secondary } from "./Theme/theme";
import { IAttachment } from "../interfaces/attachment";
import { getOneDocumentEndpoint } from "../endpoints";
import { getAttachmentFile } from "./utils";

const DOCUMENT_UPLOAD_PREFIX = "DocumentUpload";

export const documentUploadClasses = {
    root: `${DOCUMENT_UPLOAD_PREFIX}-root`,
    uploadGatePass: `${DOCUMENT_UPLOAD_PREFIX}-uploadGatePass`,
    gatepassWrapper: `${DOCUMENT_UPLOAD_PREFIX}-gatepassWrapper`,
    buttonContainer: `${DOCUMENT_UPLOAD_PREFIX}-buttonContainer`,
    removeGatepassText: `${DOCUMENT_UPLOAD_PREFIX}-removeGatepassText`,
    helperText: `${DOCUMENT_UPLOAD_PREFIX}-helperText`,
    documentOnServer: `${DOCUMENT_UPLOAD_PREFIX}-documentOnServer`,
    documentFromDevice: `${DOCUMENT_UPLOAD_PREFIX}-documentFromDevice`,
    highlightBorder: `${DOCUMENT_UPLOAD_PREFIX}-highlightBorder`,
};

export const UploadIcon = (props: { viewBox?: string }) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M6.9375 9.5C7.07812 9.5 7.21875 9.45312 7.33594 9.33594C7.42969 9.24219 7.5 9.10156 7.5 8.9375V5H9.5625C9.75 5 9.89062 4.90625 9.98438 4.71875C10.0547 4.53125 10.0312 4.34375 9.89062 4.20312L6.32812 0.640625C6.23438 0.546875 6.11719 0.5 6 0.5C5.85938 0.5 5.76562 0.546875 5.67188 0.640625L2.10938 4.20312C1.96875 4.34375 1.92188 4.53125 2.01562 4.71875C2.08594 4.90625 2.22656 5 2.4375 5H4.5V8.9375C4.5 9.10156 4.54688 9.24219 4.66406 9.33594C4.75781 9.45312 4.89844 9.5 5.0625 9.5H6.9375ZM12 9.3125C12 9.17188 11.9297 9.03125 11.8359 8.91406C11.7188 8.82031 11.5781 8.75 11.4375 8.75H8.25V8.9375C8.25 9.3125 8.10938 9.61719 7.85156 9.875C7.59375 10.1328 7.28906 10.25 6.9375 10.25H5.0625C4.6875 10.25 4.38281 10.1328 4.125 9.875C3.86719 9.61719 3.75 9.3125 3.75 8.9375V8.75H0.5625C0.398438 8.75 0.257812 8.82031 0.164062 8.91406C0.046875 9.03125 0 9.17188 0 9.3125V11.9375C0 12.1016 0.046875 12.2422 0.164062 12.3359C0.257812 12.4531 0.398438 12.5 0.5625 12.5H11.4375C11.5781 12.5 11.7188 12.4531 11.8359 12.3359C11.9297 12.2422 12 12.1016 12 11.9375V9.3125ZM9.09375 11.375C9.09375 11.5156 9.04688 11.6094 8.95312 11.7031C8.85938 11.7969 8.74219 11.8438 8.625 11.8438C8.48438 11.8438 8.39062 11.7969 8.29688 11.7031C8.20312 11.6094 8.15625 11.5156 8.15625 11.375C8.15625 11.2578 8.20312 11.1406 8.29688 11.0469C8.39062 10.9531 8.48438 10.9062 8.625 10.9062C8.74219 10.9062 8.85938 10.9531 8.95312 11.0469C9.04688 11.1406 9.09375 11.2578 9.09375 11.375ZM10.5938 11.375C10.5938 11.5156 10.5469 11.6094 10.4531 11.7031C10.3594 11.7969 10.2422 11.8438 10.125 11.8438C9.98438 11.8438 9.89062 11.7969 9.79688 11.7031C9.70312 11.6094 9.65625 11.5156 9.65625 11.375C9.65625 11.2578 9.70312 11.1406 9.79688 11.0469C9.89062 10.9531 9.98438 10.9062 10.125 10.9062C10.2422 10.9062 10.3594 10.9531 10.4531 11.0469C10.5469 11.1406 10.5938 11.2578 10.5938 11.375Z"
                fill={primary}
            />
        </SvgIcon>
    );
};

const classes = documentUploadClasses;

export const StyledDefaultGatePassUpload = styled(Grid)(({ theme }) => ({
    width: "500px",
    [theme.breakpoints.between("sm", "md")]: {
        width: "40%",
    },
    [`& .${classes.uploadGatePass}`]: {
        backgroundColor: "rgba(60, 17, 127, 0.04)",
        height: "32px",
        padding: "5px 8px",
        lineHeight: "30px",
        borderRadius: "5px",
        width: "215px",
        fontSize: "12px",
        marginTop: "5px",
        "& svg": {
            margin: "0px 0px -7px 7px",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "80%",
            margin: "10px 0px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "100%",
        },
        marginRight: "10px",
    },
    [`& .${classes.highlightBorder}`]: {
        border: `2px solid ${secondary}`,
        boxShadow: `3px 3px ${secondary}`,
    },
    [`& .${classes.documentOnServer}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "19x",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
        [theme.breakpoints.only("xs")]: {
            margin: "10px 0px",
        },
    },
    [`& .${classes.documentFromDevice}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "19x",
    },
}));

const inputStyle = {
    display: "none",
};

const defaultRemoveContent = (
    <span>
        Премахни документ
        <CancelIcon />
    </span>
);

const defaultAddContent = (
    <span>
        Прикачи документ
        <UploadIcon viewBox={"0 0 17 17"} />
    </span>
);

const getDocumentFile = async (documentObject: IAttachment | null | undefined, estateId: number) => {
    if (documentObject) {
        const action = `${getOneDocumentEndpoint}/${estateId}/${documentObject?.id}`;
        getAttachmentFile(action, documentObject);
    }
};

const downloadFile = (file: File) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

const DocumentUpload = (props: {
    name: string;
    documentObjectName: string;
    document?: File | null;
    documentObject?: IAttachment | null;
    active?: boolean;
    highlight?: boolean;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<SellerProfileState>> | Promise<void>;
    helperText: string | undefined;
    addContent?: JSX.Element;
    removeContent?: JSX.Element;
    styledComponent?: any;
    estateId: number;
    isDisabled?: boolean;
}) => {
    const setFieldValue = props.setFieldValue;
    const isActive = props.isDisabled ? false : props.active;

    const StyledGatePassUpload = props.styledComponent || StyledDefaultGatePassUpload;
    const hasDocument = props.document || props.documentObject;

    return (
        <StyledGatePassUpload>
            {hasDocument ? (
                <Grid display={"flex"} flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
                    <TextButton
                        text={defaultRemoveContent}
                        disabled={!isActive}
                        onClick={async () => {
                            await setFieldValue(props.name, null);
                            await setFieldValue(props.documentObjectName, null);
                        }}
                        component={"span"}
                        className={classes.uploadGatePass}
                    />
                    <Typography
                        onClick={() => {
                            if (props.document) {
                                downloadFile(props.document);
                            } else {
                                getDocumentFile(props.documentObject, props.estateId);
                            }
                        }}
                        className={classes.documentOnServer}>
                        {props.document?.name || props.documentObject?.name}
                    </Typography>
                    <FormHelperText>{props.helperText}</FormHelperText>
                </Grid>
            ) : (
                <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <input
                        accept={supportedFormats.join(", ")}
                        style={inputStyle}
                        type="file"
                        disabled={!isActive}
                        id={props.name}
                        name={props.name}
                        onChange={async (event) => {
                            await setFieldValue(props.name, (event.currentTarget as any).files[0]);
                        }}
                    />
                    <label htmlFor={props.name}>
                        <TextButton
                            disabled={!isActive}
                            text={defaultAddContent}
                            component={"span"}
                            className={`${classes.uploadGatePass} ${
                                !hasDocument && props.highlight && props.active ? classes.highlightBorder : ""
                            }`}
                        />
                    </label>
                </Grid>
            )}
        </StyledGatePassUpload>
    );
};

export default withMemo(DocumentUpload, ["document", "highlight", "active", "documentObject", "helperText"]);
