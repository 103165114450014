import { howItWorksClasses as classes, StyledHowItWorks } from "./StyledHowItWorks";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import houseImage from "./house.svg";

export const HowItWorks = () => {
    return (
        <StyledHowItWorks className={classes.root}>
            <Container fixed>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <img src={houseImage} className={classes.image} alt={"House with solar panels"}></img>
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12} className={classes.rightPanel}>
                        <Typography className={classes.heading}>КАКВО ПРЕДСТАВЛЯВА ПЛАТФОРМАТА vei-imot.bg?</Typography>
                        <Typography className={classes.description}>
                            Solar estates представлява удобна и практична специализирана платформа, където
                            интересуващите се от инвестиции във ВЕИ могат лесно и бързо да открият и сравнят множество
                            подходящи парцели за нуждите на изграждане на соларни и вятърни паркове или да открият
                            функциониращи вече проекти обявени за продажба.
                        </Typography>
                        <Typography className={classes.description}>
                            Чрез нашата платформа можете да получите максимално подробна информация за интересуващ ви
                            имот/проект, бързо и изцяло онлайн. Просто задайте критериите, които ви интересуват и нашата
                            търсачка ще ви предложи най-подходящите възможности.
                        </Typography>
                        <Typography className={classes.description}>
                            Solar estates предлага и иновативни решения за инвестиции във ВЕИ под формата на
                            crowdfunding финансиране на ВЕИ проекти. Повече{" "}
                            <a href={"https://vei-imot.bg/invest"}>Tук</a>
                        </Typography>
                        <Typography className={classes.description}>
                            За нашите потребители поддържаме и създаваме информационен портал, чрез който да се
                            информират за новостите в сферата на чистата енергия.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </StyledHowItWorks>
    );
};
