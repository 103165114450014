import { Button, Card, CardActions, CardContent, Grid, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IPlan } from "../../../interfaces/plan";
import { ILogged } from "../../../interfaces/user";
import { TEST_ENV_PAYMENT_URL } from "../../../env";
import { StyledBuyCreditsModal, buyCreditsModalClasses } from "./StyledEstateDetailsBuyCreditsModal";
import { getPlanDiscountPercentage } from "../PlansPaper";
import { Cost } from "../../../common/utils";
import { useState } from "react";
import creditsIcon from "../assets/creditsIcon.svg";
import CustomCheckbox from "../../../common/CustomCheckbox/CustomCheckbox";
import { paymentService } from "../../../services/payment.service";

interface EstateDetailsBuyCreditsModalProps {
    id: string | undefined;
    plans: IPlan[];
    purchasePlanId: number | null;
    paymentProperties: never[];
    user: ILogged | null;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: (value: boolean) => void;
    getPlanTitle: (plan: IPlan) => string;
    getPlanRegularPriceText: (plan: IPlan) => string;
    getPlanDiscountText: (plan: IPlan) => string;
    setPurchasePlanId: (value: number | null) => void;
    setPaymentProperties: (value: []) => void;
}

const getPlanTitlePrice = (plan: IPlan) => {
    return `${plan.price}лв`;
};

const EstateDetailsBuyCreditsModal = ({
    id,
    plans,
    paymentProperties,
    user,
    openBuyCreditsModal,
    purchasePlanId,
    setOpenBuyCreditsModal,
    getPlanTitle,
    getPlanRegularPriceText,
    getPlanDiscountText,
    setPurchasePlanId,
    setPaymentProperties,
}: EstateDetailsBuyCreditsModalProps) => {
    const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
    const [hasRequestedInvoice, setHasRequestedInvoice] = useState(false);

    const handleHasRequestedInvoice = () => {
        setHasRequestedInvoice(!hasRequestedInvoice);
    };

    return (
        <StyledBuyCreditsModal
            open={openBuyCreditsModal}
            className={buyCreditsModalClasses.root}
            onClose={() => {
                setOpenBuyCreditsModal(false);
            }}>
            <Paper className={buyCreditsModalClasses.paper}>
                <Grid
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginBottom="40px">
                    <Grid item>
                        <img src={creditsIcon} className={buyCreditsModalClasses.creditsIcon} alt="Credits Icon" />
                        <Typography className={buyCreditsModalClasses.title}>Купуване на кредити</Typography>
                    </Grid>
                    <IconButton
                        className={buyCreditsModalClasses.closeIcon}
                        onClick={() => {
                            setOpenBuyCreditsModal(false);
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid container flexDirection={"row"} justifyContent={"space-evenly"}>
                    {plans.map((plan) => {
                        return (
                            <form id={`payment-plan-${plan.id}`} action={TEST_ENV_PAYMENT_URL} method={"POST"}>
                                <Card
                                    className={`${buyCreditsModalClasses.planCard} ${
                                        selectedPlan === plan.id ? `${buyCreditsModalClasses.highlightBorder}` : ""
                                    }`}
                                    onClick={() => {
                                        setSelectedPlan(plan.id);
                                        setPurchasePlanId(plan.id);
                                    }}>
                                    <CardContent className={buyCreditsModalClasses.planCardContent}>
                                        <Typography className={buyCreditsModalClasses.planTitle}>
                                            {getPlanTitle(plan)}
                                        </Typography>
                                        {plan.quantity > 1 && getPlanDiscountPercentage(plan) > 0 ? (
                                            <>
                                                <Typography component="div" textAlign="center">
                                                    <span
                                                        className={`${buyCreditsModalClasses.secondaryText}, ${buyCreditsModalClasses.crossOut}`}>
                                                        {getPlanRegularPriceText(plan)}
                                                    </span>
                                                    <Typography
                                                        className={buyCreditsModalClasses.planTitlePrice}
                                                        display="inline-block"
                                                        paddingLeft="14px">
                                                        {getPlanTitlePrice(plan)}
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    className={buyCreditsModalClasses.planDiscountTextContainer}
                                                    marginTop="7px">
                                                    <Typography
                                                        paddingTop="3px"
                                                        className={`${buyCreditsModalClasses.secondaryText}, ${buyCreditsModalClasses.planDiscountText}`}>
                                                        {getPlanDiscountText(plan)}
                                                    </Typography>
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography className={buyCreditsModalClasses.planTitlePrice}>
                                                {getPlanTitlePrice(plan)}
                                            </Typography>
                                        )}
                                    </CardContent>
                                    <CardActions>
                                        {paymentProperties.map((data) => {
                                            return (
                                                <input
                                                    type={"text"}
                                                    value={data[1]}
                                                    className={buyCreditsModalClasses.visibilityOfPayment}
                                                    name={data[0]}
                                                />
                                            );
                                        })}
                                    </CardActions>
                                </Card>
                            </form>
                        );
                    })}
                </Grid>
                <CustomCheckbox
                    className={buyCreditsModalClasses.customCheckBox}
                    value={hasRequestedInvoice}
                    checked={hasRequestedInvoice}
                    onChange={handleHasRequestedInvoice}
                    title={"invoice"}
                    label={"Искам фактура за извършено плащане"}
                    name={"invoice"}
                />
                <Grid className={buyCreditsModalClasses.buttonContainer}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        className={buyCreditsModalClasses.acceptButton}
                        onClick={() => {
                            if (user && purchasePlanId) {
                                paymentService
                                    .buyCredits(purchasePlanId, parseInt(id as any), hasRequestedInvoice)
                                    .then((response: any) => {
                                        const newPaymentProperties = Object.entries(response.data);
                                        setPaymentProperties(newPaymentProperties as any);
                                    });
                            }
                        }}>
                        Купи
                    </Button>
                </Grid>
                <Grid container>
                    <Typography className={buyCreditsModalClasses.description} marginTop={"10px"}>
                        * Цена за отключване на ПРЕМИУМ обява съдържаща описание, прикачени документи и контакти -{" "}
                        {Cost.Document}
                        кредита.
                    </Typography>
                    <Typography className={buyCreditsModalClasses.description} marginTop={"10px"}>
                        * Цена за отключване на СТАНДАРТНА обява съдържаща само описание и контакти- {Cost.Contact}{" "}
                        кредита."
                    </Typography>
                </Grid>
            </Paper>
        </StyledBuyCreditsModal>
    );
};

export default EstateDetailsBuyCreditsModal;
