import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import {
    ColorButton,
    CssTextField,
    forgotPasswordClasses as classes,
    StyledForgotPassword,
} from "./StyledForgotPassword";
import solarEstatesLight from "./assets/solar-estates-light.svg";
import solarEstatesDark from "./assets/solar-estates-dark.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { resetPasswordService } from "../../services/reset-password.service";
import React, { useState } from "react";
import { useGA } from "../../common/hooks";

export const ForgotPassword = () => {
    useGA();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailSentError, setIsEmailSentError] = useState(false);

    const form = useFormik<{
        email: string;
    }>({
        initialValues: {
            email: "",
        },
        validationSchema: yup.object({
            email: yup.string().required("Email e задължителен").email("Email е невалиден"),
        }),
        onSubmit: (values) => {
            resetPasswordService
                .sendResetPasswordEmail(values.email)
                .then(() => {
                    setIsEmailSent(true);
                })
                .catch(() => {
                    setIsEmailSentError(true);
                });
        },
    });

    return (
        <StyledForgotPassword container className={classes.root} flexDirection={"row"}>
            <Grid item lg={5} md={5} xs={12} display={"flex"} flexDirection={"column"} className={classes.leftPanel}>
                <Grid container className={classes.logoContainer}>
                    <img src={solarEstatesLight} className={classes.logoLight} alt="Solar Estates" />
                    <img src={solarEstatesDark} className={classes.logoDark} alt="Solar Estates" />
                </Grid>
            </Grid>
            <Grid
                item
                lg={7}
                md={7}
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                className={classes.rightPanel}>
                {!isEmailSent ? (
                    <Grid container flexDirection={"column"}>
                        <Typography variant={"caption"} color="primary" className={classes.heading}>
                            Смени парола
                        </Typography>
                        <Typography className={classes.welcome}>
                            На вашата поща ще бъде изпратен имейл за смяна на парола
                        </Typography>
                        <Grid item>
                            <CssTextField
                                variant={"outlined"}
                                placeholder="Email"
                                focused
                                InputProps={{
                                    startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                                }}
                                value={form.values.email}
                                onChange={form.handleChange}
                                error={form.touched?.email && !!form.errors?.email}
                                helperText={form.errors?.email}
                                name="email"
                            />
                        </Grid>
                        <ColorButton
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                form.submitForm();
                            }}>
                            <PersonOutlineOutlinedIcon className={classes.buttonIcon} />
                            Изпрати
                        </ColorButton>
                    </Grid>
                ) : (
                    <Grid container>
                        <Typography variant={"h1"}>Email е изпратен на {form.values.email}</Typography>
                    </Grid>
                )}
            </Grid>
            <Snackbar
                open={isEmailSentError}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => setIsEmailSentError(false)}>
                <Alert severity="error" onClose={() => setIsEmailSentError(false)}>
                    Проблем при изпращане на email. Проверете дали използвате валиден email.
                </Alert>
            </Snackbar>
        </StyledForgotPassword>
    );
};
