import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Grid, InputAdornment, TextField } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { FormikErrors, FormikValues } from "formik/dist/types";
import { withMemo } from "../withMemo";
import CancelIcon from "@mui/icons-material/Cancel";
import { GOOGLE_API_KEY } from "../../env";

export interface IAutocompleteAddress {
    description: string;
    matched_substrings: {
        length: number;
        offset: number;
    }[];
    structured_formatting: {
        main_text: string;
        main_text_matched_substrings: {
            length: number;
            offset: number;
        }[];
        secondary_text: string;
    };
}

function loadScript(src: string, position: any, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null, details: null };

const getDefaultAddressOption = (value: string) => ({
    description: value,
    matched_substrings: [{ length: 1, offset: 0 }],
    structured_formatting: {
        main_text: value,
        main_text_matched_substrings: [{ length: 1, offset: 0 }],
        secondary_text: value,
    },
});

const PlacesAutocomplete = (props: {
    name: string;
    placeholder: string;
    value: string;
    startAdornmentIcon?: JSX.Element;
    startAdornmentClass?: string;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
    className: string;
    helperText: React.ReactNode;
    error: boolean;
    onChange: (newInputValue: string) => void;
    sx?: any;
    type?: string;
    disabled?: boolean;
}) => {
    const [value, setValue] = useState<IAutocompleteAddress | string>(
        props.value ? getDefaultAddressOption(props.value) : null!
    );
    const [inputValue, setInputValue] = useState(props.value);
    const [options, setOptions] = useState<any[]>([]);
    const loaded = useRef(false);

    useEffect(() => {
        if (
            typeof window !== "undefined" &&
            !loaded.current &&
            (typeof google !== "object" || typeof google.maps !== "object")
        ) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=bg`,
                document.querySelector("head"),
                "google-maps"
            );
        }
        loaded.current = true;
    }, []);

    const fetchAddress = React.useMemo(
        () =>
            throttle((request, callback) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 300),
        []
    );

    useEffect(() => {
        let active = true;
        if (loaded.current && !autocompleteService.current && window.google) {
            (autocompleteService.current as any) = new window.google.maps.places.AutocompleteService();
        }
        if (loaded.current && !autocompleteService.details) {
            (autocompleteService.details as any) = new window.google.maps.places.PlacesService(
                document.getElementById("places-id") as any
            );
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        fetchAddress(
            {
                input: inputValue,
                componentRestrictions: { country: "bg" },
                types: [props.type || "(cities)"],
            },
            (results: Array<google.maps.places.AutocompletePrediction>) => {
                if (active) {
                    let newOptions: any[] = [];
                    if (value) {
                        newOptions = [value];
                    }
                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                }
            }
        );

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchAddress, props.type]);

    return (
        <>
            {/* NOTE: This is hidden div only for the purpose of satisfying the places api */}
            <div id={"places-id"} />
            <Autocomplete
                id="addressAutoSuggest"
                className={props.className}
                sx={props.sx}
                options={options}
                disabled={props.disabled !== undefined ? props.disabled : false}
                isOptionEqualToValue={(option, value) => option.description === value}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={props.value}
                onChange={(event, selectAddress: google.maps.places.AutocompletePrediction) => {
                    setValue(selectAddress);
                    if (selectAddress) {
                        props.onChange(selectAddress.structured_formatting.main_text);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    // TODO: There is initial event created that is null,
                    // that is why we have null check here. Maybe
                    // investigate how to remove the null check
                    if (event) {
                        props.onChange(newInputValue);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        name={props.name}
                        value={props.value}
                        disabled={props.disabled !== undefined ? props.disabled : false}
                        placeholder={props.placeholder}
                        variant="outlined"
                        helperText={props.helperText}
                        error={props.error}
                        onKeyDown={(event) => {
                            if (event.key === "Backspace" && props.name === "") {
                                props.setFieldValue(props.name, "");
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {props.startAdornmentIcon ? (
                                        <InputAdornment position="start" className={props.startAdornmentClass}>
                                            {props.startAdornmentIcon}
                                        </InputAdornment>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    <CancelIcon
                                        onClick={() => {
                                            const disabled = props.disabled !== undefined ? props.disabled : false;
                                            if (!disabled) {
                                                props.setFieldValue(props.name, "");
                                                setValue(null!);
                                            }
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(props: { id?: string }, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length])
                    );

                    return (
                        <Grid container alignItems="center" key={props.id} {...props}>
                            <Grid item>
                                <LocationOnOutlinedIcon />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </>
    );
};

export default withMemo(PlacesAutocomplete, ["value", "error", "helperText"]);
