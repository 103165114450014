import React from "react";
import { FormControl, FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { withMemo } from "../withMemo";

const CustomSwitch = (props: {
    className: string;
    name: string;
    value: boolean;
    title: string;
    onChange: StandardInputProps["onChange"];
    label: string;
    sx?: any;
    labelPlacement?: FormControlLabelProps["labelPlacement"];
    disabled?: boolean;
}) => {
    return (
        <FormControl component="fieldset" className={props.className} sx={props.sx}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={props.disabled}
                        color="primary"
                        name={props.name}
                        data-automation={props.name}
                        value={props.value}
                        checked={props.value}
                        title={props.title}
                        onChange={props.onChange}
                    />
                }
                label={props.label}
                labelPlacement={props.labelPlacement}
            />
        </FormControl>
    );
};

export default withMemo(CustomSwitch, ["value", "label", "onChange"]);
