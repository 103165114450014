import axios, { AxiosInstance } from "axios";

export class HttpService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            withCredentials: true,
        });
        this.axios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("token");
                if (token) {
                    config.headers!["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    public get(url: string, headers?: Record<string, string>, responseType?: string): Promise<any> {
        return this.axios.get(url, { responseType: responseType as any }).then((result) => result.data);
    }

    public post(url: string, data?: any, headers?: Record<string, string>): Promise<void & number> {
        return this.axios.post(url, data);
    }

    public put(url: string, data?: any, headers?: Record<string, string>): Promise<void> {
        return this.axios.put(url, data);
    }

    public delete(url: string, headers?: Record<string, string>): Promise<void> {
        return this.axios.delete(url, { ...headers });
    }
}

export const httpService = new HttpService();
