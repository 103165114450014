import { Grid, Modal, styled } from "@mui/material";
import { greyLight, info, secondary } from "../../common/Theme/theme";

const VIEW_ESTATES_PREFIX = "ViewEstates";

export const viewEstatesClasses = {
    root: `${VIEW_ESTATES_PREFIX}-root`,
    cardContainer: `${VIEW_ESTATES_PREFIX}-cardContainer`,
    cardActionArea: `${VIEW_ESTATES_PREFIX}-cardActionArea`,
    cardHeading: `${VIEW_ESTATES_PREFIX}-cardHeading`,
    cardProperty: `${VIEW_ESTATES_PREFIX}-cardProperty`,
    cardPropertyIcon: `${VIEW_ESTATES_PREFIX}-cardPropertyIcon`,
    cardLocation: `${VIEW_ESTATES_PREFIX}-cardLocation`,
    cardDateTime: `${VIEW_ESTATES_PREFIX}-cardDateTime`,
    cardPrice: `${VIEW_ESTATES_PREFIX}-cardPrice`,
    cardContent: `${VIEW_ESTATES_PREFIX}-cardContent`,
    cardContentGrid: `${VIEW_ESTATES_PREFIX}-cardContentGrid`,
    cardButton: `${VIEW_ESTATES_PREFIX}-cardButton`,
    cardImage: `${VIEW_ESTATES_PREFIX}-cardImage`,
    cardLabel: `${VIEW_ESTATES_PREFIX}-cardLabel`,
    cardLabelText: `${VIEW_ESTATES_PREFIX}-cardLabelText`,
    cardLike: `${VIEW_ESTATES_PREFIX}-like`,
    cardDelete: `${VIEW_ESTATES_PREFIX}-delete`,
    cardInfo: `${VIEW_ESTATES_PREFIX}-cardInfo`,
    archiveEstateModalBox: `${VIEW_ESTATES_PREFIX}-archiveEstateModalBox`,
    archiveEstateModalSelect: `${VIEW_ESTATES_PREFIX}-archiveEstateModalSelect`,
    archiveEstateModalTextField: `${VIEW_ESTATES_PREFIX}-archiveEstateModalTextField`,
    archiveEstateModalButton: `${VIEW_ESTATES_PREFIX}-archiveEstateModalButton`,
    estatesResultsGrid: `${VIEW_ESTATES_PREFIX}-estatesResultsGrid`,
    cardPromotedContainer: `${VIEW_ESTATES_PREFIX}-cardPromotedContainer`,
};

export const StyledViewEstates = styled(Grid)(({ theme }) => ({
    [`&.${viewEstatesClasses.root}`]: {
        width: "800px",
    },
    [`& .${viewEstatesClasses.cardContainer}`]: {
        [theme.breakpoints.up("md")]: {
            width: "700px",
            marginRight: "30px",
            marginBottom: "30px",
            borderRadius: "8px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "30px",
            marginBottom: "30px",
            borderRadius: "8px",
            width: "700px",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "30px",
            borderRadius: "8px",
            width: "345px",
        },
    },
    [`& .${viewEstatesClasses.cardActionArea}`]: {
        position: "relative",
    },
    [`& .${viewEstatesClasses.cardHeading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        width: "90%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    [`& .${viewEstatesClasses.cardProperty}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        marginRight: "10px",
    },
    [`& .${viewEstatesClasses.cardPropertyIcon}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    },
    [`& .${viewEstatesClasses.cardLocation}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
    },
    [`& .${viewEstatesClasses.cardDateTime}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
        color: greyLight,
    },
    [`& .${viewEstatesClasses.cardPrice}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        marginRight: "20px",
        [theme.breakpoints.only("xs")]: {
            marginTop: "30px",
            marginBottom: "30px",
        },
    },
    [`& .${viewEstatesClasses.cardContent}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
    },
    [`& .${viewEstatesClasses.cardButton}`]: {
        width: "fit-content",
    },
    [`& .${viewEstatesClasses.cardImage}`]: {
        margin: "15px",
        width: "160px",
        height: "140px",
        borderRadius: "8px",
    },
    [`& .${viewEstatesClasses.cardLabel}`]: {
        position: "absolute",
        top: 30,
        left: 30,
        display: "flex",
        alignItems: "center",
        backgroundColor: secondary,
        borderRadius: "4px",
        padding: "5px 10px",
    },
    [`& .${viewEstatesClasses.cardLabelText}`]: {
        color: theme.palette.common.white,
    },
    [`& .${viewEstatesClasses.cardLike}`]: {
        position: "absolute",
        top: 30,
        right: 30,
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        padding: "5px",
        backgroundColor: "#FAFAFA",
    },
    [`& .${viewEstatesClasses.cardDelete}`]: {
        position: "absolute",
        top: 100,
        right: 30,
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        padding: "5px",
        backgroundColor: "#FAFAFA",
    },
    [`& .${viewEstatesClasses.cardInfo}`]: {
        flexDirection: "row",
        alignItems: "center",
    },
    [`& .${viewEstatesClasses.cardContentGrid}`]: {
        width: "70%",
    },
    [`& .${viewEstatesClasses.cardPromotedContainer}`]: {
        position: "absolute",
        bottom: 10,
        left: 35,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
        padding: "5px",
        borderRadius: "5px",
    },
}));

const ARCHIVE_MODAL_PREFIX = "ArchiveModal";

export const archiveModalClasses = {
    box: `${ARCHIVE_MODAL_PREFIX}-box`,
    select: `${ARCHIVE_MODAL_PREFIX}-select`,
    textField: `${ARCHIVE_MODAL_PREFIX}-textField`,
    button: `${ARCHIVE_MODAL_PREFIX}-button`,
    resultsGrid: `${ARCHIVE_MODAL_PREFIX}-resultsGrid`,
};

export const StyledArchiveModal = styled(Modal)(({ theme }) => ({
    [`& .${archiveModalClasses.box}`]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
    },
    [`& .${archiveModalClasses.select}`]: {
        marginBottom: "20px",
    },
    [`& .${archiveModalClasses.textField}`]: {
        marginBottom: "20px",
    },
    [`& .${archiveModalClasses.button}`]: {
        width: "fit-content",
    },
    [`& .${archiveModalClasses.resultsGrid}`]: {
        width: "fit-content",
    },
}));
