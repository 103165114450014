import { Grid, styled } from "@mui/material";
import { grey } from "../../../common/Theme/theme";

const HOW_IT_WORKS_PREFIX = "HowItWorks";

export const howItWorksClasses = {
    root: `${HOW_IT_WORKS_PREFIX}-root`,
    image: `${HOW_IT_WORKS_PREFIX}-image`,
    heading: `${HOW_IT_WORKS_PREFIX}-heading`,
    helpText: `${HOW_IT_WORKS_PREFIX}-helpText`,
    description: `${HOW_IT_WORKS_PREFIX}-description`,
    rightPanel: `${HOW_IT_WORKS_PREFIX}-rightPanel`,
};

export const StyledHowItWorks = styled(Grid)(({ theme }) => ({
    [`&.${howItWorksClasses.root}`]: {},
    [`& .${howItWorksClasses.image}`]: {
        [theme.breakpoints.up("md")]: {
            margin: "0 50px",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    [`& .${howItWorksClasses.heading}`]: {
        fontFamily: "Oswald",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "32px",
        lineHeight: "32px",
        marginBottom: "30px",
    },
    [`& .${howItWorksClasses.helpText}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        marginBottom: "50px",
    },
    [`& .${howItWorksClasses.description}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        opacity: 0.5,
        marginBottom: "30px",
    },
    [`& .${howItWorksClasses.rightPanel}`]: {
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("md")]: {
            margin: "0 20px",
            alignItems: "center",
        },
    },
}));
