import { Button, Grid, Paper, Typography } from "@mui/material";
import { FullHearth } from "../../../common/EstateCard/FullHearth";
import { DocumentCheckbox } from "../DocumentCheckbox";
import { isBuyer } from "../../../common/utils";
import { info, theme } from "../../../common/Theme/theme";
import { DropdownMap } from "../../../App";
import { IEstate } from "../../../interfaces/estate";
import {
    estateDetailsClasses as classes,
    StyledEstateDetailsUpperContainerLeft,
} from "./StyledEstateDetailsUpperContainerLeft";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { ILogged } from "../../../interfaces/user";
import { RegisterModal } from "../../../common/ConfirmModal/RegisterModal";
import { IS_PAYMENT_ENABLED } from "../../../env";

interface EstateDetailsUpperContainerLeftProps {
    user: ILogged | null;
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    showDownloadDocumentsButton: boolean;
    toggleFavourite: (value: any) => Promise<void>;
    getOtherDocumentLabel: () => string;
    setOpenDownloadDocumentsModal: (value: boolean) => void;
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
    handleDownloadButtonClick: () => void;
}

const EstateDetailsUpperContainerLeft = ({
    user,
    estate,
    dropdownsMap,
    showDownloadDocumentsButton,
    toggleFavourite,
    getOtherDocumentLabel,
    setOpenDownloadDocumentsModal,
    isOpened,
    setIsOpened,
    handleDownloadButtonClick,
}: EstateDetailsUpperContainerLeftProps) => {
    const handleDownloadButton = () => {
        if (!IS_PAYMENT_ENABLED) {
            handleDownloadButtonClick();
        } else {
            if (user) {
                setOpenDownloadDocumentsModal(true);
            } else {
                setIsOpened(true);
            }
        }
    };

    if (estate && dropdownsMap) {
        return (
            <StyledEstateDetailsUpperContainerLeft>
                <Grid container flexDirection={"column"} className={classes.upperContainerLeft}>
                    <Grid container flexDirection={"row"} alignItems={"center"} marginBottom={"15px"}>
                        <Typography className={classes.priceTag}>
                            {estate.price}{" "}
                            {estate.currency_id ? dropdownsMap!.currency[estate.currency_id].description : ""}
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={"row"}>
                        <Typography marginRight="30px" className={classes.title}>
                            {estate.title}
                        </Typography>
                        {isBuyer(user!) && estate.favourite ? (
                            <FullHearth onClick={toggleFavourite} className={classes.cursor} />
                        ) : (
                            <></>
                        )}
                        {isBuyer(user!) && !estate.favourite ? (
                            <FavoriteBorderIcon className={classes.cursor} htmlColor={info} onClick={toggleFavourite} />
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid container marginTop="20px">
                        <LocationOnOutlinedIcon htmlColor={theme.palette.common.white} />
                        <Typography className={classes.location}>
                            {dropdownsMap!.countryAreas[estate.country_area_id]?.name}
                            {","}
                        </Typography>
                        <Typography className={classes.location}>
                            {dropdownsMap!.municipality[estate.municipality_id]?.name}
                            {","}
                        </Typography>
                        <Typography className={classes.location}>{estate.city}</Typography>
                    </Grid>
                    <Grid container className={classes.topCheckboxContainer} flexDirection={"column"}>
                        {estate.has_ownership_document && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.ownership_document_attachment}
                                attachmentObject={estate.ownership_document_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Документ за собственост"}></DocumentCheckbox>
                        )}
                        {estate.sketch && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.sketch_attachment}
                                attachmentObject={estate.sketch_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Скица"}></DocumentCheckbox>
                        )}
                        {estate.design_visa && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.design_visa_attachment}
                                attachmentObject={estate.design_visa_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Виза за проектиране"}></DocumentCheckbox>
                        )}
                        {estate.erp_accession_opinion && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.erp_accession_opinion_attachment}
                                attachmentObject={estate.erp_accession_opinion_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Становище за пръсединяване от ЕРП"}></DocumentCheckbox>
                        )}
                        {estate.preliminary_contract_with_erp && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.preliminary_contract_with_erp_attachment}
                                attachmentObject={estate.preliminary_contract_with_erp_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Предварителен договор с ЕРП"}></DocumentCheckbox>
                        )}
                        {estate.official_contract_with_erp && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.official_contract_with_erp_attachment}
                                attachmentObject={estate.official_contract_with_erp_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Договор с ЕРП"}></DocumentCheckbox>
                        )}
                        {estate.pyp && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.pyp_attachment}
                                attachmentObject={estate.pyp_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"ПУП"}></DocumentCheckbox>
                        )}
                        {estate.weight_inquiry && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.weights_inquiry_attachment}
                                attachmentObject={estate.weights_inquiry_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Тежести справка"}></DocumentCheckbox>
                        )}

                        {estate.building_permit && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.building_permit_attachment}
                                attachmentObject={estate.building_permit_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Разрешение за строеж"}></DocumentCheckbox>
                        )}
                        {estate.availability_of_electricity_batch && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.availability_of_electricity_batch_attachment}
                                attachmentObject={estate.availability_of_electricity_batch_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={"Наличие на партида ел. за имота"}></DocumentCheckbox>
                        )}
                        {estate.other_documents && (
                            <DocumentCheckbox
                                estateId={estate.id}
                                attachment={estate.other_documents_attachment}
                                attachmentObject={estate.other_documents_attachment_object}
                                setOpenDocumentsModal={() => {
                                    setOpenDownloadDocumentsModal(true);
                                }}
                                name={getOtherDocumentLabel()}></DocumentCheckbox>
                        )}
                    </Grid>
                    {showDownloadDocumentsButton && (
                        <Button
                            color="secondary"
                            variant="outlined"
                            className={classes.downloadAllFilesButton}
                            onClick={handleDownloadButton}>
                            Изтегли всички
                        </Button>
                    )}
                </Grid>
                <RegisterModal isOpened={isOpened} cancel={() => setIsOpened(false)} />
            </StyledEstateDetailsUpperContainerLeft>
        );
    } else {
        return <></>;
    }
};

export default EstateDetailsUpperContainerLeft;
