// export const baseUrl = "http://localhost:6001"; // FE + BE Development
// export const baseUrl = 'https://vei-imot.bg'; // FE Development
export const baseUrl = `${window.location.protocol}//${window.location.host}`; // Production
// export const baseUrl = "https://solarestate.nolimit.software";

export const dropdownsEndpoint = `${baseUrl}/dropdowns`;
export const loginEndpoint = `${baseUrl}/login`;
export const logoutEndpoint = `${baseUrl}/logout`;
export const estateEndpoint = `${baseUrl}/estate`;
export const attachmentEndpoint = `${baseUrl}/attachments`;
export const documentsEndpoint = `${baseUrl}/documents`;
export const getOneDocumentEndpoint = `${documentsEndpoint}/one`;
export const userEndpoint = `${baseUrl}/user`;
export const buyerEndpoint = `${baseUrl}/buyer`;
export const resetPasswordEndpoint = `${baseUrl}/reset-password`;
export const exportEstatesEndpoint = `${baseUrl}/estate/export/csv`;
export const unpublishEstateEndpoint = `${baseUrl}/estate/change-status/unpublish`;
export const approveEstateEndpoint = `${baseUrl}/estate/change-status/publish`;
export const getByUserIdEndpoint = `${baseUrl}/estate/user`;
export const getPurchasedEstatesByUserIdEndpoint = `${baseUrl}/estate/purchased`;
export const getUnderReviewEndpoint = `${baseUrl}/estate/under-review`;
export const investEndpoint = `${baseUrl}/invest`;
export const investCSVEndpoint = `${baseUrl}/invest-csv`;
export const paymentEndpoint = `${baseUrl}/payment`;

export const getImageLink = (link: string) => {
    if ((baseUrl as any) !== "https://vei-imot.bg" && link.includes("https://vei-imot.bg")) {
        return link.replace("https://vei-imot.bg", baseUrl);
    } else if (
        (baseUrl as any) !== "https://solarestate.nolimit.software" &&
        link.includes("https://solarestate.nolimit.software")
    ) {
        return link.replace("https://solarestate.nolimit.software", baseUrl);
    } else {
        return link;
    }
};
