import { headerClasses, StyledHeader } from "./StyledHeader";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { customNavigate, links, NavigationRoutes, pages } from "../../routes";
import { useNavigate } from "react-router-dom";
import solarEstateLogo from "./icons/solar-estate-logo.svg";
import { UsersButtonsSection } from "../UsersButtonsSection/UsersButtonsSection";

export const Header = () => {
    let navigate = useNavigate();

    return (
        <StyledHeader>
            <AppBar position="sticky" className={headerClasses.appBarLight}>
                <Container maxWidth={"lg"} className={headerClasses.container}>
                    <Toolbar disableGutters component={Grid}>
                        <Grid container>
                            <Button
                                onClick={() => {
                                    navigate(`/${NavigationRoutes.Home}`);
                                }}>
                                <img className={headerClasses.logo} src={solarEstateLogo} alt={"Solar Estate Logo"} />
                            </Button>
                        </Grid>
                        <Box className={headerClasses.box}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page}
                                    className={
                                        page === "Енергийна Общност"
                                            ? headerClasses.navigationInvest
                                            : headerClasses.navigation
                                    }
                                    onClick={() => {
                                        customNavigate(navigate, links[index]);
                                    }}>
                                    <Typography className={headerClasses.pageLinkText}> {page} </Typography>
                                </Button>
                            ))}
                        </Box>
                        <UsersButtonsSection />
                    </Toolbar>
                </Container>
            </AppBar>
        </StyledHeader>
    );
};
