import { Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, secondary } from "../../common/Theme/theme";
import background from "./background.png";

const ESTATE_DETAILS_PREFIX = "EstateDetails";

export const estateDetailsClasses = {
    root: `${ESTATE_DETAILS_PREFIX}-root`,
    checkboxLabel: `${ESTATE_DETAILS_PREFIX}-checkboxLabel`,
    wizard: `${ESTATE_DETAILS_PREFIX}-wizard`,
    dropdown: `${ESTATE_DETAILS_PREFIX}-dropdown`,
    messageContainer: `${ESTATE_DETAILS_PREFIX}-messageContainer`,
    topCheckboxSingle: `${ESTATE_DETAILS_PREFIX}-topCheckboxSingle`,
    checkIconAvailableDocuments: `${ESTATE_DETAILS_PREFIX}-checkIconAvailableDocuments`,
    inputContainer: `${ESTATE_DETAILS_PREFIX}-inputContainer`,
    dropFilesLabel: `${ESTATE_DETAILS_PREFIX}-dropFilesLabel`,
    iconAlign: `${ESTATE_DETAILS_PREFIX}-iconAlign`,
    attachmentContainer: `${ESTATE_DETAILS_PREFIX}-attachmentContainer`,
    cityAutocomplete: `${ESTATE_DETAILS_PREFIX}-cityAutocomplete`,
    addressAutocomplete: `${ESTATE_DETAILS_PREFIX}-addressAutocomplete`,
    generatingCapacity: `${ESTATE_DETAILS_PREFIX}-generatingCapacity`,
    priceArea: `${ESTATE_DETAILS_PREFIX}-priceArea`,
    personalSubstation: `${ESTATE_DETAILS_PREFIX}-personalSubstation`,
    checkText: `${ESTATE_DETAILS_PREFIX}-checkText`,
    headerPriceArea: `${ESTATE_DETAILS_PREFIX}-headerPriceArea`,
    upperContainer: `${ESTATE_DETAILS_PREFIX}-upperContainer`,
    galleryPropertyContainer: `${ESTATE_DETAILS_PREFIX}-galleryPropertyContainer`,
    activeFor: `${ESTATE_DETAILS_PREFIX}-activeFor`,
    availableDocuments: `${ESTATE_DETAILS_PREFIX}-availableDocuments`,
    availableDocumentsHover: `${ESTATE_DETAILS_PREFIX}-availableDocumentsHover`,
    documentsLowerContainer: `${ESTATE_DETAILS_PREFIX}-documentsLowerContainer`,
    documentsPaperSingle: `${ESTATE_DETAILS_PREFIX}-documentsPaperSingle`,
    iconButtonDownloadDocument: `${ESTATE_DETAILS_PREFIX}-iconButtonDownloadDocument`,
    checkIcon: `${ESTATE_DETAILS_PREFIX}-checkIcon`,
    heading: `${ESTATE_DETAILS_PREFIX}-heading`,
    label: `${ESTATE_DETAILS_PREFIX}-label`,
    value: `${ESTATE_DETAILS_PREFIX}-value`,
};

export const StyledEstateDetails = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`&.${estateDetailsClasses.root}`]: {},
    [`& .${estateDetailsClasses.wizard}`]: {
        padding: "20px 0",
        justifyContent: "flex-end",
    },
    [`& .${estateDetailsClasses.dropdown}`]: {
        [`& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart`]:
            {
                color: "#9BA4AB",
            },
    },
    [`& .${estateDetailsClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${estateDetailsClasses.topCheckboxSingle}`]: {
        height: "40px",
        width: "55%",
        alignItems: "center",
        [theme.breakpoints.only("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    [`& .${estateDetailsClasses.iconButtonDownloadDocument}`]: {
        padding: "0",
    },
    [`& .${estateDetailsClasses.documentsPaperSingle}`]: {
        alignItems: "center",
    },
    [`& .${estateDetailsClasses.upperContainer}`]: {
        position: "relative",
        minHeight: "500px",
        width: "100%",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        [theme.breakpoints.only("md")]: {
            minHeight: "480px",
        },
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
            justifyContent: "space-between",
        },
        [theme.breakpoints.only("sm")]: {
            flexDirection: "column",
            justifyContent: "space-between",
        },
    },
    [`& .${estateDetailsClasses.checkText}`]: {
        color: "#9BA4AB",
        marginBottom: "10px",
    },
    [`& .${estateDetailsClasses.personalSubstation}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "220px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${estateDetailsClasses.messageContainer}`]: {
        padding: "50px",
        width: "100%",
        justifyContent: "center",
    },
    [`& .${estateDetailsClasses.checkIconAvailableDocuments}`]: {
        color: secondary,
        marginRight: "10px",
    },
    [`& .${estateDetailsClasses.inputContainer}`]: {
        width: "100%",
        margin: "20px 0",
    },
    [`& .${estateDetailsClasses.dropFilesLabel}`]: {
        border: `2px dashed #A5AFB9`,
        fontFamily: "Roboto, serif",
        width: "100%",
        borderRadius: "5px",
        padding: "20px",
        fontSize: "15px",
        color: "#A5AFB9",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            width: "88%",
            marginLeft: "5%",
        },
        [theme.breakpoints.only("sm")]: {
            width: "100%",
        },
    },
    [`& .${estateDetailsClasses.attachmentContainer}`]: {
        display: "flex",
        justifyContent: "space-between",
    },
    [`& .${estateDetailsClasses.cityAutocomplete}`]: {
        width: "100%",
        fontSize: "16px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${estateDetailsClasses.addressAutocomplete}`]: {
        marginTop: "20px",
        width: "300px",
        height: "90px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${estateDetailsClasses.generatingCapacity}`]: {
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${estateDetailsClasses.priceArea}`]: {
        width: "100px",
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${estateDetailsClasses.headerPriceArea}`]: {
        width: "100px",
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${estateDetailsClasses.galleryPropertyContainer}`]: {
        marginRight: "20px",
        borderRadius: "100%",
    },
    [`& .${estateDetailsClasses.activeFor}`]: {
        color: "white",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        marginRight: "20px",
    },
    [`& .${estateDetailsClasses.availableDocuments}`]: {
        color: "white",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19x",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "300px",
        display: "inline",
        whiteSpace: "nowrap",
    },
    [`& .${estateDetailsClasses.availableDocumentsHover}`]: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    [`& .${estateDetailsClasses.documentsLowerContainer}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.checkIcon}`]: {
        color: secondary,
    },
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.label}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
}));
