import { Grid, Typography } from "@mui/material";
import { StyledNewLocationsSection, newLocationsSectionClasses as classes } from "./StyledNewLocationsSection";

import { useEffect, useState } from "react";
import { IEstate } from "../../../interfaces/estate";
import { estateService } from "../../../services/estate.service";
import { EstateCard } from "../../../common/EstateCard/EstateCard";

export const NewLocationsSection = () => {
    const [estates, setEstates] = useState<IEstate[]>([]);

    const fetchLatest = () => {
        estateService.getLatest().then(setEstates);
    };

    useEffect(() => {
        fetchLatest();
    }, []);

    return (
        <StyledNewLocationsSection>
            <Grid container className={classes.innerContainer} flexDirection={"column"}>
                <Typography className={classes.heading}>Топ обяви</Typography>
                <Grid container flexDirection={"row"} justifyContent={"center"} className={classes.cardsContainer}>
                    {estates.map((estate) => (
                        <EstateCard key={estate.id} estate={estate} onToggleFavourite={fetchLatest} />
                    ))}
                </Grid>
            </Grid>
        </StyledNewLocationsSection>
    );
};
