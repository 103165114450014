import { Alert, Button, Grid, IconButton, Paper, Snackbar, Typography } from "@mui/material";
import { userService } from "../../../services/user.service";
import CloseIcon from "@mui/icons-material/Close";
import { StyledUnlockEstateModal, unlockEstateModalClasses } from "./StyledEstateDetailsUnlockEstateModal";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";
import { ILogged } from "../../../interfaces/user";
import { Cost } from "../../../common/utils";
import React, { useState } from "react";
import unlockIcon from "../assets/unlockIcon.svg";

interface EstateDetailsUnlockEstateModal {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    user: ILogged | null;
    openUnlockEstateModal: boolean;
    disableUnlockButton: boolean;
    setOpenUnlockEstateModal: (value: boolean) => void;
    setOpenSuccessModal: (value: boolean) => void;
    setDisableUnlockButton: (value: boolean) => void;
}

const EstateDetailsUnlockEstateModal = ({
    estate,
    dropdownsMap,
    openUnlockEstateModal,
    user,
    setOpenUnlockEstateModal,
    setOpenSuccessModal,
    setDisableUnlockButton,
    disableUnlockButton,
}: EstateDetailsUnlockEstateModal) => {
    const [error, setError] = useState(false);
    if (estate && dropdownsMap) {
        return (
            <StyledUnlockEstateModal
                open={openUnlockEstateModal}
                className={unlockEstateModalClasses.root}
                onClose={() => {
                    setOpenUnlockEstateModal(false);
                }}>
                <Paper className={unlockEstateModalClasses.paper}>
                    <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom="30px">
                        <img src={unlockIcon} alt="unlockIcon" className={unlockEstateModalClasses.icons} />
                        <Typography className={unlockEstateModalClasses.modalTitle}>Отключване на обявата</Typography>
                        <IconButton
                            className={unlockEstateModalClasses.icons}
                            onClick={() => {
                                setOpenUnlockEstateModal(false);
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Typography className={unlockEstateModalClasses.description} marginBottom="30px">
                        За да имаш достъп до документите, трябва да отключиш обявата за{" "}
                        <span className={unlockEstateModalClasses.price}>{Cost.Document} кредитa.</span>
                    </Typography>
                    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            className={unlockEstateModalClasses.acceptButton}
                            disabled={disableUnlockButton}
                            onClick={() => {
                                if (user) {
                                    userService
                                        .unlockEstate(user.id, estate.id.toString())
                                        .then(() => {
                                            setOpenUnlockEstateModal(false);
                                            setOpenSuccessModal(true);
                                        })
                                        .catch(() => {
                                            setError(true);
                                        });
                                    setDisableUnlockButton(true);
                                }
                            }}>
                            Отключи
                        </Button>
                    </Grid>
                    <Snackbar
                        open={error}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={() => setError(false)}>
                        <Alert severity="error" onClose={() => setError(false)}>
                            Грешка при отключване на обявата! Проверете наличието на достатъчно кредити.
                        </Alert>
                    </Snackbar>
                </Paper>
            </StyledUnlockEstateModal>
        );
    } else {
        return <></>;
    }
};

export default EstateDetailsUnlockEstateModal;
