import { Container, Grid, Modal, styled } from "@mui/material";
import { primary, secondary } from "../../common/Theme/theme";

const HOME_PREFIX = "Home";

export const homeClasses = {
    root: `${HOME_PREFIX}-root`,
    mapSection: `${HOME_PREFIX}-mapSection`,
    rightPanel: `${HOME_PREFIX}-rightPanel`,
    heading: `${HOME_PREFIX}-heading`,
    text: `${HOME_PREFIX}-text`,
    filterContainer: `${HOME_PREFIX}-filterContainer`,
    spanPrimary: `${HOME_PREFIX}-spanPrimary`,
    spanSecondary: `${HOME_PREFIX}-spanSecondary`,
    map: `${HOME_PREFIX}-map`,
};

export const StyledHome = styled(Container)(({ theme }) => ({
    [`&.${homeClasses.root}`]: {
        width: "100%",
        alignItems: "center",
    },
    [`& .${homeClasses.mapSection}`]: {
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            height: "400px",
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    [` &.${homeClasses.map}`]: {
        height: "400px",
    },
    [`& .${homeClasses.rightPanel}`]: {
        padding: "50px 50px",
        flexDirection: "column",
    },
    [`& .${homeClasses.heading}`]: {
        fontFamily: "Oswald",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "40px",
        lineHeight: "48px",
        marginBottom: "30px",
    },
    [`& .${homeClasses.text}`]: {
        ontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        opacity: 0.5,
        marginBottom: "30px",
    },
    [`& .${homeClasses.filterContainer}`]: {
        [theme.breakpoints.only("sm")]: {
            marginTop: "20px",
        },
    },
    [`& .${homeClasses.spanPrimary}`]: {
        color: primary,
    },
    [`& .${homeClasses.spanSecondary}`]: {
        color: secondary,
    },
}));

const NEW_FEATURE_MODAL_PREFIX = "NewFeatureModal";

export const newFeatureModalClasses = {
    box: `${NEW_FEATURE_MODAL_PREFIX}-box`,
    select: `${NEW_FEATURE_MODAL_PREFIX}-select`,
    textField: `${NEW_FEATURE_MODAL_PREFIX}-textField`,
    button: `${NEW_FEATURE_MODAL_PREFIX}-button`,
    resultsGrid: `${NEW_FEATURE_MODAL_PREFIX}-resultsGrid`,
    paper: `${NEW_FEATURE_MODAL_PREFIX}-paper`,
    heading: `${NEW_FEATURE_MODAL_PREFIX}-heading`,
    message: `${NEW_FEATURE_MODAL_PREFIX}-message`,
};

export const StyledNewFeatureModal = styled(Modal)(({ theme }) => ({
    [`& .${newFeatureModalClasses.box}`]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
    },
    [`& .${newFeatureModalClasses.select}`]: {
        marginBottom: "20px",
    },
    [`& .${newFeatureModalClasses.textField}`]: {
        marginBottom: "20px",
    },
    [`& .${newFeatureModalClasses.button}`]: {
        width: "fit-content",
    },
    [`& .${newFeatureModalClasses.resultsGrid}`]: {
        width: "fit-content",
    },
    [`& .${newFeatureModalClasses.paper}`]: {
        padding: "20px",
    },
    [`& .${newFeatureModalClasses.heading}`]: {
        marginBottom: "10px",
    },
    [`& .${newFeatureModalClasses.message}`]: {
        marginBottom: "20px",
    },
}));
