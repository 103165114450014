import { Grid, styled } from "@mui/material";
import { greyDark } from "../../../../../common/Theme/theme";

const CREATE_ESTATE_FORM_ADD_DOCUMENTS_PREFIX = "CreateEstateFormAddDocuments";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_ADD_DOCUMENTS_PREFIX}-checkboxLabel`,
    paperContainer: `${CREATE_ESTATE_FORM_ADD_DOCUMENTS_PREFIX}-paperContainer`,
    customTextInput: `${CREATE_ESTATE_FORM_ADD_DOCUMENTS_PREFIX}-customTextInput`,
    linkToUploadImages: `${CREATE_ESTATE_FORM_ADD_DOCUMENTS_PREFIX}-linkToUploadImages`,
};

export const StyledCreateEstateFormAddDocuments = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
    [`& .${createEstateClasses.customTextInput}`]: {
        width: "300px",
        height: "90px",
        marginTop: "10px",
    },
    [`& .${createEstateClasses.linkToUploadImages}`]: {
        color: greyDark,
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));
