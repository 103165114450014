import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Slider,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomCheckbox from "../../common/CustomCheckbox/CustomCheckbox";
import Dropdown from "../../common/Dropdown/Dropdown";
import { defaultEstatePageSize, estateService } from "../../services/estate.service";
import { adminUserClasses as classes } from "./StyledAdminUser";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import { formatDate } from "../../common/hooks";
import { GlobalContext } from "../../App";
import { NavigationRoutes } from "../../routes";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import { EstateStatus, EstateUnpublishReason } from "../../interfaces/estate";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { StyledArchiveModal, archiveModalClasses } from "../../Seller/SellerProfile/StyledViewEstates";

const defaultFilters = {
    // size_start: 0,
    // size_end: 1000000,
    price_start: 0,
    price_end: 1000000,
    // country_area_id: 0,
    // offer_type_id: 0,
    estate_type_id: 0,
    sort: "createdAt",
    direction: "DESC",
    title: "",
    status: EstateStatus.UnderReview,
};

const sliderMarks = [
    {
        value: 0,
        label: "0 BGN",
    },
    {
        value: 1000000,
        label: "1M BGN",
    },
];

const columns = [
    { id: "id", name: "Реф. номер" },
    { id: "email", name: "Email" },
    { id: "title", name: "Заглавие" },
    { id: "description", name: "Описание" },
    { id: "createdAt", name: "Дата на създаване" },
    { id: "price", name: "Цена" },
    { id: "area", name: "Площ" },
    { id: "promoted", name: "Промотирана" },
];

const directions = [
    { id: "ASC", name: "Възходящ ред" },
    { id: "DESC", name: "Низходящ ред" },
];

const statuses = [
    { id: EstateStatus.UnderReview, name: "В процес на преразглеждане" },
    { id: EstateStatus.Published, name: "Публикувана" },
    { id: EstateStatus.Unpublished, name: "Свалена" },
];

export const EstateTabPanel = () => {
    const [estateFilters, setEstateFilters] = useState<{
        estate_type_id: number;
        price_start: number;
        price_end: number;
        sort: string;
        direction: string;
        title: string;
        status: string;
    }>({ ...defaultFilters });
    const [estates, setEstates] = useState<any[]>([]);
    const [estatesPage, setEstatesPage] = useState<number>(0);
    const [estatesCount, setEstatesCount] = useState<number>(0);
    const [overMillionChecked, setOverMillionChecked] = useState(false);
    const [estateForDeletion, setEstateForDeletion] = useState<any>();
    const [disableExportButton, setDisableExportButton] = useState(false);
    const [reason, setReason] = useState("");
    const [description, setDescription] = useState("");
    const { dropdowns } = useContext(GlobalContext);
    const { dropdownsMap } = useContext(GlobalContext);
    const [disableButton, setDisableButton] = useState(false);

    const fetchEstates = () => {
        estateService.getAll({ ...estateFilters, page: estatesPage } as any).then(({ count, rows }) => {
            setEstates(rows);
            setEstatesCount(Math.ceil(count / defaultEstatePageSize));
        });
    };

    useEffect(() => {
        fetchEstates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estatesPage, estateFilters]);

    const handleOverMillionToggle = (_event: any, checked: boolean) => {
        if (checked) {
            setEstateFilters({
                ...estateFilters,
                price_start: 1000000,
                price_end: 40000000,
            });
        } else {
            setEstateFilters({
                ...estateFilters,
                price_start: 0,
                price_end: 1000000,
            });
        }
        setOverMillionChecked(checked);
    };

    const deleteEstate = async (id: string) => {
        estateService
            .unpublish(parseInt(id), reason, description)
            .then(() => {
                fetchEstates();
                setEstateForDeletion(null);
                setReason("");
                setDescription("");
            })
            .finally(() => {
                setDisableButton(false);
            });
    };

    const approveEstate = async (id: string) => {
        estateService.approve(id).then(() => {
            fetchEstates();
        });
    };

    const downloadEstatesReport = async () => {
        setDisableExportButton(true);

        const response = await estateService.exportEstates();
        const data = response.data;
        const date = new Date();

        let element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
        element.setAttribute("download", `estates-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.csv`);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        setDisableExportButton(false);
    };

    if (!dropdownsMap) {
        return null;
    }

    const handlePromoting = (event: any, id: string) => {
        if (event.target.checked) {
            estateService.promote(id).then(() => {
                fetchEstates();
            });
        } else {
            estateService.demote(id).then(() => {
                fetchEstates();
            });
        }
    };

    return (
        <>
            <Grid container justifyContent={"center"} width="100%">
                <Grid container marginBottom={"30px"} alignItems={"center"}>
                    <Grid
                        container
                        sx={{ width: "35%" }}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <Slider
                            value={[estateFilters.price_start, estateFilters.price_end]}
                            max={1000000}
                            min={0}
                            marks={sliderMarks}
                            onChange={(event, value) => {
                                const newValue = value as number[];
                                setEstateFilters({
                                    ...estateFilters,
                                    price_start: newValue[0],
                                    price_end: newValue[1],
                                });
                            }}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => <div>{value / 1000 + "k BGN"}</div>}
                            sx={{ width: 200 }}
                            className={classes.slider}
                        />
                        <CustomCheckbox
                            className={classes.overMillionCheckbox}
                            value={overMillionChecked}
                            checked={overMillionChecked}
                            onChange={handleOverMillionToggle as any}
                            title={"Над милион"}
                            label={"Над милион"}
                        />
                    </Grid>
                    <Grid container sx={{ width: "22%" }}>
                        <Dropdown
                            label="Тип имот"
                            className={classes.select}
                            startAdornment={<LandscapeOutlinedIcon />}
                            options={[{ id: 0, name: "Избери тип" }, ...dropdowns.estateTypes]}
                            value={estateFilters.estate_type_id}
                            onChange={(event) => {
                                setEstateFilters({
                                    ...estateFilters,
                                    estate_type_id: parseInt(event.target.value),
                                });
                            }}
                            name={""}
                        />
                    </Grid>
                    <Grid container className={classes.titleInputContainer}>
                        <TextField
                            className={classes.titleInput}
                            value={estateFilters.title}
                            onChange={(event) => {
                                setEstateFilters({
                                    ...estateFilters,
                                    title: event.target.value,
                                });
                            }}
                            label="Заглавие"
                            variant="outlined"
                        />
                    </Grid>
                    <Dropdown
                        label="Статус"
                        className={classes.select}
                        options={statuses}
                        value={estateFilters.status}
                        onChange={(event) => {
                            setEstateFilters({
                                ...estateFilters,
                                status: event.target.value,
                            });
                        }}
                        name={""}
                    />
                    <Dropdown
                        label="Сортирай по"
                        className={classes.select}
                        options={columns}
                        value={estateFilters.sort}
                        onChange={(event) => {
                            setEstateFilters({
                                ...estateFilters,
                                sort: event.target.value,
                            });
                        }}
                        name={""}
                    />
                    <Dropdown
                        label="Посока"
                        className={classes.select}
                        options={directions}
                        value={estateFilters.direction}
                        onChange={(event) => {
                            setEstateFilters({
                                ...estateFilters,
                                direction: event.target.value,
                            });
                        }}
                        name={""}
                    />
                    <Button
                        onClick={downloadEstatesReport}
                        variant="outlined"
                        className={classes.downloadEstatesButton}
                        disabled={disableExportButton}>
                        Изтегли всички имоти
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Референтен номер</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Телефон</TableCell>
                            <TableCell align="left">Заглавие</TableCell>
                            <TableCell align="left">Описание</TableCell>
                            <TableCell align="left">Дата на създаване</TableCell>
                            <TableCell align="left">Цена</TableCell>
                            <TableCell align="left">Площ</TableCell>
                            <TableCell align="left">Промотирана</TableCell>
                            <TableCell align="center">Виж</TableCell>
                            {estateFilters.status === EstateStatus.UnderReview && (
                                <TableCell align="center">Одобри</TableCell>
                            )}
                            <TableCell align="center">Редактирай</TableCell>
                            <TableCell align="center">Архивирай</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {estates.map((estate) => (
                            <TableRow
                                key={estate.id}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    height: "80px",
                                }}>
                                <TableCell align="left">{estate.id}</TableCell>
                                <TableCell align="left">{estate.user_email}</TableCell>
                                <TableCell align="left">{estate.user_phone}</TableCell>
                                <TableCell align="left">{estate.title}</TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        display: "block",
                                        width: "440px",
                                        height: "80px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}>
                                    {estate.description}
                                </TableCell>
                                <TableCell align="left">
                                    {estate.createdAt ? formatDate(new Date(estate.createdAt)) : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {estate.price}{" "}
                                    {estate.currency_id ? dropdownsMap!.currency[estate.currency_id].description : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {estate.area}{" "}
                                    {estate.area_unit_id ? dropdownsMap!.areaUnits[estate.area_unit_id].name : ""}
                                </TableCell>
                                <TableCell align="right">
                                    <Switch
                                        checked={estate.promoted}
                                        onChange={(event) => handlePromoting(event, estate.id)}></Switch>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            const newWindow = window.open(
                                                `/${NavigationRoutes.EstateDetails}/${estate.id}`,
                                                "_blank",
                                                "noopener,noreferrer"
                                            );
                                            if (newWindow) {
                                                newWindow.opener = null;
                                            }
                                        }}>
                                        Виж
                                    </Button>
                                </TableCell>
                                {estateFilters.status === EstateStatus.UnderReview && (
                                    <TableCell align="right">
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                approveEstate(estate.id);
                                            }}>
                                            <CheckOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                                <TableCell align="right">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            const newWindow = window.open(
                                                `/${NavigationRoutes.EditEstate}/${estate.id}`,
                                                "_blank",
                                                "noopener,noreferrer"
                                            );
                                            if (newWindow) {
                                                newWindow.opener = null;
                                            }
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setEstateForDeletion(estate.id);
                                        }}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent={"center"} className={classes.paginationContainer}>
                <Pagination
                    count={estatesCount}
                    color="secondary"
                    onChange={(_event, innerPage) => {
                        setEstatesPage(innerPage);
                    }}
                />
            </Grid>
            <StyledArchiveModal
                open={!!estateForDeletion}
                onClose={() => {
                    setEstateForDeletion(null);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className={archiveModalClasses.box}>
                    <Paper sx={{ padding: "20px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"20px"}>
                            Архивирай обява
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Причина</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                label="Причина"
                                className={archiveModalClasses.select}
                                onChange={(e) => {
                                    setReason(e.target.value);
                                }}>
                                <MenuItem value={EstateUnpublishReason.Sold}>Продадено</MenuItem>
                                <MenuItem value={EstateUnpublishReason.StopSell}>Спряна продажба</MenuItem>
                                <MenuItem value={EstateUnpublishReason.Other}>Друго</MenuItem>
                            </Select>
                            {reason === EstateUnpublishReason.Other && (
                                <TextField
                                    value={description}
                                    multiline
                                    minRows={10}
                                    maxRows={20}
                                    fullWidth={true}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                    label="Описание"
                                    className={archiveModalClasses.textField}
                                />
                            )}
                            <Button
                                variant="outlined"
                                disabled={disableButton}
                                className={archiveModalClasses.button}
                                onClick={() => {
                                    deleteEstate(estateForDeletion);
                                    setDisableButton(true);
                                }}>
                                Архивирай
                            </Button>
                        </FormControl>
                    </Paper>
                </Box>
            </StyledArchiveModal>
        </>
    );
};
