import { StyledUsersButtonsSection, usersButtonsSectionClasses as classes } from "./StyledUsersButtonsSection";
import { Button, Typography } from "@mui/material";
import { useContext } from "react";
import { customNavigate, NavigationRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { isAdmin, isBuyer, isLoggedIn, isSeller } from "../../common/utils";
import { GlobalContext } from "../../App";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddIcon from "@mui/icons-material/Add";
import { loginService } from "../../services/auth.service";

export const UsersButtonsSection = () => {
    let navigate = useNavigate();
    const { user, setUser } = useContext(GlobalContext);

    const logout = () => {
        loginService.logout().then(() => {
            setUser(null);
            navigate(`/${NavigationRoutes.Home}`);
            localStorage.setItem("token", "");
        });
    };

    return (
        <StyledUsersButtonsSection className={classes.root}>
            {!isLoggedIn(user!) ? (
                <>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<PersonIcon />}
                        onClick={() => {
                            customNavigate(navigate, NavigationRoutes.Login);
                        }}>
                        <Typography className={classes.buttonText}>Вход</Typography>
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            customNavigate(navigate, NavigationRoutes.Login);
                        }}
                        startIcon={<PersonAddAlt1Icon />}>
                        <Typography className={classes.buttonText}>Регистрация</Typography>
                    </Button>
                </>
            ) : (
                <></>
            )}
            {isSeller(user!) ? (
                <>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<PersonIcon />}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.SellerProfile}/${user!.id}`);
                        }}>
                        <Typography className={classes.buttonText}>Профил</Typography>
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="secondary"
                        sx={{ width: "170px" }}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.CreateEstate}`);
                        }}
                        startIcon={<AddIcon />}>
                        <Typography className={classes.buttonText}>Създай обява</Typography>
                    </Button>
                    <Button className={classes.logoutButton} onClick={logout}>
                        <Typography className={classes.buttonText}>Изход</Typography>
                    </Button>
                </>
            ) : (
                <></>
            )}
            {isBuyer(user!) ? (
                <>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<PersonIcon />}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.BuyerProfile}/${user!.id}`);
                        }}>
                        <Typography className={classes.buttonText}>Профил</Typography>
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="secondary"
                        sx={{ width: "170px" }}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.CreateEstate}`);
                        }}
                        startIcon={<AddIcon />}>
                        <Typography className={classes.buttonText}>Създай обява</Typography>
                    </Button>
                    <Button className={classes.logoutButton} onClick={logout}>
                        <Typography className={classes.buttonText}>Изход</Typography>
                    </Button>
                </>
            ) : (
                <></>
            )}
            {isAdmin(user!) ? (
                <>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<PersonIcon />}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.AdminUser}`);
                        }}>
                        <Typography className={classes.buttonText}>Админ</Typography>
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="secondary"
                        sx={{ width: "170px" }}
                        onClick={() => {
                            navigate(`/${NavigationRoutes.CreateEstate}`);
                        }}
                        startIcon={<AddIcon />}>
                        <Typography className={classes.buttonText}>Създай обява</Typography>
                    </Button>
                    <Button className={classes.logoutButton} onClick={logout}>
                        <Typography className={classes.buttonText}>Изход</Typography>
                    </Button>
                </>
            ) : (
                <></>
            )}
        </StyledUsersButtonsSection>
    );
};
