import { Box, styled } from "@mui/material";

const USERS_BUTTONS_SECTION = "UsersButtonsSection";

export const usersButtonsSectionClasses = {
    root: `${USERS_BUTTONS_SECTION}-root`,
    button: `${USERS_BUTTONS_SECTION}-button`,
    logoutButton: `${USERS_BUTTONS_SECTION}-logoutButton`,
    buttonText: `${USERS_BUTTONS_SECTION}-buttonText`,
};

export const StyledUsersButtonsSection = styled(Box)(({ theme }) => ({
    [`&.${usersButtonsSectionClasses.root}`]: {
        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "row",
            marginRight: "10px",
        },
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    [`& .${usersButtonsSectionClasses.button}`]: {
        textTransform: "capitalize",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: "100px",
        margin: "10px",
    },
    [`& .${usersButtonsSectionClasses.logoutButton}`]: {
        textTransform: "capitalize",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        boxSizing: "border-box",
        margin: "10px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#272D3A",
    },
    [`& .${usersButtonsSectionClasses.buttonText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.1px",
    },
}));
