import { Grid, styled } from "@mui/material";
import { primary } from "../../common/Theme/theme";

const VIEW_CONTAINER_PREFIX = "ViewContainer";

export const viewContainerClasses = {
    containerButton: `${VIEW_CONTAINER_PREFIX}-containerButton`,
    feedbackButton: `${VIEW_CONTAINER_PREFIX}-feedbackButton`,
    icon: `${VIEW_CONTAINER_PREFIX}-icon`,
};

export const StyledViewContainer = styled(Grid)(({ theme }) => ({
    overflowX: "hidden",
    overflowY: "auto",
    height: "100%",
    [`& .${viewContainerClasses.containerButton}`]: {
        display: "flex",
        alignItems: "center",
        background: primary,
        position: "fixed",
        color: "white",
        zIndex: "1000",
        top: "60%",
        right: "-65px",
        transform: "rotate(90deg)",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        "&:hover": {
            background: "white",
            color: primary,
        },
        [theme.breakpoints.only("xs")]: {
            top: "50%",
        },
    },
    [`& .${viewContainerClasses.feedbackButton}`]: {
        color: "inherit",
    },
    [`& .${viewContainerClasses.icon}`]: {
        transform: "rotate(55deg)",
    },
}));
