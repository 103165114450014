import { useContext, useState } from "react";
import {
    Alert,
    Button,
    Divider,
    Grid,
    IconButton,
    Modal,
    Paper,
    Slider,
    Snackbar,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { ViewContainer } from "../Core/ViewContainer/ViewContainer";
import { useGA } from "../common/hooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { httpService } from "../services/http.service";
import { investEndpoint } from "../endpoints";
import panel1Image from "./assets/panel1-image.svg";
import fractionEnergyImage from "./assets/fraction-energy.png";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import rectanglesIcon from "./assets/rectangles-icon.svg";
import houseImage from "./assets/house.png";
import section5Column1Icon from "./assets/section-5-column-1-icon.svg";
import section5Column2Icon from "./assets/section-5-column-2-icon.svg";
import section5Column3Icon from "./assets/section-5-column-3-icon.svg";
import section5Column4Icon from "./assets/section-5-column-4-icon.svg";
import section6Icon from "./assets/section-6-icon.svg";
import section6Person from "./assets/section-6-person.png";
import { investClasses, InvestCssTextField, StyledInvest, StyledInvestModal } from "./StyledInvest";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../routes";
import { ColorButton } from "../Authentication/Login/StyledLogin";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Dropdown from "../common/Dropdown/Dropdown";
import banknoteIcon from "./assets/banknote.svg";
import { RegisterModal } from "../common/ConfirmModal/RegisterModal";
import { GlobalContext } from "../App";

const Section5Card = ({ heading, text, icon }: { heading: string; text: string; icon: string }) => {
    return (
        <Grid className={investClasses.section5Card}>
            <img alt="" className={investClasses.section5ColumnIcon} src={icon} />
            <Typography className={`${investClasses.darkGreyText} ${investClasses.section5CardHeading}`}>
                {heading}
            </Typography>
            <Typography className={`${investClasses.greyText} ${investClasses.section5CardText}`}>{text}</Typography>
        </Grid>
    );
};

const howItWorksStepsLeftTexts = [
    "Решавате да отдадете покривът си под наем за изграждане на фотоволтаична инсталация",
    "Подавате информация за локацията, типа на покрива и др. на www.vei-imot.bg",
    "Ние се свързваме с Вас за  уточняване  на детайлите",
    "Представител на собственика на сградата подава искане към съответното ЕРП за пръсъединяване на нова производствена мощност от ВЕИ",
];

const howItWorksStepsRightTexts = [
    "ЕРП издава становище за условията и одобрената мощност",
    "Ние Ви предлагаме условията по договора за наем на покрива Ви, където се описват всички детайли относно отношенията ни.",
    "Ако решите да приемете предложението, преминаваме към изпълнение на проекта. Ние проектираме, изграждаме, узаконяваме, пускаме в експлоатация и оперираме новото фотоволтаично производство върху Вашия покрив. Процесът отнема 6-10 месеца.",
    "След като инсталацията заработи, собствениците започват регулярно да получават част от генерирания приход под формата на наем",
];

const dropdownValues = [
    { id: 0, name: "Търся инвеститори" },
    { id: 1, name: "Търся партньор" },
];

export const Invest = () => {
    useGA();
    const [openProjectStarters, setOpenProjectStarters] = useState(false);
    const [openProjectInvestors, setOpenProjectInvestors] = useState(false);
    const [openSuccessBar, setOpenSuccessBar] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const navigate = useNavigate();
    const [dropdownValue, setDropdownValue] = useState(dropdownValues[0].id);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const { user } = useContext(GlobalContext);

    const submitInvest = (values: any) => {
        setDisableSubmitButton(true);
        httpService.post(investEndpoint, values).then(() => {
            setOpenSuccessBar(true);
            setDisableSubmitButton(false);
            projectStartersForm.resetForm();
        });
    };

    const handleSubmitFormButtonClick = async () => {
        await projectStartersForm.setFieldValue("description", "коментар: " + projectStartersForm.values.description);
        projectStartersForm.handleSubmit();
    };

    const handleSubscribeButtonClick = async () => {
        await projectStartersForm.setFieldValue("description", dropdownValues[dropdownValue].name);
        projectStartersForm.handleSubmit();
    };

    const projectInvestorsForm = useFormik<{
        email: string;
        phone: string;
        sum: number;
    }>({
        initialValues: {
            email: "",
            phone: "",
            sum: 0,
        },
        validationSchema: yup.object({
            email: yup.string().required("Email e задължителен").email("Email е невалиден"),
            phone: yup.string(),
            sum: yup.number(),
        }),
        onSubmit: (values) => {
            submitInvest(values);
            setOpenProjectInvestors(false);
        },
    });

    const projectStartersForm = useFormik<{
        email: string;
        phone: string;
        description: string;
        sum: number;
    }>({
        initialValues: {
            email: "",
            phone: "",
            description: "",
            sum: 0,
        },
        validationSchema: yup.object({
            email: yup.string().required("Email e задължителен").email("Email е невалиден"),
            phone: yup.string(),
            description: yup.string(),
            sum: yup.number(),
        }),
        onSubmit: (values) => {
            submitInvest(values);
            setOpenProjectStarters(false);
        },
    });

    return (
        <ViewContainer>
            <StyledInvest className={investClasses.root}>
                <Grid display="flex" flexDirection={"column"} alignItems={"center"}>
                    <Typography className={investClasses.heading}>
                        енергийна <span className={investClasses.headingSecondColor}>общност</span>
                    </Typography>
                    <Grid className={investClasses.containerGreyBackground}>
                        <Grid className={investClasses.greyContainerLeftPanel}>
                            <Typography className={investClasses.domainText}>www.vei-imot.bg</Typography>
                            <Typography className={investClasses.blueHeading}>
                                Стани част от енергийната общност на Solar estates
                            </Typography>
                            <Typography className={investClasses.darkGreyText}>
                                Искаш да се включиш в енергийна общност? Не знаеш как и не искаш да влагаш голяма сума?
                            </Typography>
                            <Typography className={investClasses.greyText}>
                                Отдай покрива си за изгграждане на фотоволтаична инсталация лесно в рамките на минути.
                                Изкарвай пасивен доход години наред и помогни за едно по-зелено бъдеще.
                            </Typography>
                            <Button
                                onClick={() => {
                                    if (user) {
                                        navigate(`/${NavigationRoutes.CreateEstate}`);
                                    } else {
                                        setOpenRegisterModal(true);
                                    }
                                }}
                                variant="contained"
                                className={investClasses.blueButtonFilled}>
                                Регистрирай се и отдай под наем
                            </Button>
                            <Typography className={investClasses.darkGreyText}>
                                Или искаш да намериш съмишленици, с които да изградиш своя ВЕИ енергийна общност?
                            </Typography>
                            <Typography className={investClasses.greyText}>
                                Fraction Energy предоставя лесна възможност да намериш съмишленици, които да ти помогнат
                                да изградите ВЕИ енергийна общност и реализираш един или множество проекти в твоя двор,
                                на твоя покрив или друг терен.
                            </Typography>
                            <Button
                                onClick={() => setOpenProjectStarters(true)}
                                variant={"outlined"}
                                className={investClasses.blueButtonOutlined}>
                                Намери съмишленици
                            </Button>
                        </Grid>
                        <Grid className={investClasses.greyContainerRightPanel}>
                            <img src={panel1Image} alt="" />
                            <Grid className={investClasses.rightPanelTexts}>
                                <Typography className={investClasses.rightPanelComingSoon}>Очаквай скоро</Typography>
                                <img className={investClasses.rightPanelSmallImage} src={fractionEnergyImage} alt="" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <iframe
                    className={investClasses.video}
                    src="https://www.youtube.com/embed/RkL6RwETMEQ?si=IICX6u2ICxXy_dyP"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
                <Grid className={investClasses.section2Container}>
                    <Grid className={investClasses.section2ContainerLeft}>
                        <Typography className={investClasses.darkGreyHeading}>
                            Защо да се включиш в енергийната общност на Solar estates?
                        </Typography>
                    </Grid>
                    <Grid className={investClasses.section2ContainerRight}>
                        <Typography className={investClasses.greyText}>
                            Енергията от възобновяеми енергийни източници е бъдещето на устойчивата енергетика. Това е
                            отрасъл доказал своята ефективност и рентабилност. С оглед всички предизвикателства на
                            нашето съвремие, създаването на енергийни общности е инвестиция в бъдещето! Чрез нашата
                            общност също така решаваме един основен проблем на ВЕИ модела до момента, а именно да
                            срещнем производството и потреблението на електроенергия на едно и също физическо място.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={investClasses.benefitsContainer}>
                    <Grid className={investClasses.benefitsContainerLeft}>
                        <Grid
                            display={"flex"}
                            flexDirection={"row"}
                            marginBottom="40px"
                            gap={"22px"}
                            alignItems={"center"}>
                            <TrendingUpRoundedIcon className={investClasses.benefitsIcon} />
                            <Typography className={investClasses.benefitsContainerLeftHeading}>
                                Бързо, лесно и on-line
                            </Typography>
                        </Grid>
                        <Typography className={investClasses.benefitsContainerLeftText}>
                            Включи се бързо в енергийна общност, без излишни усложнение.
                        </Typography>
                        <br />
                        <Typography marginBottom={"20px"} className={investClasses.benefitsContainerLeftText}>
                            Процесът е лесен, сигурен и удобен.
                        </Typography>
                        <Grid
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}>
                            <img alt="" src={rectanglesIcon} />
                        </Grid>
                    </Grid>
                    <Grid className={investClasses.benefitsContainerRight}>
                        <Grid className={investClasses.benefitsRightContainerLeftPanel}>
                            <Grid
                                width={"100%"}
                                display={"flex"}
                                flexDirection={"row"}
                                gap={"22px"}
                                alignItems={"center"}
                                marginBottom={"44px"}>
                                <TrendingUpRoundedIcon className={investClasses.benefitsIconBlue} />
                                <Typography className={investClasses.benefitsContainerLeftHeading}>
                                    Пасивен доход
                                </Typography>
                            </Grid>
                            <Typography className={investClasses.benefitsContainerLeftText}>
                                Стойността на инсталациите за енергия от ВЕИ нормално се изплаща за срок от 5-8 години,
                                а полезният живот на съоръженията е в порядъка на 20г. и повече.
                            </Typography>
                            <br />
                            <Typography className={investClasses.benefitsContainerLeftText}>
                                Това означава, че имате възможност да релизирате висок положителен резултат от
                                включването си в енергийна общност, без да е необходимо да правите нищо допълнително.
                            </Typography>
                        </Grid>
                        <Grid className={investClasses.benefitsImageContainer}>
                            <img
                                alt=""
                                className={investClasses.benefitsImage}
                                src={houseImage}
                                width={"343px"}
                                height={"245px"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={investClasses.section4Container}>
                    <Grid className={investClasses.section4Panel}>
                        <Typography className={investClasses.darkGreyHeading}>
                            Лесния начин да печелиш от покрива си!
                        </Typography>
                    </Grid>
                    <Grid className={investClasses.section4Panel}>
                        <Typography className={investClasses.blueText}>
                            Отдай го за изграждане на ВЕИ инсталция
                        </Typography>
                        <Typography className={investClasses.greyText}>
                            Необходимостта от електрическа енергия непрекъснато се увеличава, което прави неизбежно
                            увеличава търсенето и.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                    <Grid className={investClasses.section5Background}>
                        <Section5Card
                            heading="Пасивен доход с регулярни плащания"
                            text="Печалбата се поделя пропорционално между участниците в енергийната общност."
                            icon={section5Column1Icon}
                        />
                        <Section5Card
                            heading="Нисък риск"
                            text="За всеки проект предоставяме максимално подробна информация и възможност за уеб наблюдение, за да знаеш с какво проекта, в които участваш допринася за теб и общността."
                            icon={section5Column2Icon}
                        />
                        <Section5Card
                            heading="Дългосрочни отношения  с инвеститора"
                            text="Собствениците на покриви не инвестират свои средства в инсталациите. Получават възможност да следят онлайн резултатите на ВЕИ инсталацията."
                            icon={section5Column3Icon}
                        />
                        <Section5Card
                            heading="Допринасяш за по-зелено бъдеще"
                            text="Всеки от проектите за енергийна общност е от възобновяеми енергийни източници. С това помагаш енергията да се консумира по-близо до производството и, както и да повишим дела на ВЕИ от общия енергиен микс."
                            icon={section5Column4Icon}
                        />
                    </Grid>
                </Grid>
                <Grid className={investClasses.section6Container}>
                    <Grid className={investClasses.section6UpperContainer}>
                        <Grid className={investClasses.section6UpperContainerPanel}>
                            <Grid display="flex" gap="16px" alignItems={"center"} marginBottom="52px">
                                <img alt="" src={section6Icon} />
                                <Typography className={investClasses.benefitsContainerLeftHeading}>
                                    Как работи?
                                </Typography>
                            </Grid>
                            <Grid display="flex" flexDirection="column" gap="20px">
                                {howItWorksStepsLeftTexts.map((text, index) => (
                                    <Grid display="flex" gap="16px" alignItems={"center"} flexDirection={"row"}>
                                        <Typography className={investClasses.section6StepsNumber}>
                                            0{index + 1}
                                        </Typography>
                                        <Typography className={investClasses.section6StepsText}>{text}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid className={investClasses.section6UpperContainerPanel}>
                            <Grid display="flex" flexDirection="column" gap="20px">
                                {howItWorksStepsRightTexts.map((text, index) => (
                                    <Grid display="flex" gap="16px" alignItems={"center"} flexDirection={"row"}>
                                        <Typography className={investClasses.section6StepsNumber}>
                                            0{index + howItWorksStepsLeftTexts.length + 1}
                                        </Typography>
                                        <Typography className={investClasses.section6StepsText}>{text}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={investClasses.section6LowerContainer}>
                        <Grid className={investClasses.section6LowerContainerLeftPanel}>
                            <Typography className={investClasses.section6LowerContainerHeading}>
                                ... или искаш да намерим съмишленици за реализацията на твоя проект?
                            </Typography>
                            <Typography className={investClasses.section6LowerContainerHeading2}>
                                Попълни формата за заявка
                            </Typography>
                            <Typography className={investClasses.section6StepsText}>
                                Предоставете ни данни за Вашият проеект и нашият екип ще се свърже скоро за да обсъдим
                                възможността да го включим в нашата платформа.
                            </Typography>
                            <Grid className={investClasses.section6Form}>
                                <input
                                    onChange={projectStartersForm.handleChange}
                                    value={projectStartersForm.values.email}
                                    type="email"
                                    name="email"
                                    placeholder="email@example.com"
                                    className={investClasses.inputSection6}
                                />
                                <Dropdown
                                    label=""
                                    className={investClasses.section6Dropdown}
                                    options={dropdownValues}
                                    value={dropdownValue}
                                    onChange={(event) => {
                                        const id = parseInt(event.target.value);
                                        setDropdownValue(id);
                                    }}
                                    name={""}
                                />
                                <Button
                                    variant="contained"
                                    className={investClasses.section6SubmitButton}
                                    onClick={() => handleSubscribeButtonClick()}
                                    disabled={disableSubmitButton}>
                                    Абонирай се
                                </Button>
                            </Grid>
                            {projectStartersForm.touched?.email && !!projectStartersForm.errors?.email && (
                                <p>{projectStartersForm.errors?.email}</p>
                            )}
                        </Grid>
                        <Grid className={investClasses.section6LowerContainerRightPanel}>
                            <img className={investClasses.section6PersonImage} alt="" src={section6Person} />
                        </Grid>
                    </Grid>
                </Grid>
            </StyledInvest>
            <StyledInvestModal open={openProjectStarters}>
                <Paper className={investClasses.paper}>
                    <Grid container justifyContent={"flex-end"}>
                        <IconButton onClick={() => setOpenProjectStarters(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "24px",
                            lineHeight: "28px",
                            textAlign: "center",
                            color: "#515661",
                            marginBottom: "14px",
                        }}>
                        Бъди сред първите записали се участници в енергийна общност
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "center",
                            letterSpacing: "0.1px",
                            color: "#757C8D",
                            marginBottom: "30px",
                        }}>
                        Fraction Еnergy предлага уникална възможност да откриеш проекти за изграждане на енергийни
                        общности, в които можеш да се включиш бързо и лесно.
                    </Typography>
                    <Divider sx={{ marginBottom: "30px" }} />
                    <Grid container justifyContent={"center"}>
                        <InvestCssTextField
                            variant={"outlined"}
                            placeholder="Email"
                            focused
                            InputProps={{
                                startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                            }}
                            value={projectStartersForm.values.email}
                            onChange={projectStartersForm.handleChange}
                            error={projectStartersForm.touched?.email && !!projectStartersForm.errors?.email}
                            helperText={projectStartersForm.errors?.email}
                            name="email"
                            className={investClasses.investTextField}
                        />
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <InvestCssTextField
                            variant={"outlined"}
                            placeholder="Телефон"
                            InputProps={{
                                startAdornment: <LocalPhoneOutlinedIcon htmlColor={"#BABDC3"} />,
                            }}
                            name={"phone"}
                            value={projectStartersForm.values.phone}
                            onChange={projectStartersForm.handleChange}
                            error={projectStartersForm.touched?.phone && !!projectStartersForm.errors?.phone}
                            helperText={projectStartersForm.errors?.phone}
                            className={investClasses.investTextField}
                        />
                    </Grid>
                    <Grid container>
                        <Typography className={investClasses.label}>Коментар:</Typography>
                        <InvestCssTextField
                            variant={"outlined"}
                            placeholder="Вашият коментар..."
                            name={"description"}
                            multiline
                            minRows={8}
                            value={projectStartersForm.values.description}
                            onChange={projectStartersForm.handleChange}
                            error={
                                projectStartersForm.touched?.description && !!projectStartersForm.errors?.description
                            }
                            helperText={projectStartersForm.errors?.description}
                            className={investClasses.description}
                        />
                    </Grid>
                    <Typography
                        component={"div"}
                        sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: "#757C8D",
                        }}>
                        Възможен размер на Вашето участие в енергийната общност
                    </Typography>
                    <Grid container>
                        <InvestCssTextField
                            type="number"
                            variant={"outlined"}
                            InputProps={{
                                startAdornment: (
                                    <img alt="" src={banknoteIcon} className={investClasses.banknoteIcon} />
                                ),
                                endAdornment: <Typography className={investClasses.endAdornment}>BGN</Typography>,
                            }}
                            name={"sum"}
                            value={projectStartersForm.values.sum}
                            onChange={projectStartersForm.handleChange}
                            error={projectStartersForm.touched?.sum && !!projectStartersForm.errors?.sum}
                            helperText={projectStartersForm.errors?.sum}
                            className={investClasses.investTextField}
                        />
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <ColorButton
                            onClick={() => handleSubmitFormButtonClick()}
                            disabled={disableSubmitButton}
                            variant={"contained"}
                            color="secondary"
                            className={investClasses.sendButton}>
                            Изпрати
                        </ColorButton>
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#BABDC3",
                                textAlign: "center",
                            }}>
                            Чрез изпращането на тези данни се съгласявам с условията за ползване на платформата
                            SolarEstates
                        </Typography>
                    </Grid>
                </Paper>
            </StyledInvestModal>
            <Snackbar
                open={openSuccessBar}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => {
                    setOpenSuccessBar(false);
                }}>
                <Alert
                    onClose={() => {
                        setOpenSuccessBar(false);
                    }}
                    severity="success"
                    sx={{ width: "100%" }}>
                    Успешно се записа
                </Alert>
            </Snackbar>
            <RegisterModal
                redirect={NavigationRoutes.CreateEstate}
                isOpened={openRegisterModal}
                cancel={() => setOpenRegisterModal(false)}
            />
        </ViewContainer>
    );
};
