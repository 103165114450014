import { Button, Grid } from "@mui/material";
import { StyledCreateEstateFormPreview, createEstateClasses as classes } from "./StyledCreateEstateFormPreview";

interface CreateEstateFormPreviewProps {
    next: () => void;
}

const CreateEstateFormPreview = ({ next }: CreateEstateFormPreviewProps) => {
    return (
        <StyledCreateEstateFormPreview>
            <Grid container className={classes.submitButtonContainer} justifyContent={"center"}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        next();
                    }}>
                    Прегледай
                </Button>
            </Grid>
        </StyledCreateEstateFormPreview>
    );
};

export default CreateEstateFormPreview;
