import { useMediaQuery, useTheme } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export const useMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("md"));
};

export const dateFormat = "dd/MM/yyyy";
export const dateFnUtils = new DateFnsUtils();
export const formatDate = (date: Date) => dateFnUtils.formatByString(date, dateFormat);

export const useGA = () => {
    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, []);
};
