import { TextField } from "@mui/material";
import React, { forwardRef, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { withMemo } from "../withMemo";

const NumberFormatCustom = forwardRef((props: any, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values: NumberFormatValues) => {
                onChange({
                    target: {
                        name: props.name,
                        value: parseFloat(parseFloat(values.value).toFixed(2)),
                    },
                });
            }}
            allowNegative={false}
            thousandSeparator
            decimalScale={2}
        />
    );
});

const NumberInput = (props: {
    inputClass: string;
    name: string;
    placeholder?: string;
    label: string;
    value: number;
    sx?: any;
    onBlur?: (onFocusValue: number, onBlueValue: number) => void;
    setFieldValue?: (field: string, value: unknown, shouldValidate?: boolean | undefined) => any | Promise<void>;
    handleChange: StandardInputProps["onChange"];
    error: boolean;
    helperText: React.ReactNode;
    disabled?: boolean;
}) => {
    const [onFocusValue, setOnFocusValue] = useState(props.value);

    return (
        <TextField
            className={props.inputClass}
            name={props.name}
            placeholder={props.placeholder}
            label={props.label}
            variant="outlined"
            value={props.value}
            sx={props.sx}
            disabled={props.disabled}
            onBlur={() => {
                if (props.onBlur) {
                    props.onBlur(onFocusValue, props.value);
                }
            }}
            onFocus={() => {
                setOnFocusValue(props.value);
            }}
            data-automation={props.name}
            onChange={props.handleChange}
            color="primary"
            inputMode={"decimal"}
            error={props.error}
            helperText={props.helperText}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
        />
    );
};

export default withMemo(NumberInput, ["value", "error", "helperText"]);
