import { Paper } from "@mui/material";
import { CreatedImagePreview } from "../../CreatedImagePreview";
import { ImagePreview } from "../../ImagePreview";
import { IAttachment } from "../../../../../interfaces/attachment";
import {
    StyledCreateEstateFormDisplayAttachments,
    createEstateClasses as classes,
} from "./StyledCreateEstateFormDisplayAttachments";

interface CreateEstateFormDisplayAttachmentsProps {
    attachments: File[];
    oldAttachments: IAttachment[];
    showDelete: boolean;
    deletedOldAttachment: (index: number) => void;
    setAttachments: (attachments: File[]) => void;
}

const CreateEstateFormDisplayAttachments = ({
    attachments,
    oldAttachments,
    showDelete,
    deletedOldAttachment,
    setAttachments,
}: CreateEstateFormDisplayAttachmentsProps) => {
    if (showDelete) {
        return (
            <StyledCreateEstateFormDisplayAttachments>
                <Paper className={classes.paperContainer}>
                    {oldAttachments.map((attachment, index) => (
                        <CreatedImagePreview
                            attachment={attachment}
                            key={attachment.name}
                            showDelete={showDelete}
                            deletePhoto={() => {
                                deletedOldAttachment(index);
                            }}
                        />
                    ))}
                    {attachments.map((attachment, index) => (
                        <ImagePreview
                            file={attachment}
                            key={attachment.name}
                            showDelete={showDelete}
                            deletePhoto={() => {
                                setAttachments(attachments.filter((_, i) => i !== index));
                            }}
                        />
                    ))}
                </Paper>
            </StyledCreateEstateFormDisplayAttachments>
        );
    } else {
        return (
            <StyledCreateEstateFormDisplayAttachments>
                <Paper className={classes.paperContainer}>
                    {oldAttachments.map((attachment, index) => (
                        <CreatedImagePreview attachment={attachment} key={attachment.name} showDelete={showDelete} />
                    ))}
                    {attachments.map((attachment, index) => (
                        <ImagePreview file={attachment} key={attachment.name} showDelete={showDelete} />
                    ))}
                </Paper>
            </StyledCreateEstateFormDisplayAttachments>
        );
    }
};

export default CreateEstateFormDisplayAttachments;
