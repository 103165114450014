import { IEstate } from "../../../interfaces/estate";
import { attachmentService } from "../../../services/attachment.service";
import { documentKeys } from "../../../services/estate-service.utils";

export interface SellerProfileState extends IEstate {
    ownership_document_attachment_file: File | null;
    sketch_attachment_file: File | null;
    erp_accession_opinion_attachment_file: File | null;
    official_contract_with_erp_attachment_file: File | null;
    preliminary_contract_with_erp_attachment_file: File | null;
    pyp_attachment_file: File | null;
    weights_inquiry_attachment_file: File | null;
    building_permit_attachment_file: File | null;
    availability_of_electricity_batch_attachment_file: File | null;
    design_visa_attachment_file: File | null;
    other_documents_attachment_file: File | null;
}

export const defaultEstate: SellerProfileState = {
    // Core
    id: 0,
    title: "",
    renewable_type_id: 0,
    estate_type_id: 0,
    offer_type_id: 0,

    price: 0,
    area: 0,
    description: "",
    upi_purpose: "",

    country_area_id: 0,
    municipality_id: 0,
    city: "",

    land_category_id: 0,
    land_type_id: 0,

    // Exact Address
    exact_address: "",
    cadastral_identification_number: "",
    upi_pi_number: "",
    coordinates_lat: 0,
    coordinates_lng: 0,
    chosen_location: false,

    // Acting Park Details
    pricing_id: 0,
    // TODO: Need pricing value
    external_financing_amount: 0,
    put_into_operation: false,
    external_financing: false,
    support_schemes: false,

    // Need contact per estate, since we can copy the existing or create new ones
    email: "",
    phone: "",

    // Present Documents
    has_ownership_document: false,
    sketch: false,
    design_visa: false,
    erp_accession_opinion: false,
    preliminary_contract_with_erp: false,
    official_contract_with_erp: false,
    pyp: false,
    weight_inquiry: false,
    building_permit: false,
    other_documents: false,

    // Other Documents
    is_substation_present: false,
    personal_substation: false,
    substation_distance_in_meters: 0,
    availability_of_electricity_batch: false,
    electrical_wire: false,
    voltage_id: 0,
    meters_to_nearest_electricity_pillar: 0,
    capacity: 0,
    capacity_type_id: 0,
    currency_id: 1,
    area_unit_id: 1,
    support_schemes_description: "",
    put_into_operation_year: new Date().getFullYear(),
    solar_panel_brand: "",
    solar_panel_capacity: 0,
    solar_panel_capacity_id: 1,
    solar_panel_count: 0,

    solar_invertor_brand: "",
    solar_invertor_capacity: 0,
    solar_invertor_capacity_id: 1,
    solar_invertor_count: 0,

    wind_brand: "",
    wind_capacity: 0,
    wind_capacity_id: 1,
    wind_count: 0,
    attachments: [],

    ownership_document_attachment: 0,
    sketch_attachment: 0,
    erp_accession_opinion_attachment: 0,
    official_contract_with_erp_attachment: 0,
    preliminary_contract_with_erp_attachment: 0,
    pyp_attachment: 0,
    weights_inquiry_attachment: 0,
    building_permit_attachment: 0,
    availability_of_electricity_batch_attachment: 0,
    design_visa_attachment: 0,
    other_documents_attachment: 0,
    other_documents_attachment_description: "",

    ownership_document_attachment_file: null,
    sketch_attachment_file: null,
    erp_accession_opinion_attachment_file: null,
    official_contract_with_erp_attachment_file: null,
    preliminary_contract_with_erp_attachment_file: null,
    pyp_attachment_file: null,
    weights_inquiry_attachment_file: null,
    building_permit_attachment_file: null,
    availability_of_electricity_batch_attachment_file: null,
    design_visa_attachment_file: null,
    other_documents_attachment_file: null,

    weights_inquiry_attachment_object: null,
    availability_of_electricity_batch_attachment_object: null,
    building_permit_attachment_object: null,
    pyp_attachment_object: null,
    preliminary_contract_with_erp_attachment_object: null,
    design_visa_attachment_object: null,
    official_contract_with_erp_attachment_object: null,
    erp_accession_opinion_attachment_object: null,
    ownership_document_attachment_object: null,
    sketch_attachment_object: null,
    other_documents_attachment_object: null,
    isPaid: true,
    has_uploaded_documents: false,
    promoted: false,
};

export const getValuesWithDocumentsIds = async (values: any) => {
    const documents = documentKeys.map((keys) => {
        return values[keys.fileKey];
    });
    const uploadedDocumentsKeys = documentKeys.filter((keys) => {
        return values[keys.availabilityKey];
    });
    const documentsIds = (await attachmentService.uploadDocuments(documents)).data;
    uploadedDocumentsKeys.forEach((keys, index) => {
        values[keys.idKey] = documentsIds[index];
    });
    return values;
};

export const getValuesWithUpdatedDocumentsIds = async (values: any) => {
    // files to upload
    // new files id to values
    // deleted object to null values
    const idsForDeletion: number[] = [];
    const newDocumentsKeys = documentKeys.filter((keys) => {
        return values[keys.fileKey];
    });
    const documentsIds = (
        await attachmentService.uploadDocuments(
            newDocumentsKeys.map((newDocumentKeys) => values[newDocumentKeys.fileKey])
        )
    ).data;
    newDocumentsKeys.forEach((keys, index) => {
        values[keys.idKey] = documentsIds[index];
    });
    const deletedDocumentsKeys = documentKeys.filter((keys) => {
        return !values[keys.fileKey] && !values[keys.objectKey] && !!values[keys.idKey];
    });
    deletedDocumentsKeys.forEach((keys) => {
        idsForDeletion.push(values[keys.idKey]);
        values[keys.idKey] = null;
    });
    return [values, idsForDeletion];
};

export const deleteDocuments = async (estateId: number, ids: number[]) => {
    if (ids.length > 0) {
        await attachmentService.deleteDocuments(estateId, ids);
    }
};
