export const mapStyles = [
    {
        featureType: "administrative",
        elementType: "labels",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "landscape.natural",
        elementType: "labels",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "transit.line",
        elementType: "labels.text",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        elementType: "geometry",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        elementType: "labels",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels",
        stylers: [
            {
                color: "#FFFFFF",
            },
        ],
    },
];
