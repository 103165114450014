import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { secondary } from "../../common/Theme/theme";
import { getOneDocumentEndpoint } from "../../endpoints";
import { estateDetailsClasses as classes } from "./StyledEstateDetails";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { IAttachment } from "../../interfaces/attachment";
import { getAttachmentFile } from "../../common/utils";

interface DocumentCheckboxProps {
    attachment: number;
    attachmentObject: IAttachment | null;
    estateId: number;
    name: string;
    setOpenDocumentsModal: () => void;
}

const getDocumentFile = (action: string, attachmentObject?: IAttachment) => {
    getAttachmentFile(action, attachmentObject);
};

export const DocumentCheckboxAvailableDocuments = ({
    attachment,
    attachmentObject,
    estateId,
    name,
    setOpenDocumentsModal,
}: DocumentCheckboxProps) => {
    const handleClick = () => {
        if (!!!attachmentObject && !!attachment) {
            setOpenDocumentsModal();
        }
        if (!!attachmentObject && !!attachment) {
            getDocumentFile(`${getOneDocumentEndpoint}/${estateId}/${attachmentObject?.id}`, attachmentObject);
        }
    };
    return (
        <Grid container alignItems={"center"} marginBottom={"5px"}>
            {!!attachment ? (
                <Tooltip title="Има качен документ" placement="right">
                    <span>
                        <IconButton
                            className={classes.iconButtonDownloadDocument}
                            onClick={() => {
                                handleClick();
                            }}>
                            <TaskOutlinedIcon htmlColor={secondary} />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : (
                <Tooltip
                    title="Собственикът на тази обява е отбелязал този документ за наличен, но не го е качил"
                    placement="right">
                    <CheckIcon className={classes.checkIcon} />
                </Tooltip>
            )}
            <Typography
                variant={"body1"}
                className={`${classes.documentsLowerContainer} ${attachment && classes.availableDocumentsHover}`}
                onClick={() => {
                    handleClick();
                }}>
                {name}
            </Typography>
        </Grid>
    );
};
