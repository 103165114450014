import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_LOCATION_MAP_PREFIX = "CreateEstateFormLocationMap";

export const createEstateClasses = {
    paperContainer: `${CREATE_ESTATE_FORM_LOCATION_MAP_PREFIX}-paperContainer`,
};

export const StyledCreateEstateFormLocationMap = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.paperContainer}`]: {
        marginBottom: "10px",
        marginLeft: "10px",
        height: "400px",
        width: "100%",
    },
}));
