import { Grid, styled } from "@mui/material";
import { greyLight, info, secondary } from "../../../common/Theme/theme";

const NEWLOCATIONS_PREFIX = "NewLocations";

export const newLocationsSectionClasses = {
    heading: `${NEWLOCATIONS_PREFIX}-heading`,
    secondaryText: `${NEWLOCATIONS_PREFIX}-secondaryText`,
    innerContainer: `${NEWLOCATIONS_PREFIX}-innerContainer`,
    cardActionArea: `${NEWLOCATIONS_PREFIX}-cardActionArea`,
    cardHeading: `${NEWLOCATIONS_PREFIX}-cardHeading`,
    cardProperty: `${NEWLOCATIONS_PREFIX}-cardProperty`,
    cardPropertyIcon: `${NEWLOCATIONS_PREFIX}-cardPropertyIcon`,
    cardLocation: `${NEWLOCATIONS_PREFIX}-cardLocation`,
    cardDateTime: `${NEWLOCATIONS_PREFIX}-cardDateTime`,
    cardPrice: `${NEWLOCATIONS_PREFIX}-cardPrice`,
    cardContent: `${NEWLOCATIONS_PREFIX}-cardContent`,
    cardButton: `${NEWLOCATIONS_PREFIX}-cardButton`,
    cardImage: `${NEWLOCATIONS_PREFIX}-cardImage`,
    cardLabel: `${NEWLOCATIONS_PREFIX}-cardLabel`,
    cardLabelText: `${NEWLOCATIONS_PREFIX}-cardLabelText`,
    cardLike: `${NEWLOCATIONS_PREFIX}-like`,
    cardsContainer: `${NEWLOCATIONS_PREFIX}-cardsContainer`,
};

export const StyledNewLocationsSection = styled(Grid)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
        padding: "80px 80px 80px 80px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px 20px",
    },
    [`& .${newLocationsSectionClasses.innerContainer}`]: {
        [theme.breakpoints.down("md")]: {
            alignItems: "center",
        },
    },
    [`& .${newLocationsSectionClasses.cardsContainer}`]: {
        marginTop: "30px",
    },
    [`& .${newLocationsSectionClasses.heading}`]: {
        fontFamily: "Oswald",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "32px",
        lineHeight: "32px",
    },
    [`& .${newLocationsSectionClasses.secondaryText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: greyLight,
        marginTop: "10px",
        marginBottom: "40px",
    },
    [`& .${newLocationsSectionClasses.cardActionArea}`]: {
        position: "relative",
    },
    [`& .${newLocationsSectionClasses.cardHeading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
    },
    [`& .${newLocationsSectionClasses.cardProperty}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        marginRight: "10px",
    },
    [`& .${newLocationsSectionClasses.cardPropertyIcon}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    },
    [`& .${newLocationsSectionClasses.cardLocation}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
    },
    [`& .${newLocationsSectionClasses.cardDateTime}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
        color: greyLight,
    },
    [`& .${newLocationsSectionClasses.cardPrice}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        marginRight: "20px",
    },
    [`& .${newLocationsSectionClasses.cardContent}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
    },
    [`& .${newLocationsSectionClasses.cardButton}`]: {
        borderRadius: "100px",
        width: "185px",
        fontWeight: "normal",
        fontSize: "16px",
        textTransform: "capitalize",
    },
    [`& .${newLocationsSectionClasses.cardImage}`]: {
        margin: "auto",
        marginTop: "15px",
        width: "90%",
        borderRadius: "8px",
    },
    [`& .${newLocationsSectionClasses.cardLabel}`]: {
        position: "absolute",
        top: 30,
        left: 30,
        display: "flex",
        alignItems: "center",
        backgroundColor: secondary,
        borderRadius: "4px",
        padding: "5px 10px",
    },
    [`& .${newLocationsSectionClasses.cardLabelText}`]: {
        color: theme.palette.common.white,
    },
    [`& .${newLocationsSectionClasses.cardLike}`]: {
        position: "absolute",
        top: 30,
        right: 30,
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        padding: "5px",
        backgroundColor: "#FAFAFA",
    },
}));
