import { Button, Grid, styled, TextField } from "@mui/material";
import { grey, greyLight } from "../../common/Theme/theme";

const FORGOT_PASSWORD_PREFIX = "ForgotPassword";
import loginImage from "../../assets/login-image.svg";

export const forgotPasswordClasses = {
    root: `${FORGOT_PASSWORD_PREFIX}-root`,
    logoContainer: `${FORGOT_PASSWORD_PREFIX}-logoContainer`,
    logoLight: `${FORGOT_PASSWORD_PREFIX}-logoLight`,
    logoDark: `${FORGOT_PASSWORD_PREFIX}-logoDark`,
    leftPanel: `${FORGOT_PASSWORD_PREFIX}-leftPanel`,
    rightPanel: `${FORGOT_PASSWORD_PREFIX}-rightPanel`,
    link: `${FORGOT_PASSWORD_PREFIX}-link`,
    welcome: `${FORGOT_PASSWORD_PREFIX}-welcome`,
    buttonIcon: `${FORGOT_PASSWORD_PREFIX}-buttonIcon`,
    heading: `${FORGOT_PASSWORD_PREFIX}-heading`,
};

export const StyledForgotPassword = styled(Grid)(({ theme }) => ({
    [`&.${forgotPasswordClasses.root}`]: {},
    [`& .${forgotPasswordClasses.leftPanel}`]: {
        [theme.breakpoints.up("md")]: {
            backgroundImage: `url(${loginImage})`,
            width: "100%",
            height: "110vh",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.only("sm")]: {
            backgroundImage: `url(${loginImage})`,
            width: "100%",
            height: "50vh",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        },
    },
    [`& .${forgotPasswordClasses.rightPanel}`]: {
        background: "rgba(225, 224, 223, 0.5)",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 160px",
        },
        [theme.breakpoints.only("sm")]: {
            height: "60vh",
        },
        [theme.breakpoints.only("md")]: {
            height: "110vh",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "100px 30px",
            height: "110vh",
        },
    },
    [`& .${forgotPasswordClasses.logoContainer}`]: {
        width: "10%",
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            left: "10%",
            top: "10%",
        },
        [theme.breakpoints.only("sm")]: {
            left: "5%",
            top: "5%",
        },
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            margin: "20px",
        },
    },
    [`& .${forgotPasswordClasses.logoLight}`]: {
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        [theme.breakpoints.only("sm")]: {
            display: "block",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    [`& .${forgotPasswordClasses.logoDark}`]: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        [theme.breakpoints.only("sm")]: {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    [`& .${forgotPasswordClasses.link}`]: {
        textDecoration: "none",
    },
    [`& .${forgotPasswordClasses.welcome}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: greyLight,
        marginBottom: "30px",
    },
    [`& .${forgotPasswordClasses.buttonIcon}`]: {
        marginRight: "10px",
    },
    [`& .${forgotPasswordClasses.heading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    fontFamily: "Rubik",
    borderRadius: "100px",
    height: "40px",
    textTransform: "capitalize",
    marginBottom: "30px",
    width: "fit-content",
}));

export const CssTextField = styled(TextField)(({ theme }) => ({
    color: grey,
    background: "transparent",
    borderColor: greyLight,
    boxSizing: "border-box",
    borderRadius: "8px",
    marginBottom: "40px",
    marginRight: "40px",
    width: "90%",
    "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart":
        {
            background: theme.palette.common.white,
            border: "1px solid rgba(225, 224, 223, 0.5)",
        },
    "& label.Mui-focused": {
        color: greyLight,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "rgba(225, 224, 223, 0.5)",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&:hover fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
    },
}));
