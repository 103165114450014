import React, { useContext } from "react";
import { Button, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { createEstateClasses as classes } from "./StyledCreateEstate";
import NumberInput from "../../../common/NumberInput/NumberInput";
import CustomTextInput from "../../../common/CustomTextInput/CustomerTextInput";
import CustomSwitch from "../../../common/CustomSwitch/CustomSwitch";
import { GlobalContext } from "../../../App";
import { primary, secondary } from "../../../common/Theme/theme";
import solarIcon from "../assets/solar-icon.png";
import windIcon from "../assets/wind-icon.png";
import { IForm } from "../../../interfaces/form";
import { SellerProfileState } from "./utils";
import { existingParkId, piId, solarRenewableId, upiId, windRenewableId } from "./schema";
import { Info } from "@mui/icons-material";
import CustomAutocomplete from "../../../common/CustomAutocomplete/CustomAutocomplete";
import { ImagePreview } from "./ImagePreview";
import WaterIcon from "@mui/icons-material/Water";
import PlacesAutocomplete from "../../../common/PlacesAutocomplete/PlacesAutocomplete";
import { GOOGLE_API_KEY } from "../../../env";
import { getLocation } from "../../../common/utils";
import { IAttachment } from "../../../interfaces/attachment";
import { CreatedImagePreview } from "./CreatedImagePreview";
import CreateEstateFormDetails from "./CreateEstateForm/CreateEstateFormDetails/CreateEstateFormDetails";
import CreateEstateFormEstateType from "./CreateEstateForm/CreateEstateFormEstateType/CreateEstateFormEstateType";
import CreateEstateFormOthers from "./CreateEstateForm/CreateEstateFormOthers/CreateEstateFormOthers";
import CreateEstateFormAddress from "./CreateEstateForm/CreateEstateFormAddress/CreateEstateFormAddress";
import CreateEstateFormContacts from "./CreateEstateForm/CreateEstateFormContacts/CreateEstateFormContacts";
import CreateEstateFormDisplayAttachments from "./CreateEstateForm/CreateEstateFormDisplayAttachments/CreateEstateFormDisplayAttachments";
import CreateEstateFormLocationMapPreview from "./CreateEstateForm/CreateEstateFormLocationMapPreview/CreateEstateFormLocationMapPreview";
import CreateEstateFormAddDocuments from "./CreateEstateForm/CreateEstateFormAddDocuments/CreateEstateFormAddDocuments";

export const maxFileSize = 10 * 1000 * 1024; // 10MB
export const imageFormats = [".png", ".jpg", ".jpeg"];

//locationMap addDocuments ImagePreview

export const CreateEstatePreview = ({
    form,
    next,
    back,
    attachments,
    oldAttachments,
    publishing,
}: {
    form: IForm<SellerProfileState>;
    next: () => void;
    back: () => void;
    attachments: File[];
    oldAttachments: IAttachment[];
    publishing: boolean;
}) => {
    const { dropdowns, dropdownsMap } = useContext(GlobalContext);
    const mapLocation = getLocation(form.values, dropdownsMap);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <CreateEstateFormDetails form={form} dropdowns={dropdowns} isDisabled={true} />
                <CreateEstateFormEstateType form={form} dropdowns={dropdowns} isDisabled={true} />
                <CreateEstateFormAddDocuments
                    form={form}
                    handleSwitchToggle={() => {}}
                    highlightDocumentUploads={false}
                    isDisabled={true}
                />
                <CreateEstateFormOthers form={form} dropdowns={dropdowns} isDisabled={true} />
                <CreateEstateFormDisplayAttachments
                    attachments={attachments}
                    oldAttachments={oldAttachments}
                    deletedOldAttachment={() => {}}
                    setAttachments={() => {}}
                    showDelete={true}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <CreateEstateFormLocationMapPreview form={form} mapLocation={mapLocation} />
                <CreateEstateFormAddress form={form} isDisabled={true} />
                <CreateEstateFormContacts form={form} isDisabled={true} />
                <Grid
                    container
                    className={classes.submitButtonContainer}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        sx={{ height: "40px", width: "150px" }}
                        disabled={publishing}
                        onClick={() => {
                            next();
                        }}>
                        Публикувай
                    </Button>
                    <Button
                        sx={{ marginTop: "10px", height: "40px", width: "150px" }}
                        variant={"contained"}
                        color={"primary"}
                        disabled={publishing}
                        onClick={() => {
                            back();
                        }}>
                        Назад
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
