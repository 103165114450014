import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import React from "react";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";

const CustomCheckbox = (props: {
    className: string;
    name?: string;
    value: boolean;
    checked: boolean;
    onChange: StandardInputProps["onChange"];
    title: string;
    label: any;
    sx?: any;
    disabled?: boolean;
    error?: any;
    helperText?: string;
}) => {
    return (
        <FormControl component="fieldset" className={props.className} error={props.error}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        name={props.name}
                        data-automation={props.name}
                        value={props.value}
                        disabled={props.disabled}
                        checked={props.checked !== undefined ? props.checked : props.value}
                        title={props.title}
                        onChange={props.onChange}
                    />
                }
                label={props.label}
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
};

// export default withMemo(CustomCheckbox, ["value", "checked"]);
export default CustomCheckbox;
