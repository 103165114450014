import { Grid, styled } from "@mui/material";
import { grey } from "../../common/Theme/theme";

const ABOUT_PREFIX = "About";

export const aboutClasses = {
    root: `${ABOUT_PREFIX}-root`,
    heading: `${ABOUT_PREFIX}-heading`,
    paragraph: `${ABOUT_PREFIX}-paragraph`,
    title: `${ABOUT_PREFIX}-title`,
    logo: `${ABOUT_PREFIX}-logo`,
};

export const logoStyle = {
    marginTop: "50px",
    marginBottom: "20px",
};

export const StyledAbout = styled(Grid)(({ theme }) => ({
    [`&.${aboutClasses.root}`]: {
        width: "100%",
    },
    [`& .${aboutClasses.heading}`]: {
        marginBottom: "10px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: grey,
    },
    [`& .${aboutClasses.paragraph}`]: {
        marginBottom: "10px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: grey,
        textAlign: "justify",
    },
    [`& .${aboutClasses.title}`]: {
        fontFamily: "Oswald",
        fontWeight: 700,
        marginBottom: "10px",
    },
    [`& .${aboutClasses.logo}`]: {
        marginTop: "20px",
        marginBottom: "20px",
    },
}));
