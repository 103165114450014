import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavigationRoutes } from "./routes";
import { theme } from "./common/Theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Login } from "./Authentication/Login/Login";
import SellerProfile from "./Seller/SellerProfile/SellerProfile";
import { ComingSoon } from "./ComingSoon/ComingSoon";
import { Dropdowns, dropdownService } from "./services/dropdown.service";
import { ILogged } from "./interfaces/user";
import { Home } from "./Core/Home/Home";
import EstateDetails from "./Core/EstateDetails/EstateDetails";
import AdminUser from "./Admin/AdminUser/AdminUser";
import { EstateListing } from "./Core/EstateListing/EstateListing";
import CreateEstate from "./Seller/SellerProfile/CreateEstate/CreateEstate";
import { EditEstate } from "./Seller/SellerProfile/EditEstate/EditEstate";
import { ForgotPassword } from "./Authentication/ForgotPassword/ForgotPassword";
import BuyerProfile from "./Buyer/BuyerProfile/BuyerProfile";
import { ResetPassword } from "./Authentication/ResetPassword/ResetPassword";
import { userService } from "./services/user.service";
import { Register } from "./Authentication/Register/Register";
import { FutureWork } from "./FutureWork/FutureWork";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import { CookiesPolicy } from "./Core/CookiesPolicy/CookiesPolicy";
import { About } from "./Core/About/About";
import { Invest } from "./Invest/Invest";
ReactGA.initialize("G-YQ0CV6W0WG");

Sentry.init({
    dsn: "https://3e1ad8c3f7be4007a3e65de41206f3a1@o1238653.ingest.sentry.io/6389530",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const demo = true;

export interface DropdownMap {
    renewableTypes: Record<string, { id: number; name: string }>;
    countryAreas: Record<string, { id: number; name: string }>;
    estateTypes: Record<string, { id: number; name: string }>;
    landCategories: Record<string, { id: number; name: string }>;
    landTypes: Record<string, { id: number; name: string }>;
    pricing: Record<string, { id: number; name: string }>;
    capacityTypes: Record<string, { id: number; name: string }>;
    voltage: Record<string, { id: number; name: string }>;
    areaUnits: Record<string, { id: number; name: string; description: string }>;
    municipality: Record<string, { id: number; name: string; country_area_id?: number }>;
    currency: Record<string, { id: number; name: string; description: string }>;
    years: Record<string, { id: number; name: string; description: string }>;
    offerTypes: Record<string, { id: number; name: string; description: string }>;
    invertorCapacityTypes: Record<string, { id: number; name: string }>;
    windCapacityTypes: Record<string, { id: number; name: string }>;
}

export interface IGlobalContext {
    dropdowns: Dropdowns;
    user: ILogged | null;
    setUser: any;
    isLoadingUser: boolean;
    dropdownsMap: DropdownMap | null;
}

const hardCodedDropdowns = {
    invertorCapacityTypes: [
        {
            id: 1,
            name: "kVA",
        },
        {
            id: 2,
            name: "MVA",
        },
    ],
    windCapacityTypes: [
        {
            id: 1,
            name: "kW",
        },
        {
            id: 2,
            name: "MW",
        },
    ],
};

const defaultDropdowns: Dropdowns = {
    renewableTypes: [],
    countryAreas: [],
    estateTypes: [],
    municipality: [],
    landTypes: [],
    pricing: [],
    capacityTypes: [],
    voltage: [],
    landCategories: [],
    currency: [],
    areaUnits: [],
    years: [],
    offerTypes: [],
    ...hardCodedDropdowns,
};

export const GlobalContext = createContext<IGlobalContext>({
    // Use default values
    dropdowns: defaultDropdowns,
    user: null,
    isLoadingUser: true,
    setUser: () => {},
    dropdownsMap: null,
});

function App() {
    // TODO: Use default values
    const [dropdowns, setDropdowns] = useState<Dropdowns>(defaultDropdowns);
    const [dropdownsMap, setDropdownsMap] = useState<DropdownMap | null>(null);
    const [user, setUser] = useState<ILogged>(null as any);
    const [isLoadingUser, setIsLoadingUser] = useState(true);

    useEffect(() => {
        dropdownService.getAll().then((innerDropdowns) => {
            setDropdowns({
                ...innerDropdowns,
                ...hardCodedDropdowns,
            });
        });
    }, []);

    useEffect(() => {
        userService
            .getCurrentUser()
            .then((result) => {
                setUser(result);
            })
            .finally(() => {
                setIsLoadingUser(false);
            });
    }, []);

    useEffect(() => {
        if (dropdowns.capacityTypes.length !== 0) {
            const innerDropdownMap = Object.keys(dropdowns).reduce((dropdownMap: any, key: string) => {
                const dropdownItem = (dropdowns as any)[key].reduce((acc: any, item: any) => {
                    acc[item.id] = item;
                    return acc;
                }, {} as any);
                return {
                    ...dropdownMap,
                    [key]: dropdownItem,
                };
            }, {});
            setDropdownsMap(innerDropdownMap);
        }
    }, [dropdowns]);

    if (demo) {
        return (
            <div className="App">
                <GlobalContext.Provider value={{ dropdowns, user, isLoadingUser, setUser, dropdownsMap }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <BrowserRouter>
                            <Routes>
                                <Route path={`/${NavigationRoutes.Default}`} element={<Home />} />
                                {<Route path={`/${NavigationRoutes.Home}`} element={<Home />} />}
                                <Route path={`/${NavigationRoutes.Login}`} element={<Login />} />
                                <Route path={`/${NavigationRoutes.Login}/redirect/:redirect`} element={<Login />} />
                                <Route path={`/${NavigationRoutes.Login}/:hash`} element={<Login />} />
                                <Route path={`/${NavigationRoutes.Register}`} element={<Register />} />
                                <Route path={`/${NavigationRoutes.ForgotPassword}`} element={<ForgotPassword />} />
                                <Route path={`/${NavigationRoutes.ResetPassword}/:hash`} element={<ResetPassword />} />
                                <Route path={`/${NavigationRoutes.EstateListing}`} element={<EstateListing />} />
                                <Route path={`/${NavigationRoutes.EstateDetails}/:id`} element={<EstateDetails />} />
                                <Route
                                    path={`/${NavigationRoutes.EstateDetails}/:id/:open_modal`}
                                    element={<EstateDetails />}
                                />
                                <Route
                                    path={`/${NavigationRoutes.EstateDetails}/:id/:open_modal/:order_id`}
                                    element={<EstateDetails />}
                                />
                                <Route path={`/${NavigationRoutes.SellerProfile}/:id`} element={<SellerProfile />} />
                                <Route
                                    path={`/${NavigationRoutes.SellerProfile}/:id/:open_modal`}
                                    element={<SellerProfile />}
                                />
                                <Route
                                    path={`/${NavigationRoutes.SellerProfile}/:id/:open_modal/:order_id`}
                                    element={<SellerProfile />}
                                />
                                <Route path={`/${NavigationRoutes.BuyerProfile}/:id`} element={<BuyerProfile />} />
                                <Route
                                    path={`/${NavigationRoutes.BuyerProfile}/:id/:open_modal`}
                                    element={<BuyerProfile />}
                                />
                                <Route
                                    path={`/${NavigationRoutes.BuyerProfile}/:id/:open_modal/:order_id`}
                                    element={<BuyerProfile />}
                                />
                                <Route path={`/${NavigationRoutes.CreateEstate}`} element={<CreateEstate />} />
                                <Route path={`/${NavigationRoutes.EditEstate}/:id`} element={<EditEstate />} />
                                <Route path={`/${NavigationRoutes.AdminUser}`} element={<AdminUser />} />
                                <Route path={`/${NavigationRoutes.Contractors}`} element={<FutureWork />} />
                                <Route path={`/${NavigationRoutes.Invest}`} element={<Invest />} />
                                <Route path={`/${NavigationRoutes.Usefull}`} element={<FutureWork />} />
                                <Route path={`/${NavigationRoutes.CookiesPolicy}`} element={<CookiesPolicy />} />
                                <Route path={`/${NavigationRoutes.About}`} element={<About />} />
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </GlobalContext.Provider>
            </div>
        );
    }
    return (
        <ThemeProvider theme={theme}>
            <ComingSoon />
        </ThemeProvider>
    );
}

export default App;
