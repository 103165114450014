import { Grid, Paper, Typography } from "@mui/material";
import PlacesAutocomplete from "../../../../../common/PlacesAutocomplete/PlacesAutocomplete";
import CustomTextInput from "../../../../../common/CustomTextInput/CustomerTextInput";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import { createEstateClasses as classes, StyledCreateEstateFormAddress } from "./StyledCreateEstateFormAddress";

interface CreateEstateFormAddressProps {
    form: IForm<SellerProfileState>;
    isDisabled: boolean;
}

const CreateEstateFormAddress = ({ form, isDisabled }: CreateEstateFormAddressProps) => {
    return (
        <StyledCreateEstateFormAddress>
            <Grid container>
                <Paper className={`${classes.rightCards} ${classes.paperContainer}`}>
                    <Typography variant={"h6"} color="#757C8D">
                        Точен адрес
                    </Typography>
                    <PlacesAutocomplete
                        name={"exact_address"}
                        className={classes.addressAutocomplete}
                        placeholder={"Toчен Адрес"}
                        disabled={isDisabled}
                        value={form.values.exact_address}
                        onChange={(newInputValue) => {
                            form.setFieldValue("exact_address", newInputValue);
                        }}
                        error={!!form.touched?.exact_address && !!form.errors?.exact_address}
                        setFieldValue={form.setFieldValue}
                        helperText={form.errors?.exact_address}
                        type={"address"}
                    />
                    <CustomTextInput
                        label="Кадастрален номер"
                        name={"cadastral_identification_number"}
                        className={`${classes.customTextInput} ${classes.checkboxLabel}`}
                        placeholder={"Кадастрален номер"}
                        disabled={isDisabled}
                        value={form.values.cadastral_identification_number}
                        onChange={form.handleChange}
                        error={
                            form.touched?.cadastral_identification_number &&
                            !!form.errors?.cadastral_identification_number
                        }
                        helperText={form.errors.cadastral_identification_number}
                    />
                    <CustomTextInput
                        label="УПИ/ПИ номер"
                        name={"upi_pi_number"}
                        className={`${classes.customTextInput} ${classes.checkboxLabel}`}
                        placeholder={"УПИ/ПИ номер"}
                        disabled={isDisabled}
                        value={form.values.upi_pi_number}
                        onChange={form.handleChange}
                        error={form.touched?.upi_pi_number && !!form.errors?.upi_pi_number}
                        helperText={form.errors.upi_pi_number}
                    />
                </Paper>
            </Grid>
        </StyledCreateEstateFormAddress>
    );
};

export default CreateEstateFormAddress;
