import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_ADDRESS_PREFIX = "CreateEstateFormAddress";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_ADDRESS_PREFIX}-checkboxLabel`,
    rightCards: `${CREATE_ESTATE_FORM_ADDRESS_PREFIX}-rightCards`,
    addressAutocomplete: `${CREATE_ESTATE_FORM_ADDRESS_PREFIX}-addressAutocomplete`,
    paperContainer: `${CREATE_ESTATE_FORM_ADDRESS_PREFIX}-paperContainer`,
    customTextInput: `${CREATE_ESTATE_FORM_ADDRESS_PREFIX}-customTextInput`,
};

export const StyledCreateEstateFormAddress = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.rightCards}`]: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    [`& .${createEstateClasses.addressAutocomplete}`]: {
        marginTop: "20px",
        width: "300px",
        height: "90px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
    },
    [`& .${createEstateClasses.customTextInput}`]: {
        marginTop: "20px",
        width: "300px",
        height: "90px",
    },
}));
