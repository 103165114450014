import { Button, Grid, Paper, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { grey, info, primary, success } from "../../../common/Theme/theme";
import { EstateImagePreview } from "../EstateImagePreview";
import { IEstate } from "../../../interfaces/estate";
import { IAttachment } from "../../../interfaces/attachment";
import { getImageLink } from "../../../endpoints";
import { DropdownMap } from "../../../App";
import {
    StyledGallyModal,
    estateDetailsClasses as classes,
    StyledEstateDetailsUpperContainerRight,
} from "./StyledEstateDetailsUpperContainerRight";

interface EstateDetailsUpperContainerRightProps {
    estate: IEstate;
    openImageModal: boolean;
    attachments: IAttachment[];
    dropdownsMap: DropdownMap | null;
    setOpenImageModal: (value: boolean) => void;
}

const EstateDetailsUpperContainerRight = ({
    estate,
    openImageModal,
    attachments,
    dropdownsMap,
    setOpenImageModal,
}: EstateDetailsUpperContainerRightProps) => {
    return (
        <StyledEstateDetailsUpperContainerRight>
            <Grid container className={classes.upperContainerRight}>
                <Grid container flexDirection={"row"}>
                    <Grid container flexDirection={"column"} width="auto">
                        <Grid container flexDirection={"row"} marginLeft="20px">
                            <CameraAltOutlinedIcon htmlColor={grey} />
                            <Typography marginLeft="10px" className={classes.galleryTitle}>
                                Галерия
                            </Typography>
                        </Grid>
                        {attachments.length === 0 ? (
                            <Carousel
                                autoPlay={false}
                                navButtonsAlwaysVisible={true}
                                className={classes.carouselParameters}>
                                <Grid container className={classes.emptyGalleryState}>
                                    <Typography margin="30px 0" color={primary} variant={"body1"}>
                                        Няма прикачени снимки
                                    </Typography>
                                </Grid>
                            </Carousel>
                        ) : (
                            <Carousel
                                autoPlay={false}
                                navButtonsAlwaysVisible={true}
                                indicatorContainerProps={{
                                    style: {
                                        marginTop: "0",
                                    },
                                }}
                                className={classes.galleryCarousel}>
                                {attachments.map((attachment, i) => (
                                    <Grid container justifyContent="center">
                                        <EstateImagePreview
                                            link={attachment.link}
                                            openModal={() => {
                                                setOpenImageModal(true);
                                            }}
                                            key={attachment.name}
                                        />
                                    </Grid>
                                ))}
                            </Carousel>
                        )}
                    </Grid>
                    <StyledGallyModal
                        open={openImageModal}
                        onClose={() => {
                            setOpenImageModal(false);
                        }}>
                        <Grid
                            container
                            height="100vh"
                            display={"inline-flex"}
                            justifyContent={"center"}
                            alignItems={"center"}>
                            <Grid
                                container
                                className={classes.modalCarouselContainer}
                                width={"fit-content"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}>
                                <Carousel
                                    autoPlay={false}
                                    navButtonsAlwaysVisible={true}
                                    className={classes.modalCarousel}
                                    indicatorContainerProps={{
                                        style: {
                                            marginTop: "0",
                                        },
                                    }}>
                                    {attachments.map((attachment, i) => (
                                        <Grid container justifyContent="center">
                                            <Button
                                                onClick={() => {
                                                    window.open(getImageLink(attachment.link), "_blank");
                                                }}>
                                                <img
                                                    alt=""
                                                    src={getImageLink(attachment.link)}
                                                    className={classes.modalImage}
                                                />
                                            </Button>
                                        </Grid>
                                    ))}
                                </Carousel>
                                <Button
                                    variant="outlined"
                                    className={classes.closeButton}
                                    onClick={() => {
                                        setOpenImageModal(false);
                                    }}>
                                    Затвори
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledGallyModal>
                    {estate && dropdownsMap && (
                        <Grid container flexDirection={"column"} width="auto" marginLeft="20px" marginRight="20px">
                            <Grid container flexDirection={"row"} marginBottom="20px">
                                <FormatListBulletedIcon htmlColor={grey} />
                                <Typography marginLeft="10px" className={classes.galleryTitle}>
                                    Подробности
                                </Typography>
                            </Grid>
                            <Grid container alignItems={"center"} flexDirection={"row"} marginBottom={"20px"}>
                                <Grid marginRight="20px">
                                    <LandscapeOutlinedIcon htmlColor={primary} />
                                </Grid>
                                <Grid>
                                    <Typography className={classes.galleryProperty}>Тип на имота</Typography>
                                    <Typography className={classes.galleryPropertyValue}>
                                        {estate.estate_type_id
                                            ? dropdownsMap!.estateTypes[estate.estate_type_id].name
                                            : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={"center"} flexDirection={"row"} marginBottom={"20px"}>
                                <Grid marginRight="20px">
                                    <SettingsOverscanOutlinedIcon htmlColor={success} />
                                </Grid>
                                <Grid>
                                    <Typography className={classes.galleryProperty}>Площ</Typography>
                                    <Typography className={classes.galleryPropertyValue}>
                                        {estate.area}{" "}
                                        {estate.area_unit_id ? dropdownsMap!.areaUnits[estate.area_unit_id].name : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={"center"} flexDirection={"row"} marginBottom={"20px"}>
                                <Grid marginRight="20px">
                                    <AttachMoneyOutlinedIcon htmlColor={info} />
                                </Grid>
                                <Grid>
                                    <Typography className={classes.galleryProperty}>Цена</Typography>
                                    <Typography className={classes.galleryPropertyValue}>
                                        {estate.price}{" "}
                                        {estate.currency_id
                                            ? dropdownsMap!.currency[estate.currency_id].description
                                            : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </StyledEstateDetailsUpperContainerRight>
    );
};

export default EstateDetailsUpperContainerRight;
