import {
    Alert,
    Grid,
    IconButton,
    Pagination,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import Dropdown from "../../common/Dropdown/Dropdown";
import { IUser, UserStatus, UserType } from "../../interfaces/user";
import { adminUserClasses as classes } from "./StyledAdminUser";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { NavigationRoutes } from "../../routes";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { defaultUserPageSize, userService } from "../../services/user.service";
import { IUserFilters } from "../../interfaces/filters";

export const UsersTabPanel = () => {
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState("");
    const [status, setStatus] = useState("");
    const [users, setUsers] = useState<IUser[]>([]);
    const [usersCount, setUsersCount] = useState<number>(0);
    const [usersPage, setUsersPage] = useState<number>(0);
    const [filters, setFilters] = useState<IUserFilters>({
        email: "",
        status: "",
        type: "",
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const fetchUsers = () => {
        userService.getAll({ ...filters, page: usersPage } as any).then(({ count, rows }) => {
            setUsers(rows);
            setUsersCount(Math.ceil(count / defaultUserPageSize));
        });
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, usersPage]);

    useEffect(() => {
        setFilters({ email: email, status: status, type: userType });
    }, [email, status, userType]);

    const handleUserToggle = (id: number, innerStatus: string) => {
        if (innerStatus == UserStatus.Active) {
            userService
                .deactivate(id)
                .then(() => {
                    fetchUsers();
                    setSuccess(true);
                })
                .catch(() => {
                    setError(true);
                });
        } else {
            userService
                .activate(id)
                .then(() => {
                    fetchUsers();
                    setSuccess(true);
                })
                .catch(() => {
                    setError(true);
                });
        }
    };

    return (
        <>
            <Grid container alignItems="center">
                <Grid container>
                    <Grid container className={classes.emailInputContainer}>
                        <TextField
                            className={classes.emailInput}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            label="Email"
                            variant="outlined"
                        />
                    </Grid>

                    <Dropdown
                        className={classes.dropdown}
                        value={userType}
                        onChange={(event) => setUserType(event.target.value)}
                        name={""}
                        label={"Тип потребител"}
                        options={[
                            { id: UserType.Admin, name: UserType.Admin },
                            { id: UserType.Seller, name: UserType.Seller },
                            { id: UserType.Buyer, name: UserType.Buyer },
                        ]}
                    />
                    <Dropdown
                        className={classes.dropdown}
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                        name={""}
                        label={"Статус"}
                        options={[
                            { id: UserStatus.Active, name: UserStatus.Active },
                            { id: UserStatus.Inactive, name: UserStatus.Inactive },
                        ]}
                    />
                    <IconButton
                        onClick={() => {
                            setEmail("");
                            setUserType("");
                            setStatus("");
                        }}>
                        <FilterAltOffOutlinedIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="right">Тип потребител</TableCell>
                            <TableCell align="right">Статус</TableCell>
                            <TableCell align="right">Редактирай</TableCell>
                            <TableCell align="right">Деактивиране/Активиране</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user: any) => (
                            <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="left">{user.email}</TableCell>
                                <TableCell align="right">{user.type}</TableCell>
                                <TableCell align="right">{user.status}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            if (user.type === UserType.Seller) {
                                                const newWindow = window.open(
                                                    `/${NavigationRoutes.SellerProfile}/${user.id}`,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                );
                                                if (newWindow) {
                                                    newWindow.opener = null;
                                                }
                                            } else if (user.type === UserType.Buyer) {
                                                const newWindow = window.open(
                                                    `/${NavigationRoutes.BuyerProfile}/${user.id}`,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                );
                                                if (newWindow) {
                                                    newWindow.opener = null;
                                                }
                                            }
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    <Switch
                                        checked={user.status == UserStatus.Active}
                                        onClick={() => {
                                            handleUserToggle(user.id, user.status);
                                        }}></Switch>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent={"center"} className={classes.paginationContainer}>
                <Pagination
                    count={usersCount}
                    color="secondary"
                    onChange={(_event, innerPage) => {
                        setUsersPage(innerPage);
                    }}
                />
            </Grid>
            <Snackbar
                open={success}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                onClose={() => setSuccess(false)}>
                <Alert severity="success" onClose={() => setSuccess(false)}>
                    Success
                </Alert>
            </Snackbar>
            <Snackbar
                open={error}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                onClose={() => setError(false)}>
                <Alert severity="error" onClose={() => setError(false)}>
                    Server error
                </Alert>
            </Snackbar>
        </>
    );
};
