import { Grid, Paper, Typography } from "@mui/material";
import DocumentUpload from "../../../../../common/DocumentUpload";
import CustomSwitch from "../../../../../common/CustomSwitch/CustomSwitch";
import CustomTextInput from "../../../../../common/CustomTextInput/CustomerTextInput";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import {
    StyledCreateEstateFormAddDocuments,
    createEstateClasses as classes,
} from "./StyledCreateEstateFormAddDocuments";

interface CreateEstateFormAddDocumentsProps {
    form: IForm<SellerProfileState>;
    handleSwitchToggle: (
        availabilityKey: keyof SellerProfileState,
        fileKey: keyof SellerProfileState,
        attachmentObjectKey: keyof SellerProfileState
    ) => void;
    highlightDocumentUploads: boolean;
    isDisabled: boolean;
}

const CreateEstateFormAddDocuments = ({
    form,
    handleSwitchToggle,
    highlightDocumentUploads,
    isDisabled,
}: CreateEstateFormAddDocumentsProps) => {
    const scrollDownHandler = () => {
        const uploadImagesElement = document.getElementById("uploadImages");
        if (uploadImagesElement) {
            uploadImagesElement.scrollIntoView();
        }
    };
    return (
        <StyledCreateEstateFormAddDocuments>
            <Grid container>
                <Paper className={classes.paperContainer}>
                    <Typography variant={"h6"} color="#757C8D">
                        Налични документи
                    </Typography>
                    <Typography variant={"h6"} color="#757C8D">
                        Ако искате да качите повече от един документ може да го направите в{" "}
                        <Typography
                            variant={"h6"}
                            onClick={scrollDownHandler}
                            className={classes.linkToUploadImages}
                            component="span">
                            секцията за снимки
                        </Typography>
                        .
                    </Typography>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Документ за собственост"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "has_ownership_document",
                                    "ownership_document_attachment_file",
                                    "ownership_document_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"has_ownership_document"}
                            title={"Документ за собственост"}
                            value={form.values.has_ownership_document}
                        />
                        <DocumentUpload
                            name={"ownership_document_attachment_file"}
                            documentObjectName={"ownership_document_attachment_object"}
                            estateId={form.values.id}
                            active={form.values.has_ownership_document}
                            highlight={highlightDocumentUploads}
                            document={form.values.ownership_document_attachment_file}
                            documentObject={form.values.ownership_document_attachment_object}
                            setFieldValue={form.setFieldValue}
                            helperText={form.errors.ownership_document_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Скица"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle("sketch", "sketch_attachment_file", "sketch_attachment_object");
                            }}
                            className={classes.checkboxLabel}
                            name={"sketch"}
                            title={"Скица"}
                            value={form.values.sketch}
                        />
                        <DocumentUpload
                            name={"sketch_attachment_file"}
                            documentObjectName={"sketch_attachment_object"}
                            active={form.values.sketch}
                            estateId={form.values.id}
                            highlight={highlightDocumentUploads}
                            document={form.values.sketch_attachment_file}
                            documentObject={form.values.sketch_attachment_object}
                            setFieldValue={form.setFieldValue}
                            helperText={form.errors.sketch_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Виза за проектиране"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "design_visa",
                                    "design_visa_attachment_file",
                                    "design_visa_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"design_visa"}
                            title={"Виза за проектиране"}
                            value={form.values.design_visa}
                        />
                        <DocumentUpload
                            name={"design_visa_attachment_file"}
                            documentObjectName={"design_visa_attachment_object"}
                            active={form.values.design_visa}
                            highlight={highlightDocumentUploads}
                            estateId={form.values.id}
                            document={form.values.design_visa_attachment_file}
                            documentObject={form.values.design_visa_attachment_object}
                            setFieldValue={form.setFieldValue}
                            helperText={form.errors.design_visa_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Становище за пръсединяване от ЕРП"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "erp_accession_opinion",
                                    "erp_accession_opinion_attachment_file",
                                    "erp_accession_opinion_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"erp_accession_opinion"}
                            title={"Становище за пръсединяване от ЕРП"}
                            value={form.values.erp_accession_opinion}
                        />
                        <DocumentUpload
                            name={"erp_accession_opinion_attachment_file"}
                            documentObjectName={"erp_accession_opinion_attachment_object"}
                            active={form.values.erp_accession_opinion}
                            highlight={highlightDocumentUploads}
                            estateId={form.values.id}
                            document={form.values.erp_accession_opinion_attachment_file}
                            documentObject={form.values.erp_accession_opinion_attachment_object}
                            setFieldValue={form.setFieldValue}
                            helperText={form.errors.erp_accession_opinion_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Предварителен договор с ЕРП"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "preliminary_contract_with_erp",
                                    "preliminary_contract_with_erp_attachment_file",
                                    "preliminary_contract_with_erp_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"preliminary_contract_with_erp"}
                            title={"Предварителен договор с ЕРП"}
                            value={form.values.preliminary_contract_with_erp}
                        />
                        <DocumentUpload
                            name={"preliminary_contract_with_erp_attachment_file"}
                            documentObjectName={"preliminary_contract_with_erp_attachment_object"}
                            active={form.values.preliminary_contract_with_erp}
                            highlight={highlightDocumentUploads}
                            estateId={form.values.id}
                            document={form.values.preliminary_contract_with_erp_attachment_file}
                            documentObject={form.values.preliminary_contract_with_erp_attachment_object}
                            setFieldValue={form.setFieldValue}
                            helperText={form.errors.preliminary_contract_with_erp_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Договор с ЕРП"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "official_contract_with_erp",
                                    "official_contract_with_erp_attachment_file",
                                    "official_contract_with_erp_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"official_contract_with_erp"}
                            title={"Договор с ЕРП"}
                            value={form.values.official_contract_with_erp}
                        />
                        <DocumentUpload
                            name={"official_contract_with_erp_attachment_file"}
                            documentObjectName={"official_contract_with_erp_attachment_object"}
                            active={form.values.official_contract_with_erp}
                            highlight={highlightDocumentUploads}
                            document={form.values.official_contract_with_erp_attachment_file}
                            documentObject={form.values.official_contract_with_erp_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.official_contract_with_erp_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"ПУП"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle("pyp", "pyp_attachment_file", "pyp_attachment_object");
                            }}
                            className={classes.checkboxLabel}
                            name={"pyp"}
                            title={"ПУП"}
                            value={form.values.pyp}
                        />
                        <DocumentUpload
                            name={"pyp_attachment_file"}
                            documentObjectName={"pyp_attachment_object"}
                            active={form.values.pyp}
                            highlight={highlightDocumentUploads}
                            document={form.values.pyp_attachment_file}
                            documentObject={form.values.pyp_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.pyp_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Тежести справка"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "weight_inquiry",
                                    "weights_inquiry_attachment_file",
                                    "weights_inquiry_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"weight_inquiry"}
                            title={"Тежести справка"}
                            value={form.values.weight_inquiry}
                        />
                        <DocumentUpload
                            name={"weights_inquiry_attachment_file"}
                            documentObjectName={"weights_inquiry_attachment_object"}
                            active={form.values.weight_inquiry}
                            highlight={highlightDocumentUploads}
                            document={form.values.weights_inquiry_attachment_file}
                            documentObject={form.values.weights_inquiry_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.weights_inquiry_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Разрешение за строеж"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "building_permit",
                                    "building_permit_attachment_file",
                                    "building_permit_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"building_permit"}
                            title={"Тежести справка"}
                            value={form.values.building_permit}
                        />
                        <DocumentUpload
                            name={"building_permit_attachment_file"}
                            documentObjectName={"building_permit_attachment_object"}
                            active={form.values.building_permit}
                            highlight={highlightDocumentUploads}
                            document={form.values.building_permit_attachment_file}
                            documentObject={form.values.building_permit_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.building_permit_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Наличие на партида ел. за имота"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "availability_of_electricity_batch",
                                    "availability_of_electricity_batch_attachment_file",
                                    "availability_of_electricity_batch_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"availability_of_electricity_batch"}
                            title={"Наличие на партида ел. за имота"}
                            value={form.values.availability_of_electricity_batch}
                        />
                        <DocumentUpload
                            name={"availability_of_electricity_batch_attachment_file"}
                            documentObjectName={"availability_of_electricity_batch_attachment_object"}
                            active={form.values.availability_of_electricity_batch}
                            highlight={highlightDocumentUploads}
                            document={form.values.availability_of_electricity_batch_attachment_file}
                            documentObject={form.values.availability_of_electricity_batch_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.availability_of_electricity_batch_attachment_file}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                    <Grid container marginTop="20px" alignItems={"center"}>
                        <CustomSwitch
                            label={"Друг документ"}
                            disabled={isDisabled}
                            onChange={() => {
                                handleSwitchToggle(
                                    "other_documents",
                                    "other_documents_attachment_file",
                                    "other_documents_attachment_object"
                                );
                            }}
                            className={classes.checkboxLabel}
                            name={"other_documents"}
                            title={"Друг документ"}
                            value={form.values.other_documents}
                        />
                        <DocumentUpload
                            name={"other_documents_attachment_file"}
                            documentObjectName={"other_documents_attachment_object"}
                            active={form.values.other_documents}
                            highlight={highlightDocumentUploads}
                            document={form.values.other_documents_attachment_file}
                            documentObject={form.values.other_documents_attachment_object}
                            setFieldValue={form.setFieldValue}
                            estateId={form.values.id}
                            helperText={form.errors.other_documents_attachment_file}
                            isDisabled={isDisabled}
                        />
                        <CustomTextInput
                            label="Описание"
                            name={"other_documents_attachment_description"}
                            disabled={!form.values.other_documents}
                            className={classes.customTextInput}
                            placeholder={"Описание"}
                            value={form.values.other_documents_attachment_description}
                            onChange={form.handleChange}
                            error={
                                form.touched?.other_documents_attachment_description &&
                                !!form.errors?.other_documents_attachment_description
                            }
                            helperText={form.errors?.other_documents_attachment_description}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </StyledCreateEstateFormAddDocuments>
    );
};

export default CreateEstateFormAddDocuments;
