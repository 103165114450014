import { Drawer, styled } from "@mui/material";

const COOKIES_CONSENT_PREFIX = "CookiesConsent";

export const cookiesConsentClasses = {
    text: `${COOKIES_CONSENT_PREFIX}-text`,
    button: `${COOKIES_CONSENT_PREFIX}-button`,
};

export const StyledCookiesConsentDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${cookiesConsentClasses.text}`]: {
        ontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        margin: "30px",
    },
    [`& .${cookiesConsentClasses.button}`]: {
        textTransform: "capitalize",
    },
}));
