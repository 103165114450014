import React from "react";
import { InputAdornment, TextField, Autocomplete } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { withMemo } from "../withMemo";

const CustomAutocomplete = (props: {
    name: string;
    options: string[];
    handleSelect: (value: string) => void;
    value: string;
    error: boolean;
    label: string;
    helperText: React.ReactNode;
    className?: string;
    sx?: any;
    loading: boolean;
    loadOptions?: () => void;
    defaultValue?: string;
    disabled?: boolean;
}) => {
    return (
        <Autocomplete
            freeSolo
            disableClearable
            id={props.name}
            loading={props.loading}
            options={props.options}
            className={props.className}
            sx={props.sx}
            disabled={props.disabled !== undefined ? props.disabled : false}
            onChange={(event, newValue) => {
                props.handleSelect(newValue);
            }}
            onInputChange={(event, newValue) => {
                props.handleSelect(newValue);
            }}
            onFocus={() => {
                if (props.loadOptions) {
                    props.loadOptions();
                }
            }}
            inputValue={props.value}
            value={props.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    margin="normal"
                    name={props.name}
                    value={props.value}
                    error={props.error}
                    disabled={props.disabled !== undefined ? props.disabled : false}
                    helperText={props.helperText}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="start">
                                <CancelIcon
                                    onClick={() => {
                                        const disabled = props.disabled !== undefined ? props.disabled : false;
                                        if (!disabled) {
                                            props.handleSelect(props.defaultValue!);
                                        }
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default withMemo(CustomAutocomplete, ["error", "value", "helperText", "loading", "options"]);
