import { Grid, styled } from "@mui/material";
import { primary, secondary } from "../../../../../common/Theme/theme";

const CREATE_ESTATE_FORM_EXISTING_PARK_PREFIX = "CreateEstateFormeExistingPark";

export const createEstateClasses = {
    gridBackgroundColorPrimary: `${CREATE_ESTATE_FORM_EXISTING_PARK_PREFIX}-gridBackgroundColorPrimary`,
    gridBackgroundColorSecondary: `${CREATE_ESTATE_FORM_EXISTING_PARK_PREFIX}-gridBackgroundColorSecondary`,
    gridBackgroundColor: `${CREATE_ESTATE_FORM_EXISTING_PARK_PREFIX}-gridBackgroundColor`,
    waterIcon: `${CREATE_ESTATE_FORM_EXISTING_PARK_PREFIX}-waterIcon`,
};

export const StyledCreateEstateFormExistingPark = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.gridBackgroundColorPrimary}`]: {
        background: primary,
    },
    [`& .${createEstateClasses.gridBackgroundColorSecondary}`]: {
        background: secondary,
    },
    [`& .${createEstateClasses.gridBackgroundColor}`]: {
        background: "#515661",
    },
    [`& .${createEstateClasses.waterIcon}`]: {
        color: "white",
        width: "55%",
        height: "50%",
    },
}));
