import { paymentEndpoint } from "../endpoints";
import { httpService, HttpService } from "./http.service";
import { intentionKey, ModalNameParams } from "../Core/EstateDetails/EstateDetails";

export class PaymentService {
    constructor(private readonly httpService: HttpService) {}

    public getAllPlans(): Promise<any> {
        return this.httpService.get(`${paymentEndpoint}/plans`);
    }

    public buyCredits(planId: number, estateId: number | undefined, requestInvoice: boolean): Promise<any> {
        const intention = sessionStorage.getItem(intentionKey) as ModalNameParams;
        return this.httpService.post(`${paymentEndpoint}/buy/credits`, {
            planId,
            estateId: estateId,
            requestInvoice: requestInvoice,
            intention,
        });
    }

    public requestInvoice(data: any): Promise<any> {
        return this.httpService.post(`${paymentEndpoint}/invoice`, data);
    }

    public getOrder(id: number): Promise<any> {
        return this.httpService.get(`${paymentEndpoint}/order/${id}`);
    }
}

export const paymentService = new PaymentService(httpService);
