import { Button, Grid, styled, TextField } from "@mui/material";
import { grey, greyLight } from "../../common/Theme/theme";

const VIEW_PROFILE_PREFIX = "ViewProfile";

export const viewProfileClasses = {
    root: `${VIEW_PROFILE_PREFIX}-root`,
    buttonIcon: `${VIEW_PROFILE_PREFIX}-buttonIcon`,
    inputRow: `${VIEW_PROFILE_PREFIX}-inputRow`,
};

export const StyledViewProfile = styled(Grid)(({ theme }) => ({
    [`&.${viewProfileClasses.root}`]: {
        marginTop: "30px",
        width: "fit-content",
        [theme.breakpoints.up("lg")]: {
            padding: "50px 80px",
        },
        [theme.breakpoints.down("md")]: {
            margin: "auto",
            marginTop: "30px",
            justifyContent: "center",
        },
    },
    [`& .${viewProfileClasses.buttonIcon}`]: {
        marginRight: "10px",
    },
    [`& .${viewProfileClasses.inputRow}`]: {
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.only("sm")]: {
            margin: "50px",
            flexDirection: "column",
        },
    },
}));

export const CssTextField = styled(TextField)(({ theme }) => ({
    color: grey,
    background: "transparent",
    borderColor: greyLight,
    boxSizing: "border-box",
    borderRadius: "8px",
    marginBottom: "40px",
    [theme.breakpoints.up("md")]: {
        marginRight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "300px",
    },
    "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart":
        {
            background: theme.palette.common.white,
            border: "1px solid rgba(225, 224, 223, 0.5)",
        },
    "& label.Mui-focused": {
        color: greyLight,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "rgba(225, 224, 223, 0.5)",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&:hover fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    fontFamily: "Rubik",
    borderRadius: "100px",
    height: "40px",
    textTransform: "capitalize",
    marginBottom: "30px",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
        width: "300px",
        height: "50px",
    },
}));
