import { Grid, styled } from "@mui/material";

const HEADER_PREFIX = "Navigation";

const classArray = [
    "root",
    "logo",
    "container",
    "navigation",
    "appBarLight",
    "pageLinkText",
    "box",
    "navigationInvest",
] as const;

type ArrayElement = typeof classArray[number];

type ClassType = {
    [K in ArrayElement]: string;
};

export const headerClasses = classArray.reduce<ClassType>((combinedClasses, current) => {
    combinedClasses[current] = `${HEADER_PREFIX}-${current}`;
    return combinedClasses;
}, {} as any);

export const StyledHeader = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`& .${headerClasses.appBarLight}`]: {
        backgroundColor: theme.palette.common.white,
        height: "88px",
        [`& .${headerClasses.logo}`]: {
            width: "132px",
            height: "39px",
        },
        [`& .${headerClasses.container}`]: {
            margin: "auto",
        },
        [`& .${headerClasses.navigation}`]: {
            width: "120px",
            fontSize: 16,
            my: 2,
            color: "white",
            display: "block",
            textTransform: "capitalize",
        },
        [`& .${headerClasses.navigationInvest}`]: {
            width: "180px",
            fontSize: 16,
            my: 2,
            color: "white",
            display: "block",
            textTransform: "capitalize",
        },
        [`& .${headerClasses.pageLinkText}`]: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "19px",
            color: "#272D3A",
        },
        [`& .${headerClasses.box}`]: {
            display: "flex",
            flexDirection: "row",
            marginRight: "10px",
        },
    },
}));
