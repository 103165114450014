import { Grid, Paper, Typography } from "@mui/material";
import CustomTextInput from "../../../../../common/CustomTextInput/CustomerTextInput";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import { StyledCreateEstateFormContacts, createEstateClasses as classes } from "./StyledCreateEstateFormContacts";

interface CreateEstateFormContactsProps {
    form: IForm<SellerProfileState>;
    isDisabled: boolean;
}

const CreateEstateFormContacts = ({ form, isDisabled }: CreateEstateFormContactsProps) => {
    return (
        <StyledCreateEstateFormContacts>
            <Grid container>
                <Paper className={`${classes.paperContainer} ${classes.rightCards}`}>
                    <Typography variant={"h6"} color="#757C8D">
                        Контакти
                    </Typography>
                    <CustomTextInput
                        label="Email"
                        name={"email"}
                        className={`${classes.customTextInput} ${classes.checkboxLabel}`}
                        placeholder={"Email"}
                        disabled={isDisabled}
                        onChange={form.handleChange}
                        value={form.values.email}
                        error={form.touched?.email && !!form.errors?.email}
                        helperText={form.errors.email}
                    />
                    <CustomTextInput
                        label="Телефон"
                        name={"phone"}
                        className={`${classes.customTextInput} ${classes.checkboxLabel}`}
                        placeholder={"Телефон"}
                        disabled={isDisabled}
                        multiline
                        value={form.values.phone}
                        onChange={form.handleChange}
                        error={form.touched?.phone && !!form.errors?.phone}
                        helperText={form.errors.phone}
                    />
                </Paper>
            </Grid>
        </StyledCreateEstateFormContacts>
    );
};

export default CreateEstateFormContacts;
