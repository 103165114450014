import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_DISPLAY_ATTACHMENTS_PREFIX = "CreateEstateFormDisplayAttachments";

export const createEstateClasses = {
    paperContainer: `${CREATE_ESTATE_FORM_DISPLAY_ATTACHMENTS_PREFIX}-paperContainer`,
};

export const StyledCreateEstateFormDisplayAttachments = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.paperContainer}`]: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
}));
