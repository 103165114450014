import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { ReactNode } from "react";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { withMemo } from "../withMemo";

const Dropdown = (props: {
    className?: string;
    sx?: any;
    value: any;
    label?: string;
    name: string;
    onChange: StandardInputProps["onChange"];
    options: { id: number | string; name: string }[];
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    startAdornment?: React.ReactNode;
}) => {
    return (
        <FormControl className={props.className} sx={props.sx || {}} error={!!props.error}>
            <FormLabel>{props.label}</FormLabel>
            <Select
                variant="outlined"
                value={props.value}
                disabled={props.disabled}
                name={props.name}
                startAdornment={props.startAdornment}
                onChange={props.onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}>
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
};

export default withMemo(Dropdown, ["value", "error", "placeholder", "helperText", "className", "options", "onChange"]);
