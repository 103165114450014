import { Grid, styled } from "@mui/material";
import { greyLighter } from "../../../common/Theme/theme";

const WHAT_DO_YOU_WANT_T0_BUY_PREFIX = "WhatDoYouWantToBuy";

export const whatDoYouWantToBuyClasses = {
    root: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-root`,
    innerContainer: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-innerContainer`,
    cardsContainer: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-cardsContainer`,
    heading: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-heading`,
    card: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-card`,
    cardIconWrapper: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-cardIconWrapper`,
    cardIcon: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-cardIcon`,
    cardTitle: `${WHAT_DO_YOU_WANT_T0_BUY_PREFIX}-cardTitle`,
};

export const StyledWhatDoYouWantToBuy = styled(Grid)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
        padding: "80px 80px 80px 80px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "20px 20px",
    },
    [`& .${whatDoYouWantToBuyClasses.innerContainer}`]: {
        [theme.breakpoints.down("md")]: {
            alignItems: "center",
        },
    },
    [`& .${whatDoYouWantToBuyClasses.cardsContainer}`]: {
        marginTop: "30px",
    },
    [`& .${whatDoYouWantToBuyClasses.heading}`]: {
        fontFamily: "Oswald",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "32px",
        lineHeight: "32px",
    },
    [`& .${whatDoYouWantToBuyClasses.card}`]: {
        width: "250px",
        height: "250px",
        background: "#FAFAFA",
        borderRadius: "8px",
        margin: "0 10px",
        cursor: "pointer",
    },
    [`& .${whatDoYouWantToBuyClasses.cardIconWrapper}`]: {
        width: "100px",
        height: "100px",
        borderRadius: "100%",
        marginBottom: "20px",
    },
    [`& .${whatDoYouWantToBuyClasses.cardIcon}`]: {
        width: "50px",
        height: "50px",
    },
    [`& .${whatDoYouWantToBuyClasses.cardTitle}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
    },
}));
