import { Grid, Modal, styled, TextField } from "@mui/material";
import { grey, greyDark, greyLight, info, primary, secondary } from "../common/Theme/theme";

const INVEST_PREFIX = "Invest";

const classArray = [
    "root",
    "investPersonImage",
    "paper",
    "investPersonWrapper",
    "slider",
    "investTextField",
    "description",
    "heading",
    "headingSecondColor",
    "containerGreyBackground",
    "greyContainerLeftPanel",
    "greyContainerRightPanel",
    "domainText",
    "darkGreyText",
    "greyText",
    "blueHeading",
    "blueButtonFilled",
    "blueButtonOutlined",
    "rightPanelTexts",
    "rightPanelComingSoon",
    "rightPanelSmallImage",
    "darkGreyHeading",
    "benefitsContainer",
    "benefitsContainerLeft",
    "benefitsContainerRight",
    "benefitsIcon",
    "benefitsIconBlue",
    "benefitsContainerLeftHeading",
    "benefitsContainerLeftText",
    "learnMoreButton",
    "blueText",
    "section4Container",
    "section4Panel",
    "section5Background",
    "section5Card",
    "section5CardText",
    "section5ColumnIcon",
    "section5CardHeading",
    "section6Container",
    "section6UpperContainer",
    "section6LowerContainer",
    "section6UpperContainerPanel",
    "section6StepsText",
    "section6StepsNumber",
    "section6LowerContainerLeftPanel",
    "section6LowerContainerRightPanel",
    "section6LowerContainerHeading",
    "section6LowerContainerHeading2",
    "section6PersonImage",
    "benefitsImage",
    "benefitsImageContainer",
    "benefitsRightContainerRightPanel",
    "benefitsRightContainerLeftPanel",
    "section2Container",
    "section2ContainerLeft",
    "section2ContainerRight",
    "section1Image",
    "label",
    "greyLabel",
    "banknoteIcon",
    "endAdornment",
    "sendButton",
    "video",
    "inputSection6",
    "section6SubmitButton",
    "section6Dropdown",
    "section6Form",
] as const;

type ArrayElement = typeof classArray[number];

type ClassType = {
    [K in ArrayElement]: string;
};

export const investClasses = classArray.reduce<ClassType>((combinedClasses, current) => {
    combinedClasses[current] = `${INVEST_PREFIX}-${current}`;
    return combinedClasses;
}, {} as any);

export const StyledInvest = styled(Grid)(({ theme }) => ({
    [`&.${investClasses.root}`]: {
        overflow: "hidden",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px",
        gap: "36px",
        [theme.breakpoints.down("md")]: {
            padding: "64px 0px 0px 0px",
        },
    },
    [`& .${investClasses.heading}`]: {
        color: info,
        fontFamily: "Rubik",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "60px",
        textTransform: "uppercase",
        marginBottom: "36px",
    },
    [`& .${investClasses.headingSecondColor}`]: {
        color: greyDark,
    },
    [`& .${investClasses.containerGreyBackground}`]: {
        borderRadius: "15px",
        background: "linear-gradient(90deg, #F5F5F5 1.24%, rgba(245, 245, 245, 0.00) 99.88%)",
        display: "flex",
        flexDirection: "row",
        padding: "64px 20px 64px 132px",
        maxWidth: "100%",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "16px",
        },
    },
    [`& .${investClasses.greyContainerLeftPanel}`]: {
        width: "55%",
        doisplay: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
    },
    [`& .${investClasses.greyContainerRightPanel}`]: {
        display: "flex",
        flexDirection: "column",
        width: "45%",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    [`& .${investClasses.domainText}`]: {
        color: "#272D3A",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        textTransform: "uppercase",
        marginBottom: "20px",
    },
    [`& .${investClasses.darkGreyText}`]: {
        color: "#272D3A",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "25px",
        marginBottom: "12px",
    },
    [`& .${investClasses.greyText}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fonteWight: "500",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        marginBottom: "28px",
        textAlign: "justify",
    },
    [`& .${investClasses.blueHeading}`]: {
        color: primary,
        fontFamily: "Rubik",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "56px",
        textTransform: "uppercase",
        marginBottom: "24px",
    },
    [`& .${investClasses.blueButtonFilled}`]: {
        borderRadius: "100px",
        background: primary,
        textTransform: "none",
        padding: "10x 16px",
        marginBottom: "32px",
    },
    [`& .${investClasses.blueButtonOutlined}`]: {
        borderRadius: "100px",
        border: `1px solid ${primary}`,
        textTransform: "none",
    },
    [`& .${investClasses.rightPanelTexts}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px 40px",
    },
    [`& .${investClasses.rightPanelComingSoon}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "48px",
        textTransform: "uppercase",
    },
    [`& .${investClasses.rightPanelSmallImage}`]: {
        width: "241px",
        height: "55px",
    },
    [`& .${investClasses.darkGreyHeading}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "44px",
    },
    [`& .${investClasses.benefitsContainer}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px 128px 0px 64px",
        gap: "52px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "28px",
            padding: "0px",
        },
    },
    [`& .${investClasses.benefitsRightContainerLeftPanel}`]: {
        display: "flex",
        flexDirection: "column",
        width: "70%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    [`& .${investClasses.section2ContainerRight}`]: {
        width: "50%",
        paddingTop: "8px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            gap: "12px",
        },
    },
    [`& .${investClasses.benefitsContainerLeft}`]: {
        display: "flex",
        flexDirection: "column",
        width: "40%",
        borderRadius: "20px",
        background: "#FFEFD0",
        padding: "40px 40px 0 40px",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "20px 20px 0 20px",
        },
    },
    [`& .${investClasses.benefitsIcon}`]: {
        width: "40px",
        height: "40px",
        color: "#FFFFFF",
        background: greyDark,
        borderRadius: "10px",
        padding: "4px",
    },
    [`& .${investClasses.benefitsIconBlue}`]: {
        width: "40px",
        height: "40px",
        color: "#FFFFFF",
        background: primary,
        borderRadius: "10px",
        padding: "4px",
    },
    [`& .${investClasses.benefitsContainerLeftHeading}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "600",
    },
    [`& .${investClasses.benefitsContainerLeftText}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
    },
    [`& .${investClasses.learnMoreButton}`]: {
        height: "32px",
        borderRadius: "100px",
        textTransform: "none",
        color: greyDark,
    },
    [`& .${investClasses.benefitsContainerRight}`]: {
        width: "60%",
        borderRadius: "20px",
        background:
            "linear-gradient(0deg, #F5F5F5 0%, #F5F5F5 100%), linear-gradient(106deg, rgba(255, 174, 19, 0.70) 0%, #FBE4B7 33.35%, rgba(250, 250, 250, 0.66) 56.59%, #FBE3B4 86.29%)",
        display: "flex",
        flexDirection: "row",
        padding: "40px 40px 0 40px",
        overflowX: "visible",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 20px 0px 20px",
        },
    },
    [`& .${investClasses.blueText}`]: {
        color: primary,
        fontFamily: "Rubik",
        fontSize: "24px",
        fontStyle: "normal",
        fonteWight: "500",
        lineHeight: "36px",
    },
    [`& .${investClasses.section4Container}`]: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        padding: "0px 64px",
        gap: "40px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            padding: "0px 20px",
        },
    },
    [`& .${investClasses.benefitsImage}`]: {
        [theme.breakpoints.down("md")]: {
            width: "171px",
            height: "122px",
        },
    },
    [`& .${investClasses.benefitsImageContainer}`]: {
        display: "flex",
        width: "30%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "16px",
        },
    },
    [`& .${investClasses.benefitsRightContainerRightPanel}`]: {
        display: "flex",
        flexDirection: "column",
        width: "30%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    [`& .${investClasses.section2Container}`]: {
        display: "flex",
        flexDirection: "row",
        padding: "20px 64px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    [`& .${investClasses.section2ContainerLeft}`]: {
        width: "50%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexDirection: "column",
            padding: "0px 20px",
        },
    },
    [`& .${investClasses.section4Panel}`]: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            gap: "12px",
        },
    },
    [`& .${investClasses.section5Background}`]: {
        borderRadius: "20px",
        background: "#F5F5F5",
        width: "65%",
        height: "420px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "visible",
        gap: "56px",
        [theme.breakpoints.only("sm")]: {
            marginTop: "40px",
            height: "auto",
            width: "100%",
            background: "none",
            flexDirection: "column",
            justifyContent: "start",
            gap: "80px",
            padding: "0px 16px 100px 16px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            width: "100%",
            background: "none",
            flexDirection: "column",
            justifyContent: "start",
            gap: "72px",
            padding: "0px 16px 100px 16px",
        },
    },
    [`& .${investClasses.section5Card}`]: {
        minWidth: "260px",
        height: "295px",
        transform: "skewY(15deg)",
        background: theme.palette.common.white,
        borderRadius: "20px",
        padding: "40px 20px 20px 20px",
        boxShadow: `3px 3px 10px ${greyLight}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        textAlign: "center",
        [theme.breakpoints.up("xl")]: {
            minWidth: "350px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "60px 20px 20px 20px",
            height: "250px",
        },
    },
    [`& .${investClasses.section5CardText}`]: {
        transform: "skewY(-15deg)",
    },
    [`& .${investClasses.section5ColumnIcon}`]: {
        transform: "skewY(-15deg)",
        position: "absolute",
        top: "-55px",
    },
    [`& .${investClasses.section5CardHeading}`]: {
        transform: "skewY(-15deg)",
    },
    [`& .${investClasses.section6Container}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "100px",
        [theme.breakpoints.down("md")]: {
            marginTop: "60px",
            padding: "0px 20px",
        },
    },
    [`& .${investClasses.section6UpperContainer}`]: {
        borderRadius: "20px",
        border: `2px solid ${secondary}`,
        display: "flex",
        flexDirection: "row",
        gap: "44px",
        width: "80%",
        padding: "50px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            width: "100%",
        },
    },
    [`& .${investClasses.section6LowerContainer}`]: {
        display: "flex",
        flexDirection: "row",
        width: "80%",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            width: "100%",
        },
        borderRadius: "20px",
        boxShadow: "3px 5px 35px 0px rgba(0, 0, 0, 0.08)",
    },
    [`& .${investClasses.section6UpperContainerPanel}`]: {
        width: "50%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    [`& .${investClasses.section6StepsText}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
    },
    [`& .${investClasses.section6StepsNumber}`]: {
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "10px",
    },
    [`& .${investClasses.section6LowerContainerLeftPanel}`]: {
        width: "65%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "50px",
        [theme.breakpoints.down("lg")]: {
            width: "70%",
            padding: "20px",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    [`& .${investClasses.section6LowerContainerRightPanel}`]: {
        width: "35%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        [theme.breakpoints.down("lg")]: {
            width: "30%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    [`& .${investClasses.section6LowerContainerHeading}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
    },
    [`& .${investClasses.section6LowerContainerHeading2}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
    },
    [`& .${investClasses.section6PersonImage}`]: {
        borderRadius: "0px 20px 20px 0px",
        width: "100%",
        height: "100%",
    },
    [`& .${investClasses.inputSection6}`]: {
        height: "40px",
        borderRadius: "100px",
        background: "#F5F5F5",
        border: "none",
        outline: "none",
        padding: "12px 16px",
        minWidth: "230px",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        "&:focus": {
            border: "none",
            outline: "none",
        },
    },
    [`& .${investClasses.section6SubmitButton}`]: {
        borderRadius: "100px",
        background: primary,
        textTransform: "none",
        padding: "10x 16px",
        height: "40px",
        width: "160px",
    },
    [`& .${investClasses.section6Dropdown}`]: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "100px",
            border: "1px solid var(--Final-Border-Color, rgba(225, 224, 223, 0.50))",
            background: "var(--V1-White, #FFF)",
        },
        "& .MuiInputBase-formControl": {
            height: "41px",
            marginTop: "4px",
        },
    },
    [`& .${investClasses.section6Form}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
        overflow: "visible",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "20px",
            overflow: "none",
        },
    },
    [`& .${investClasses.video}`]: {
        borderRadius: "20px",
        width: "80%",
        aspectRatio: "16/9",
    },
}));

export const StyledInvestModal = styled(Modal)(({ theme }) => ({
    width: "100%",
    height: "100%",
    [theme.breakpoints.only("lg")]: {
        width: "550px",
        top: "1%",
        left: "34%",
        right: "33%",
        maxHeight: "950px",
    },
    [theme.breakpoints.only("md")]: {
        width: "550px",
        height: "100%",
        maxHeight: "950px",
        top: "1%",
        left: "34%",
        right: "33%",
    },
    [theme.breakpoints.only("sm")]: {
        width: "550px",
        height: "100%",
        maxHeight: "950px",
        top: "6%",
        left: "16%",
        right: "15%",
    },
    [`& .${investClasses.paper}`]: {
        width: "100%",
        height: "100%",
        padding: "30px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    [`& .${investClasses.slider}`]: {
        color: primary,
        margin: "30px 15px 60px 15px",
    },
    [`& .${investClasses.investTextField}`]: {
        width: "100%",
    },
    [`& .${investClasses.description}`]: {
        height: "250px",
    },
    [`& .${investClasses.label}`]: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "36px",
    },
    [`& .${investClasses.greyLabel}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "36px",
        marginBottom: "4px",
    },
    [`& .${investClasses.banknoteIcon}`]: {
        paddingRight: "4px",
    },
    [`& .${investClasses.endAdornment}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
    },
    [`& .${investClasses.sendButton}`]: {
        width: "130px",
    },
}));

export const InvestCssTextField = styled(TextField)(({ theme }) => ({
    color: grey,
    background: "transparent",
    borderColor: greyLight,
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    height: "90px",
    "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart":
        {
            background: theme.palette.common.white,
            border: "1px solid rgba(225, 224, 223, 0.5)",
        },
    "& label.Mui-focused": {
        color: greyLight,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "rgba(225, 224, 223, 0.5)",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&:hover fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(225, 224, 223, 0.5)",
        },
    },
    "&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    "&input[type=number]": {
        "-moz-appearance": "textfield",
    },
}));
