import { httpService, HttpService } from "./http.service";
import { buyerEndpoint } from "../endpoints";

export class BuyerService {
    constructor(private readonly httpService: HttpService) {}

    public async makeFavourite(estateId: number): Promise<void> {
        await this.httpService.post(`${buyerEndpoint}/favourite/${estateId}`);
    }

    public async makeUnFavourite(estateId: number): Promise<void> {
        await this.httpService.delete(`${buyerEndpoint}/favourite/${estateId}`);
    }
}

export const buyerService = new BuyerService(httpService);
