import { Grid, styled } from "@mui/material";
import { primary, secondary } from "../../../../../common/Theme/theme";

const CREATE_ESTATE_FORM_DETAILS_PREFIX = "CreateEstateFormDetails";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-checkboxLabel`,
    dropdown: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdown`,
    cityAutocomplete: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-cityAutocomplete`,
    priceArea: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-priceArea`,
    paperContainer: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-paperContainer`,
    customTextInputTitle: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-customTextInputTitle`,
    customTextInputDescription: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-customTextInputDescription`,
    dropdownEstateType: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateType`,
    dropdownEstateOfferAndArea: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateOffer`,
    dropdownEstateValue: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateValue`,
    dropdownEstateCountryAreas: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateCountryAreas`,
    dropdownEstateMunicipality: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateMunicipality`,
    dropdownEstatePurpose: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstatePurpose`,
    dropdownEstateLandCategory: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateLandCategory`,
    dropdownEstateLandType: `${CREATE_ESTATE_FORM_DETAILS_PREFIX}-dropdownEstateLandType`,
};

export const StyledCreateEstateFormDetails = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`& .${createEstateClasses.dropdown}`]: {
        [`& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart`]:
            {
                color: "#9BA4AB",
            },
    },
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.cityAutocomplete}`]: {
        width: "100%",
        fontSize: "16px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${createEstateClasses.priceArea}`]: {
        width: "100px",
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "70px",
        },
        [theme.breakpoints.only("xs")]: {
            marginRight: "20px",
            marginBottom: "50px",
        },
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
    [`& .${createEstateClasses.customTextInputTitle}`]: {
        marginTop: "10px",
        width: "400px",
    },
    [`& .${createEstateClasses.customTextInputDescription}`]: {
        marginTop: "20px",
        width: "100%",
    },
    [`& .${createEstateClasses.dropdownEstateType}`]: {
        width: "220px",
        height: "90px",
        marginRight: "20px",
        marginBottom: "30px",
    },
    [`& .${createEstateClasses.dropdownEstateOfferAndArea}`]: {
        width: "150px",
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownEstateValue}`]: {
        width: "100px",
        marginRight: "40px",
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownEstateCountryAreas}`]: {
        width: "240px",
        marginRight: "10px",
        height: "100px",
    },
    [`& .${createEstateClasses.dropdownEstateMunicipality}`]: {
        width: "290px",
        fontSize: "16px",
        marginRight: "10px",
        height: "100px",
    },
    [`& .${createEstateClasses.dropdownEstatePurpose}`]: {
        width: "290px",
        fontSize: "16px",
        marginRight: "10px",
    },
    [`& .${createEstateClasses.dropdownEstateLandCategory}`]: {
        marginRight: "20px",
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownEstateLandType}`]: {
        width: "180px",
        height: "90px",
    },
}));
