import { Grid, Modal, styled } from "@mui/material";

const CREATE_ESTATE_PREFIX = "CreateEstate";

export const createEstateClasses = {
    root: `${CREATE_ESTATE_PREFIX}-root`,
    checkboxLabel: `${CREATE_ESTATE_PREFIX}-checkboxLabel`,
    wizard: `${CREATE_ESTATE_PREFIX}-wizard`,
    dropdown: `${CREATE_ESTATE_PREFIX}-dropdown`,
    messageContainer: `${CREATE_ESTATE_PREFIX}-messageContainer`,
    checkIcon: `${CREATE_ESTATE_PREFIX}-checkIcon`,
    inputContainer: `${CREATE_ESTATE_PREFIX}-inputContainer`,
    dropFilesLabel: `${CREATE_ESTATE_PREFIX}-dropFilesLabel`,
    iconAlign: `${CREATE_ESTATE_PREFIX}-iconAlign`,
    attachmentContainer: `${CREATE_ESTATE_PREFIX}-attachmentContainer`,
    cityAutocomplete: `${CREATE_ESTATE_PREFIX}-cityAutocomplete`,
    addressAutocomplete: `${CREATE_ESTATE_PREFIX}-addressAutocomplete`,
    renewableSpecificFieldContainer: `${CREATE_ESTATE_PREFIX}-renewableSpecificFieldContainer`,
    generatingCapacity: `${CREATE_ESTATE_PREFIX}-generatingCapacity`,
    priceArea: `${CREATE_ESTATE_PREFIX}-priceArea`,
    personalSubstation: `${CREATE_ESTATE_PREFIX}-personalSubstation`,
    titleAndWizardContainer: `${CREATE_ESTATE_PREFIX}-titleAndWizardContainer`,
    wizardContainer: `${CREATE_ESTATE_PREFIX}-wizardContainer`,
    rightCards: `${CREATE_ESTATE_PREFIX}-rightCards`,
    submitButtonContainer: `${CREATE_ESTATE_PREFIX}-submitButtonContainer`,
    fileNamePreview: `${CREATE_ESTATE_PREFIX}-fileNamePreview`,
};

export const StyledCreateEstate = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`&.${createEstateClasses.root}`]: {},
    [`& .${createEstateClasses.wizard}`]: {
        padding: "20px 0",
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-end",
        },
        [theme.breakpoints.only("sm")]: {
            justifyContent: "center",
            width: "100%",
            margin: "auto",
        },
        [theme.breakpoints.only("xs")]: {
            justifyContent: "start",
            width: "100%",
            margin: "auto",
        },
    },
    [`& .${createEstateClasses.dropdown}`]: {
        [`& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart`]:
            {
                color: "#9BA4AB",
            },
    },
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.personalSubstation}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "220px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.messageContainer}`]: {
        padding: "50px",
        width: "100%",
        justifyContent: "center",
    },
    [`& .${createEstateClasses.checkIcon}`]: {
        width: "100px",
        height: "100px",
        color: theme.palette.success.main,
        marginBottom: "25px",
    },
    [`& .${createEstateClasses.inputContainer}`]: {
        width: "100%",
        margin: "20px 0",
    },
    [`& .${createEstateClasses.dropFilesLabel}`]: {
        border: `2px dashed #A5AFB9`,
        fontFamily: "Roboto, serif",
        width: "100%",
        borderRadius: "5px",
        padding: "20px",
        fontSize: "15px",
        color: "#A5AFB9",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            width: "88%",
            marginLeft: "5%",
        },
        [theme.breakpoints.only("sm")]: {
            width: "100%",
        },
    },
    [`& .${createEstateClasses.attachmentContainer}`]: {
        display: "flex",
        justifyContent: "space-between",
    },
    [`& .${createEstateClasses.cityAutocomplete}`]: {
        width: "100%",
        fontSize: "16px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${createEstateClasses.addressAutocomplete}`]: {
        marginTop: "20px",
        width: "300px",
        height: "90px",
        ["& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot"]:
            {
                paddingRight: 0,
            },
    },
    [`& .${createEstateClasses.renewableSpecificFieldContainer}`]: {
        marginTop: "20px",
        height: "120px",
        [theme.breakpoints.only("md")]: {
            height: "unset",
        },
        [theme.breakpoints.only("sm")]: {
            height: "unset",
        },
        [theme.breakpoints.only("xs")]: {
            height: "unset",
        },
    },
    [`& .${createEstateClasses.generatingCapacity}`]: {
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${createEstateClasses.priceArea}`]: {
        width: "100px",
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "70px",
        },
        [theme.breakpoints.only("xs")]: {
            marginRight: "20px",
            marginBottom: "50px",
        },
    },
    [`& .${createEstateClasses.titleAndWizardContainer}`]: {
        [theme.breakpoints.up("md")]: {
            justifyContent: "space-between",
            alignItems: "center",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    [`& .${createEstateClasses.wizardContainer}`]: {
        [theme.breakpoints.up("md")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
    },
    [`& .${createEstateClasses.rightCards}`]: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    ".css-1vl6pfg-MuiGrid-root ul": {
        [theme.breakpoints.down("md")]: {
            padding: "0",
        },
    },
    [`& .${createEstateClasses.submitButtonContainer}`]: {
        [theme.breakpoints.up("md")]: {
            padding: "20px",
            marginBottom: "10px",
            marginLeft: "10px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "20px",
            justifyContent: "center",
        },
    },
    [`& .${createEstateClasses.fileNamePreview}`]: {
        fontSize: "12px",
        color: "#A5AFB9",
    },
}));

const CONFIRM_MODAL_PREFIX = "confirmModal";

export const confirmModalClasses = {
    root: `${CONFIRM_MODAL_PREFIX}-root`,
    paper: `${CONFIRM_MODAL_PREFIX}-paper`,
    acceptButton: `${CONFIRM_MODAL_PREFIX}-acceptButton`,
    declineButton: `${CONFIRM_MODAL_PREFIX}-declineButton`,
};

export const StyledConfirmModal = styled(Modal)(({ theme }) => ({
    [`&.${confirmModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    [`& .${confirmModalClasses.paper}`]: {
        padding: "20px",
        height: "400px",
        width: "500px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        [theme.breakpoints.only("xs")]: {
            height: "500px",
        },
    },
    [`& .${confirmModalClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${confirmModalClasses.acceptButton}`]: {},
}));
