import { Button, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../../routes";
import { StyledCookiesConsentDrawer, cookiesConsentClasses as classes } from "./StyledCookiesConsentDrawer";

export const CookiesConsentDrawer = (props: { open: boolean; onAccept: () => void }) => {
    const navigate = useNavigate();
    return (
        <StyledCookiesConsentDrawer anchor={"bottom"} open={props.open}>
            <Grid container flexDirection={"row"}>
                <Grid item md={10} lg={10}>
                    <Typography className={classes.text}>
                        Този сайт използва бисквитки за по-добро функциониране и анализ на трафика. Натиснете{" "}
                        <Link
                            onClick={() => {
                                navigate(`/${NavigationRoutes.CookiesPolicy}`);
                            }}>
                            тук
                        </Link>
                        , за да разберете повече. Продължавайки напред Вие се съгласявате с нашата политика за
                        бисквитки.
                    </Typography>
                </Grid>
                <Button
                    className={classes.button}
                    onClick={() => {
                        props.onAccept();
                    }}>
                    Приемам
                </Button>
            </Grid>
        </StyledCookiesConsentDrawer>
    );
};
