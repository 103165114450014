import { Grid, Paper, Typography } from "@mui/material";
import { estateDetailsClasses as classes, StyledEstateDetailsMap } from "./StyledEstateDetailsMap";
import { getCoordinatesOrLocation } from "../../../common/utils";
import { GOOGLE_API_KEY } from "../../../env";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";

interface EstateDetailsMapProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
}

const EstateDetailsMap = ({ estate, dropdownsMap }: EstateDetailsMapProps) => {
    return (
        <StyledEstateDetailsMap>
            {estate.country_area_id && estate.municipality_id && estate.city ? (
                <Grid container>
                    <Paper className={classes.mapContainer}>
                        <iframe
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            title={`map`}
                            loading="lazy"
                            allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${getCoordinatesOrLocation(
                                estate,
                                dropdownsMap
                            )}`}
                        />
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
            {estate.exact_address || estate.cadastral_identification_number || estate.upi_pi_number ? (
                <Grid container>
                    <Paper className={classes.paperContainer}>
                        {estate.exact_address && (
                            <Grid container marginTop="10px" flexDirection={"column"}>
                                <Typography variant={"h6"} className={classes.heading}>
                                    Точен адрес
                                </Typography>
                                <Typography variant={"body1"} className={classes.value} marginTop="10px">
                                    {estate.exact_address}
                                </Typography>
                            </Grid>
                        )}
                        {estate.cadastral_identification_number && (
                            <Grid container marginTop="10px">
                                <Typography variant={"body1"} width="100%" className={classes.label}>
                                    Кадастрален номер
                                </Typography>
                                <Typography component="div" variant={"body1"} className={classes.value}>
                                    {estate.cadastral_identification_number}
                                </Typography>
                            </Grid>
                        )}

                        {estate.upi_pi_number && (
                            <Grid container marginTop="10px">
                                <Typography component="div" width="100%" variant={"body1"} className={classes.label}>
                                    УПИ/ПИ номер
                                </Typography>
                                <Typography component="div" variant={"body1"} className={classes.value}>
                                    {estate.upi_pi_number}
                                </Typography>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </StyledEstateDetailsMap>
    );
};

export default EstateDetailsMap;
