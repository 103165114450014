import { Alert, Button, Grid, IconButton, Paper, Snackbar, Typography } from "@mui/material";
import { userService } from "../../../services/user.service";
import CloseIcon from "@mui/icons-material/Close";
import {
    StyledUnlockEstateModal,
    unlockEstateDiscountModalClasses,
} from "./StyledEstateDetailsUnlockEstateDiscountModal";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";
import { ILogged } from "../../../interfaces/user";
import { Cost } from "../../../common/utils";
import React, { useState } from "react";
import unlockIcon from "../assets/unlockIcon.svg";

interface EstateDetailsUnlockEstateDiscountModalProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    user: ILogged | null;
    openUnlockEstateDiscountModal: boolean;
    disableUnlockButton: boolean;
    setOpenUnlockEstateDiscountModal: (value: boolean) => void;
    setOpenSuccessModal: (value: boolean) => void;
    setDisableUnlockButton: (value: boolean) => void;
}

const EstateDetailsUnlockEstateDiscountModal = ({
    estate,
    dropdownsMap,
    openUnlockEstateDiscountModal: openUnlockEstateModal,
    user,
    setOpenUnlockEstateDiscountModal: setOpenUnlockEstateDiscountModal,
    setOpenSuccessModal,
    setDisableUnlockButton,
    disableUnlockButton,
}: EstateDetailsUnlockEstateDiscountModalProps) => {
    const [error, setError] = useState(false);
    if (estate && dropdownsMap) {
        return (
            <StyledUnlockEstateModal
                open={openUnlockEstateModal}
                className={unlockEstateDiscountModalClasses.root}
                onClose={() => {
                    setOpenUnlockEstateDiscountModal(false);
                }}>
                <Paper className={unlockEstateDiscountModalClasses.paper}>
                    <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom="30px">
                        <Grid item>
                            <img src={unlockIcon} alt="unlockIcon" className={unlockEstateDiscountModalClasses.icons} />
                        </Grid>
                        <Grid item>
                            <Typography className={unlockEstateDiscountModalClasses.modalTitle}>
                                Отключване на обявата
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={unlockEstateDiscountModalClasses.icons}
                                onClick={() => {
                                    setOpenUnlockEstateDiscountModal(false);
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography className={unlockEstateDiscountModalClasses.description} marginBottom="30px">
                        За да имаш достъп до документите, трябва да отключиш обявата за{" "}
                        <span className={unlockEstateDiscountModalClasses.price}>
                            {Cost.DocumentMinusContact} кредитa.
                        </span>
                    </Typography>
                    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            className={unlockEstateDiscountModalClasses.acceptButton}
                            disabled={disableUnlockButton}
                            onClick={() => {
                                if (user) {
                                    userService
                                        .unlockEstateDiscount(user.id, estate.id.toString())
                                        .then(() => {
                                            setOpenUnlockEstateDiscountModal(false);
                                            setOpenSuccessModal(true);
                                        })
                                        .catch(() => {
                                            setError(true);
                                        });
                                    setDisableUnlockButton(true);
                                }
                            }}>
                            Отключи
                        </Button>
                    </Grid>
                    <Snackbar
                        open={error}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={() => setError(false)}>
                        <Alert severity="error" onClose={() => setError(false)}>
                            Грешка при отключване на обявата! Проверете дали сте отключили контактите или наличието на
                            достатъчно кредити.
                        </Alert>
                    </Snackbar>
                </Paper>
            </StyledUnlockEstateModal>
        );
    } else {
        return <></>;
    }
};

export default EstateDetailsUnlockEstateDiscountModal;
