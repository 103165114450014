import { IEstate } from "./estate";

export enum UserType {
    Admin = "admin",
    Seller = "seller",
    Buyer = "buyer",
}

export enum UserStatus {
    Active = "active",
    Inactive = "inactive",
}

export interface IUser {
    id: number;
    email: string;
    password: string;
    name: string;
    type: UserType;
    adminId: string;
    sellerId: string;
    buyerId: string;
    status: UserStatus;
}

export interface ISeller extends IUser {
    estates: IEstate[];
}

export interface Buyer extends IUser {
    paymentId: string;
}

export interface IProfile {
    info: {
        name: string;
        phone: string;
        email?: string;
        credit_count: number;
    };
    rows: IEstate[];
    count: number;
}

export interface ILogged {
    id: string;
    role: UserType;
    token: string;
}
