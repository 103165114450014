import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_OTHERS_PREFIX = "CreateEstateFormOthers";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-checkboxLabel`,
    personalSubstation: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-personalSubstation`,
    dropdown: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-dropdown`,
    dropdownVoltage: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-dropdownVoltage`,
    paperContainer: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-paperContainer`,
    numberInputSubstation: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-numberInputSubstation`,
    numberInputElectricityPillar: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-numberInputSubstation`,
    customSwitch: `${CREATE_ESTATE_FORM_OTHERS_PREFIX}-customSwitch`,
};

export const StyledCreateEstateFormOthers = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.personalSubstation}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "220px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.dropdown}`]: {
        [`& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart`]:
            {
                color: "#9BA4AB",
            },
    },
    [`& .${createEstateClasses.dropdownVoltage}`]: {
        width: "100%",
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
    [`& .${createEstateClasses.numberInputSubstation}`]: {
        height: "90px",
        marginRight: "20px",
        width: "200px",
    },
    [`& .${createEstateClasses.numberInputElectricityPillar}`]: {
        marginRight: "20px",
        height: "90px",
    },
    [`& .${createEstateClasses.customSwitch}`]: {
        height: "90px",
    },
}));
