import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_PREVIEW_PREFIX = "CreateEstateFormPreview";

export const createEstateClasses = {
    submitButtonContainer: `${CREATE_ESTATE_FORM_PREVIEW_PREFIX}-submitButtonContainer`,
};

export const StyledCreateEstateFormPreview = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.submitButtonContainer}`]: {
        [theme.breakpoints.up("md")]: {
            padding: "20px",
            marginBottom: "10px",
            marginLeft: "10px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "20px",
            justifyContent: "center",
        },
    },
}));
