import { IAttachment } from "./attachment";

export interface IEstate {
    id: number;
    // Core
    title: string;
    renewable_type_id: number;
    estate_type_id: number;
    offer_type_id: number;

    price: number;
    area: number;
    description: string;

    country_area_id: number;
    municipality_id: number;
    city: string;

    land_category_id: number;
    land_type_id: number;
    upi_purpose: string;

    // Exact Address
    exact_address: string;
    cadastral_identification_number: string;
    upi_pi_number: string;
    coordinates_lat: number;
    coordinates_lng: number;
    chosen_location: boolean;

    // Acting Park Details
    capacity: number;
    capacity_type_id: number;
    pricing_id: number;
    // TODO: Need pricing value
    put_into_operation: boolean;
    put_into_operation_year: number;
    external_financing: boolean;
    external_financing_amount: number;
    support_schemes: boolean;
    support_schemes_description: string;

    // Need contact per estate, since we can copy the existing or create new ones
    email: string;
    phone: string;

    // Present Documents
    has_ownership_document: boolean;
    sketch: boolean;
    design_visa: boolean;
    erp_accession_opinion: boolean;
    preliminary_contract_with_erp: boolean;
    official_contract_with_erp: boolean;
    pyp: boolean;
    weight_inquiry: boolean;
    building_permit: boolean;

    // Other Documents
    is_substation_present: boolean;
    personal_substation: boolean;
    substation_distance_in_meters: number;
    availability_of_electricity_batch: boolean;
    electrical_wire: boolean;
    other_documents: boolean;
    voltage_id: number;
    meters_to_nearest_electricity_pillar: number;
    currency_id: number;
    area_unit_id: number;

    solar_panel_brand: string;
    solar_panel_capacity: number;
    solar_panel_capacity_id: number;
    solar_panel_count: number;
    solar_invertor_brand: string;
    solar_invertor_capacity: number;
    solar_invertor_capacity_id: number;
    solar_invertor_count: number;

    wind_brand: string;
    wind_capacity: number;
    wind_capacity_id: number;
    wind_count: number;
    createdAt?: Date;

    attachments: IAttachment[];

    favourite?: boolean;

    ownership_document_attachment: number;
    sketch_attachment: number;
    erp_accession_opinion_attachment: number;
    official_contract_with_erp_attachment: number;
    preliminary_contract_with_erp_attachment: number;
    pyp_attachment: number;
    weights_inquiry_attachment: number;
    building_permit_attachment: number;
    availability_of_electricity_batch_attachment: number;
    design_visa_attachment: number;
    other_documents_attachment: number;
    other_documents_attachment_description: string;
    has_uploaded_documents: boolean;

    weights_inquiry_attachment_object: IAttachment | null;
    availability_of_electricity_batch_attachment_object: IAttachment | null;
    building_permit_attachment_object: IAttachment | null;
    pyp_attachment_object: IAttachment | null;
    preliminary_contract_with_erp_attachment_object: IAttachment | null;
    design_visa_attachment_object: IAttachment | null;
    official_contract_with_erp_attachment_object: IAttachment | null;
    erp_accession_opinion_attachment_object: IAttachment | null;
    ownership_document_attachment_object: IAttachment | null;
    sketch_attachment_object: IAttachment | null;
    other_documents_attachment_object: IAttachment | null;
    isPaid: boolean;
    promoted: boolean;
}

export interface IEstateResult {
    count: number;
    rows: IEstate[];
}
export enum EstateUnpublishReason {
    Sold = "sold",
    StopSell = "stop sell",
    Other = "other",
}

export enum EstateStatus {
    Published = "published",
    Unpublished = "unpublished",
    UnderReview = "under_review",
}
