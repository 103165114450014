export enum StyleClassPrefix {
    ComingSoon = "ComingSoon",
}

export const addPrefix = (prefix: string, className: string): string => `${prefix}-${className}`;

export function classNames(...className: string[]) {
    const classNames = Array.from(arguments);
    return classNames.length ? classNames.join(" ") : undefined;
}
