export enum NavigationRoutes {
    Default = "",
    Home = "home",
    About = "about",
    Links = "home#links",
    Blog = "https://vei-imot.bg/blog",
    EstateListing = "estate-listing",
    EstateDetails = "estate-details",
    AdminUser = "admin-user",
    AdminEstate = "admin-estate",
    Login = "login",
    Logout = "logout",
    Register = "register",
    ForgotPassword = "forgot-password",
    ResetPassword = "reset-password",
    SellerProfile = "seller-profile",
    CreateEstate = "create-estate",
    EditEstate = "edit-estate",
    BuyerProfile = "buyer-profile",
    Contractors = "contractors",
    Invest = "invest",
    Usefull = "usefull",
    CookiesPolicy = "cookies-policy",
}

export const links: NavigationRoutes[] = [
    NavigationRoutes.Home,
    NavigationRoutes.EstateListing,
    NavigationRoutes.Invest,
    NavigationRoutes.Blog,
    NavigationRoutes.About,
];

export const pages = ["Начало", "Имоти", "Енергийна Общност", "Полезно", "За нас"];
export const supportLinks = [...pages];
export const customNavigate = (navigate: any, route: NavigationRoutes) => {
    if (route === NavigationRoutes.Links) {
        window.location.href = `/${NavigationRoutes.Links}`;
    } else if (route === NavigationRoutes.Blog) {
        window.location.href = NavigationRoutes.Blog;
    } else {
        navigate(`/${route}`);
    }
};
