import React, { useContext, useEffect, useState } from "react";
import {
    confirmModalClasses,
    createEstateClasses as classes,
    StyledConfirmModal,
    StyledCreateEstate,
} from "./StyledCreateEstate";
import { ViewContainer } from "../../../Core/ViewContainer/ViewContainer";
import { Alert, Button, CircularProgress, Container, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { WizardProgressItem, WizardProgressItemStatus } from "../../../common/Wizard/interfaces";
import { Wizard } from "../../../common/Wizard/Wizard";
import {
    defaultEstate,
    deleteDocuments,
    getValuesWithDocumentsIds,
    getValuesWithUpdatedDocumentsIds,
    SellerProfileState,
} from "./utils";
import { estateSchema } from "./schema";
import { useFormik } from "formik";
import { estateService } from "../../../services/estate.service";
import { CreateEstateForm } from "./CreateEstateForm";
import { CreateEstatePreview } from "./CreateEstatePreview";
import { CreateEstateSuccess } from "./CreateEstateSuccess";
import { attachmentService } from "../../../services/attachment.service";
import { IEstate } from "../../../interfaces/estate";
import { IAttachment } from "../../../interfaces/attachment";
import { GlobalContext } from "../../../App";
import { getJoinedLocationParts } from "../../../common/utils";
import { FeatureBoundary } from "../../../common/FeatureBoundary/FeatureBoundary";
import { NavigationRoutes } from "../../../routes";
import { useGA } from "../../../common/hooks";
import { documentKeys } from "../../../services/estate-service.utils";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { grey } from "../../../common/Theme/theme";
import * as Sentry from "@sentry/react";

const createWizardItems = (items: any[]): WizardProgressItem[] =>
    items.map((item, index) => ({ ...item, stepNumber: index + 1 }));

export const managedServicesItems = createWizardItems([
    {
        status: WizardProgressItemStatus.InProgress,
        subTitle: "Избери",
    },
    {
        status: WizardProgressItemStatus.Pending,
        subTitle: "Преглeдай",
    },
    {
        status: WizardProgressItemStatus.Pending,
        subTitle: "Публикувай",
    },
]);

const CreateEstate = ({ estate, oldAttachments }: { estate?: IEstate; oldAttachments?: IAttachment[] }) => {
    useGA();
    const [wizardItems, setWizardItems] = useState<WizardProgressItem[]>(managedServicesItems);
    const [currentStep, setCurrentStep] = useState(0);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [errorMessages, setErrorMessages] = useState<string>("");
    const [estateId, setEstateId] = useState<number>(0);
    const [deletedAttachments, setDeletedAttachments] = useState<IAttachment[]>([]);
    const [innerOldAttachments, setInnerOldAttachments] = useState<IAttachment[]>(oldAttachments || []);
    const { dropdownsMap } = useContext(GlobalContext);
    const [publishing, setPublishing] = useState(false);
    const [openDocumentWarningModal, setOpenDocumentWarningModal] = useState(false);
    const [highlightDocumentUploads, setHighlightDocumentUploads] = useState(false);
    const { user } = useContext(GlobalContext);

    useEffect(() => {
        setInnerOldAttachments(oldAttachments || []);
    }, [oldAttachments]);

    const create = async (values: any) => {
        const location = getJoinedLocationParts(form.values, dropdownsMap);
        values = await getValuesWithDocumentsIds(values);
        estateService
            .save(values, location)
            .then((innerEstate) => {
                setEstateId(innerEstate.id);
                if (attachments.length > 0) {
                    attachmentService
                        .createAttachments(attachments, innerEstate.id)
                        .then(() => {
                            setCurrentStep(2);
                            setPublishing(false);
                        })
                        .catch(() => {
                            setErrorMessages("Server error");
                        });
                } else {
                    setCurrentStep(2);
                    setPublishing(false);
                }
            })
            .catch(() => {
                setErrorMessages("Server error");
            });
    };

    const update = async (values: any) => {
        const location = getJoinedLocationParts(form.values, dropdownsMap);
        let idsForDeletion: number[] = [];
        [values, idsForDeletion] = await getValuesWithUpdatedDocumentsIds(values);
        estateService
            .update(
                {
                    ...estate,
                    ...values,
                },
                location
            )
            .then(async () => {
                setEstateId(estate!.id);
                deleteDocuments(estate!.id, idsForDeletion);
                if (attachments.length > 0) {
                    try {
                        await attachmentService.createAttachments(attachments, estate!.id);
                    } catch (error) {
                        setErrorMessages("Server error");
                        return;
                    }
                }
                if (deletedAttachments.length > 0) {
                    try {
                        await attachmentService.delete(
                            deletedAttachments.map((deletedAttachment) => deletedAttachment.id)
                        );
                    } catch (error) {
                        setErrorMessages("Server error");
                    }
                }
                setCurrentStep(2);
                setPublishing(false);
            })
            .catch((error) => {
                setErrorMessages("Server error");
            });
    };

    const form = useFormik<SellerProfileState>({
        initialValues: {
            ...({
                ...defaultEstate,
                ...estate,
            } || defaultEstate),
        },
        validationSchema: estateSchema,
        onSubmit: (values) => {
            if (currentStep === 1) {
                if (estate) {
                    update(values);
                } else {
                    create(values);
                }
            }
        },
    });

    useEffect(() => {
        form.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkForEmptyFile = () => {
        let showMessage = documentKeys.some((documentKeyGroup) => {
            return (
                form.values[documentKeyGroup.availabilityKey] &&
                !form.values[documentKeyGroup.fileKey] &&
                !form.values[documentKeyGroup.objectKey]
            );
        });
        if (showMessage) {
            setOpenDocumentWarningModal(true);
        } else {
            submitForm();
        }
    };

    const submitForm = async () => {
        await form.submitForm();
        if (form.isValid) {
            setCurrentStep(1);
            setWizardItems(
                createWizardItems([
                    {
                        status: WizardProgressItemStatus.Done,
                        subTitle: "Избери",
                    },
                    {
                        status: WizardProgressItemStatus.InProgress,
                        subTitle: "Преглeдай",
                    },
                    {
                        status: WizardProgressItemStatus.Pending,
                        subTitle: "Публикувай",
                    },
                ])
            );
        } else {
            const errors = Object.values(form.errors).join(",");
            setErrorMessages(errors);
            Sentry.captureException(errors);
        }
    };

    const handleBack = async () => {
        setCurrentStep(0);
        setWizardItems(managedServicesItems);
    };

    const handleReview = async () => {
        await form.validateForm();
        checkForEmptyFile();
    };

    const handleFinish = async () => {
        if (publishing) {
            return;
        }
        await form.submitForm();
        setPublishing(true);
        setWizardItems(
            createWizardItems([
                {
                    status: WizardProgressItemStatus.Done,
                    subTitle: "Избери",
                },
                {
                    status: WizardProgressItemStatus.Done,
                    subTitle: "Преглeдай",
                },
                {
                    status: WizardProgressItemStatus.InProgress,
                    subTitle: "Публикувай",
                },
            ])
        );
    };

    const deletedOldAttachment = (index: number) => {
        const newOldAttachments = innerOldAttachments.filter((attachment, innerIndex) => innerIndex !== index);
        setInnerOldAttachments(newOldAttachments);
        setDeletedAttachments([...deletedAttachments, innerOldAttachments[index]]);
    };

    return (
        <ViewContainer>
            <StyledCreateEstate className={classes.root}>
                {publishing && <CircularProgress sx={{ position: "fixed", left: "50%", top: "50%" }} />}
                <Container fixed>
                    <Grid container sx={{ marginTop: "30px" }}>
                        <Grid container className={classes.titleAndWizardContainer}>
                            <Typography
                                component={"div"}
                                variant={"subtitle1"}
                                sx={{ color: "#515661", fontSize: "20px" }}>
                                Създаване на обява
                            </Typography>
                            <Grid container className={classes.wizardContainer}>
                                <Wizard items={wizardItems} rootClass={classes.wizard} />
                            </Grid>
                        </Grid>
                        {currentStep === 0 && (
                            <CreateEstateForm
                                form={form}
                                next={handleReview}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                oldAttachments={innerOldAttachments}
                                deletedOldAttachment={deletedOldAttachment}
                                highlightDocumentUploads={highlightDocumentUploads}
                            />
                        )}
                        {currentStep === 1 && (
                            <CreateEstatePreview
                                form={form}
                                next={handleFinish}
                                attachments={attachments}
                                oldAttachments={innerOldAttachments}
                                publishing={publishing}
                                back={handleBack}
                            />
                        )}
                        {currentStep === 2 && estateId && <CreateEstateSuccess estateId={estateId} user={user} />}
                    </Grid>
                </Container>
                <Snackbar
                    open={!!errorMessages}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={() => setErrorMessages("")}>
                    <Alert severity="error" onClose={() => setErrorMessages("")}>
                        {errorMessages}
                    </Alert>
                </Snackbar>
            </StyledCreateEstate>
            <StyledConfirmModal
                open={openDocumentWarningModal}
                className={confirmModalClasses.root}
                onClose={() => {
                    setOpenDocumentWarningModal(false);
                }}>
                <Paper className={confirmModalClasses.paper}>
                    <Typography sx={{ fontSize: "30px" }}>Липсващ документ</Typography>
                    <Grid container justifyContent={"center"}>
                        <ImageNotSupportedIcon sx={{ fontSize: "70px" }} htmlColor={grey} />
                    </Grid>
                    <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
                        Не сте качили избрания документ. С цел да повишим качеството на нашата услуга и да представяме
                        максимално информативни обяви на потребителите ни, е необходимо да добавите съответния на
                        маркираното поле документ. Допустимите типове файлове са .jpg, .png, .pdf.
                    </Typography>
                    <Typography
                        sx={{ textAlign: "center" }}
                        marginTop="10px"
                        marginBottom="10px"
                        variant="h3"
                        color={grey}>
                        <i>
                            *Ако желаете може да заличите личните данни съдържащи се в докумнетите, като останалата
                            информация трябва да остане видима и в цялост.
                        </i>
                    </Typography>
                    <Grid container justifyContent={"center"}>
                        <Button
                            className={confirmModalClasses.acceptButton}
                            variant={"contained"}
                            onClick={() => {
                                setHighlightDocumentUploads(true);
                                setOpenDocumentWarningModal(false);
                            }}>
                            Качи Файл
                        </Button>
                    </Grid>
                </Paper>
            </StyledConfirmModal>
        </ViewContainer>
    );
};

export default ({ estate, oldAttachments }: { estate?: IEstate; oldAttachments?: IAttachment[] }) => (
    <FeatureBoundary route={NavigationRoutes.CreateEstate}>
        <CreateEstate estate={estate} oldAttachments={oldAttachments} />
    </FeatureBoundary>
);
