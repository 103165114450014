import { Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, secondary } from "../../../common/Theme/theme";

const ESTATE_DETAILS__CONTACTS_PREFIX = "EstateDetailsContacts";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS__CONTACTS_PREFIX}-heading`,
    label: `${ESTATE_DETAILS__CONTACTS_PREFIX}-label`,
    value: `${ESTATE_DETAILS__CONTACTS_PREFIX}-value`,
    unlockButton: `${ESTATE_DETAILS__CONTACTS_PREFIX}-unlockButton`,
    contactsPaper: `${ESTATE_DETAILS__CONTACTS_PREFIX}-contactsPaper`,
};

export const StyledEstateDetailsContacts = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.label}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.unlockButton}`]: {
        borderRadius: "20px",
        width: "150px",
        textTransform: "none",
        marginTop: "20px",
    },
    [`& .${estateDetailsClasses.contactsPaper}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
}));
