import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { estateService } from "../../../services/estate.service";
import { IEstate } from "../../../interfaces/estate";
import { IAttachment } from "../../../interfaces/attachment";
import CreateEstate from "../CreateEstate/CreateEstate";
import { FeatureBoundary } from "../../../common/FeatureBoundary/FeatureBoundary";
import { NavigationRoutes } from "../../../routes";
import { useGA } from "../../../common/hooks";

export const EditEstate = () => {
    useGA();
    const { id } = useParams();
    const [estate, setEstate] = useState<IEstate>(null as any);
    const [attachments, setAttachments] = useState<IAttachment[]>([]);

    useEffect(() => {
        if (id) {
            estateService.getId(id!).then(({ estate, attachments }) => {
                setEstate(estate);
                setAttachments(attachments);
            });
        }
    }, [id]);

    if (estate) {
        return <CreateEstate estate={estate} oldAttachments={attachments} />;
    } else {
        return null;
    }
};

export default () => (
    <FeatureBoundary route={NavigationRoutes.EditEstate}>
        <EditEstate />
    </FeatureBoundary>
);
