import { Grid, Modal, styled } from "@mui/material";
import { grey, greyLighter, primary, secondary } from "../../common/Theme/theme";

const PLANS_PAPER_PREFIX = "plansPaper";

export const plansPaperClasses = {
    root: `${PLANS_PAPER_PREFIX}-root`,
    paper: `${PLANS_PAPER_PREFIX}-paper`,
    acceptButton: `${PLANS_PAPER_PREFIX}-acceptButton`,
    declineButton: `${PLANS_PAPER_PREFIX}-declineButton`,
    checkIcon: `${PLANS_PAPER_PREFIX}-checkIcon`,
    secondaryText: `${PLANS_PAPER_PREFIX}-secondaryText`,
    planCard: `${PLANS_PAPER_PREFIX}-planCard`,
    planCardContent: `${PLANS_PAPER_PREFIX}-planCardContent`,
    title: `${PLANS_PAPER_PREFIX}-title`,
    description: `${PLANS_PAPER_PREFIX}-description`,
    planTitle: `${PLANS_PAPER_PREFIX}-planTitle`,
    planTitlePrice: `${PLANS_PAPER_PREFIX}-planTitlePrice`,
    planContainerTitle: `${PLANS_PAPER_PREFIX}-planContainerTitle`,
    planDiscountText: `${PLANS_PAPER_PREFIX}-planDiscountText`,
    planDiscountTextContainer: `${PLANS_PAPER_PREFIX}-planDiscountTextContainer`,
    creditsIcon: `${PLANS_PAPER_PREFIX}-creditsIcon`,
    customCheckBox: `${PLANS_PAPER_PREFIX}-customCheckBox`,
    customCheckBoxLabel: `${PLANS_PAPER_PREFIX}-customCheckBoxLabel`,
    highlightBorder: `${PLANS_PAPER_PREFIX}-highlightBorder`,
    crossOut: `${PLANS_PAPER_PREFIX}-crossOut`,
    closeIcon: `${PLANS_PAPER_PREFIX}-closeIcon`,
    visibilityOfPayment: `${PLANS_PAPER_PREFIX}-visibilityOfPayment`,
    buttonContainer: `${PLANS_PAPER_PREFIX}-buttonContainer`,
    titleContainer: `${PLANS_PAPER_PREFIX}-titleContainer`,
};
export const StyledPlansPaperCredits = styled(Grid)(({ theme }) => ({
    [`&.${plansPaperClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    [`& .${plansPaperClasses.paper}`]: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            width: "730px",
        },
    },
    [`& .${plansPaperClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${plansPaperClasses.acceptButton}`]: {
        width: "248px",
        height: "40px",
        marginTop: "90px",
        marginBottom: "30px",
        left: "20px",
        gap: "8px",
        borderRadius: "100px",
        color: "white",
    },
    [`& .${plansPaperClasses.checkIcon}`]: {
        color: primary,
        marginRight: "10px",
    },
    [`& .${plansPaperClasses.secondaryText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
    [`& .${plansPaperClasses.planCard}`]: {
        [theme.breakpoints.only("sm")]: {
            width: "250px",
            margin: "auto",
            marginBottom: "20px",
        },
        width: "284px",
        height: "173px",
        borderRadius: "10px",
        marginBottom: "20px",
        gap: "16px",
    },
    [`& .${plansPaperClasses.planCardContent}`]: {
        marginTop: "40px",
        height: "102px",
    },
    [`& .${plansPaperClasses.title}`]: {
        [theme.breakpoints.only("xs")]: {
            paddingRight: "30px",
        },
        color: "#515661",
    },
    [`& .${plansPaperClasses.titleContainer}`]: {
        [theme.breakpoints.only("xs")]: {
            width: "290px",
            paddingBottom: "40px",
            margin: "auto",
        },
        [theme.breakpoints.only("sm")]: {
            width: "270px",
            paddingBottom: "40px",
            margin: "auto",
        },
        width: "260px",
    },
    [`& .${plansPaperClasses.description}`]: {
        fontFamily: "Rubik",
        fontWeight: "500",
        fontSize: "14px",
        fontStyle: "italic",
    },
    [`& .${plansPaperClasses.planTitle}`]: {
        fontFamily: "Rubik",
        fontWeight: "500",
        fontSize: "24px",
        marginBottom: "7px",
        color: "#515661",
        lineHeight: "28px",
        textAlign: "center",
    },
    [`& .${plansPaperClasses.planTitlePrice}`]: {
        fontFamily: "Rubik",
        fontWeight: "700",
        fontSize: "16px",
        color: "#515661",
        lineHeight: "19px",
        textAlign: "center",
    },
    [`& .${plansPaperClasses.planDiscountText}`]: {
        top: "3px",
        textAlign: "center",
        fontWeight: "500",
        fontSize: "12px",
        color: greyLighter,
        lineHeight: "14px",
    },
    [`& .${plansPaperClasses.planDiscountTextContainer}`]: {
        width: "103px",
        height: "20px",
        gap: "10px",
        marginLeft: "70px",
        borderRadius: "4px",
        backgroundColor: secondary,
    },
    [`& .${plansPaperClasses.creditsIcon}`]: {
        paddingLeft: "30px",
    },
    [`& .${plansPaperClasses.customCheckBox}`]: {
        lineHeight: "40px",
        color: grey,
        left: "28px",
    },
    [`& .${plansPaperClasses.customCheckBoxLabel}`]: {
        fontFamily: "Rubik",
        fontWeight: "500",
        fontSize: "14px",
    },
    [`& .${plansPaperClasses.highlightBorder}`]: {
        border: "solid 3px",
        borderColor: secondary,
        borderRadius: "10px",
    },
    [`& .${plansPaperClasses.crossOut}`]: {
        textDecoration: "line-through",
    },
    [`& .${plansPaperClasses.visibilityOfPayment}`]: {
        visibility: "hidden",
    },
    [`& .${plansPaperClasses.buttonContainer}`]: {
        width: "284px",
        margin: "auto",
    },
}));

const REQUEST_INVOICE_MODAL_PREFIX = "requestInvoiceModal";

export const requestInvoiceModalClasses = {
    root: `${REQUEST_INVOICE_MODAL_PREFIX}-root`,
    paper: `${REQUEST_INVOICE_MODAL_PREFIX}-paper`,
    acceptButton: `${REQUEST_INVOICE_MODAL_PREFIX}-acceptButton`,
    description: `${REQUEST_INVOICE_MODAL_PREFIX}-description`,
};

export const StyledRequestInvoiceModal = styled(Modal)(({ theme }) => ({
    [`&.${requestInvoiceModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${requestInvoiceModalClasses.paper}`]: {
        padding: "20px",
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    [`& .${requestInvoiceModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
        marginBottom: "10px",
    },
    [`& .${requestInvoiceModalClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
}));
