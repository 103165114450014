import { Modal, styled } from "@mui/material";
import { primary } from "../Theme/theme";
import { addPrefix } from "../Theme/utils";

const prefix = "RegisterModal";

const classes = {
    header: addPrefix(prefix, "header"),
    contentContainer: addPrefix(prefix, "contentContainer"),
    message: addPrefix(prefix, "message"),
    confirmButton: addPrefix(prefix, "confirmButton"),
    modalContent: addPrefix(prefix, "modalContent"),
    button: addPrefix(prefix, "button"),
    buttonText: addPrefix(prefix, "buttonText"),
};

export const StyledRegisterModal = styled(Modal)(({ theme }) => ({
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    [`.${classes.modalContent}`]: {
        marginTop: 30,
        maxWidth: 800,
        maxHeight: 740,
        borderRadius: 16,
        background: "white",
        [theme.breakpoints.between("xs", "md")]: {
            overflowX: "hidden",
            overflowY: "scroll",
        },
        [theme.breakpoints.only("xs")]: {
            margin: 5,
        },
        [theme.breakpoints.only("sm")]: {
            margin: 10,
        },
        [theme.breakpoints.only("md")]: {
            margin: 15,
        },
        [`& .${classes.header}`]: {
            width: "100%",
            height: 60,
            background: primary,
            color: "white",
            fontFamily: "Roboto",
            borderRadius: "16px 16px 0 0",
        },
        [`& .${classes.contentContainer}`]: {
            width: "100%",
            padding: 20,
        },
        [`& .${classes.message}`]: {
            textAlign: "center",
        },
        [`& .${classes.confirmButton}`]: {
            backgroundColor: primary,
        },
    },
    [`& .${classes.button}`]: {
        textTransform: "capitalize",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: "100px",
        margin: "10px",
    },
    [`& .${classes.buttonText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.1px",
    },
}));

export const styledRegisterModalClasses = classes;
