import { estateDetailsClasses as classes, StyledEstateDetailsType } from "./StyledEstateDetailsType";
import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { existingParkId, solarRenewableId, windRenewableId } from "../../../Seller/SellerProfile/CreateEstate/schema";
import { Info } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";

interface EstateDetailsTypeProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
}

const EstateDetailsType = ({ estate, dropdownsMap }: EstateDetailsTypeProps) => {
    if (estate.estate_type_id === existingParkId) {
        return (
            <StyledEstateDetailsType>
                <Grid container>
                    <Paper className={classes.paperContainer}>
                        <Typography variant={"h6"} component={"div"} className={classes.heading}>
                            Действащ парк
                        </Typography>
                        <Grid container marginTop="20px">
                            <Typography variant={"body1"} className={classes.value}>
                                Генерираща мощност от {estate.capacity}{" "}
                                {dropdownsMap!.capacityTypes[estate.capacity_type_id]?.name}
                            </Typography>
                        </Grid>

                        <Grid container marginTop="20px">
                            <Typography variant={"body1"} className={classes.value}>
                                Ценообразуване на {dropdownsMap!.pricing[estate.pricing_id]?.name}
                            </Typography>
                        </Grid>

                        {estate.support_schemes && (
                            <Grid container marginTop="20px" height="30px">
                                <CheckIcon className={classes.checkIcon} />
                                <Typography variant={"body1"} className={classes.value}>
                                    Подпомагащи схеми: {estate.support_schemes_description}
                                </Typography>
                            </Grid>
                        )}

                        {estate.external_financing && (
                            <Grid container marginTop="10px" height="30px" alignItems={"center"}>
                                <CheckIcon className={classes.checkIcon} />
                                <Typography variant={"body1"} className={classes.value}>
                                    Външно финансиране стойност {estate.external_financing_amount}
                                </Typography>
                                <Tooltip title="Под външно финансиране се разбира финансиране от кредити, институции, банки, лизинги, оперативни програми и тнт.">
                                    <IconButton>
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}

                        {estate.put_into_operation && (
                            <Grid container marginTop="10px" height="60px" alignItems={"center"}>
                                <CheckIcon className={classes.checkIcon} />
                                <Typography variant={"body1"} className={classes.value}>
                                    Въведен в експлотация през {estate.put_into_operation_year}
                                </Typography>
                            </Grid>
                        )}

                        {estate.renewable_type_id === solarRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <Grid item lg={4} md={4} className={classes.renewableSpecificTypeById}>
                                    <Typography component={"div"} variant={"body1"} className={classes.label}>
                                        Марка панели:
                                    </Typography>
                                    <Typography component={"div"} variant={"body1"} className={classes.value}>
                                        {estate.solar_panel_brand}
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component="div" variant={"body1"} className={classes.label}>
                                        Капацитет на панел
                                    </Typography>
                                    <Typography component="div" variant={"body1"} className={classes.value}>
                                        {estate.solar_panel_capacity}
                                        {dropdownsMap!.capacityTypes[estate.solar_panel_capacity_id]?.name}
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component="div" variant={"body1"} className={classes.label}>
                                        Брой панели
                                    </Typography>
                                    <Typography component="div" variant={"body1"} className={classes.value}>
                                        {estate.solar_panel_count}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {estate.renewable_type_id === solarRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <Grid item lg={4} md={4} className={classes.renewableSpecificTypeById}>
                                    <Typography component={"div"} variant={"body1"} className={classes.label}>
                                        Марка инвертори:
                                    </Typography>
                                    <Typography component={"div"} variant={"body1"} className={classes.value}>
                                        {estate.solar_invertor_brand}
                                    </Typography>
                                </Grid>
                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component={"div"} variant={"body1"} className={classes.label}>
                                        Капацитет на инвертор:
                                    </Typography>
                                    <Typography component={"div"} variant={"body1"} className={classes.value}>
                                        {estate.solar_invertor_capacity}
                                        {dropdownsMap!.invertorCapacityTypes[estate.solar_invertor_capacity_id]?.name}
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component="div" variant={"body1"} className={classes.label}>
                                        Брой инвертори
                                    </Typography>
                                    <Typography component="div" variant={"body1"} className={classes.value}>
                                        {estate.solar_invertor_count}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {estate.renewable_type_id === windRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <Grid item lg={4} md={4} className={classes.renewableSpecificTypeById}>
                                    <Typography component={"div"} variant={"body1"} className={classes.label}>
                                        Марка вятърни турбини:
                                    </Typography>
                                    <Typography component={"div"} variant={"body1"} className={classes.value}>
                                        {estate.wind_brand}
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component="div" variant={"body1"} className={classes.label}>
                                        Капацитет на турбина
                                    </Typography>
                                    <Typography component="div" variant={"body1"} className={classes.value}>
                                        {estate.wind_capacity}
                                        {dropdownsMap!.windCapacityTypes[estate.wind_capacity_id]?.name}
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} className={classes.renewableSpecificTypeById}>
                                    <Typography component="div" variant={"body1"} className={classes.label}>
                                        Брой вятърни турбини
                                    </Typography>
                                    <Typography component="div" variant={"body1"} className={classes.value}>
                                        {estate.wind_count}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            </StyledEstateDetailsType>
        );
    }
    return null;
};

export default EstateDetailsType;
