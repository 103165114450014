import { Grid, styled } from "@mui/material";
import { getImageLink } from "../../endpoints";

const ESTATE_IMAGE_PREVIEW_PREFIX = "EstateImagePreview";

export const imagePreviewClasses = {
    image: `${ESTATE_IMAGE_PREVIEW_PREFIX}-image`,
    bigImage: `${ESTATE_IMAGE_PREVIEW_PREFIX}-bigImage`,
};

export const StyledEstateImagePreview = styled(Grid)(({ theme }) => ({
    width: "400px",
    height: "280px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    objectFit: "contain",
    [`& .${imagePreviewClasses.image}`]: {
        width: "400px",
        height: "280px",
        objectFit: "contain",
    },
}));

export const previewSizes = { big: "big", small: "small" };

const classes = imagePreviewClasses;

export const EstateImagePreview = ({ link, openModal }: { link: string; openModal: () => void }) => {
    return (
        <StyledEstateImagePreview container>
            {link && (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/alt-text
                <img
                    src={getImageLink(link)}
                    className={classes.image}
                    onClick={() => {
                        openModal();
                    }}
                />
            )}
        </StyledEstateImagePreview>
    );
};
