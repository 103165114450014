import { Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, primary, secondary } from "../../../common/Theme/theme";

const ESTATE_DETAILS_DESCRIPTION_PREFIX = "EstateDetailsDescription";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-heading`,
    label: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-label`,
    value: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-value`,
    unlockButton: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-unlockButton`,
    contactsPaper: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-contactsPaper`,
    description: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-description`,
    paperContainer: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-paperContainer`,
    estateTypeContainer: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-estateTypeContainer`,
    estateTypeContainerImageOfSolarIcon: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-estateTypeContainerImageOfSolarIcon`,
    estateTypeContainerImageOfWindIcon: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-estateTypeContainerImageOfWindIcon`,
    estateTypeContainerImageOfWaterIcon: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-estateTypeContainerImageOfWaterIcon`,
    WaterIcon: `${ESTATE_DETAILS_DESCRIPTION_PREFIX}-WaterIcon`,
};

export const StyledEstateDetailsDescription = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.label}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.unlockButton}`]: {
        borderRadius: "20px",
        width: "150px",
        textTransform: "none",
        marginTop: "20px",
    },
    [`& .${estateDetailsClasses.contactsPaper}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    [`& .${estateDetailsClasses.description}`]: {
        width: "800px",
        overflow: "visible",
        overflowWrap: "anywhere",
        [theme.breakpoints.only("xs")]: {
            width: "400px",
        },
    },
    [`& .${estateDetailsClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
    [`& .${estateDetailsClasses.estateTypeContainer}`]: {
        borderRadius: "5px",
        padding: "2px",
        marginRight: "20px",
    },
    [`& .${estateDetailsClasses.estateTypeContainerImageOfSolarIcon}`]: {
        borderRadius: "50%",
        width: "100px",
        height: "100px",
        marginBottom: "20px",
        background: secondary,
    },
    [`& .${estateDetailsClasses.estateTypeContainerImageOfWindIcon}`]: {
        borderRadius: "50%",
        width: "100px",
        height: "100px",
        marginBottom: "20px",
        background: "#515661",
    },
    [`& .${estateDetailsClasses.estateTypeContainerImageOfWaterIcon}`]: {
        borderRadius: "50%",
        width: "100px",
        height: "100px",
        marginBottom: "20px",
        background: primary,
    },
    [`& .${estateDetailsClasses.WaterIcon}`]: {
        color: "white",
        width: "55%",
        height: "50%",
    },
}));
