import { Modal, styled } from "@mui/material";

const INVOICE_MODAL_PREFIX = "invoiceModal";

export const invoiceModalClasses = {
    root: `${INVOICE_MODAL_PREFIX}-root`,
    paper: `${INVOICE_MODAL_PREFIX}-paper`,
    acceptButton: `${INVOICE_MODAL_PREFIX}-acceptButton`,
    description: `${INVOICE_MODAL_PREFIX}-description`,
    dropdown: `${INVOICE_MODAL_PREFIX}-dropdown`,
    textInput: `${INVOICE_MODAL_PREFIX}-textInput`,
    checkbox: `${INVOICE_MODAL_PREFIX}-checkbox`,
};
export const StyledInvoiceModal = styled(Modal)(({ theme }) => ({
    [`&.${invoiceModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${invoiceModalClasses.paper}`]: {
        padding: "20px",
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "600px",
        overflowY: "scroll",
    },
    [`& .${invoiceModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
        marginTop: "20px",
    },
    [`& .${invoiceModalClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
    [`& .${invoiceModalClasses.dropdown}`]: {
        marginTop: "20px",
    },
    [`& .${invoiceModalClasses.textInput}`]: {
        marginTop: "20px",
    },
    [`& .${invoiceModalClasses.checkbox}`]: {
        marginTop: "20px",
    },
}));
