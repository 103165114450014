import { Grid, styled } from "@mui/material";
import { addPrefix, StyleClassPrefix } from "../common/Theme/utils";

const prefix = StyleClassPrefix.ComingSoon;

export const comingSoonClasses = {
    root: addPrefix(prefix, "root"),
    rightColumn: addPrefix(prefix, "rightColumn"),
    blueLabel: addPrefix(prefix, "blueLabel"),
    yellowLabel: addPrefix(prefix, "yellowLabel"),
    mobileLogo: addPrefix(prefix, "mobileLogo"),
    headerText: addPrefix(prefix, "headerText"),
    subHeaderText: addPrefix(prefix, "subHeaderText"),
    leftColumn: addPrefix(prefix, "leftColumn"),
};

export const StyledComingSoon = styled(Grid)(({ theme }) => ({
    [`&.${comingSoonClasses.root}`]: {
        width: "100%",
        height: "100vh",
    },
    [`& .${comingSoonClasses.rightColumn}`]: {
        padding: "0 160px",
        background: "rgba(225, 224, 223, 0.5)",
        [theme.breakpoints.only("xs")]: {
            padding: "10px",
        },
    },
    [`& .${comingSoonClasses.yellowLabel}`]: {
        color: "#FFAE13",
    },
    [`& .${comingSoonClasses.blueLabel}`]: {
        color: "#2975BC",
    },
    [`& .${comingSoonClasses.headerText}`]: {
        fontWeight: 700,
        lineHeight: "36px",
        textTransform: "uppercase",
        marginBottom: "50px",
        marginTop: "100px",
        fontFamily: "Oswald",
        textAlign: "left",
    },
    [`& .${comingSoonClasses.subHeaderText}`]: {
        fontWeight: 700,
        lineHeight: "25px",
        textTransform: "uppercase",
        marginBottom: "22px",
        fontFamily: "Rubik",
        fontSize: "16px",
        textAlign: "justify",
        width: "100%",
        color: "#757C8D",
        [theme.breakpoints.only("xs")]: {
            width: "90%",
            textAlign: "left",
        },
    },
    [`& .${comingSoonClasses.mobileLogo}`]: {
        display: "none",
        [theme.breakpoints.only("xs")]: {
            padding: "32px 0 0 36px",
            background: "rgba(225, 224, 223, 0.5)",
            display: "flex",
            justifyContent: "center",
        },
    },
    [`& .${comingSoonClasses.leftColumn}`]: {
        padding: "0 120px",
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
}));
