import { Button, Grid, Paper, Typography } from "@mui/material";
import { estateDetailsClasses as classes, StyledEstateDetailsContacts } from "./StyledEstateDetailsContacts";
import { IEstate } from "../../../interfaces/estate";
import { RegisterModal } from "../../../common/ConfirmModal/RegisterModal";

interface EstateDetailsContactsProps {
    estate: IEstate;
    setOpenContactPreviewModal: (value: boolean) => void;
    isGuest: boolean;
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
}

const EstateDetailsContacts = ({
    estate,
    setOpenContactPreviewModal,
    isGuest,
    isOpened,
    setIsOpened,
}: EstateDetailsContactsProps) => {
    if (estate.email && estate.phone) {
        return (
            <StyledEstateDetailsContacts>
                <Grid container>
                    <Paper className={classes.contactsPaper}>
                        <Typography variant={"h6"} className={classes.heading}>
                            Контакти
                        </Typography>
                        <Grid container marginTop="10px">
                            <Typography variant={"body1"} className={classes.label}>
                                Email:&nbsp;
                            </Typography>
                            <Typography variant={"body1"} className={classes.value}>
                                {estate.email}
                            </Typography>
                        </Grid>
                        <Grid container marginTop="10px">
                            <Typography variant={"body1"} className={classes.label}>
                                Телефон:&nbsp;
                            </Typography>
                            <Typography variant={"body1"} className={classes.value}>
                                {estate.phone}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </StyledEstateDetailsContacts>
        );
    }
    return (
        <StyledEstateDetailsContacts>
            <Grid container>
                <Paper className={classes.contactsPaper}>
                    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant={"h6"} className={classes.heading}>
                                Контакти
                            </Typography>
                            <Grid container marginTop="10px">
                                <Typography variant={"body1"} className={classes.label}>
                                    Email:&nbsp;
                                </Typography>
                                <Typography variant={"body1"} className={classes.value}>
                                    *****@***.***
                                </Typography>
                            </Grid>
                            <Grid container marginTop="10px">
                                <Typography variant={"body1"} className={classes.label}>
                                    Телефон:&nbsp;
                                </Typography>
                                <Typography variant={"body1"} className={classes.value}>
                                    08********
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item alignItems={"center"} justifyContent={"center"}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.unlockButton}
                                onClick={() => {
                                    if (isGuest) {
                                        setIsOpened(true);
                                    } else {
                                        setOpenContactPreviewModal(true);
                                    }
                                }}>
                                Отключи
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <RegisterModal isOpened={isOpened} cancel={() => setIsOpened(false)} />
        </StyledEstateDetailsContacts>
    );
};

export default EstateDetailsContacts;
