import React from "react";

import { Grid, Typography } from "@mui/material";
import { StyledWizard, styledWizardClasses as classes } from "./StyledWizard";
import { WizardProgressItem } from "./interfaces";

const ProgressItemBottom = ({ subTitle = "SubTitle text", status, stepNumber }: WizardProgressItem) => (
    <li className={`${classes.progressItem} ${status}`} key={stepNumber}>
        <Grid className={classes.stepPoint}>
            <Typography variant="h6">{stepNumber}</Typography>
        </Grid>
        <Typography variant="h5" className={classes.subtitleText}>
            {subTitle}
        </Typography>
        <hr className={classes.itemLine} />
    </li>
);

export const Wizard = ({ items, rootClass, xs }: { items: WizardProgressItem[]; rootClass?: string; xs?: number }) => {
    return (
        <StyledWizard container alignItems="center" justifyContent="center" className={rootClass}>
            <Grid item xs={xs || 6}>
                <ul>{items.map(ProgressItemBottom)}</ul>
            </Grid>
        </StyledWizard>
    );
};
