import { aboutClasses as classes, logoStyle, StyledAbout } from "./StyledAbout";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { Container, Grid, Link, Typography } from "@mui/material";
import solarEstateLogo from "./solar-estate-logo.svg";
import luchezar from "./luchezar.png";
import zdravko from "./zdravko.png";

export const About = () => {
    return (
        <ViewContainer>
            <StyledAbout className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid
                            lg={10}
                            md={10}
                            container
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <img alt="solar estate logo" src={solarEstateLogo} style={logoStyle}></img>
                            <Typography variant="h1" className={classes.title}>
                                Историята на Solar Estates
                            </Typography>
                            <Grid alignItems={"left"} marginBottom={"20px"}>
                                <Typography className={classes.heading}>Предистория</Typography>
                                <Typography className={classes.paragraph}>
                                    Устойчивото енергийно бъдеще на човечеството в последните години, е тема от
                                    най-голямо значение и все повече хора, и индустрии започват да си дават сметка за
                                    това.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    Преди 10-15 години се случи първата вълна на усилено внедряване на възобновяеми
                                    енергийни източници (ВЕИ) в България, като тази вълна беше стимулирана от държавата
                                    с преференциални цени за изкупуване, на произведената енергия. Това се дължеше на
                                    факта, че технологиите бяха относително нови, с висока цена на инвестициите, както и
                                    потенциално неконкурентни, на конвенционалния пазар за електроенергия.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    Засиленият интерес от подобна гарантирана инвестиция, доведе до сериозни по обем
                                    реализирани ВЕИ проекти в периода и последващо бързо намаляване на стимулите от
                                    страна на държавата. Така се стигна до момента от преди 2-3 години, когато ВЕИ
                                    проекти се реализираха, в значително намалени обеми.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    Това се промени рязко, с възстановяването на световната икономика след първите вълни
                                    на пандемията от Covid-19, когато се появи сериозен недостиг на енергия в глобален
                                    мащаб и цените на енергоизточниците полетяха нагоре.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    Вече доказали се като надеждни във времето, технологиите за ВЕИ, благодарение на
                                    намалената цена за инвестиции в тях, в комбинация с глада за енергия и високите
                                    цени, доведоха до естествения процес на засилване, на интереса към тях. В последните
                                    1-2 години стигнахме до момента, в който само за България има заявени ВЕИ проекти,
                                    за внушителната мощност от над 12 хил. MW.
                                </Typography>
                            </Grid>
                            <Grid alignItems={"left"} marginBottom={"50px"}>
                                <Typography className={classes.heading}>Нашата история</Typography>
                                <Typography className={classes.paragraph}>
                                    Tака започна да възниква ПРОБЛЕМЪТ, с подходящото локализиране на потенциални места
                                    за реализация на ВЕИ проектите, а също и да се заражда нуждата от специализиран
                                    пазар, за такива локации. В същото време, има съществен брой на инвеститори, във
                                    вече реализирани ВЕИ проекти, които желаят да излязат от тези свои инвестиции, които
                                    обаче все още имат доста полезен живот.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    В този момент, идеята за Solar Estates и vei-imot.bg хрумва на Лъчезар Петров в
                                    средата на 2021 година. Една платформа, на която заинтересованите страни в тази
                                    сфера лесно и бързо, могат да намерят необходимата информация и решение.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    Като специалист с над 10г. опит в енергетиката и инвеститор във ВЕИ проекти, Лъчезар
                                    ясно забелязва нуждата за подобна платформа. Тази предизвикателна задача обаче,
                                    изисква и друг вид опит и той се обръща към Здравко Здравков, който пък е специалист
                                    в областта на консултациите и продажбите, със сериозен интерес в енергийната сфера.
                                    Така техните два екипа обединяват усилия и започват разработването на платформата
                                    vei-imot.bg в края на 2021 година.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    На 08.05.2022 е стартирана първата версия на платформата.
                                </Typography>
                            </Grid>
                            <Grid container alignItems={"left"} flexDirection="row" marginBottom={"80px"}>
                                <Grid marginRight={"300px"} marginBottom={"30px"}>
                                    <img
                                        alt="luchezar"
                                        src={luchezar}
                                        height="168px"
                                        width="168px"
                                        style={{ marginBottom: "10px" }}></img>
                                    <Typography className={classes.heading}>Лъчезар Петров</Typography>
                                    <Typography className={classes.paragraph}>Oсновател и CEO</Typography>
                                    <Typography>Solar Estates</Typography>
                                    <Link href="mailto:L.petrov@vei-imot.bg">L.petrov@vei-imot.bg</Link>
                                </Grid>
                                <Grid>
                                    <img
                                        alt="zdravko"
                                        src={zdravko}
                                        height="168px"
                                        width="168px"
                                        style={{ marginBottom: "10px" }}></img>
                                    <Typography className={classes.heading}>Здравко Здравков</Typography>
                                    <Typography className={classes.paragraph}>
                                        Съосновател и Ръководител Бизнес Развитие
                                    </Typography>
                                    <Typography>Solar Estates</Typography>
                                    <Link href="mailto:Z.zdravkov@vei-imot.bg">Z.zdravkov@vei-imot.bg</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </StyledAbout>
        </ViewContainer>
    );
};
