import { Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import {
    StyledDownloadDocumentsModal,
    downloadDocumentsModalClasses,
} from "./StyledEstateDetailsDownloadDocumentsModal";
import CheckIcon from "@mui/icons-material/Check";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";
import CloseIcon from "@mui/icons-material/Close";
import { documentKeys } from "../../../services/estate-service.utils";
import creditsIcon from "../assets/creditsIcon.svg";

interface EstateDetailsDownloadDocumentsModalProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    openDownloadDocumentsModal: boolean;
    setOpenDownloadDocumentsModal: (value: boolean) => void;
    handleDownloadButtonClick: () => void;
    getOtherDocumentLabel: () => string;
}

const EstateDetailsDownloadDocumentsModal = ({
    estate,
    dropdownsMap,
    openDownloadDocumentsModal,
    setOpenDownloadDocumentsModal,
    handleDownloadButtonClick,
    getOtherDocumentLabel,
}: EstateDetailsDownloadDocumentsModalProps) => {
    if (estate && dropdownsMap) {
        return (
            <StyledDownloadDocumentsModal
                open={openDownloadDocumentsModal}
                className={downloadDocumentsModalClasses.root}
                onClose={() => {
                    setOpenDownloadDocumentsModal(false);
                }}>
                <Paper className={downloadDocumentsModalClasses.paper}>
                    <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom={"40px"}>
                        <Grid item>
                            <img src={creditsIcon} alt="Credits Icon" />
                        </Grid>
                        <Grid item>
                            <Typography className={downloadDocumentsModalClasses.modalTitle}>
                                Изтегляне на документи
                            </Typography>
                        </Grid>
                        <Grid item className={downloadDocumentsModalClasses.modalCloseIcon}>
                            <IconButton
                                onClick={() => {
                                    setOpenDownloadDocumentsModal(false);
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                        <Grid item>
                            <Typography className={downloadDocumentsModalClasses.estateTitle} marginRight={"20px"}>
                                {estate.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={downloadDocumentsModalClasses.estatePrice}>
                                {estate.price}{" "}
                                {estate.currency_id ? dropdownsMap!.currency[estate.currency_id].description : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={downloadDocumentsModalClasses.dividerLine} />
                    <Grid container marginTop={"20px"} marginBottom={"20px"}>
                        <Typography className={downloadDocumentsModalClasses.modalDescription} marginBottom={"10px"}>
                            С натискането на бутона ще получите възможност срещу заплащане да видите контактите за
                            връзка и да свалите следните документи съдържащи се в обявата:
                        </Typography>
                        <Grid container>
                            <CheckIcon className={downloadDocumentsModalClasses.checkIcon} />
                            <Typography variant={"body1"} className={downloadDocumentsModalClasses.documentText}>
                                Контакти
                            </Typography>
                        </Grid>
                        {documentKeys.map(({ availabilityKey, idKey, nameOfDocument }) => {
                            if (!!estate[idKey] && estate[availabilityKey]) {
                                return (
                                    <Grid container>
                                        <CheckIcon className={downloadDocumentsModalClasses.checkIcon} />
                                        <Typography
                                            variant={"body1"}
                                            className={downloadDocumentsModalClasses.documentText}>
                                            {nameOfDocument || getOtherDocumentLabel()}
                                        </Typography>
                                    </Grid>
                                );
                            }
                        })}
                        <Typography className={downloadDocumentsModalClasses.modalTextException} marginTop={"10px"}>
                            *(ако вече сте отключили тази обява, документите ще се свалят автоматично)
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            className={`${downloadDocumentsModalClasses.acceptButton} ${downloadDocumentsModalClasses.widthOfButton}`}
                            onClick={() => {
                                handleDownloadButtonClick();
                            }}>
                            Изтегли
                        </Button>
                    </Grid>
                </Paper>
            </StyledDownloadDocumentsModal>
        );
    }
    return null;
};

export default EstateDetailsDownloadDocumentsModal;
