import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX = "CreateEstateFormEstateType";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-checkboxLabel`,
    dropdown: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-dropdown`,
    renewableSpecificFieldContainer: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-renewableSpecificFieldContainer`,
    generatingCapacity: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-generatingCapacity`,
    paperContainer: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-paperContainer`,
    numberInputCapacity: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-numberInputCapacity`,
    numberInputFinancingAmount: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-numberInputFinancingAmount`,
    numberInputPanelInventorWindCapacity: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-numberInputPanelInventorWindCapacity`,
    numberInputPanelInventorWindCount: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-numberInputPanelInventorWindCount`,
    dropdownCapacity: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-dropdownCapacity`,
    dropdownPricing: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-dropdownPricing`,
    dropdownPanelInventorWindCapacity: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-dropdownPanelInventorWindCapacity`,
    customSwitchSchemesFinancingInOperation: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-customSwitchSchemesFinancingInOperation`,
    customSwitchSchemesDescription: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-customSwitchSchemesDescription`,
    customSwitchPutIntoOperationYear: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-customSwitchPutIntoOperationYear`,
    customSwitchPanelsInventorBrand: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-customSwitchPanelsInventorBrand`,
    customSwitchWindBrand: `${CREATE_ESTATE_FORM_ESTATE_TYPEPREFIX}-customSwitchWindBrand`,
};

export const StyledCreateEstateFormEstateType = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`& .${createEstateClasses.dropdown}`]: {
        [`& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart`]:
            {
                color: "#9BA4AB",
            },
    },
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.renewableSpecificFieldContainer}`]: {
        marginTop: "20px",
        height: "120px",
        [theme.breakpoints.only("md")]: {
            height: "unset",
        },
        [theme.breakpoints.only("sm")]: {
            height: "unset",
        },
        [theme.breakpoints.only("xs")]: {
            height: "unset",
        },
    },
    [`& .${createEstateClasses.generatingCapacity}`]: {
        paddingTop: "22px",
        height: "90px",
        [theme.breakpoints.only("md")]: {
            marginRight: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            marginRight: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
    [`& .${createEstateClasses.numberInputCapacity}`]: {
        width: "200px",
    },
    [`& .${createEstateClasses.numberInputFinancingAmount}`]: {
        width: "300px",
    },
    [`& .${createEstateClasses.numberInputPanelInventorWindCapacity}`]: {
        marginRight: "10px",
        height: "90px",
    },
    [`& .${createEstateClasses.numberInputPanelInventorWindCount}`]: {
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownCapacity}`]: {
        width: "130px",
        marginRight: "20px",
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownPricing}`]: {
        width: "250px",
        height: "90px",
    },
    [`& .${createEstateClasses.dropdownPanelInventorWindCapacity}`]: {
        width: "100%",
    },
    [`& .${createEstateClasses.customSwitchSchemesFinancingInOperation}`]: {
        width: "250px",
    },
    [`& .${createEstateClasses.customSwitchSchemesFinancingInOperation}`]: {
        width: "300px",
        height: "90px",
    },
    [`& .${createEstateClasses.customSwitchPutIntoOperationYear}`]: {
        width: "200px",
    },
    [`& .${createEstateClasses.customSwitchPanelsInventorBrand}`]: {
        marginRight: "10px",
        width: "200px",
        height: "90px",
    },
    [`& .${createEstateClasses.customSwitchWindBrand}`]: {
        width: "200px",
        marginRight: "10px",
        height: "90px",
    },
}));
