import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { DropdownMap } from "../../../../../App";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import { Button, Grid, Paper } from "@mui/material";
import { Map, Marker } from "../../Map";
import { GOOGLE_API_KEY } from "../../../../../env";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getJoinedLocationParts } from "../../../../../common/utils";
import { createEstateClasses as classes, StyledCreateEstateFormLocationMap } from "./StyledCreateEstateFormLocationMap";

interface CreateEstateFormLocationMapProps {
    form: IForm<SellerProfileState>;
    dropdownsMap: DropdownMap | null;
    selectCoordinates: boolean;
    zoom: number;
    center: google.maps.LatLngLiteral;
    location: google.maps.LatLng | undefined;
    render: (status: Status) => JSX.Element;
    onClick: (e: google.maps.MapMouseEvent) => void;
    onIdle: (m: google.maps.Map) => void;
    setSelectCoordinates: (location: boolean) => void;
    setCenter: ({ lat, lng }: any) => void;
}

const CreateEstateFormLocationMap = ({
    form,
    dropdownsMap,
    selectCoordinates,
    zoom,
    location,
    render,
    center,
    onClick,
    onIdle,
    setSelectCoordinates,
    setCenter,
}: CreateEstateFormLocationMapProps) => {
    if (form.values.country_area_id && form.values.municipality_id && form.values.city && dropdownsMap) {
        return (
            <StyledCreateEstateFormLocationMap container>
                <Paper className={classes.paperContainer}>
                    {selectCoordinates ? (
                        <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
                            <Map
                                center={center}
                                onClick={onClick}
                                onIdle={onIdle}
                                zoom={zoom}
                                mapTypeControl={false}
                                fullscreenControl={false}
                                streetViewControl={false}
                                draggable={true}
                                keyboardShortcuts={false}
                                scaleControl={true}
                                scrollwheel={true}
                                disableDoubleClickZoom={true}
                                style={{ flexGrow: "1", height: "100%" }}>
                                {location && <Marker position={location} />}
                            </Map>
                        </Wrapper>
                    ) : (
                        <iframe
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            title={`map`}
                            loading="lazy"
                            allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=
                                    ${dropdownsMap!.countryAreas[form.values.country_area_id]?.name},
                                    ${dropdownsMap!.municipality[form.values.municipality_id]?.name},
                                    ${form.values.city},
                                    ${form.values.exact_address}
                                `}
                        />
                    )}
                </Paper>
                <Grid container marginBottom="10px" marginLeft="10px">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const nextValue = !selectCoordinates;
                            setSelectCoordinates(nextValue);
                            if (nextValue) {
                                const results = await geocodeByAddress(
                                    getJoinedLocationParts(form.values, dropdownsMap)
                                );
                                const latLng = await getLatLng(results[0]);
                                setCenter({
                                    lat: latLng.lat,
                                    lng: latLng.lng,
                                });
                            }
                            if (!nextValue) {
                                form.setFieldValue("coordinates_lat", 0);
                                form.setFieldValue("coordinates_lng", 0);
                                form.setFieldValue("chosen_location", false);
                            }
                        }}>
                        {selectCoordinates ? "Използвай адреса" : "Избери точна локация"}
                    </Button>
                </Grid>
            </StyledCreateEstateFormLocationMap>
        );
    }
    return null;
};

export default CreateEstateFormLocationMap;
