import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { StyledViewContainer, viewContainerClasses } from "./StyledViewContainer";
import { Button, Container, Grid } from "@mui/material";
import { useMobile } from "../../common/hooks";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import StarIcon from "@mui/icons-material/Star";

interface ViewContainerProps {
    children: React.ReactNode;
}

export const ViewContainer = (props: ViewContainerProps) => {
    const mobile = useMobile();

    return (
        <StyledViewContainer container>
            <Grid className={viewContainerClasses.containerButton} color="white">
                <StarIcon className={viewContainerClasses.icon} />
                <Button className={viewContainerClasses.feedbackButton} variant="text" href="mailto:office@vei-imot.bg">
                    Обратна връзка
                </Button>
            </Grid>
            {mobile ? (
                <>
                    <MobileNavigation />
                    <Container disableGutters={true} fixed>
                        {props.children}
                    </Container>
                </>
            ) : (
                <>
                    <Header />
                    {props.children}
                    <Footer />
                </>
            )}
        </StyledViewContainer>
    );
};
