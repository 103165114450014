import React, { useEffect, useRef, useState } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { NavigationRoutes } from "../../../routes";

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

export const Map: React.FC<MapProps> = ({ onClick, onIdle, children, style, ...options }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            const newMap = new google.maps.Map(ref.current, {});
            setMap(newMap);
            // TODO: This is hack try to fix it properly
            (window as any).map = newMap;
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div id="google-map" ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

export const Marker: React.FC<{ id?: number } & google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [_, setInfoWindow] = useState<google.maps.InfoWindow>();

    useEffect(() => {
        if (!marker) {
            const newMarker = new google.maps.Marker({
                map: (window as any).map,
            });
            const infoWindow = new google.maps.InfoWindow({
                content: `<div>
                    <h3>${options.title}</h3>
                    <button style="
                        color: #2975BC;
                        border-radius: 100px;
                        width: 185px;
                        min-width: 64px;
                        padding: 5px 15px;
                        cursor: pointer;
                        font-weight: normal;
                        font-size: 16px;
                        border: 1px solid rgba(41, 117, 188, 0.5);
                        text-transform: capitalize;"
                        onclick="window.location.href='/${NavigationRoutes.EstateDetails}/${options.id}'">
                        Виж детайли
                        </button>
                </div>`,
            });
            setInfoWindow(infoWindow);

            newMarker.addListener("click", () => {
                infoWindow.open({
                    anchor: newMarker,
                    map: (window as any).map,
                    shouldFocus: false,
                });
            });
            setMarker(newMarker);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, options.id, options.title]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
    ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
});

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
