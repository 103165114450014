import { Grid, styled } from "@mui/material";

const ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX = "EstateDetailsUpperContainerLeft";

export const estateDetailsClasses = {
    topCheckboxContainer: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-topCheckboxContainer`,
    upperContainerLeft: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-upperContainerLeft`,
    priceTag: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-priceTag`,
    title: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-title`,
    location: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-location`,
    downloadAllFilesButton: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-downloadAllFilesButton`,
    cursor: `${ESTATE_DETAILS_UPPER_CONTAINER_LEFT_PREFIX}-cursor`,
};

export const StyledEstateDetailsUpperContainerLeft = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.topCheckboxContainer}`]: {
        marginTop: "20px",
        maxHeight: "240px",
        [theme.breakpoints.only("xs")]: {
            maxHeight: "480px",
        },
        [theme.breakpoints.only("sm")]: {
            maxHeight: "none",
        },
    },
    [`& .${estateDetailsClasses.upperContainerLeft}`]: {
        top: "5%",
        left: "5%",
        position: "absolute",
        width: "650px",
        [theme.breakpoints.only("xs")]: {
            position: "unset",
            width: "400px",
            marginTop: "80px",
            padding: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            position: "unset",
            width: "100%",
            marginTop: "80px",
            padding: "20px",
        },
    },
    [`& .${estateDetailsClasses.priceTag}`]: {
        color: "white",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: "24px",
        lineHeight: "19px",
        marginRight: "20px",
    },
    [`& .${estateDetailsClasses.title}`]: {
        color: "white",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "32px",
        lineHeight: "40px",
        [theme.breakpoints.only("xs")]: {
            width: "400px",
        },
    },
    [`& .${estateDetailsClasses.location}`]: {
        color: "white",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.downloadAllFilesButton}`]: {
        borderRadius: "20px",
        width: "200px",
        textTransform: "none",
    },
    [`& .${estateDetailsClasses.cursor}`]: {
        cursor: "pointer",
    },
}));
