import { Button, Grid, Paper, Typography } from "@mui/material";
import { estateDetailsClasses as classes, StyledEstateDetailsDocuments } from "./StyledEstateDetailsDocuments";
import { DocumentCheckboxAvailableDocuments } from "../DocumentCheckboxAvailableDocuments";
import { IEstate } from "../../../interfaces/estate";
import { IAttachment } from "../../../interfaces/attachment";
import { documentKeys } from "../../../services/estate-service.utils";
import { RegisterModal } from "../../../common/ConfirmModal/RegisterModal";
import { ILogged } from "../../../interfaces/user";
import { IS_PAYMENT_ENABLED } from "../../../env";

interface EstateDetailsDocumentsProps {
    user: ILogged | null;
    estate: IEstate;
    showDownloadDocumentsButton: boolean;
    handleDownloadButtonClick: () => void;
    setOpenDownloadDocumentsModal: (value: boolean) => void;
    getOtherDocumentLabel: () => string;
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
}

const EstateDetailsDocuments = ({
    user,
    estate,
    showDownloadDocumentsButton,
    setOpenDownloadDocumentsModal,
    handleDownloadButtonClick,
    getOtherDocumentLabel,
    isOpened,
    setIsOpened,
}: EstateDetailsDocumentsProps) => {
    const isDocumentAdded = documentKeys.some(({ availabilityKey }) => {
        return !!estate[availabilityKey];
    });

    const handleDownloadButton = () => {
        if (!IS_PAYMENT_ENABLED) {
            handleDownloadButtonClick();
        } else {
            if (user) {
                setOpenDownloadDocumentsModal(true);
            } else {
                setIsOpened(true);
            }
        }
    };

    if (isDocumentAdded) {
        return (
            <StyledEstateDetailsDocuments>
                <Grid container>
                    <Paper className={classes.documentsPaper}>
                        <Typography variant={"h6"} className={classes.heading}>
                            Налични документи
                        </Typography>
                        <Grid container marginTop="20px" flexDirection={"column"}>
                            {documentKeys.map(({ availabilityKey, idKey, objectKey, nameOfDocument }) => {
                                if (estate[availabilityKey]) {
                                    return (
                                        <DocumentCheckboxAvailableDocuments
                                            estateId={estate.id}
                                            attachment={estate[idKey] as number}
                                            attachmentObject={estate[objectKey] as IAttachment | null}
                                            setOpenDocumentsModal={() => {
                                                setOpenDownloadDocumentsModal(true);
                                            }}
                                            name={
                                                nameOfDocument || getOtherDocumentLabel()
                                            }></DocumentCheckboxAvailableDocuments>
                                    );
                                }
                            })}
                            {showDownloadDocumentsButton && (
                                <Grid container alignItems={"center"} justifyContent={"center"}>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        className={classes.downloadAllFilesButtonMarginTop}
                                        onClick={handleDownloadButton}>
                                        Изтегли всички
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                <RegisterModal isOpened={isOpened} cancel={() => setIsOpened(false)} />
            </StyledEstateDetailsDocuments>
        );
    }
    return null;
};

export default EstateDetailsDocuments;
