import { Grid, Modal, styled } from "@mui/material";
import { greyLight, primary } from "../../common/Theme/theme";

const ADMIN_USER_PREFIX = "AdminUser";

export const adminUserClasses = {
    container: `${ADMIN_USER_PREFIX}-container`,
    root: `${ADMIN_USER_PREFIX}-root`,
    dropdown: `${ADMIN_USER_PREFIX}-dropdown`,
    emailInput: `${ADMIN_USER_PREFIX}-emailInput`,
    emailInputContainer: `${ADMIN_USER_PREFIX}-emailInputContainer`,
    paginationContainer: `${ADMIN_USER_PREFIX}-paginationContainer`,
    slider: `${ADMIN_USER_PREFIX}-slider`,
    overMillionCheckbox: `${ADMIN_USER_PREFIX}-overMillionCheckbox`,
    select: `${ADMIN_USER_PREFIX}-select`,
    direction: `${ADMIN_USER_PREFIX}-direction`,
    titleInput: `${ADMIN_USER_PREFIX}-titleInput`,
    titleInputContainer: `${ADMIN_USER_PREFIX}-titleInputContainer`,
    downloadEstatesButton: `${ADMIN_USER_PREFIX}-downloadEstatesButton`,
};

export const StyledAdminUser = styled(Grid)(({ theme }) => ({
    [`&.${adminUserClasses.root}`]: {
        width: "100%",
        marginTop: "50px",
    },
    [`& .${adminUserClasses.container}`]: {
        width: "1400px",
    },
    [`& .${adminUserClasses.paginationContainer}`]: {
        marginTop: "20px",
    },
    [`& .${adminUserClasses.dropdown}`]: {
        backgroundColor: theme.palette.common.white,
        color: greyLight,
        width: "180px",
        height: "fit-content",
        borderRadius: "8px",
        margin: "20px 20px 20px 0px",
    },
    [`& .${adminUserClasses.emailInputContainer}`]: {
        height: "90px",
        width: "30%",
        paddingTop: "42px",
    },
    [`& .${adminUserClasses.emailInput}`]: {
        backgroundColor: theme.palette.common.white,
        color: greyLight,
        width: "250px",
        height: "fit-content",
        borderRadius: "8px",
    },
    [`& .${adminUserClasses.slider}`]: {
        color: primary,
        marginLeft: "40px",
        marginRight: "30px",
        marginBottom: "7px",
    },
    [`& .${adminUserClasses.select}`]: {
        backgroundColor: theme.palette.common.white,
        color: greyLight,
        width: "220px",
        height: "fit-content",
        borderRadius: "8px",
        margin: "20px 20px 20px 20px",
    },
    [`& .${adminUserClasses.direction}`]: {
        backgroundColor: theme.palette.common.white,
        width: "100px",
        height: "fit-content",
        borderRadius: "8px",
        margin: "20px 20px 20px 20px",
    },
    [`& .${adminUserClasses.overMillionCheckbox}`]: {
        color: primary,
        fontSize: "16px",
        "& .MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root":
            {
                color: primary,
            },
        "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
            fontSize: "16px",
        },
    },
    [`& .${adminUserClasses.titleInput}`]: {
        backgroundColor: theme.palette.common.white,
        color: greyLight,
        width: "250px",
        height: "fit-content",
        borderRadius: "8px",
        marginTop: "18px",
    },
    [`& .${adminUserClasses.titleInputContainer}`]: {
        height: "88px",
        width: "280px",
        alignItems: "center",
    },
    [`& .${adminUserClasses.downloadEstatesButton}`]: {
        height: "50px",
        marginLeft: "40px",
        marginTop: "20px",
    },
}));
