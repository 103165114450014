import { Button, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { greyLight, info, primary, secondary, success } from "../Theme/theme";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { NavigationRoutes } from "../../routes";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { IEstate } from "../../interfaces/estate";
import estatePhoto from "./bg.png";
import { useNavigate } from "react-router-dom";
import { estateCardClasses as classes, StyledEstateCard } from "./StyledEstateCard";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../App";
import { formatDate } from "../hooks";
import { getImageLink } from "../../endpoints";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { isBuyer } from "../utils";
import { buyerService } from "../../services/buyer.service";
import { FullHearth } from "./FullHearth";
import { RegisterModal } from "../ConfirmModal/RegisterModal";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";

export const EstateCard = ({ estate, onToggleFavourite }: { estate: IEstate; onToggleFavourite?: () => void }) => {
    const { dropdownsMap } = useContext(GlobalContext);
    const { user } = useContext(GlobalContext);

    const toggleFavourite = async (event: any) => {
        event.stopPropagation();
        if (estate.favourite) {
            await buyerService.makeUnFavourite(estate.id);
        } else {
            await buyerService.makeFavourite(estate.id);
        }
        if (onToggleFavourite) {
            onToggleFavourite();
        }
    };

    const seeDetails = () => {
        if (user) {
            window.open(`/${NavigationRoutes.EstateDetails}/${estate.id}`, "_blank");
        } else {
            window.open(`/${NavigationRoutes.EstateDetails}/${estate.id}/guest`, "_blank");
        }
    };

    if (!dropdownsMap) {
        return null;
    }
    return (
        <StyledEstateCard>
            <CardActionArea className={classes.cardActionArea} onClick={seeDetails}>
                {estate.attachments[0]! ? (
                    <CardMedia
                        component="img"
                        image={getImageLink(estate.attachments[0].link)}
                        alt={estate.title}
                        className={classes.cardImage}
                    />
                ) : (
                    <CardMedia component="img" image={estatePhoto} alt={estate.title} className={classes.cardImage} />
                )}
                {isBuyer(user!) ? (
                    <Grid container width={"fit-content"} className={classes.cardLike}>
                        {estate.favourite ? (
                            <FullHearth onClick={toggleFavourite} />
                        ) : (
                            <FavoriteBorderIcon htmlColor={info} onClick={toggleFavourite} />
                        )}
                    </Grid>
                ) : (
                    <></>
                )}
                {estate.promoted && (
                    <Grid container width={"fit-content"} className={classes.promotedContainer}>
                        <Typography variant="h4" color={"secondary"}>
                            Промотирана
                        </Typography>
                    </Grid>
                )}

                <CardContent className={classes.cardContent}>
                    <Typography title={`${estate.title}`} className={classes.cardHeading} gutterBottom variant="h2">
                        {estate.title}
                    </Typography>
                    <Grid container flexDirection={"row"} alignItems={"center"}>
                        {estate.has_uploaded_documents ? (
                            <>
                                <DocumentScannerOutlinedIcon
                                    htmlColor={secondary}
                                    className={classes.cardPropertyIcon}
                                />
                                <Typography className={classes.cardProperty}>Има качени документи</Typography>
                            </>
                        ) : (
                            <Grid sx={{ height: "20px" }}></Grid>
                        )}
                    </Grid>
                    <Grid container flexDirection={"row"} marginBottom={"20px"} alignItems="center">
                        <WindowOutlinedIcon className={classes.cardPropertyIcon} htmlColor={info} />
                        <Typography className={classes.cardProperty}>
                            {estate.area}
                            {estate.area_unit_id ? dropdownsMap!.areaUnits[estate.area_unit_id].name : ""}
                        </Typography>
                        <Typography className={classes.cardProperty}>
                            {estate.estate_type_id ? dropdownsMap!.estateTypes[estate.estate_type_id].name : ""}
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={"row"} marginBottom={"30px"} alignItems="center">
                        <LocationOnOutlinedIcon className={classes.cardPropertyIcon} />
                        <Typography className={classes.cardLocation}>
                            {dropdownsMap!.countryAreas[estate.country_area_id].name}
                        </Typography>
                        <AccessTimeOutlinedIcon className={classes.cardPropertyIcon} htmlColor={greyLight} />
                        <Typography className={classes.cardDateTime}>
                            {estate.createdAt ? formatDate(new Date(estate.createdAt)) : ""}
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={"row"} marginBottom={"30px"} alignItems="center">
                        <Typography className={classes.cardPrice}>
                            {estate.price}
                            {estate.currency_id ? dropdownsMap!.currency[estate.currency_id].description : ""}
                        </Typography>
                        <Button
                            variant={"outlined"}
                            onClick={seeDetails}
                            className={classes.cardButton}
                            endIcon={<ArrowRightAltOutlinedIcon />}>
                            Виж детайли
                        </Button>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </StyledEstateCard>
    );
};
