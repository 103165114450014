import { Button, Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, secondary } from "../../../common/Theme/theme";

const FILTER_PREFIX = "Filter";

export const filterClasses = {
    root: `${FILTER_PREFIX}-root`,
    select: `${FILTER_PREFIX}-select`,
    slider: `${FILTER_PREFIX}-slider`,
    container: `${FILTER_PREFIX}-container`,
    selectedTab: `${FILTER_PREFIX}-selectedTab`,
    notSelectedTab: `${FILTER_PREFIX}-notSelectedTab`,
    firstTab: `${FILTER_PREFIX}-firstTab`,
    innerTab: `${FILTER_PREFIX}-innerTab`,
    lastTab: `${FILTER_PREFIX}-lastTab`,
    text: `${FILTER_PREFIX}-text`,
    overMillionCheckbox: `${FILTER_PREFIX}-overMillionCheckbox`,
    sliderContainer: `${FILTER_PREFIX}-sliderContainer`,
};

export const StyledFilter = styled(Grid)(({ theme }) => ({
    [`&.${filterClasses.root}`]: {
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.up("md")]: {
            width: "100%",
            marginTop: "30px",
            marginBottom: "20px",
        },
        [theme.breakpoints.only("sm")]: {
            width: "95%",
            marginBottom: "20px",
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${filterClasses.select}`]: {
        backgroundColor: theme.palette.common.white,
        color: greyLight,
        width: "220px",
        height: "fit-content",
        borderRadius: "8px",
        [theme.breakpoints.up("sm")]: {
            margin: "20px 0px 20px 20px",
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: "20px",
            marginBottom: "20px",
        },
    },
    [`& .${filterClasses.slider}`]: {
        color: theme.palette.common.white,
        [theme.breakpoints.not("xs")]: {
            marginLeft: "40px",
            marginRight: "30px",
            marginBottom: "7px",
        },
        width: "200px",
    },
    [`& .${filterClasses.container}`]: {
        backgroundColor: greyDark,
        borderRadius: "0px 8px 8px 8px",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "10px 10px 0 0",
            justifyContent: "space-around",
        },
        padding: "10px 0",
    },
    [`& .${filterClasses.selectedTab}`]: {
        backgroundColor: secondary,
    },
    [`& .${filterClasses.notSelectedTab}`]: {
        backgroundColor: grey,
    },
    [`& .${filterClasses.firstTab}`]: {
        width: "fit-content",
        padding: "10px 20px",
        borderRadius: "8px 0px 0px 0px",
        cursor: "pointer",
    },
    [`& .${filterClasses.innerTab}`]: {
        width: "fit-content",
        padding: "10px 20px",
        cursor: "pointer",
    },
    [`& .${filterClasses.lastTab}`]: {
        width: "fit-content",
        padding: "10px 20px",
        borderRadius: "0px 8px 0px 0px",
        cursor: "pointer",
    },
    [`& .${filterClasses.text}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
    },
    "& .MuiSlider-markLabel": {
        color: theme.palette.common.white,
    },
    "& .MuiSvgIcon-root": {
        marginRight: "5px",
    },
    [`& .${filterClasses.overMillionCheckbox}`]: {
        color: "white",
        fontSize: "16px",
        "& .MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root":
            {
                color: "white",
            },
        "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
            fontSize: "16px",
        },
    },
    [`& .${filterClasses.sliderContainer}`]: {
        [theme.breakpoints.up("sm")]: {
            width: "250px",
        },
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    fontFamily: "Rubik",
    borderRadius: "10px",
    textTransform: "capitalize",
    width: "fit-content",
    height: "fit-content",
    margin: "0 10px",
    [theme.breakpoints.only("xs")]: {
        marginTop: "20px",
        marginBottom: "30px",
    },
    [theme.breakpoints.only("sm")]: {
        marginBottom: "30px",
        marginLeft: "20px",
    },
}));
