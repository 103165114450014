import React from "react";
import { Grid, Typography } from "@mui/material";
import { ViewContainer } from "../Core/ViewContainer/ViewContainer";
import { useGA } from "../common/hooks";

export const FutureWork = () => {
    useGA();
    return (
        <ViewContainer>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "500px" }}>
                <Typography variant="body1">Oчаквайте скоро!</Typography>
            </Grid>
        </ViewContainer>
    );
};
