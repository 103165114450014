import { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { ViewContainer } from "../../Core/ViewContainer/ViewContainer";
import { buyerProfileClasses as classes, StyledBuyerProfile } from "./StyledBuyerProfile";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { info, secondary, success } from "../../common/Theme/theme";
import { userService } from "../../services/user.service";
import { useNavigate, useParams } from "react-router-dom";
import { IProfile } from "../../interfaces/user";
import { ViewEstates } from "./ViewEstates";
import { ViewEstates as SellerViewEstates } from "../../Seller/SellerProfile/ViewEstates";
import { ViewProfile } from "./ViewProfile";
import { GlobalContext } from "../../App";
import { FeatureBoundary } from "../../common/FeatureBoundary/FeatureBoundary";
import { NavigationRoutes } from "../../routes";
import KeyIcon from "@mui/icons-material/Key";
import { useGA } from "../../common/hooks";
import { ViewPurchasedEstates } from "./ViewPurchasedEstates";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { PlansPaper } from "../../Core/EstateDetails/PlansPaper";
import { SuccessModal } from "../../common/SuccessModal/SuccessModal";
import { ErrorModal } from "../../common/ErrorModal/ErrorModal";
import { InvoiceModal } from "../../common/InvoiceModal/InvoiceModal";
import { ViewUnderReviewEstates } from "./ViewUnderReviewEstates";
import { paymentService } from "../../services/payment.service";
import { IS_PAYMENT_ENABLED } from "../../env";

enum ModalNameParams {
    Success = "success",
    Error = "error",
    Invoice = "invoice",
}

const BuyerProfile = () => {
    useGA();
    const { id, open_modal, order_id } = useParams();
    const options = {
        profile: "profile",
        likes: "likes",
        publishedEstates: "publishedEstates",
        underReviewEstates: "underReviewEstates",
        purchasedEstates: "purchasedEstates",
        buyCredits: "buyCredits",
    };
    const [selectedOption, setSelectedOption] = useState(options.likes);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [order, setOrder] = useState();
    const { dropdownsMap } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [profile, setProfile] = useState<IProfile>({
        info: {
            name: "",
            phone: "",
            email: "",
            credit_count: 0,
        },
        count: 0,
        rows: [],
    });

    const fetchUser = () => {
        if (id) {
            userService.getById(id).then((innerProfile) => {
                setProfile({ ...innerProfile });
            });
        }
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (order_id) {
            paymentService.getOrder(parseInt(order_id)).then((order) => {
                setOrder(order);
            });
        }
    }, [order_id]);

    useEffect(() => {
        if (open_modal === ModalNameParams.Success) {
            setOpenSuccessModal(true);
            setSelectedOption(options.buyCredits);
        }
        if (open_modal === ModalNameParams.Error) {
            setOpenErrorModal(true);
            setSelectedOption(options.buyCredits);
        }
        if (open_modal === ModalNameParams.Invoice) {
            setOpenInvoiceModal(true);
            setSelectedOption(options.buyCredits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open_modal]);

    const updateProfileInfo = (info: { name: string; phone: string; credit_count: number }) => {
        setProfile({ ...profile, info });
    };

    return (
        <ViewContainer>
            <StyledBuyerProfile className={classes.root}>
                <Grid container flexDirection={"row"}>
                    <Grid
                        container
                        flexDirection={"column"}
                        width={"fit-content"}
                        height={"fit-content"}
                        className={classes.userPropertiesContainer}
                        alignItems={"center"}>
                        <Typography>{profile.info.name}</Typography>
                        <Typography className={classes.userPropertiesEmail}>{profile.info.email}</Typography>
                        {IS_PAYMENT_ENABLED && <Typography>Кредити: {profile.info.credit_count}</Typography>}
                        <Button
                            startIcon={<FormatListBulletedOutlinedIcon htmlColor={success} />}
                            className={classes.userPropertiesButtons}
                            onClick={() => setSelectedOption(options.likes)}>
                            Харесани
                        </Button>
                        <Button
                            startIcon={<FormatListBulletedOutlinedIcon htmlColor={success} />}
                            className={classes.userPropertiesButtons}
                            onClick={() => setSelectedOption(options.publishedEstates)}>
                            Мои Обяви
                        </Button>
                        <Button
                            startIcon={<FormatListBulletedOutlinedIcon htmlColor={success} />}
                            className={classes.userPropertiesButtons}
                            onClick={() => setSelectedOption(options.underReviewEstates)}>
                            Обяви за преглеждане
                        </Button>
                        {IS_PAYMENT_ENABLED && (
                            <Button
                                startIcon={<FormatListBulletedOutlinedIcon htmlColor={success} />}
                                className={classes.userPropertiesButtons}
                                onClick={() => setSelectedOption(options.purchasedEstates)}>
                                Отключени Обяви
                            </Button>
                        )}
                        <Button
                            startIcon={<PersonOutlineOutlinedIcon htmlColor={secondary} />}
                            className={classes.userPropertiesButtons}
                            onClick={() => setSelectedOption(options.profile)}>
                            Профил
                        </Button>
                        <Button
                            startIcon={<KeyIcon htmlColor={info} />}
                            className={classes.userPropertiesButtons}
                            onClick={() => navigate(`/${NavigationRoutes.ForgotPassword}/`)}>
                            Смяна на паролата
                        </Button>
                        {IS_PAYMENT_ENABLED && (
                            <Button
                                startIcon={<MonetizationOnOutlinedIcon htmlColor={info} />}
                                className={classes.userPropertiesButtons}
                                onClick={() => setSelectedOption(options.buyCredits)}>
                                Купи Кредити
                            </Button>
                        )}
                    </Grid>
                    {selectedOption === options.likes && dropdownsMap && (
                        <ViewEstates id={id} rows={profile.rows} onToggleFavourite={fetchUser} />
                    )}
                    {selectedOption === options.publishedEstates && dropdownsMap && <SellerViewEstates id={id} />}
                    {selectedOption === options.underReviewEstates && dropdownsMap && (
                        <ViewUnderReviewEstates id={id} />
                    )}
                    {selectedOption === options.profile && (
                        <ViewProfile id={id} profileInfo={profile.info} updateProfileInfo={updateProfileInfo} />
                    )}
                    {selectedOption === options.purchasedEstates && <ViewPurchasedEstates id={id} />}
                    {selectedOption === options.buyCredits && <PlansPaper />}
                </Grid>
            </StyledBuyerProfile>
            <SuccessModal open={openSuccessModal} setOpen={setOpenSuccessModal} text={"Успешно закупи кредити"} />
            <ErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                text={"Възникна грешка при закупуването на кредити"}
            />
            <InvoiceModal
                open={openInvoiceModal}
                setOpen={setOpenInvoiceModal}
                order={order}
                email={profile.info.email}
            />
        </ViewContainer>
    );
};

export default () => (
    <FeatureBoundary route={NavigationRoutes.BuyerProfile}>
        <BuyerProfile />
    </FeatureBoundary>
);
