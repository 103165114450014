import { Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, secondary } from "../../../common/Theme/theme";

const ESTATE_DETAILS_TYPE_PREFIX = "EstateDetailsType";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS_TYPE_PREFIX}-heading`,
    value: `${ESTATE_DETAILS_TYPE_PREFIX}-value`,
    label: `${ESTATE_DETAILS_TYPE_PREFIX}-label`,
    checkIcon: `${ESTATE_DETAILS_TYPE_PREFIX}-checkIcon`,
    renewableSpecificFieldContainer: `${ESTATE_DETAILS_TYPE_PREFIX}-renewableSpecificFieldContainer`,
    renewableSpecificTypeById: `${ESTATE_DETAILS_TYPE_PREFIX}-renewableSpecificTypeById`,
    paperContainer: `${ESTATE_DETAILS_TYPE_PREFIX}-paperContainer`,
};

export const StyledEstateDetailsType = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.checkIcon}`]: {
        color: secondary,
    },
    [`& .${estateDetailsClasses.renewableSpecificFieldContainer}`]: {
        height: "80px",
        [theme.breakpoints.only("md")]: {
            height: "unset",
        },
        [theme.breakpoints.only("sm")]: {
            height: "unset",
        },
        [theme.breakpoints.only("xs")]: {
            height: "unset",
        },
    },
    [`& .${estateDetailsClasses.label}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.renewableSpecificTypeById}`]: {
        marginRight: "10px",
        paddingTop: "8px",
    },
    [`& .${estateDetailsClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
}));
