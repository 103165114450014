import { Grid, styled } from "@mui/material";
import { grey } from "../../../common/Theme/theme";

const ESTATE_DETAILS_DOCUMENTS_PREFIX = "EstateDetailsDocuments";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS_DOCUMENTS_PREFIX}-heading`,
    documentsPaper: `${ESTATE_DETAILS_DOCUMENTS_PREFIX}-documentsPaper`,
    downloadAllFilesButtonMarginTop: `${ESTATE_DETAILS_DOCUMENTS_PREFIX}-downloadAllFilesButtonMarginTop`,
};

export const StyledEstateDetailsDocuments = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.documentsPaper}`]: {
        padding: "10px",
        marginBottom: "10px",
        width: "100%",
        height: "auto",
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    [`& .${estateDetailsClasses.downloadAllFilesButtonMarginTop}`]: {
        borderRadius: "20px",
        width: "200px",
        textTransform: "none",
        marginTop: "20px",
    },
}));
