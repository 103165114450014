import { Grid, styled } from "@mui/material";
import { info } from "../../common/Theme/theme";

const SELLER_PROFILE_DETAILS_PREFIX = "SellerProfileDetails";

export const sellerProfileClasses = {
    root: `${SELLER_PROFILE_DETAILS_PREFIX}-root`,
    userPropertiesContainer: `${SELLER_PROFILE_DETAILS_PREFIX}-userPropertiesContainer`,
    profilePicture: `${SELLER_PROFILE_DETAILS_PREFIX}-profilePicture`,
    welcome: `${SELLER_PROFILE_DETAILS_PREFIX}-welcome`,
    userPropertiesButtons: `${SELLER_PROFILE_DETAILS_PREFIX}-userPropertiesButtons`,
    userPropertiesFavoritesButton: `${SELLER_PROFILE_DETAILS_PREFIX}-userPropertiesFavoritesButton`,
    userPropertiesEmail: `${SELLER_PROFILE_DETAILS_PREFIX}-userPropertiesEmail`,
};

export const StyledSellerProfile = styled(Grid)(({ theme }) => ({
    [`&.${sellerProfileClasses.root}`]: {
        backgroundColor: "#F8F8F8",
        [theme.breakpoints.up("md")]: {
            width: "100%",
            padding: "100px 100px",
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            width: "100vw",
            minHeight: "100vh",
            alignItems: "center",
        },
    },
    [`& .${sellerProfileClasses.userPropertiesContainer}`]: {
        backgroundColor: theme.palette.common.white,
        padding: "10px 10px",
        borderRadius: "8px",
        margin: "auto",
        marginTop: "30px",
        marginBottom: "30px",
        [theme.breakpoints.only("xs")]: {
            marginTop: "70px",
        },
    },
    [`& .${sellerProfileClasses.profilePicture}`]: {
        margin: "0 30px",
        width: "80px",
        height: "80px",
    },
    [`& .${sellerProfileClasses.welcome}`]: {
        margin: "0 30px",
    },
    [`& .${sellerProfileClasses.userPropertiesButtons}`]: {
        textTransform: "capitalize",
        color: theme.palette.common.black,
        backgroundColor: "#FAFAFA",
        borderRadius: "100px",
        width: "300px",
        marginTop: "20px",
    },
    [`& .${sellerProfileClasses.userPropertiesFavoritesButton}`]: {
        textTransform: "capitalize",
        color: theme.palette.common.white,
        backgroundColor: info,
        borderRadius: "100px",
        width: "300px",
        marginBottom: "25px",
    },
    [`& .${sellerProfileClasses.userPropertiesEmail}`]: {
        marginTop: "10px",
    },
}));
