import { Grid, Typography } from "@mui/material";
import { StyledWhatDoYouWantToBuy, whatDoYouWantToBuyClasses as classes } from "./StyledWhatDoYouWantToBuy";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { primary, secondary, info, success, theme } from "../../../common/Theme/theme";
import { useContext } from "react";
import { GlobalContext } from "../../../App";

interface Props {
    handleChange: (type: number) => void;
}

const icons = [
    <ApartmentIcon htmlColor={theme.palette.common.white} className={classes.cardIcon} />,
    <AgricultureIcon htmlColor={theme.palette.common.white} className={classes.cardIcon} />,
    <HouseOutlinedIcon htmlColor={theme.palette.common.white} className={classes.cardIcon} />,
    <SolarPowerOutlinedIcon htmlColor={theme.palette.common.white} className={classes.cardIcon} />,
];
const colors = [secondary, success, primary, info];

export const WhatDoYouWantToBuy = ({ handleChange }: Props) => {
    const { dropdowns } = useContext(GlobalContext);

    return (
        <StyledWhatDoYouWantToBuy>
            <Grid container className={classes.innerContainer} flexDirection={"column"}>
                <Typography className={classes.heading}>Какъв тип имот/проект търсиш?</Typography>
                <Grid container flexDirection={"row"} justifyContent={"center"} className={classes.cardsContainer}>
                    {dropdowns.estateTypes.map((type) => (
                        <Grid
                            container
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            className={classes.card}
                            key={type.id}
                            onClick={() => {
                                handleChange(type.id);
                            }}>
                            <Grid
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                className={classes.cardIconWrapper}
                                sx={{ background: colors[type.id - 1] }}>
                                {icons[type.id - 1]}
                            </Grid>
                            <Typography className={classes.cardTitle}>{type.name}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </StyledWhatDoYouWantToBuy>
    );
};
