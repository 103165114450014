import { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const IMAGE_PREVIEW_PREFIX = "ImagePreview";

export const imagePreviewClasses = {
    image: `${IMAGE_PREVIEW_PREFIX}-image`,
    deleteIcon: `${IMAGE_PREVIEW_PREFIX}-deleteIcon`,
};

export const StyledImagePreview = styled(Grid)(({ theme }) => ({
    width: "260px",
    height: "170px",
    background: "white",
    marginRight: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    objectFit: "contain",
    [`& .${imagePreviewClasses.image}`]: {
        width: "220px",
        height: "150px",
        objectFit: "contain",
    },
    [`& .${imagePreviewClasses.deleteIcon}`]: {
        position: "absolute",
        top: 4,
        right: 4,
        display: "inline-block",
        fill: theme.palette.primary.main,
        "z-index": 2,
        cursor: "pointer",
    },
}));

const classes = imagePreviewClasses;

export const ImagePreview = ({
    file,
    deletePhoto,
    showDelete,
}: {
    file: File;
    deletePhoto?: () => void;
    showDelete?: boolean;
}) => {
    const [preview, setPreview] = useState<any>(null);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!file) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [file]);

    return (
        <StyledImagePreview container>
            {showDelete && <DeleteIcon className={classes.deleteIcon} onClick={deletePhoto} />}
            {file && <img src={preview} alt={"preview"} className={classes.image} />}
        </StyledImagePreview>
    );
};
