import { Container, Grid, Slider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorButton, filterClasses as classes, StyledFilter } from "./StyledFilter";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { theme } from "../../../common/Theme/theme";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { GlobalContext } from "../../../App";
import { useSearchParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import CustomCheckbox from "../../../common/CustomCheckbox/CustomCheckbox";
import { IEstateFilters } from "../../../interfaces/filters";
import { defaultOrder } from "../../../services/estate.service";

interface FilterProps {
    handleChange: (filters: IEstateFilters) => void;
}

const lastSizeElementId = 5;

const sizeFilters = [
    { id: 1, name: "0-1000 кв.м", start: 0, end: 1000 },
    { id: 2, name: "1001-3000 кв.м", start: 1001, end: 3000 },
    { id: 3, name: "3001-5000 кв.м", start: 3001, end: 5000 },
    { id: 4, name: "5001-10000 кв.м", start: 5001, end: 10000 },
    { id: lastSizeElementId, name: "над 10 дка", start: 10001 },
];

const sizeFiltersMap = sizeFilters.reduce((acc, current) => {
    (acc as any)[current.id] = current;
    return acc;
}, {});

const sliderMarks = [
    {
        value: 0,
        label: "0 BGN",
    },
    {
        value: 1000000,
        label: "1M BGN",
    },
];

export const defaultFilters = {
    size_start: 0,
    size_end: 1000000,
    price_start: 0,
    price_end: 1000000,
    country_area_id: 0,
    offer_type_id: 0,
    estate_type_id: 0,
    sort: defaultOrder.sort,
    direction: defaultOrder.direction,
};

export const Filter = ({ handleChange }: FilterProps) => {
    const [size, setSize] = useState(0);
    const [filters, setFilters] = useState<IEstateFilters>({ ...defaultFilters });
    const { dropdowns } = useContext(GlobalContext);
    const [searchParams] = useSearchParams();
    const [overMillionChecked, setOverMillionChecked] = useState(false);

    useEffect(() => {
        const initialFilters = {};
        searchParams.forEach(function (value, key) {
            (initialFilters as any)[key] = parseInt(value);
        });
        if (Object.keys(initialFilters).length > 0) {
            // TODO: This is terrible fix, need to move the filters in component to hold the state
            // because on landing on the page we get race condition. with default and params of the page
            // sometimes the first is returned second and it holds the state
            if ((initialFilters as any).size_start && (initialFilters as any).size_end) {
                const initialSize = sizeFilters.find(
                    (item) =>
                        item.start === (initialFilters as any).size_start &&
                        item.end === (initialFilters as any).size_end
                );
                setSize(initialSize ? initialSize.id : 0);
            }
            if ((initialFilters as any).size_start && !(initialFilters as any).size_end) {
                setSize(lastSizeElementId);
            }
            setFilters(initialFilters as any);
            handleChange(initialFilters as any);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOverMillionToggle = (_event: any, checked: boolean) => {
        if (checked) {
            setFilters({
                ...filters,
                price_start: 1000000,
                price_end: 40000000,
            });
        } else {
            setFilters({
                ...filters,
                price_start: 0,
                price_end: 1000000,
            });
        }
        setOverMillionChecked(checked);
    };

    return (
        <StyledFilter container className={classes.root}>
            <Container>
                <Grid container flexDirection={"row"}>
                    {dropdowns.offerTypes.map((offerType, index) => {
                        return (
                            <Grid
                                item
                                key={offerType.id}
                                className={`${index === 0 ? classes.firstTab : ""} ${
                                    index === dropdowns.offerTypes.length - 1 ? classes.lastTab : ""
                                }
                                ${index > 0 && index < dropdowns.offerTypes.length - 1 ? classes.innerTab : ""}
                                ${
                                    filters.offer_type_id === offerType.id
                                        ? classes.selectedTab
                                        : classes.notSelectedTab
                                }`}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        offer_type_id: offerType.id,
                                    });
                                }}>
                                <Typography className={classes.text} color={theme.palette.common.white}>
                                    {offerType.name}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container alignItems="center" className={classes.container}>
                    <Dropdown
                        className={classes.select}
                        startAdornment={<LocationOnOutlinedIcon />}
                        options={[{ id: 0, name: "Избери Област" }, ...dropdowns.countryAreas]}
                        value={filters.country_area_id}
                        onChange={(event) => {
                            setFilters({ ...filters, country_area_id: parseInt(event.target.value) });
                        }}
                        name={""}
                    />
                    <Dropdown
                        className={classes.select}
                        startAdornment={<WindowOutlinedIcon />}
                        options={[{ id: 0, name: "Избери Размер" }, ...sizeFilters]}
                        value={size}
                        onChange={(event) => {
                            setSize(parseInt(event.target.value));
                            const sizeItem = (sizeFiltersMap as any)[event.target.value];
                            if (sizeItem) {
                                setFilters({
                                    ...filters,
                                    size_start: sizeItem.start,
                                    size_end: sizeItem.end,
                                });
                            } else {
                                setFilters({
                                    ...filters,
                                    size_start: 0,
                                    size_end: 1000000,
                                });
                            }
                        }}
                        name={""}
                    />
                    <Dropdown
                        className={classes.select}
                        startAdornment={<LandscapeOutlinedIcon />}
                        options={[{ id: 0, name: "Избери Имот" }, ...dropdowns.estateTypes]}
                        value={filters.estate_type_id}
                        onChange={(event) => {
                            setFilters({ ...filters, estate_type_id: parseInt(event.target.value) });
                        }}
                        name={""}
                    />
                    <Grid container className={classes.sliderContainer} flexDirection={"column"} alignItems={"center"}>
                        <Slider
                            value={[filters.price_start, filters.price_end]}
                            max={1000000}
                            min={0}
                            marks={sliderMarks}
                            onChange={(event, value) => {
                                const newValue = value as number[];
                                setFilters({ ...filters, price_start: newValue[0], price_end: newValue[1] });
                            }}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => <div>{value / 1000 + "k BGN"}</div>}
                            className={classes.slider}
                        />
                        <CustomCheckbox
                            className={classes.overMillionCheckbox}
                            value={overMillionChecked}
                            checked={overMillionChecked}
                            onChange={handleOverMillionToggle as any}
                            title={"Над милион"}
                            label={"Над милион"}
                        />
                    </Grid>
                    <Grid>
                        <ColorButton variant="contained" color="secondary" onClick={() => handleChange(filters)}>
                            <SearchOutlinedIcon />
                        </ColorButton>
                        <ColorButton
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setFilters({ ...defaultFilters });
                                handleChange({ ...defaultFilters });
                                setOverMillionChecked(false);
                                setSize(0);
                            }}>
                            <ClearIcon />
                        </ColorButton>
                    </Grid>
                </Grid>
            </Container>
        </StyledFilter>
    );
};
