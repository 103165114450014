import { Grid, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAttachment } from "../../../interfaces/attachment";
import { getImageLink } from "../../../endpoints";

const CREATED_IMAGE_PREVIEW_PREFIX = "CreatedImagePreview";

export const createdImagePreviewClasses = {
    image: `${CREATED_IMAGE_PREVIEW_PREFIX}-image`,
    deleteIcon: `${CREATED_IMAGE_PREVIEW_PREFIX}-deleteIcon`,
};

export const StyledCreatedImagePreview = styled(Grid)(({ theme }) => ({
    width: "260px",
    height: "170px",
    background: "white",
    marginRight: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    objectFit: "contain",
    [`& .${createdImagePreviewClasses.image}`]: {
        width: "220px",
        height: "150px",
        objectFit: "contain",
    },
    [`& .${createdImagePreviewClasses.deleteIcon}`]: {
        position: "absolute",
        top: 4,
        right: 4,
        display: "inline-block",
        fill: theme.palette.primary.main,
        "z-index": 2,
        cursor: "pointer",
    },
}));

const classes = createdImagePreviewClasses;

export const CreatedImagePreview = ({
    attachment,
    deletePhoto,
    showDelete,
}: {
    attachment: IAttachment;
    deletePhoto?: () => void;
    showDelete?: boolean;
}) => {
    return (
        <StyledCreatedImagePreview container>
            {showDelete && <DeleteIcon className={classes.deleteIcon} onClick={deletePhoto} />}
            <img src={getImageLink(attachment.link)} alt={"preview"} className={classes.image} />
        </StyledCreatedImagePreview>
    );
};
