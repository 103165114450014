import React from "react";
import { Button, styled } from "@mui/material";

const StyledTextButton = styled(Button)(() => ({
    textTransform: "capitalize",
}));

export const TextButton = (props: {
    text: string | JSX.Element;
    onClick?: (event?: any) => void;
    className?: string;
    disabled?: boolean;
    component?: string;
}) => {
    return (
        <StyledTextButton {...props} color="primary" className={props.className}>
            {props.text}
        </StyledTextButton>
    );
};
