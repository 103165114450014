import { Card, styled } from "@mui/material";
import { greyLight, secondary } from "../Theme/theme";

const ESTATE_CARD_PREFIX = "NewLocations";

export const estateCardClasses = {
    cardContainer: `${ESTATE_CARD_PREFIX}-cardContainer`,
    cardActionArea: `${ESTATE_CARD_PREFIX}-cardActionArea`,
    cardHeading: `${ESTATE_CARD_PREFIX}-cardHeading`,
    cardProperty: `${ESTATE_CARD_PREFIX}-cardProperty`,
    cardPropertyIcon: `${ESTATE_CARD_PREFIX}-cardPropertyIcon`,
    cardLocation: `${ESTATE_CARD_PREFIX}-cardLocation`,
    cardDateTime: `${ESTATE_CARD_PREFIX}-cardDateTime`,
    cardPrice: `${ESTATE_CARD_PREFIX}-cardPrice`,
    cardContent: `${ESTATE_CARD_PREFIX}-cardContent`,
    cardButton: `${ESTATE_CARD_PREFIX}-cardButton`,
    cardImage: `${ESTATE_CARD_PREFIX}-cardImage`,
    cardLabel: `${ESTATE_CARD_PREFIX}-cardLabel`,
    cardLabelText: `${ESTATE_CARD_PREFIX}-cardLabelText`,
    cardLike: `${ESTATE_CARD_PREFIX}-like`,
    promotedContainer: `${ESTATE_CARD_PREFIX}-promotedContainer`,
};

export const StyledEstateCard = styled(Card)(({ theme }) => ({
    width: "350px",
    height: "470px",
    [theme.breakpoints.up("md")]: {
        maxWidth: "345",
        marginRight: "30px",
        marginBottom: "30px",
        borderRadius: "8px",
    },
    [theme.breakpoints.only("sm")]: {
        marginRight: "30px",
        marginBottom: "30px",
        borderRadius: "8px",
        maxWidth: "345",
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
        borderRadius: "8px",
        maxWidth: "345",
    },
    [`& .${estateCardClasses.cardActionArea}`]: {
        position: "relative",
    },
    [`& .${estateCardClasses.cardHeading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "nowrap",
    },
    [`& .${estateCardClasses.cardProperty}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        marginRight: "10px",
    },
    [`& .${estateCardClasses.cardPropertyIcon}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    },
    [`& .${estateCardClasses.cardLocation}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
    },
    [`& .${estateCardClasses.cardDateTime}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "12px",
        marginRight: "10px",
        color: greyLight,
    },
    [`& .${estateCardClasses.cardPrice}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        marginRight: "20px",
    },
    [`& .${estateCardClasses.cardContent}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
    },
    [`& .${estateCardClasses.cardButton}`]: {
        borderRadius: "100px",
        width: "185px",
        fontWeight: "normal",
        fontSize: "16px",
        textTransform: "capitalize",
    },
    [`& .${estateCardClasses.cardImage}`]: {
        margin: "auto",
        marginTop: "15px",
        width: "90%",
        borderRadius: "8px",
        height: "230px",
    },
    [`& .${estateCardClasses.cardLabel}`]: {
        position: "absolute",
        top: 30,
        left: 30,
        display: "flex",
        alignItems: "center",
        backgroundColor: secondary,
        borderRadius: "4px",
        padding: "5px 10px",
        width: "80%",
    },
    [`& .${estateCardClasses.cardLabelText}`]: {
        color: theme.palette.common.white,
    },
    [`& .${estateCardClasses.cardLike}`]: {
        position: "absolute",
        top: 30,
        right: 30,
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        padding: "5px",
        backgroundColor: "#FAFAFA",
    },
    [`& .${estateCardClasses.promotedContainer}`]: {
        position: "absolute",
        top: 30,
        left: 30,
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
        padding: "5px",
        borderRadius: "5px",
    },
}));
