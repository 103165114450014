import { httpService, HttpService } from "./http.service";
import { resetPasswordEndpoint } from "../endpoints";

export class ResetPasswordService {
    constructor(private readonly httpService: HttpService) {}

    public async sendResetPasswordEmail(email: string): Promise<void> {
        await this.httpService.post(`${resetPasswordEndpoint}/${email}`);
    }

    public async resetPassword(resetPasswordHash: string, password: string): Promise<void> {
        await this.httpService.put(`${resetPasswordEndpoint}/${resetPasswordHash}`, {
            password,
        });
    }
}

export const resetPasswordService = new ResetPasswordService(httpService);
