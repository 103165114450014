import { Grid, Paper } from "@mui/material";
import { GOOGLE_API_KEY } from "../../../../../env";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import {
    StyledCreateEstateFormLocationMapPreview,
    createEstateClasses as classes,
} from "./StyledCreateEstateFormLocationMapPreview";

interface CreateEstateFormLocationMapPreviewProps {
    form: IForm<SellerProfileState>;
    mapLocation: string;
}

const CreateEstateFormLocationMapPreview = ({ form, mapLocation }: CreateEstateFormLocationMapPreviewProps) => {
    if (form.values.country_area_id && form.values.municipality_id && form.values.city) {
        return (
            <StyledCreateEstateFormLocationMapPreview>
                <Grid container>
                    <Paper className={classes.paperContainer}>
                        <iframe
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            title={`map`}
                            loading="lazy"
                            allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${mapLocation}`}
                        />
                    </Paper>
                </Grid>
            </StyledCreateEstateFormLocationMapPreview>
        );
    }
    return null;
};

export default CreateEstateFormLocationMapPreview;
