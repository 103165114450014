import {
    existingParkId,
    piId,
    solarRenewableId,
    upiId,
    windRenewableId,
} from "../Seller/SellerProfile/CreateEstate/schema";
import { SellerProfileState } from "../Seller/SellerProfile/CreateEstate/utils";
import { IEstate } from "../interfaces/estate";

export const documentKeys: {
    availabilityKey: keyof IEstate;
    fileKey: keyof SellerProfileState;
    idKey: keyof IEstate;
    objectKey: keyof IEstate;
    nameOfDocument: string;
}[] = [
    {
        availabilityKey: "has_ownership_document",
        fileKey: "ownership_document_attachment_file",
        idKey: "ownership_document_attachment",
        objectKey: "ownership_document_attachment_object",
        nameOfDocument: "Документ за собственост",
    },
    {
        availabilityKey: "sketch",
        fileKey: "sketch_attachment_file",
        idKey: "sketch_attachment",
        objectKey: "sketch_attachment_object",
        nameOfDocument: "Скица",
    },
    {
        availabilityKey: "design_visa",
        fileKey: "design_visa_attachment_file",
        idKey: "design_visa_attachment",
        objectKey: "design_visa_attachment_object",
        nameOfDocument: "Виза за проектиране",
    },
    {
        availabilityKey: "erp_accession_opinion",
        fileKey: "erp_accession_opinion_attachment_file",
        idKey: "erp_accession_opinion_attachment",
        objectKey: "erp_accession_opinion_attachment_object",
        nameOfDocument: "Становище за пръсединяване от ЕРП",
    },
    {
        availabilityKey: "preliminary_contract_with_erp",
        fileKey: "preliminary_contract_with_erp_attachment_file",
        idKey: "preliminary_contract_with_erp_attachment",
        objectKey: "preliminary_contract_with_erp_attachment_object",
        nameOfDocument: "Предварителен договор с ЕРП",
    },
    {
        availabilityKey: "official_contract_with_erp",
        fileKey: "official_contract_with_erp_attachment_file",
        idKey: "official_contract_with_erp_attachment",
        objectKey: "official_contract_with_erp_attachment_object",
        nameOfDocument: "Договор с ЕРП",
    },
    {
        availabilityKey: "pyp",
        fileKey: "pyp_attachment_file",
        idKey: "pyp_attachment",
        objectKey: "pyp_attachment_object",
        nameOfDocument: "ПУП",
    },
    {
        availabilityKey: "weight_inquiry",
        fileKey: "weights_inquiry_attachment_file",
        idKey: "weights_inquiry_attachment",
        objectKey: "weights_inquiry_attachment_object",
        nameOfDocument: "Тежести справка",
    },
    {
        availabilityKey: "building_permit",
        fileKey: "building_permit_attachment_file",
        idKey: "building_permit_attachment",
        objectKey: "building_permit_attachment_object",
        nameOfDocument: "Разрешение за строеж",
    },
    {
        availabilityKey: "availability_of_electricity_batch",
        fileKey: "availability_of_electricity_batch_attachment_file",
        idKey: "availability_of_electricity_batch_attachment",
        objectKey: "availability_of_electricity_batch_attachment_object",
        nameOfDocument: "Наличие на партида ел. за имота",
    },
    {
        //Here we need to have this function getOtherDocumentLabel()
        availabilityKey: "other_documents",
        fileKey: "other_documents_attachment_file",
        idKey: "other_documents_attachment",
        objectKey: "other_documents_attachment_object",
        nameOfDocument: "",
    },
];

export const cleanEstate = (estate: IEstate) => {
    if (estate.estate_type_id !== upiId) {
        (estate as any).upi_purpose = null;
    }

    if (estate.estate_type_id !== piId) {
        (estate as any).land_category_id = null;
        (estate as any).land_type_id = null;
    }

    if (estate.estate_type_id !== existingParkId) {
        (estate as any).pricing_id = null;
        (estate as any).capacity = null;
        (estate as any).capacity_type_id = null;
        (estate as any).support_schemes = null;
        (estate as any).support_schemes_description = null;
        (estate as any).external_financing = null;
        (estate as any).external_financing_amount = null;
        (estate as any).put_into_operation = null;
        (estate as any).put_into_operation_year = null;
    }

    if (!estate.is_substation_present) {
        (estate as any).substation_distance_in_meters = null;
    }
    if (!estate.electrical_wire) {
        (estate as any).voltage_id = null;
        (estate as any).meters_to_nearest_electricity_pillar = null;
    }
    if (estate.estate_type_id !== existingParkId) {
        (estate.solar_panel_brand as any) = null;
        (estate.solar_panel_capacity as any) = null;
        (estate.solar_panel_capacity_id as any) = null;
        (estate.solar_panel_count as any) = null;
        (estate.solar_invertor_brand as any) = null;
        (estate.solar_invertor_capacity as any) = null;
        (estate.solar_invertor_capacity_id as any) = null;
        (estate.solar_invertor_count as any) = null;
        (estate.wind_brand as any) = null;
        (estate.wind_capacity as any) = null;
        (estate.wind_capacity_id as any) = null;
        (estate.wind_count as any) = null;
        (estate.renewable_type_id as any) = null;
    } else if (estate.estate_type_id === existingParkId && estate.renewable_type_id !== solarRenewableId) {
        (estate as any).solar_panel_brand = null;
        (estate as any).solar_panel_capacity = null;
        (estate as any).solar_panel_capacity_id = null;
        (estate as any).solar_panel_count = null;
        (estate as any).solar_invertor_brand = null;
        (estate as any).solar_invertor_capacity = null;
        (estate as any).solar_invertor_capacity_id = null;
        (estate as any).solar_invertor_count = null;
    } else if (estate.estate_type_id === existingParkId && estate.renewable_type_id === windRenewableId) {
        (estate.wind_brand as any) = null;
        (estate.wind_capacity as any) = null;
        (estate.wind_capacity_id as any) = null;
        (estate.wind_count as any) = null;
    }

    documentKeys.forEach((keys) => {
        if (!estate[keys.availabilityKey as keyof IEstate]) {
            (estate[keys.idKey as keyof IEstate] as any) = null;
        }
    });

    return estate;
};
