import * as yup from "yup";

export const supportedFormats = [".jpg", ".png", ".jpeg", ".pdf"];
const maxFileSizeMb = 10;
export const maxFileSize = maxFileSizeMb * 1000 * 1024; // 10MB

export const existingParkId = 4;
export const upiId = 1;
export const piId = 2;
export const solarRenewableId = 3;
export const windRenewableId = 2;

const getDocumentSchema = () => {
    return yup
        .mixed()
        .test(
            "fileSize",
            `Файлът е твърде голям, моля ползвайте под ${maxFileSizeMb}`,
            (value) => value === null || (value && value.size <= maxFileSize)
        )
        .test(
            "fileFormat",
            `Форматът е невалиден, моля използвайте ${supportedFormats.join(",")}`,
            (value) =>
                value === null ||
                (value &&
                    supportedFormats.some((supportedFormat) =>
                        value.name.toLowerCase().endsWith(supportedFormat.toLowerCase())
                    ))
        );
};

const getDefaultNumberSchema = (label: string) =>
    yup.number().min(1, `Изберете стойност за '${label}'`).required(`Полето '${label}' е задължително`);

export const estateSchema = yup.object({
    title: yup
        .string()
        .min(1, "Полето 'Заглавие' е задължително")
        .max(200, "Полето 'Заглавие' не трябва да надвишава 200 символа")
        .trim()
        .required("Полето 'Заглавие' е задължително"),
    description: yup.string().max(1500, "Полето 'Oписание' не трябва да надвишава 1500 символа").trim(),
    estate_type_id: getDefaultNumberSchema("Тип имот"),
    offer_type_id: getDefaultNumberSchema("Тип обява"),

    renewable_type_id: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === existingParkId,
            then: yup
                .number()
                .min(1, "Изберете стойност за 'ВЕИ'")
                .required("Полето 'ВЕИ' е задължително")
                .typeError("Полето 'ВЕИ' е задължително"),
        }),

    price: getDefaultNumberSchema("Цена"),
    area: getDefaultNumberSchema("Площ"),

    upi_purpose: yup
        .string()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === upiId,
            then: yup
                .string()
                .trim()
                .max(200, "Полето 'Предназначение' не трябва да надвишава 200 символа")
                .required("Полето 'Предназначение' е задължително"),
        }),

    country_area_id: getDefaultNumberSchema("Област"),
    municipality_id: getDefaultNumberSchema("Община"),
    city: yup.string().required("Полето е задължително"),

    land_category_id: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === piId,
            then: yup
                .number()
                .min(1, "Изберете стойност за 'Категория земя'")
                .required("Полето 'Категория земя' е задължително"),
        }),
    land_type_id: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === piId,
            then: yup.number().min(1, "Изберете стойност за 'Тип земя'").required("Полето 'Тип земя' е задължително"),
        }),

    // Exact Address
    exact_address: yup.string().max(300, "Полето 'Точен адрес' не трябва да надвишава 300 символа"),
    cadastral_identification_number: yup
        .string()
        .max(100, "Полето 'Kaдастрален номер' не трябва да надвишава 100 символа"),
    upi_pi_number: yup.string().max(100, "Полето не трябва да надвишава 100 символа"),

    currency_id: getDefaultNumberSchema("Валута"),
    area_unit_id: getDefaultNumberSchema("Mерна единица"),

    coordinates_lat: yup.number(),
    coordinates_lng: yup.number(),
    chosen_location: yup.boolean().nullable(),

    // Acting Park Details
    pricing_id: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === existingParkId,
            then: yup
                .number()
                .min(1, "Изберете стойност за 'Ценообразуване'")
                .required("Полето 'Ценообразуване' е задължително"),
        }),
    capacity: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === existingParkId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Генерираща мощност'")
                .min(1, "Изберете стойност за 'Генерираща мощност'")
                .required("Полето 'Генерираща мощност' е задължително"),
        }),
    capacity_type_id: yup
        .number()
        .nullable()
        .when("estate_type_id", {
            is: (estate_type_id: number) => estate_type_id === existingParkId,
            then: yup
                .number()
                .min(1, "Изберете стойност за 'Mерна Единица'")
                .required("Полето 'Mерна Единица' е задължително"),
        }),
    support_schemes: yup.boolean().nullable(),
    support_schemes_description: yup
        .string()
        .nullable()
        .when(["estate_type_id", "support_schemes"], {
            is: (estate_type_id: number, support_schemes: boolean) =>
                estate_type_id === existingParkId && support_schemes,
            then: yup
                .string()
                .trim()
                .min(1, "Изберете стойност за 'Oписание на подпомагащите схеми'")
                .required("Полето 'Oписание на подпомагащите схеми' е задължително"),
        }),
    external_financing: yup.boolean().nullable(),
    external_financing_amount: yup
        .number()
        .nullable()
        .typeError("")
        .when(["estate_type_id", "external_financing"], {
            is: (estate_type_id: number, external_financing: boolean) =>
                estate_type_id === existingParkId && external_financing,
            then: yup
                .number()
                .min(1, "Изберете стойност за 'Стойност на въшно финансиране'")
                .required("Полето 'Стойност на въшно финансиране' е задължително")
                .typeError("Полето 'Стойност на въшно финансиране' е задължително"),
        }),

    put_into_operation: yup.boolean().nullable(),
    put_into_operation_year: yup
        .number()
        .typeError("")
        .nullable()
        .when(["estate_type_id", "put_into_operation"], {
            is: (estate_type_id: number, put_into_operation: boolean) =>
                estate_type_id === existingParkId && put_into_operation,
            then: yup
                .number()
                .min(2000, "Изберете стойност за 'Година'")
                .typeError("Изберете стойност за 'Година'")
                .required("Полето 'Година' е задължително"),
        }),

    // Need contact per estate, since we can copy the existing or create new ones
    email: yup.string().trim().required("Полето 'Email' е задължително").email("Полето 'Email' трбябва да е валидно"),
    phone: yup.string().trim().required("Полето 'Телефон' е задължително"),

    // Present Documents
    has_ownership_document: yup.boolean(),
    sketch: yup.boolean(),
    design_visa: yup.boolean(),
    erp_accession_opinion: yup.boolean(),
    preliminary_contract_with_erp: yup.boolean(),
    official_contract_with_erp: yup.boolean().nullable(),
    pyp: yup.boolean(),
    weight_inquiry: yup.boolean(),
    building_permit: yup.boolean(),
    availability_of_electricity_batch: yup.boolean(),

    // Other Documents
    is_substation_present: yup.boolean(),
    personal_substation: yup.boolean().nullable(),
    substation_distance_in_meters: yup
        .number()
        .typeError("Изберете стойност за 'Метри до трафопост'")
        .nullable()
        .when(["is_substation_present"], {
            is: (is_substation_present: boolean) => is_substation_present,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Метри до трафопост'")
                .min(1, "Изберете стойност за 'Метри до трафопост'")
                .required("Полето 'Метри до трафопост' е задължително"),
        }),
    electrical_wire: yup.boolean(),
    voltage_id: yup
        .number()
        .nullable()
        .typeError("Изберете стойност за 'Напрежение'")
        .when(["electrical_wire"], {
            is: (electrical_wire: boolean) => electrical_wire,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Напрежение'")
                .min(1, "Изберете стойност за 'Напрежение'")
                .required("Полето е задължително за 'Напрежение'"),
        }),
    meters_to_nearest_electricity_pillar: yup
        .number()
        .nullable()
        .typeError("Изберете стойност за 'Метри до стълб'")
        .when(["electrical_wire"], {
            is: (electrical_wire: boolean) => electrical_wire,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Метри до стълб'")
                .min(1, "Изберете стойност за 'Метри до стълб'")
                .required("Полето е задължително за 'Метри до стълб'"),
        }),

    solar_panel_brand: yup
        .string()
        .trim()
        .nullable()
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .string()
                .trim()
                .min(1, "Полето 'Марка панели' е задължително")
                .max(200, "Полето 'Марка панели' не трябва да надвишава 200 символа")
                .required("Полето 'Марка панели' е задължително"),
        }),
    solar_panel_capacity: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Kaпацитет'")
                .min(1, "Изберете стойност за 'Kaпацитет'")
                .required("Полето е задължително за 'Kaпацитет'"),
        }),
    solar_panel_capacity_id: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Мерна единица'")
                .min(1, "Изберете стойност за 'Мерна единица'")
                .required("Полето 'Мерна единица' е задължително"),
        }),
    solar_panel_count: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Брой'")
                .min(1, "Изберете стойност за 'Брой'")
                .required("Полето 'Брой' е задължително"),
        }),

    solar_invertor_brand: yup
        .string()
        .trim()
        .nullable()
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .string()
                .trim()
                .min(1, "Полето 'Марка инвертори' е задължително")
                .max(200, "Полето 'Марка инвертори'  не трябва да надвишава 200 символа")
                .required("Полето 'Марка инвертори'  е задължително"),
        }),
    solar_invertor_capacity: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Kaпацитет'")
                .min(1, "Изберете стойност за 'Kaпацитет'")
                .required("Полето е задължително за 'Kaпацитет'"),
        }),
    solar_invertor_capacity_id: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Мерна единица'")
                .min(1, "Изберете стойност за 'Мерна единица'")
                .required("Полето 'Мерна единица' е задължително"),
        }),
    solar_invertor_count: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === solarRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Брой'")
                .min(1, "Изберете стойност за 'Брой'")
                .required("Полето 'Брой' е задължително"),
        }),

    wind_brand: yup
        .string()
        .trim()
        .nullable()
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === windRenewableId,
            then: yup
                .string()
                .trim()
                .min(1, "Полето 'Марка вятърни турбини' е задължително")
                .max(200, "Полето 'Марка вятърни турбини' не трябва да надвишава 200 символа")
                .required("Полето 'Марка вятърни турбини' е задължително"),
        }),
    wind_capacity: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === windRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Kaпацитет'")
                .min(1, "Изберете стойност за 'Kaпацитет'")
                .required("Полето 'Kaпацитет' е задължително"),
        }),
    wind_capacity_id: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === windRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Мерна единица'")
                .min(1, "Изберете стойност за 'Мерна единица'")
                .required("Полето 'Мерна единица' е задължително"),
        }),
    wind_count: yup
        .number()
        .nullable()
        .typeError("Изберете стойност")
        .when(["estate_type_id", "renewable_type_id"], {
            is: (estate_type_id: number, renewable_type_id: number) =>
                estate_type_id === existingParkId && renewable_type_id === windRenewableId,
            then: yup
                .number()
                .typeError("Изберете стойност за 'Брой'")
                .min(1, "Изберете стойност за 'Брой'")
                .required("Полето 'Брой' е задължително"),
        }),
    // TODO: Need to be linked with the toggle
    ownership_document_attachment_file: getDocumentSchema(),
    sketch_attachment_file: getDocumentSchema(),
    erp_accession_opinion_attachment_file: getDocumentSchema(),
    official_contract_with_erp_attachment_file: getDocumentSchema(),
    preliminary_contract_with_erp_attachment_file: getDocumentSchema(),
    pyp_attachment_file: getDocumentSchema(),
    weights_inquiry_attachment_file: getDocumentSchema(),
    building_permit_attachment_file: getDocumentSchema(),
    availability_of_electricity_batch_attachment_file: getDocumentSchema(),
    design_visa_attachment_file: getDocumentSchema(),

    weights_inquiry_attachment_object: yup.object().nullable(),
    availability_of_electricity_batch_attachment_object: yup.object().nullable(),
    building_permit_attachment_object: yup.object().nullable(),
    pyp_attachment_object: yup.object().nullable(),
    preliminary_contract_with_erp_attachment_object: yup.object().nullable(),
    design_visa_attachment_object: yup.object().nullable(),
    official_contract_with_erp_attachment_object: yup.object().nullable(),
    erp_accession_opinion_attachment_object: yup.object().nullable(),
    ownership_document_attachment_object: yup.object().nullable(),
    sketch_attachment_object: yup.object().nullable(),
});
