const CONFIRM_MODAL_PREFIX = "confirmModal";

export const confirmModalClasses = {
    root: `${CONFIRM_MODAL_PREFIX}-root`,
    paper: `${CONFIRM_MODAL_PREFIX}-paper`,
    acceptButton: `${CONFIRM_MODAL_PREFIX}-acceptButton`,
    declineButton: `${CONFIRM_MODAL_PREFIX}-declineButton`,
    rejectButton: `${CONFIRM_MODAL_PREFIX}-rejectButton`,
    deleteIcon: `${CONFIRM_MODAL_PREFIX}-deleteIcon`,
};
import { Modal, styled } from "@mui/material";

export const StyledConfirmModal = styled(Modal)(({ theme }) => ({
    [`&.${confirmModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    [`& .${confirmModalClasses.paper}`]: {
        padding: "20px",
        height: "400px",
        width: "500px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        [theme.breakpoints.only("xs")]: {
            height: "500px",
        },
    },
    [`& .${confirmModalClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${confirmModalClasses.rejectButton}`]: {
        marginRight: "10px",
    },
    [`& .${confirmModalClasses.deleteIcon}`]: {
        fontSize: "70px",
    },
    [`& .${confirmModalClasses.acceptButton}`]: {},
}));
