import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_UPLOAD_IMAGES_PREFIX = "CreateEstateFormUploadImages";

export const createEstateClasses = {
    dropFilesLabel: `${CREATE_ESTATE_FORM_UPLOAD_IMAGES_PREFIX}-dropFilesLabel`,
    inputContainer: `${CREATE_ESTATE_FORM_UPLOAD_IMAGES_PREFIX}-inputContainer`,
};

export const StyledCreateEstateFormUploadImages = styled(Grid)(({ theme }) => ({
    [`& .${createEstateClasses.inputContainer}`]: {
        width: "100%",
        margin: "20px 0",
    },
    [`& .${createEstateClasses.dropFilesLabel}`]: {
        border: `2px dashed #A5AFB9`,
        fontFamily: "Roboto, serif",
        width: "100%",
        borderRadius: "5px",
        padding: "20px",
        fontSize: "15px",
        color: "#A5AFB9",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            width: "88%",
            marginLeft: "5%",
        },
        [theme.breakpoints.only("sm")]: {
            width: "100%",
        },
    },
}));
