import React, { useContext } from "react";
import { NavigationRoutes } from "../../routes";
import { GlobalContext } from "../../App";
import { UserType } from "../../interfaces/user";
import { ViewContainer } from "../../Core/ViewContainer/ViewContainer";
import { Grid, Typography } from "@mui/material";

const openRoutes = [
    NavigationRoutes.Default,
    NavigationRoutes.Home,
    NavigationRoutes.Login,
    NavigationRoutes.Register,
    NavigationRoutes.ForgotPassword,
    NavigationRoutes.ResetPassword,
];

const sellerRoutes = [
    NavigationRoutes.EstateDetails,
    NavigationRoutes.EstateListing,
    NavigationRoutes.CreateEstate,
    NavigationRoutes.EditEstate,
    NavigationRoutes.SellerProfile,
];

const buyerRoutes = [
    NavigationRoutes.BuyerProfile,
    NavigationRoutes.EstateDetails,
    NavigationRoutes.EstateListing,
    NavigationRoutes.EditEstate,
    NavigationRoutes.CreateEstate,
];

const adminRoutes = [
    NavigationRoutes.EstateDetails,
    NavigationRoutes.EstateListing,
    NavigationRoutes.CreateEstate,
    NavigationRoutes.EditEstate,
    NavigationRoutes.SellerProfile,
    NavigationRoutes.BuyerProfile,
    NavigationRoutes.AdminUser,
];

export const FeatureBoundary = (props: { route: NavigationRoutes; children: any }) => {
    const { user } = useContext(GlobalContext);
    if (openRoutes.includes(props.route)) {
        return props.children;
    } else if (user?.role === UserType.Seller && sellerRoutes.includes(props.route)) {
        return props.children;
    } else if (user?.role === UserType.Buyer && buyerRoutes.includes(props.route)) {
        return props.children;
    } else if (user?.role === UserType.Admin && adminRoutes.includes(props.route)) {
        return props.children;
    }
    return (
        <ViewContainer>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "500px" }}>
                <Typography variant="body1">
                    Нямате достъп до тази страница. Моля влезте в системата и опитайте пак!
                </Typography>
            </Grid>
        </ViewContainer>
    );
};
