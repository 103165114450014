import { Grid, styled } from "@mui/material";
import { primary } from "../../../common/Theme/theme";

const CREATE_ESTATE_SUCCESS_PREFIX = "CreateEstateSuccess";

export const createEstateSuccessClasses = {
    messageContainer: `${CREATE_ESTATE_SUCCESS_PREFIX}-messageContainer`,
    checkIcon: `${CREATE_ESTATE_SUCCESS_PREFIX}-checkIcon`,
    message: `${CREATE_ESTATE_SUCCESS_PREFIX}-message`,
};

export const StyledCreateEstateSuccess = styled(Grid)(({ theme }) => ({
    width: "100%",
    justifyContent: "center",
    [`& .${createEstateSuccessClasses.messageContainer}`]: {
        padding: "50px",
    },
    [`& .${createEstateSuccessClasses.message}`]: {
        color: primary,
    },
    [`& .${createEstateSuccessClasses.checkIcon}`]: {
        width: "100px",
        height: "100px",
        color: theme.palette.success.main,
        marginBottom: "25px",
    },
}));
