import { Button, Card, CardActions, CardContent, Grid, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../App";
import { TEST_ENV_PAYMENT_URL } from "../../env";
import { IPlan } from "../../interfaces/plan";
import { paymentService } from "../../services/payment.service";
import { plansPaperClasses, StyledPlansPaperCredits } from "./StyledPlansPaper";
import creditsIcon from "./assets/creditsIcon.svg";
import { Cost } from "../../common/utils";
import CustomCheckbox from "../../common/CustomCheckbox/CustomCheckbox";

interface IPlansPaperProps {
    estateId?: number;
}

export const getRegularPlanPrice = (plan: IPlan) => {
    return plan.quantity;
};

export const getPlanDiscountPercentage = (plan: IPlan) => {
    return Math.round(100 - (Math.ceil(plan.price) / getRegularPlanPrice(plan)) * 100);
};

export const getPlanTitle = (plan: IPlan) => {
    return `${plan.quantity} ${plan.quantity > 1 ? "кредита" : "кредит"}`;
};
export const getPlanTitlePrice = (plan: IPlan) => {
    return `${plan.price}лв`;
};

export const getPlanRegularPriceText = (plan: IPlan) => {
    return `${getRegularPlanPrice(plan)}лв`;
};

export const getPlanDiscountText = (plan: IPlan) => {
    return `${getPlanDiscountPercentage(plan)}% намаление`;
};

export const PlansPaper = ({ estateId }: IPlansPaperProps) => {
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [paymentProperties, setPaymentProperties] = useState([]);
    const [purchasePlanId, setPurchasePlanId] = useState<number | null>(null);
    const [hasRequestedInvoice, setHasRequestedInvoice] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
    const { user } = useContext(GlobalContext);

    const handleHasRequestedInvoice = () => {
        setHasRequestedInvoice(!hasRequestedInvoice);
    };

    const fetchPlans = () => {
        paymentService.getAllPlans().then((result) => {
            setPlans(result);
        });
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    useEffect(() => {
        if (!!purchasePlanId && paymentProperties.length > 0) {
            (document.getElementById(`payment-plan-${purchasePlanId}`) as any)?.submit();
        }
    }, [purchasePlanId, paymentProperties]);

    return (
        <StyledPlansPaperCredits className={plansPaperClasses.root}>
            <Paper className={plansPaperClasses.paper}>
                <Grid
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    className={plansPaperClasses.titleContainer}
                    marginBottom="40px">
                    <img src={creditsIcon} className={plansPaperClasses.creditsIcon} alt="Credits Icon" />
                    <Typography className={plansPaperClasses.title}>Купуване на кредити</Typography>
                </Grid>
                <Grid container flexDirection={"row"} justifyContent={"space-evenly"}>
                    {plans.map((plan) => {
                        return (
                            <form id={`payment-plan-${plan.id}`} action={TEST_ENV_PAYMENT_URL} method={"POST"}>
                                <Card
                                    className={`${plansPaperClasses.planCard} ${
                                        selectedPlan === plan.id ? `${plansPaperClasses.highlightBorder}` : ""
                                    }`}
                                    onClick={() => {
                                        setSelectedPlan(plan.id);
                                        setPurchasePlanId(plan.id);
                                    }}>
                                    <CardContent className={plansPaperClasses.planCardContent}>
                                        <Typography className={plansPaperClasses.planTitle}>
                                            {getPlanTitle(plan)}
                                        </Typography>
                                        {plan.quantity > 1 && getPlanDiscountPercentage(plan) > 0 ? (
                                            <>
                                                <Typography component="div" textAlign="center">
                                                    <span
                                                        className={`${plansPaperClasses.secondaryText}, ${plansPaperClasses.crossOut}`}>
                                                        {getPlanRegularPriceText(plan)}
                                                    </span>
                                                    <Typography
                                                        className={plansPaperClasses.planTitlePrice}
                                                        display="inline-block"
                                                        paddingLeft="14px">
                                                        {getPlanTitlePrice(plan)}
                                                    </Typography>
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    className={plansPaperClasses.planDiscountTextContainer}
                                                    marginTop="7px">
                                                    <Typography
                                                        paddingTop="3px"
                                                        className={`${plansPaperClasses.secondaryText}, ${plansPaperClasses.planDiscountText}`}>
                                                        {getPlanDiscountText(plan)}
                                                    </Typography>
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography className={plansPaperClasses.planTitlePrice}>
                                                {getPlanTitlePrice(plan)}
                                            </Typography>
                                        )}
                                    </CardContent>
                                    <CardActions>
                                        {paymentProperties.map((data) => {
                                            return (
                                                <input
                                                    type={"text"}
                                                    value={data[1]}
                                                    className={plansPaperClasses.visibilityOfPayment}
                                                    name={data[0]}
                                                />
                                            );
                                        })}
                                    </CardActions>
                                </Card>
                            </form>
                        );
                    })}
                </Grid>
                <CustomCheckbox
                    className={plansPaperClasses.customCheckBox}
                    value={hasRequestedInvoice}
                    checked={hasRequestedInvoice}
                    onChange={handleHasRequestedInvoice}
                    title={"invoice"}
                    label={"Искам фактура за извършено плащане"}
                    name={"invoice"}
                />
                <Grid className={plansPaperClasses.buttonContainer}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        className={plansPaperClasses.acceptButton}
                        onClick={() => {
                            if (user && purchasePlanId) {
                                paymentService
                                    .buyCredits(purchasePlanId, estateId, hasRequestedInvoice)
                                    .then((response: any) => {
                                        const newPaymentProperties = Object.entries(response.data);
                                        setPaymentProperties(newPaymentProperties as any);
                                    });
                            }
                        }}>
                        Купи
                    </Button>
                </Grid>
                <Grid container>
                    <Typography className={plansPaperClasses.description} marginTop={"10px"}>
                        * Цена за отключване на ПРЕМИУМ обява съдържаща описание, прикачени документи и контакти -{" "}
                        {Cost.Document}
                        кредита.
                    </Typography>
                    <Typography className={plansPaperClasses.description} marginTop={"10px"}>
                        * Цена за отключване на СТАНДАРТНА обява съдържаща само описание и контакти- {Cost.Contact}{" "}
                        кредита."
                    </Typography>
                </Grid>
            </Paper>
        </StyledPlansPaperCredits>
    );
};
