import { ILogged, UserType } from "../interfaces/user";
import { IEstate } from "../interfaces/estate";
import { IAttachment } from "../interfaces/attachment";
import { httpService } from "../services/http.service";

export const isSeller = (user: ILogged) => {
    return user && user.role === UserType.Seller;
};

export const isAdmin = (user: ILogged) => {
    return user && user.role === UserType.Admin;
};

export const isBuyer = (user: ILogged) => {
    return user && user.role === UserType.Buyer;
};

export const isLoggedIn = (user: ILogged) => {
    return !!user;
};

export const getJoinedLocationParts = (estate: IEstate, dropdownsMap: any) =>
    `${dropdownsMap!.countryAreas[estate.country_area_id]?.name},
                                        ${dropdownsMap!.municipality[estate.municipality_id]?.name},
                                        ${estate.city},
                                        ${estate.exact_address}`;

export const getLocation = (estate: IEstate, dropdownsMap: any) => {
    return estate.chosen_location
        ? `${estate.coordinates_lat},${estate.coordinates_lng}`
        : getJoinedLocationParts(estate, dropdownsMap);
};

export const getCoordinatesOrLocation = (estate: IEstate, dropdownsMap: any) => {
    return estate.coordinates_lat && estate.coordinates_lng
        ? `${estate.coordinates_lat},${estate.coordinates_lng}`
        : getJoinedLocationParts(estate, dropdownsMap);
};

export const getMimetype = (attachmentObject?: IAttachment) => {
    if (!attachmentObject) {
        return "image/jpg";
    }
    const extension = attachmentObject?.name.split(".")[1].toLowerCase();
    if (extension === "pdf") {
        return "application/pdf";
    } else if (extension === "png") {
        return "image/png";
    } else if (extension === "jpg") {
        return "image/jpg";
    } else if (extension === "heic") {
        return "image/heic";
    } else {
        return "image/jpg";
    }
};

export const getAttachmentFile = (action: string, attachmentObject?: IAttachment) => {
    httpService.get(action, undefined, "arraybuffer").then((response) => {
        const href = URL.createObjectURL(new Blob([response], { type: getMimetype(attachmentObject) }));

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", attachmentObject?.name || "document");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};

export enum Cost {
    Document = 20,
    Contact = 5,
    DocumentMinusContact = 15,
}
