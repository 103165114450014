import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledSuccessModal, successModalClasses } from "./StyledSuccessModal";

interface ISuccessModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose?: () => void;
    text: string;
}

export const SuccessModal = (props: ISuccessModalProps) => {
    return (
        <StyledSuccessModal
            open={props.open}
            className={successModalClasses.root}
            onClose={() => {
                if (props.onClose) {
                    props.onClose();
                }
            }}>
            <Paper className={successModalClasses.paper}>
                <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>{props.text}</Typography>
                    <IconButton
                        onClick={() => {
                            props.setOpen(false);
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        className={successModalClasses.acceptButton}
                        onClick={() => {
                            props.setOpen(false);
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        Добре
                    </Button>
                </Grid>
            </Paper>
        </StyledSuccessModal>
    );
};
