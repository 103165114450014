import { Modal, styled } from "@mui/material";

const ERROR_MODAL_PREFIX = "errorModal";

export const errorModalClasses = {
    root: `${ERROR_MODAL_PREFIX}-root`,
    paper: `${ERROR_MODAL_PREFIX}-paper`,
    acceptButton: `${ERROR_MODAL_PREFIX}-acceptButton`,
    description: `${ERROR_MODAL_PREFIX}-description`,
};
export const StyledErrorModal = styled(Modal)(({ theme }) => ({
    [`&.${errorModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${errorModalClasses.paper}`]: {
        padding: "20px",
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    [`& .${errorModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
    },
    [`& .${errorModalClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
}));
