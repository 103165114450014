import { Grid, Typography } from "@mui/material";
import WaterIcon from "@mui/icons-material/Water";
import solarIcon from "../../../assets/solar-icon.png";
import windIcon from "../../../assets/wind-icon.png";
import { primary } from "../../../../../common/Theme/theme";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import {
    StyledCreateEstateFormExistingPark,
    createEstateClasses as classes,
} from "./StyledCreateEstateFormExistingPark";

interface CreateEstateFormExistingPark {
    form: IForm<SellerProfileState>;
    isDisabled: boolean;
}
const CreateEstateFormExistingPark = ({ form, isDisabled }: CreateEstateFormExistingPark) => {
    if (isDisabled) {
        return (
            <StyledCreateEstateFormExistingPark>
                <Grid container marginTop="40px">
                    <Grid
                        item
                        lg={2}
                        display={form.values.renewable_type_id === 3 ? "flex" : "none"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px">
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColorSecondary}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <img src={solarIcon} alt={"Solar"} width={"55%"} height={"50%"} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            фТЕЦ
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        display={form.values.renewable_type_id === 2 ? "flex" : "none"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px">
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColor}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <img src={windIcon} alt={"Wind"} width={"50%"} height={"70%"} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            ВяЕЦ
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        display={form.values.renewable_type_id === 1 ? "flex" : "none"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px"
                        onClick={() => {
                            form.setFieldValue("renewable_type_id", 1);
                        }}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColorPrimary}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <WaterIcon className={classes.waterIcon} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            ВЕЦ
                        </Typography>
                    </Grid>
                </Grid>
            </StyledCreateEstateFormExistingPark>
        );
    } else {
        return (
            <StyledCreateEstateFormExistingPark>
                <Grid container marginTop="40px">
                    <Grid
                        item
                        lg={2}
                        display="flex"
                        flexDirection={"column"}
                        alignItems={"center"}
                        border={`1px solid ${form.values.renewable_type_id === 3 ? primary : "transparent"}`}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px"
                        onClick={() => {
                            form.setFieldValue("renewable_type_id", 3);
                            form.setFieldValue("solar_panel_capacity_id", 1);
                            form.setFieldValue("solar_invertor_capacity_id", 1);
                        }}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColorSecondary}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <img src={solarIcon} alt={"Solar"} width={"55%"} height={"50%"} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            фТЕЦ
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        display="flex"
                        flexDirection={"column"}
                        alignItems={"center"}
                        border={`1px solid ${form.values.renewable_type_id === 2 ? primary : "transparent"}`}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px"
                        onClick={() => {
                            form.setFieldValue("renewable_type_id", 2);
                            form.setFieldValue("wind_capacity_id", 1);
                        }}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColor}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <img src={windIcon} alt={"Wind"} width={"50%"} height={"70%"} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            ВяЕЦ
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        display="flex"
                        flexDirection={"column"}
                        alignItems={"center"}
                        border={`1px solid ${form.values.renewable_type_id === 1 ? primary : "transparent"}`}
                        borderRadius="5px"
                        padding="2px"
                        marginRight="20px"
                        onClick={() => {
                            form.setFieldValue("renewable_type_id", 1);
                        }}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius="50%"
                            className={classes.gridBackgroundColorPrimary}
                            width="100px"
                            height="100px"
                            marginBottom="20px">
                            <WaterIcon className={classes.waterIcon} />
                        </Grid>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            ВЕЦ
                        </Typography>
                    </Grid>
                </Grid>
            </StyledCreateEstateFormExistingPark>
        );
    }
};

export default CreateEstateFormExistingPark;
