import { Grid, Modal, styled } from "@mui/material";
import { grey, greyDark, greyLight } from "../../../common/Theme/theme";

const ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX = "EstateDetailsUpperContainerRight";

export const estateDetailsClasses = {
    upperContainerRight: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-upperContainerRight`,
    galleryProperty: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-galleryProperty`,
    galleryPropertyValue: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-galleryPropertyValue`,
    galleryTitle: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-galleryTitle`,
    emptyGalleryState: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-emptyGalleryState`,
    galleryCarousel: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-galleryCarousel`,
    modalCarousel: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-modalCarousel`,
    modalCarouselContainer: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-modalCarouselContainer`,
    closeButton: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-closeButton`,
    modalImage: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-modalImage`,
    carouselParameters: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-carouselParameters`,
    galleryPropertiesContainer: `${ESTATE_DETAILS_UPPER_CONTAINER_RIGHT_PREFIX}-galleryPropertiesContainer`,
};

export const StyledEstateDetailsUpperContainerRight = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.upperContainerRight}`]: {
        bottom: "10%",
        left: "53%",
        position: "absolute",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        width: "auto",
        [theme.breakpoints.only("xs")]: {
            position: "unset",
            margin: "0px",
            width: "100%",
            padding: "5px",
            borderRadius: "0px",
        },
        [theme.breakpoints.only("sm")]: {
            position: "unset",
            width: "95%",
            margin: "13px",
        },
    },
    [`& .${estateDetailsClasses.galleryProperty}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
    },
    [`& .${estateDetailsClasses.galleryPropertyValue}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
    },
    [`& .${estateDetailsClasses.emptyGalleryState}`]: {
        background: "white",
        height: "230px",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.only("md")]: {
            height: "230px",
        },
    },
    [`& .${estateDetailsClasses.galleryTitle}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.galleryCarousel}`]: {
        [theme.breakpoints.up("md")]: {
            width: "350px",
            height: "245px",
            margin: "20px",
        },
        [theme.breakpoints.down("md")]: {
            width: "320px",
            height: "224px",
            margin: "10px",
        },
    },
    [`& .${estateDetailsClasses.carouselParameters}`]: {
        width: "350px",
        height: "233px",
    },
}));

export const StyledGallyModal = styled(Modal)(({ theme }) => ({
    [`& .${estateDetailsClasses.modalCarousel}`]: {
        color: "red",
        [theme.breakpoints.up("md")]: {
            width: "800px",
            height: "560px",
            margin: "10px",
        },
        [theme.breakpoints.only("sm")]: {
            width: "600px",
            height: "420px",
            margin: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "320px",
            height: "224px",
            margin: "10px",
        },
    },
    [`& .${estateDetailsClasses.modalCarouselContainer}`]: {
        background: "white",
        borderRadius: "5px",
        padding: "20px",
    },
    [`& .${estateDetailsClasses.closeButton}`]: {
        marginTop: "20px",
    },
    [`& .${estateDetailsClasses.modalImage}`]: {
        width: "800px",
        height: "560px",
        objectFit: "contain",
    },
}));
