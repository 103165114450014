import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { StyledCreateEstateSuccess, createEstateSuccessClasses as classes } from "./StyledCreateEstateSuccess";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../../routes";
import { ILogged, UserType } from "../../../interfaces/user";

export const CreateEstateSuccess = ({ estateId, user }: { estateId: number; user: ILogged | null }) => {
    const navigate = useNavigate();

    return (
        <StyledCreateEstateSuccess>
            <Grid
                container
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                className={classes.messageContainer}>
                <CheckCircleOutlineIcon className={classes.checkIcon} />
                {user && user.role === UserType.Admin ? (
                    <Typography variant="h2" className={classes.message}>
                        Обявата е публикувана!
                    </Typography>
                ) : (
                    <Typography variant="h2" className={classes.message}>
                        Обявата е пратена за преглеждане!
                    </Typography>
                )}

                <Button
                    variant="outlined"
                    sx={{ borderRadius: "20px", marginTop: "20px" }}
                    color="primary"
                    onClick={() => window.open(`/${NavigationRoutes.EstateDetails}/${estateId}`, "_blank")}>
                    Виж обявата
                </Button>
            </Grid>
        </StyledCreateEstateSuccess>
    );
};
