import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import CustomTextInput from "../../../../../common/CustomTextInput/CustomerTextInput";
import Dropdown from "../../../../../common/Dropdown/Dropdown";
import NumberInput from "../../../../../common/NumberInput/NumberInput";
import PlacesAutocomplete from "../../../../../common/PlacesAutocomplete/PlacesAutocomplete";
import { Info } from "@mui/icons-material";
import { existingParkId, piId, upiId } from "../../schema";
import { SellerProfileState } from "../../utils";
import { Dropdowns } from "../../../../../services/dropdown.service";
import { IForm } from "../../../../../interfaces/form";
import { StyledCreateEstateFormDetails, createEstateClasses as classes } from "./StyledCreateEstateFormDetails";
import CreateEstateFormExistingPark from "../CreateEstateFormExistingPark/CreateEstateFormExistingPark";

interface createEstateFormDetailsProps {
    form: IForm<SellerProfileState>;
    dropdowns: Dropdowns;
    isDisabled: boolean;
}

const CreateEstateFormDetails = ({ form, dropdowns, isDisabled }: createEstateFormDetailsProps) => {
    return (
        <StyledCreateEstateFormDetails>
            <Grid container>
                <Paper className={classes.paperContainer}>
                    <Typography variant={"h6"} component={"div"} color="#757C8D">
                        Детайли
                    </Typography>
                    <Grid container>
                        <Grid container height="90px">
                            <CustomTextInput
                                label="Заглавие"
                                name={"title"}
                                className={`${classes.customTextInputTitle} ${classes.checkboxLabel}`}
                                placeholder={"Заглавие"}
                                disabled={isDisabled}
                                value={form.values.title}
                                onChange={form.handleChange}
                                error={form.touched?.title && !!form.errors?.title}
                                helperText={form.errors.title}
                            />
                        </Grid>

                        <Grid container height="170px">
                            <CustomTextInput
                                label="Описание"
                                name={"description"}
                                className={classes.customTextInputDescription}
                                placeholder={"Описание"}
                                disabled={isDisabled}
                                multiline
                                value={form.values.description}
                                rows={4}
                                onChange={form.handleChange}
                                error={form.touched?.description && !!form.errors?.description}
                                helperText={form.errors?.description}
                            />
                        </Grid>
                    </Grid>
                    <Grid container marginTop="10px">
                        <Dropdown
                            label={"Тип имот"}
                            disabled={isDisabled}
                            className={`${classes.dropdown} ${classes.dropdownEstateType}`}
                            options={dropdowns.estateTypes}
                            value={form.values.estate_type_id}
                            onChange={form.handleChange}
                            name={"estate_type_id"}
                            error={form.touched?.estate_type_id && !!form.errors?.estate_type_id}
                            helperText={form.errors?.estate_type_id}
                        />

                        <Dropdown
                            label={"Тип обява"}
                            disabled={isDisabled}
                            className={`${classes.dropdown} ${classes.dropdownEstateOfferAndArea}`}
                            options={dropdowns.offerTypes}
                            value={form.values.offer_type_id}
                            onChange={form.handleChange}
                            name={"offer_type_id"}
                            error={form.touched?.offer_type_id && !!form.errors?.offer_type_id}
                            helperText={form.errors?.offer_type_id}
                        />
                    </Grid>
                    {form.values.estate_type_id === existingParkId && (
                        <>
                            <Typography variant={"h6"} component={"div"} color="#757C8D">
                                ВЕИ
                            </Typography>
                            <CreateEstateFormExistingPark form={form} isDisabled={isDisabled} />
                        </>
                    )}

                    <Grid container marginTop="30px">
                        <Grid item lg={3} md={3} className={classes.priceArea}>
                            <NumberInput
                                label={"Цена"}
                                disabled={isDisabled}
                                value={form.values.price}
                                handleChange={form.handleChange}
                                name={"price"}
                                inputClass={""}
                                error={!!(form.touched?.price && !!form.errors?.price)}
                                helperText={form.errors?.price}
                            />
                        </Grid>

                        <Dropdown
                            label={"Валута"}
                            disabled={isDisabled}
                            options={dropdowns.currency.map((item: any) => ({
                                id: item.id,
                                name: item.description,
                            }))}
                            value={form.values.currency_id}
                            className={`${classes.dropdown} ${classes.dropdownEstateValue}`}
                            onChange={form.handleChange}
                            name={"currency_id"}
                            error={form.touched?.currency_id && !!form.errors?.currency_id}
                            helperText={form.errors?.currency_id}
                        />
                        <Grid item lg={3} md={3} className={classes.priceArea}>
                            <NumberInput
                                handleChange={form.handleChange}
                                name={"area"}
                                label={"Площ на имота"}
                                disabled={isDisabled}
                                value={form.values.area}
                                inputClass={""}
                                error={!!(form.touched?.area && !!form.errors?.area)}
                                helperText={form.errors?.area}
                            />
                        </Grid>
                        <Dropdown
                            label={"Мерна единица"}
                            disabled={isDisabled}
                            options={dropdowns.areaUnits}
                            value={form.values.area_unit_id}
                            className={`${classes.dropdown} ${classes.dropdownEstateOfferAndArea}`}
                            onChange={form.handleChange}
                            name={"area_unit_id"}
                            error={form.touched?.area_unit_id && !!form.errors?.area_unit_id}
                            helperText={form.errors?.area_unit_id}
                        />
                    </Grid>
                    <Grid container marginTop="30px" width="100%" alignItems={"flex-end"}>
                        <Dropdown
                            label={"Области"}
                            disabled={isDisabled}
                            options={dropdowns.countryAreas}
                            value={form.values.country_area_id}
                            className={`${classes.dropdown} ${classes.dropdownEstateCountryAreas}`}
                            onChange={(event) => {
                                form.handleChange!(event);
                            }}
                            name={"country_area_id"}
                            error={form.touched?.country_area_id && !!form.errors?.country_area_id}
                            helperText={form.errors?.country_area_id}
                        />
                        <Dropdown
                            label={"Oбщина"}
                            disabled={isDisabled}
                            options={dropdowns.municipality.filter((innerMunicipality: any) =>
                                form.values.country_area_id !== 0
                                    ? innerMunicipality.country_area_id === form.values.country_area_id
                                    : true
                            )}
                            className={`${classes.dropdownEstateMunicipality} ${classes.dropdown}`}
                            value={form.values.municipality_id}
                            onChange={form.handleChange}
                            name={"municipality_id"}
                            error={form.touched?.municipality_id && !!form.errors?.municipality_id}
                            helperText={form.errors?.municipality_id}
                        />
                        <Grid item lg={4} width="290px" paddingTop="7px" height="100px">
                            <PlacesAutocomplete
                                name={"city"}
                                disabled={isDisabled}
                                className={classes.cityAutocomplete}
                                placeholder={"Град/Село"}
                                value={form.values.city}
                                onChange={(newInputValue) => {
                                    form.setFieldValue("city", newInputValue);
                                }}
                                error={!!form.touched?.city && !!form.errors?.city}
                                setFieldValue={form.setFieldValue}
                                helperText={form.errors?.city}
                                type={"(cities)"}
                            />
                        </Grid>
                    </Grid>

                    {form.values.estate_type_id === upiId && (
                        <Grid container alignItems={"center"} marginTop="30px" height="90px">
                            <CustomTextInput
                                label="Предназначение"
                                disabled={isDisabled}
                                name={"upi_purpose"}
                                className={classes.dropdownEstatePurpose}
                                placeholder={"Предназначение"}
                                multiline
                                value={form.values.upi_purpose}
                                onChange={form.handleChange}
                                error={form.touched?.upi_purpose && !!form.errors?.upi_purpose}
                                helperText={form.errors?.upi_purpose}
                            />
                            <Tooltip title="Напр. “за друг вид производствен, складов обект” или “Ниско застрояване до 10м”">
                                <IconButton>
                                    <Info />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}

                    {form.values.estate_type_id === piId && (
                        <Grid container marginTop="20px">
                            <Dropdown
                                label={"Категория земя"}
                                disabled={isDisabled}
                                options={dropdowns.landCategories}
                                value={form.values.land_category_id}
                                className={`${classes.dropdown} ${classes.dropdownEstateLandCategory}`}
                                onChange={form.handleChange}
                                name={"land_category_id"}
                                error={form.touched?.land_category_id && !!form.errors?.land_category_id}
                                helperText={form.errors?.land_category_id}
                            />

                            <Dropdown
                                label={"Tип Земя"}
                                disabled={isDisabled}
                                className={`${classes.dropdown} ${classes.dropdownEstateLandType}`}
                                options={dropdowns.landTypes}
                                value={form.values.land_type_id}
                                onChange={form.handleChange}
                                name={"land_type_id"}
                                error={form.touched?.land_type_id && !!form.errors?.land_type_id}
                                helperText={form.errors?.land_type_id}
                            />
                        </Grid>
                    )}
                </Paper>
            </Grid>
        </StyledCreateEstateFormDetails>
    );
};

export default CreateEstateFormDetails;
