import React, { useCallback, useContext, useEffect, useState } from "react";
import { estateListingClasses, StyledEstateListing } from "./StyledEstateListing";
import { defaultFilters, Filter } from "../Home/Filter/Filter";
import { defaultEstatePageSize, estateService } from "../../services/estate.service";
import { IEstate } from "../../interfaces/estate";
import { Container, Grid, Typography } from "@mui/material";
import { EstateCard } from "../../common/EstateCard/EstateCard";
import { GlobalContext } from "../../App";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { Pagination } from "@mui/lab";
import { IEstateFilters } from "../../interfaces/filters";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { GOOGLE_API_KEY } from "../../env";
import { Map, Marker } from "../../Seller/SellerProfile/CreateEstate/Map";
import { useGA } from "../../common/hooks";

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

export const EstateListing = () => {
    useGA();
    const [estates, setEstates] = useState<IEstate[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const { dropdownsMap, user, isLoadingUser } = useContext(GlobalContext);
    const [filters, setFilters] = useState<IEstateFilters>({} as any);
    const [zoom, setZoom] = React.useState(11);
    const [filtersCache, setFiltersCache] = useState<IEstateFilters>({ ...defaultFilters });
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: 42.6953468,
        lng: 23.3314917,
    });
    const [estateMarkersInfo, setEstateMarkersInfo] = useState<
        { coordinates: google.maps.LatLng; title: string; id: number }[]
    >([]);

    const fetchEstates = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            estateService.getAll({ ...filters, page } as any).then(({ count, rows }) => {
                setEstates(rows);
                setEstateMarkersInfo(
                    rows
                        .filter((estate) => estate.coordinates_lat && estate.coordinates_lng)
                        .map((estate) => {
                            return {
                                coordinates: new google.maps.LatLng(estate.coordinates_lat, estate.coordinates_lng),
                                title: estate.title,
                                id: estate.id,
                            };
                        })
                );
                setCount(Math.ceil(count / defaultEstatePageSize));
            });
        }, 300);
        setTimeoutId(newTimeoutId);
    };

    useEffect(() => {
        fetchEstates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, page]);

    const onIdle = (m: google.maps.Map) => {
        setZoom(m.getZoom()!);
        setCenter(m.getCenter()!.toJSON());
    };

    const fitBounds = () => {
        if ((window as any).map && estateMarkersInfo.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            estateMarkersInfo
                .map((estateMarkerInfo) => estateMarkerInfo.coordinates)
                .forEach((item) => {
                    bounds.extend(item);
                });
            (window as any).map.fitBounds(bounds);
        }
    };

    // Fit bounds on mount, and when the markers change
    useEffect(() => {
        fitBounds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estateMarkersInfo]);

    const handleSetFilters = useCallback(
        (filters: IEstateFilters) => {
            if (isLoadingUser) {
                setFiltersCache({ ...filters });
                return;
            }

            setFilters(filters);
        },
        [isLoadingUser, user]
    );

    useEffect(() => {
        if (!isLoadingUser) {
            setFilters({ ...filtersCache });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingUser, user]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    return (
        <ViewContainer>
            <Grid container sx={{ width: "100%", height: "400px" }}>
                <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
                    <Map
                        center={center}
                        zoom={zoom}
                        onIdle={onIdle}
                        mapTypeControl={false}
                        fullscreenControl={false}
                        streetViewControl={false}
                        draggable={true}
                        keyboardShortcuts={false}
                        scaleControl={true}
                        scrollwheel={true}
                        disableDoubleClickZoom={true}
                        style={{ flexGrow: "1", height: "100%" }}>
                        {estateMarkersInfo.map((estateMarkerInfo) => {
                            return (
                                <Marker
                                    id={estateMarkerInfo.id}
                                    position={estateMarkerInfo.coordinates}
                                    title={estateMarkerInfo.title}
                                />
                            );
                        })}
                    </Map>
                </Wrapper>
            </Grid>
            <StyledEstateListing className={estateListingClasses.root} fixed>
                <Grid container className={estateListingClasses.filterContainer}>
                    <Filter handleChange={handleSetFilters} />
                </Grid>
                <Grid
                    container
                    className={estateListingClasses.container}
                    flexDirection={"row"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    justifyContent={"center"}>
                    <Container maxWidth={"md"}>
                        {estates.length > 0 ? (
                            <Grid container justifyContent={"center"} className={estateListingClasses.estatesWrapper}>
                                {dropdownsMap &&
                                    estates.map((estate) => (
                                        <EstateCard estate={estate} onToggleFavourite={fetchEstates} />
                                    ))}
                                <Grid container justifyContent={"center"}>
                                    {count && (
                                        <Pagination
                                            count={count}
                                            page={page}
                                            color="secondary"
                                            onChange={handlePageChange}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container justifyContent={"center"} sx={{ padding: "100px" }}>
                                <Typography variant={"h2"}>Няма намерени имоти за тези филтри</Typography>
                            </Grid>
                        )}
                    </Container>
                </Grid>
            </StyledEstateListing>
        </ViewContainer>
    );
};
