import React from "react";
import { StyledRegisterModal, styledRegisterModalClasses as modalClasses } from "./StyledRegisterModal";
import { Button, Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { customNavigate, NavigationRoutes } from "../../routes";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useNavigate } from "react-router-dom";

interface IRegisterModal {
    isOpened: boolean;
    header?: string;
    message?: string;
    className?: string;
    redirect?: string;
    cancel?: () => void;
}

export const RegisterModal = ({
    isOpened,
    header = "Вход/Регистрация",
    message = "За да достъпите тази страница трябва да влезете в системата!",
    className,
    cancel,
    redirect,
}: IRegisterModal) => {
    let navigate = useNavigate();

    return (
        <StyledRegisterModal open={isOpened} className={className}>
            <Grid container direction="column" className={modalClasses.modalContent}>
                <Grid container direction="column" wrap="nowrap">
                    <Grid className={modalClasses.header} container justifyContent="center" alignItems="center">
                        {header}
                    </Grid>
                    <Grid
                        className={modalClasses.contentContainer}
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        rowGap={3}>
                        <Typography variant="body1" className={modalClasses.message}>
                            {message}
                        </Typography>
                        <Grid container direction="row" wrap="nowrap" justifyContent={"space-between"}>
                            <Grid container direction="row" wrap="nowrap" justifyContent="flex-start" columnGap={1}>
                                <Button
                                    className={modalClasses.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        cancel && cancel();
                                    }}>
                                    <Typography className={modalClasses.buttonText}>Назад</Typography>
                                </Button>
                            </Grid>
                            <Grid container direction="row" wrap="nowrap" justifyContent="flex-end" columnGap={1}>
                                <Button
                                    className={modalClasses.button}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<PersonIcon />}
                                    onClick={() => {
                                        if (redirect) {
                                            window.location.href = `/${NavigationRoutes.Login}/redirect/${redirect}`;
                                        } else {
                                            customNavigate(navigate, NavigationRoutes.Login);
                                        }
                                    }}>
                                    <Typography className={modalClasses.buttonText}>Вход</Typography>
                                </Button>
                                <Button
                                    className={modalClasses.button}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        customNavigate(navigate, NavigationRoutes.Register);
                                    }}
                                    startIcon={<PersonAddAlt1Icon />}>
                                    <Typography className={modalClasses.buttonText}>Регистрация</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledRegisterModal>
    );
};
