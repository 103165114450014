import { Grid, styled } from "@mui/material";

const CREATE_ESTATE_FORM_CONTACTS_PREFIX = "CreateEstateFormContacts";

export const createEstateClasses = {
    checkboxLabel: `${CREATE_ESTATE_FORM_CONTACTS_PREFIX}-checkboxLabel`,
    rightCards: `${CREATE_ESTATE_FORM_CONTACTS_PREFIX}-rightCards`,
    paperContainer: `${CREATE_ESTATE_FORM_CONTACTS_PREFIX}-paperContainer`,
    customTextInput: `${CREATE_ESTATE_FORM_CONTACTS_PREFIX}-customTextInput`,
};

export const StyledCreateEstateFormContacts = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`& .${createEstateClasses.checkboxLabel}`]: {
        marginRight: "40px",
        marginTop: "10px",
        width: "250px",
        ["& .MuiTypography-root"]: {
            fontSize: "16px",
            color: "#9BA4AB",
        },
    },
    [`& .${createEstateClasses.rightCards}`]: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    [`& .${createEstateClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
    },
    [`& .${createEstateClasses.customTextInput}`]: {
        marginTop: "20px",
        width: "300px",
        height: "90px",
    },
}));
