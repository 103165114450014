import { Modal, styled } from "@mui/material";
import { grey, greyDark, primary } from "../../../common/Theme/theme";

const ESTATE_DETAILS_CONTACT_INFO_MODAL = "estateDetailsContactInfoModal";

export const estateDetailsContactInfoModalClasses = {
    root: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-root`,
    paper: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-paper`,
    acceptButton: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-acceptButton`,
    declineButton: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-declineButton`,
    checkIcon: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-checkIcon`,
    estateTitle: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-estateTitle`,
    estatePrice: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-estatePrice`,
    modalTitle: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-modalTitle`,
    modalDescription: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-modalDescription`,
    documentName: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-documentName`,
    widthOfButton: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-widthOfButton`,
    dividerLine: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-dividerLine`,
    creditsIcon: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-creditsIcon`,
    ModalCloseIcon: `${ESTATE_DETAILS_CONTACT_INFO_MODAL}-ModalCloseIcon`,
};

export const StyledEstateDetailsContactInfoModal = styled(Modal)(({ theme }) => ({
    [`&.${estateDetailsContactInfoModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${estateDetailsContactInfoModalClasses.paper}`]: {
        padding: "20px",
        width: "450px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        [theme.breakpoints.only("xs")]: {
            width: "300px",
            margin: "auto",
        },
    },
    [`& .${estateDetailsContactInfoModalClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${estateDetailsContactInfoModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
    },
    [`& .${estateDetailsContactInfoModalClasses.checkIcon}`]: {
        color: primary,
        marginRight: "10px",
    },
    [`& .${estateDetailsContactInfoModalClasses.modalTitle}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        paddingRight: "130px",
        marginBottom: "5px",
        [theme.breakpoints.only("xs")]: {
            paddingRight: "20px",
            fontSize: "15px",
        },
    },
    [`& .${estateDetailsContactInfoModalClasses.modalDescription}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        marginBottom: "20px",
    },
    [`& .${estateDetailsContactInfoModalClasses.estateTitle}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        width: "300px",
    },
    [`& .${estateDetailsContactInfoModalClasses.estatePrice}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        position: "fixed",
        paddingLeft: "300px",
        [theme.breakpoints.only("xs")]: {
            position: "inherit",
            paddingLeft: "0px",
        },
    },
    [`& .${estateDetailsContactInfoModalClasses.documentName}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
    [`& .${estateDetailsContactInfoModalClasses.widthOfButton}`]: {
        width: "120px",
    },
    [`& .${estateDetailsContactInfoModalClasses.dividerLine}`]: {
        width: "400px",
        marginTop: "20px",
    },
    [`& .${estateDetailsContactInfoModalClasses.creditsIcon}`]: {
        [theme.breakpoints.only("xs")]: {
            width: "15px",
        },
    },
    [`& .${estateDetailsContactInfoModalClasses.ModalCloseIcon}`]: {
        marginBottom: "3px",
        [theme.breakpoints.only("xs")]: {
            width: "15px",
        },
    },
}));
