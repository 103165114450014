import { Grid, styled } from "@mui/material";
import { grey, greyDark, secondary } from "../../../common/Theme/theme";

const ESTATE_DETAILS_OTHERS_PREFIX = "EstateDetailsOthers";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS_OTHERS_PREFIX}-heading`,
    value: `${ESTATE_DETAILS_OTHERS_PREFIX}-value`,
    checkIcon: `${ESTATE_DETAILS_OTHERS_PREFIX}-checkIcon`,
    paperContainer: `${ESTATE_DETAILS_OTHERS_PREFIX}-paperContainer`,
};

export const StyledEstateDetailsOthers = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.checkIcon}`]: {
        color: secondary,
        marginBottom: "-5px",
    },
    [`& .${estateDetailsClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        width: "100%",
    },
}));
