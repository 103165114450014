import { Grid } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { IAttachment } from "../../../../../interfaces/attachment";
import {
    StyledCreateEstateFormUploadImages,
    createEstateClasses as classes,
} from "./StyledCreateEstateFormUploadImages";
import CreateEstateFormDisplayAttachments from "../CreateEstateFormDisplayAttachments/CreateEstateFormDisplayAttachments";

interface CreateEstateFormUploadImagesProps {
    attachments: File[];
    fileInputField: React.LegacyRef<HTMLInputElement> | undefined;
    oldAttachments: IAttachment[];
    saveAttachmentAndUpdateLoad: (fileList: FileList) => void;
    handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDragOver: (event: any) => void;
    handleDragEnter: (event: any) => void;
    handleDragLeave: (event: any) => void;
    deletedOldAttachment: (index: number) => void;
    setAttachments: (attachments: File[]) => void;
}

const CreateEstateFormUploadImages = ({
    attachments,
    fileInputField,
    oldAttachments,
    saveAttachmentAndUpdateLoad,
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    deletedOldAttachment,
    setAttachments,
}: CreateEstateFormUploadImagesProps) => {
    return (
        <StyledCreateEstateFormUploadImages>
            <Grid container>
                <Grid container>
                    <div className={classes.inputContainer}>
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            multiple
                            type="file"
                            ref={fileInputField}
                            id={"imageUploads"}
                            name={`imageUploads`}
                            onChange={(event) => {
                                saveAttachmentAndUpdateLoad(event.currentTarget.files!);
                            }}
                        />
                        <label htmlFor={`imageUploads`}>
                            <div
                                id="uploadImages"
                                className={classes.dropFilesLabel}
                                onDrop={(e) => handleDrop(e)}
                                onDragOver={(e) => handleDragOver(e)}
                                onDragEnter={(e) => handleDragEnter(e)}
                                onDragLeave={(e) => handleDragLeave(e)}>
                                <CloudUpload viewBox={"0 -5 24 26"} /> Избери снимки
                            </div>
                        </label>
                    </div>
                </Grid>
                <CreateEstateFormDisplayAttachments
                    attachments={attachments}
                    oldAttachments={oldAttachments}
                    deletedOldAttachment={deletedOldAttachment}
                    setAttachments={setAttachments}
                    showDelete={true}
                />
            </Grid>
        </StyledCreateEstateFormUploadImages>
    );
};

export default CreateEstateFormUploadImages;
