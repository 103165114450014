import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { SellerProfileState } from "../../utils";
import { existingParkId, solarRenewableId, windRenewableId } from "../../schema";
import Dropdown from "../../../../../common/Dropdown/Dropdown";
import NumberInput from "../../../../../common/NumberInput/NumberInput";
import CustomAutocomplete from "../../../../../common/CustomAutocomplete/CustomAutocomplete";
import CustomSwitch from "../../../../../common/CustomSwitch/CustomSwitch";
import CustomTextInput from "../../../../../common/CustomTextInput/CustomerTextInput";
import { Info } from "@mui/icons-material";
import { Dropdowns } from "../../../../../services/dropdown.service";
import { IForm } from "../../../../../interfaces/form";
import { StyledCreateEstateFormEstateType, createEstateClasses as classes } from "./StyledCreateEstateFormEstateType";

interface CreateEstateFormEstateTypeProps {
    form: IForm<SellerProfileState>;
    dropdowns: Dropdowns;
    isDisabled: boolean;
}

const CreateEstateFormEstateType = ({ form, dropdowns, isDisabled }: CreateEstateFormEstateTypeProps) => {
    if (form.values.estate_type_id === existingParkId) {
        return (
            <StyledCreateEstateFormEstateType>
                <Grid container>
                    <Paper className={classes.paperContainer}>
                        <Typography variant={"h6"} component={"div"} color="#757C8D">
                            Действащ парк
                        </Typography>
                        <Grid container marginTop="20px">
                            <Grid item lg={3} className={classes.generatingCapacity}>
                                <NumberInput
                                    label={"Генерираща мощност"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"capacity"}
                                    error={!!(form.touched?.capacity && !!form.errors?.capacity)}
                                    helperText={form.errors?.capacity}
                                    value={form.values.capacity}
                                    inputClass={classes.numberInputCapacity}
                                />
                            </Grid>

                            <Dropdown
                                label={"Мерна единица"}
                                disabled={isDisabled}
                                options={dropdowns.capacityTypes}
                                value={form.values.capacity_type_id}
                                className={`${classes.dropdown} ${classes.dropdownCapacity}`}
                                onChange={form.handleChange}
                                name={"capacity_type_id"}
                                error={form.touched?.capacity_type_id && !!form.errors?.capacity_type_id}
                                helperText={form.errors?.capacity_type_id}
                            />
                            <Dropdown
                                label={"Ценообразуване"}
                                disabled={isDisabled}
                                className={`${classes.dropdown} ${classes.dropdownPricing}`}
                                options={dropdowns.pricing}
                                value={form.values.pricing_id}
                                onChange={form.handleChange}
                                name={"pricing_id"}
                                error={form.touched?.pricing_id && !!form.errors?.pricing_id}
                                helperText={form.errors?.pricing_id}
                            />
                        </Grid>

                        <Grid container marginTop="60px" height="90px">
                            <CustomSwitch
                                label={"Подпомагащи схеми"}
                                disabled={isDisabled}
                                className={`${classes.checkboxLabel} ${classes.customSwitchSchemesFinancingInOperation}`}
                                onChange={form.handleChange}
                                name={"support_schemes"}
                                title={"Подпомагащи схеми"}
                                value={form.values.support_schemes}
                            />
                            <CustomTextInput
                                label={"Описание на подпомагащите схеми"}
                                disabled={isDisabled}
                                name={"support_schemes_description"}
                                className={classes.customSwitchSchemesDescription}
                                placeholder={"Описание на подпомагащите схеми"}
                                value={form.values.support_schemes_description}
                                onChange={form.handleChange}
                                error={
                                    form.touched?.support_schemes_description &&
                                    !!form.errors?.support_schemes_description
                                }
                                helperText={form.errors?.support_schemes_description}
                            />
                        </Grid>

                        <Grid container marginTop="10px" height="90px" alignItems={"center"}>
                            <CustomSwitch
                                label={"Външно финансиране"}
                                disabled={isDisabled}
                                className={`${classes.checkboxLabel} ${classes.customSwitchSchemesFinancingInOperation}`}
                                onChange={form.handleChange}
                                name={"external_financing"}
                                title={"Външно финансиране"}
                                value={form.values.external_financing}
                            />
                            <NumberInput
                                label={"Стойност на въшно финансиране"}
                                disabled={isDisabled}
                                handleChange={form.handleChange}
                                name={"external_financing_amount"}
                                error={
                                    !!(
                                        form.touched?.external_financing_amount &&
                                        !!form.errors?.external_financing_amount
                                    )
                                }
                                helperText={form.errors?.external_financing_amount}
                                value={form.values.external_financing_amount}
                                inputClass={classes.numberInputFinancingAmount}
                            />
                            <Tooltip title="Под външно финансиране се разбира финансиране от кредити, институции, банки, лизинги, оперативни програми и тнт.">
                                <IconButton>
                                    <Info />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid container marginTop="10px" height="90px" alignItems={"center"}>
                            <CustomSwitch
                                label={"Въведен в експлотация"}
                                disabled={isDisabled}
                                onChange={form.handleChange}
                                className={`${classes.checkboxLabel} ${classes.customSwitchSchemesFinancingInOperation}`}
                                name={"put_into_operation"}
                                title={"Въведен в експлотация"}
                                value={form.values.put_into_operation}
                            />
                            <CustomAutocomplete
                                label={"Година на въвеждане в експлотация"}
                                disabled={isDisabled}
                                name={"put_into_operation_year"}
                                options={dropdowns.years.map((item) => item.id.toString())}
                                loading={false}
                                className={classes.customSwitchPutIntoOperationYear}
                                value={form.values?.put_into_operation_year?.toString()}
                                error={
                                    !!(form.touched?.put_into_operation_year && !!form.errors?.put_into_operation_year)
                                }
                                helperText={form.errors?.put_into_operation_year}
                                handleSelect={(newValue: any) => {
                                    form.setFieldValue("put_into_operation_year", parseInt(newValue));
                                }}
                                defaultValue={new Date().getFullYear().toString()}
                            />
                        </Grid>

                        {form.values.renewable_type_id === solarRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <CustomTextInput
                                    label="Марка панели"
                                    disabled={isDisabled}
                                    name={"solar_panel_brand"}
                                    className={classes.customSwitchPanelsInventorBrand}
                                    placeholder={"Марка панели"}
                                    value={form.values.solar_panel_brand}
                                    onChange={form.handleChange}
                                    error={form.touched?.solar_panel_brand && !!form.errors?.solar_panel_brand}
                                    helperText={form.errors.solar_panel_brand}
                                />

                                <NumberInput
                                    label={"Капацитет на панел"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"solar_panel_capacity"}
                                    error={
                                        !!(form.touched?.solar_panel_capacity && !!form.errors?.solar_panel_capacity)
                                    }
                                    helperText={form.errors?.solar_panel_capacity}
                                    value={form.values.solar_panel_capacity}
                                    inputClass={classes.numberInputPanelInventorWindCapacity}
                                />

                                <Grid container width="120px" height="120px" marginRight="10px" paddingTop="8px">
                                    <Dropdown
                                        label={"Мерна единица"}
                                        className={`${classes.dropdown} ${classes.dropdownPanelInventorWindCapacity}`}
                                        disabled={true}
                                        options={dropdowns.capacityTypes}
                                        value={form.values.solar_panel_capacity_id}
                                        onChange={form.handleChange}
                                        name={"solar_panel_capacity_id"}
                                        error={
                                            form.touched?.solar_panel_capacity_id &&
                                            !!form.errors?.solar_panel_capacity_id
                                        }
                                        helperText={form.errors?.solar_panel_capacity_id}
                                    />
                                </Grid>

                                <NumberInput
                                    label={"Брой панели"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"solar_panel_count"}
                                    error={!!(form.touched?.solar_panel_count && !!form.errors?.solar_panel_count)}
                                    helperText={form.errors?.solar_panel_count}
                                    value={form.values.solar_panel_count}
                                    inputClass={classes.numberInputPanelInventorWindCount}
                                />
                            </Grid>
                        )}

                        {form.values.renewable_type_id === solarRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <CustomTextInput
                                    label={"Марка инвертори"}
                                    disabled={isDisabled}
                                    name={"solar_invertor_brand"}
                                    className={classes.customSwitchPanelsInventorBrand}
                                    placeholder={"Марка инвертори"}
                                    value={form.values.solar_invertor_brand}
                                    onChange={form.handleChange}
                                    error={form.touched?.solar_invertor_brand && !!form.errors?.solar_invertor_brand}
                                    helperText={form.errors.solar_invertor_brand}
                                />
                                <NumberInput
                                    label={"Привидна мощност"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"solar_invertor_capacity"}
                                    error={
                                        !!(
                                            form.touched?.solar_invertor_capacity &&
                                            !!form.errors?.solar_invertor_capacity
                                        )
                                    }
                                    helperText={form.errors?.solar_invertor_capacity}
                                    value={form.values.solar_invertor_capacity}
                                    inputClass={classes.numberInputPanelInventorWindCapacity}
                                />

                                <Grid container width="120px" height="120px" marginRight="10px" paddingTop="8px">
                                    <Dropdown
                                        label={"Мерна единица"}
                                        disabled={true}
                                        className={`${classes.dropdown} ${classes.dropdownPanelInventorWindCapacity}`}
                                        options={dropdowns.invertorCapacityTypes}
                                        value={form.values.solar_invertor_capacity_id}
                                        onChange={form.handleChange}
                                        name={"solar_invertor_capacity_id"}
                                        error={
                                            form.touched?.solar_invertor_capacity_id &&
                                            !!form.errors?.solar_invertor_capacity_id
                                        }
                                        helperText={form.errors?.solar_invertor_capacity_id}
                                    />
                                </Grid>

                                <NumberInput
                                    label={"Брой инвертори"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"solar_invertor_count"}
                                    error={
                                        !!(form.touched?.solar_invertor_count && !!form.errors?.solar_invertor_count)
                                    }
                                    helperText={form.errors?.solar_invertor_count}
                                    value={form.values.solar_invertor_count}
                                    inputClass={classes.numberInputPanelInventorWindCount}
                                />
                            </Grid>
                        )}

                        {form.values.renewable_type_id === windRenewableId && (
                            <Grid container className={classes.renewableSpecificFieldContainer} alignItems={"flex-end"}>
                                <CustomTextInput
                                    label="Марка вятърни турбини"
                                    disabled={isDisabled}
                                    name={"wind_brand"}
                                    className={classes.customSwitchWindBrand}
                                    placeholder={"Марка вятърни турбини"}
                                    value={form.values.wind_brand}
                                    onChange={form.handleChange}
                                    error={form.touched?.wind_brand && !!form.errors?.wind_brand}
                                    helperText={form.errors.wind_brand}
                                />
                                <NumberInput
                                    label={"Капацитет на вятърни турбини"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"wind_capacity"}
                                    error={!!(form.touched?.wind_capacity && !!form.errors?.wind_capacity)}
                                    helperText={form.errors?.wind_capacity}
                                    value={form.values.wind_capacity}
                                    inputClass={classes.numberInputPanelInventorWindCapacity}
                                />

                                <Grid container width="120px" height="120px" marginRight="10px" paddingTop="8px">
                                    <Dropdown
                                        label={"Мерна единица"}
                                        disabled={isDisabled}
                                        className={`${classes.dropdown} ${classes.dropdownPanelInventorWindCapacity}`}
                                        options={dropdowns.windCapacityTypes}
                                        value={form.values.wind_capacity_id}
                                        onChange={form.handleChange}
                                        name={"wind_capacity_id"}
                                        error={form.touched?.wind_capacity_id && !!form.errors?.wind_capacity_id}
                                        helperText={form.errors?.wind_capacity_id}
                                    />
                                </Grid>

                                <NumberInput
                                    label={"Брой перки"}
                                    disabled={isDisabled}
                                    handleChange={form.handleChange}
                                    name={"wind_count"}
                                    error={!!(form.touched?.solar_invertor_count && !!form.errors?.wind_count)}
                                    helperText={form.errors?.wind_count}
                                    value={form.values.wind_count}
                                    inputClass={classes.numberInputPanelInventorWindCount}
                                />
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            </StyledCreateEstateFormEstateType>
        );
    }
    return null;
};

export default CreateEstateFormEstateType;
