import { Grid, styled } from "@mui/material";
import { addPrefix } from "../../common/Theme/utils";
import { WizardProgressItemStatus } from "./interfaces";
import { primary, success } from "../Theme/theme";

const prefix = "Wizard";

export const baseLightOne = "#FFFFFF";
export const baseLightTwo = "#A5AFB9";
export const baseLightThree = "#EDEFF2";

export const classes = {
    progressItem: addPrefix(prefix, "progress-item"),
    stepPoint: addPrefix(prefix, "step-point"),
    itemLine: addPrefix(prefix, "item-line"),
    subtitleText: addPrefix(prefix, "subtitle-text"),
};

export const StyledWizard = styled(Grid)({
    padding: "70px 0",
    ul: {
        ["--main-color"]: baseLightTwo,
        ["--background-color"]: baseLightThree,
        ["--status-text-color"]: "var(--main-color)",
        ["--subtitle-text-color"]: "var(--main-color)",
        [`.${WizardProgressItemStatus.Done}`]: {
            ["--main-color"]: success,
            ["--background-color"]: "var(--main-color)",
            ["--status-text-color"]: baseLightOne,
            ["--subtitle-text-color"]: success,
        },
        [`.${WizardProgressItemStatus.InProgress}`]: {
            ["--main-color"]: primary,
            ["--background-color"]: "var(--main-color)",
            ["--status-text-color"]: baseLightOne,
            ["--subtitle-text-color"]: "var(--main-color)",
        },
        margin: 0,
        flex: 1,
        display: "flex",
        flexFlow: "row nowrap",
        [`.${classes.progressItem}`]: {
            position: "relative",
            flex: 1,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            textAlign: "center",
            ["--item-line-color"]: "var(--main-color)",
            ["--item-line-style"]: "solid",
            ["&:first-of-type"]: {
                [`.${classes.itemLine}`]: {
                    display: "none",
                },
            },
            [`.${classes.itemLine}`]: {
                borderTopWidth: "2px",
                borderTopColor: "var(--item-line-color)",
                borderTopStyle: "var(--item-line-style)",
                position: "absolute",
                top: 20,
                right: "50%",
                margin: 0,
                width: "100%",
            },
            [`&.${WizardProgressItemStatus.InProgress}`]: {
                ["--item-line-style"]: "dashed",
                ["--item-line-color"]: success,
            },
            [`.${classes.stepPoint}`]: {
                zIndex: 1,
                display: "flex",
                marginBottom: 24,
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                background: "var(--background-color)",
                color: "var(--status-text-color)",
                border: `2px solid var(--main-color)`,
                borderRadius: "50%",
            },
            [`.${classes.subtitleText}`]: {
                color: "var(--subtitle-text-color)",
                padding: "0 10px",
                textTransform: "uppercase",
            },
        },
    },
});

export const styledWizardClasses = classes;
