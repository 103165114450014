import { Modal, styled } from "@mui/material";
import { grey } from "../../../common/Theme/theme";

const UNLOCK_ESTATE_MODAL_PREFIX = "unlockEstateModal";

export const unlockEstateModalClasses = {
    root: `${UNLOCK_ESTATE_MODAL_PREFIX}-root`,
    paper: `${UNLOCK_ESTATE_MODAL_PREFIX}-paper`,
    acceptButton: `${UNLOCK_ESTATE_MODAL_PREFIX}-acceptButton`,
    description: `${UNLOCK_ESTATE_MODAL_PREFIX}-description`,
    modalTitle: `${UNLOCK_ESTATE_MODAL_PREFIX}-modalTitle`,
    price: `${UNLOCK_ESTATE_MODAL_PREFIX}-price`,
    icons: `${UNLOCK_ESTATE_MODAL_PREFIX}-icons`,
};

export const StyledUnlockEstateModal = styled(Modal)(({ theme }) => ({
    [`&.${unlockEstateModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${unlockEstateModalClasses.paper}`]: {
        padding: "20px",
        width: "600px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        [theme.breakpoints.only("xs")]: {
            width: "320px",
            margin: "auto",
        },
    },
    [`& .${unlockEstateModalClasses.acceptButton}`]: {
        width: "200px",
        height: "40px",
        borderRadius: "100px",
        color: "white",
    },
    [`& .${unlockEstateModalClasses.description}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
        },
    },
    [`& .${unlockEstateModalClasses.modalTitle}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        paddingRight: "290px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
            paddingRight: "50px",
        },
    },
    [`& .${unlockEstateModalClasses.price}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
    },
    [`& .${unlockEstateModalClasses.icons}`]: {
        [theme.breakpoints.only("xs")]: {
            width: "15px",
        },
    },
}));
