import { Button, Grid, Paper, Typography } from "@mui/material";
import { StyledConfirmModal, confirmModalClasses } from "./StyledCreateEstateFormConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { IForm } from "../../../../../interfaces/form";
import { SellerProfileState } from "../../utils";
import { grey } from "../../../../../common/Theme/theme";

interface CreateEstateFormConfirmModalProps {
    form: IForm<SellerProfileState>;
    keysToDisable: any;
    setKeysToDisable: (variable: any) => void;
}

const CreateEstateFormConfirmModal = ({ form, keysToDisable, setKeysToDisable }: CreateEstateFormConfirmModalProps) => {
    return (
        <StyledConfirmModal
            open={!!keysToDisable}
            className={confirmModalClasses.root}
            onClose={() => {
                setKeysToDisable(null);
            }}>
            <Paper className={confirmModalClasses.paper}>
                <Typography fontSize="30px">Изтриване на файл</Typography>
                <Grid container justifyContent={"center"}>
                    <DeleteIcon className={confirmModalClasses.deleteIcon} htmlColor={grey} />
                </Grid>
                <Typography textAlign="center" fontSize="18px">
                    На път сте да изтриете предоставения документи и това ще намали атрактивноста на имота ви. Искате ли
                    да продължите?
                </Typography>
                <Grid container justifyContent={"center"}>
                    <Button
                        variant={"outlined"}
                        className={confirmModalClasses.rejectButton}
                        onClick={() => {
                            setKeysToDisable(null);
                        }}>
                        Откажи
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={async () => {
                            await form.setFieldValue(keysToDisable.availabilityKey, false);
                            await form.setFieldValue(keysToDisable.fileKey, null);
                            await form.setFieldValue(keysToDisable.attachmentObjectKey, null);
                            setKeysToDisable(null);
                        }}>
                        Продължи
                    </Button>
                </Grid>
            </Paper>
        </StyledConfirmModal>
    );
};

export default CreateEstateFormConfirmModal;
