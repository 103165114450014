import { Modal, styled } from "@mui/material";

const SUCCESS_MODAL_PREFIX = "successModal";

export const successModalClasses = {
    root: `${SUCCESS_MODAL_PREFIX}-root`,
    paper: `${SUCCESS_MODAL_PREFIX}-paper`,
    acceptButton: `${SUCCESS_MODAL_PREFIX}-acceptButton`,
    description: `${SUCCESS_MODAL_PREFIX}-description`,
};
export const StyledSuccessModal = styled(Modal)(({ theme }) => ({
    [`&.${successModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
    },
    [`& .${successModalClasses.paper}`]: {
        padding: "20px",
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    [`& .${successModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
    },
    [`& .${successModalClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
}));
