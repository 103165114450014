import { viewProfileClasses as classes, StyledViewProfile, CssTextField, ColorButton } from "./StyledViewProfile";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Alert, Grid, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { userService } from "../../services/user.service";
import { useState } from "react";

export const ViewProfile = ({
    id,
    profileInfo,
    updateProfileInfo,
}: {
    id: string | undefined;
    profileInfo: { name: string; phone: string };
    updateProfileInfo: (info: any) => void;
}) => {
    const [success, setSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);

    const form = useFormik<{
        name: string;
        phone: string;
    }>({
        initialValues: {
            name: profileInfo.name,
            phone: profileInfo.phone,
        },
        validationSchema: yup.object({
            name: yup.string().required("Името e задължително"),
            phone: yup.string().required("Телефонът е задължителен"),
        }),
        onSubmit: (values) => {
            if (id) {
                userService
                    .updateInfo(id, values)
                    .then(() => {
                        setSuccess(true);
                        updateProfileInfo(values);
                    })
                    .catch(() => {
                        setHasError(true);
                    });
            } else {
                setHasError(true);
            }
        },
    });

    return (
        <StyledViewProfile container className={classes.root}>
            <Grid container className={classes.inputRow}>
                <CssTextField
                    variant={"outlined"}
                    placeholder="Име"
                    focused
                    InputProps={{
                        startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                    }}
                    value={form.values.name}
                    onChange={form.handleChange}
                    error={form.touched?.name && !!form.errors?.name}
                    helperText={form.errors?.name}
                    name="name"
                />
                <CssTextField
                    variant={"outlined"}
                    placeholder="Телефон"
                    focused
                    InputProps={{
                        startAdornment: <LocalPhoneOutlinedIcon htmlColor={"#BABDC3"} />,
                    }}
                    value={form.values.phone}
                    onChange={form.handleChange}
                    error={form.touched?.phone && !!form.errors?.phone}
                    helperText={form.errors?.phone}
                    name="phone"
                />
            </Grid>
            <ColorButton variant="contained" color="secondary" onClick={form.submitForm}>
                <PersonOutlineOutlinedIcon className={classes.buttonIcon} />
                Запамети
            </ColorButton>
            <Snackbar
                open={success}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => setSuccess(false)}>
                <Alert severity="success" onClose={() => setSuccess(false)}>
                    Успешно променихте данните.
                </Alert>
            </Snackbar>
            <Snackbar
                open={hasError}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => setHasError(false)}>
                <Alert severity="error" onClose={() => setHasError(false)}>
                    Стана грешка. Моля опитайте пак.
                </Alert>
            </Snackbar>
        </StyledViewProfile>
    );
};
