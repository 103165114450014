import { httpService, HttpService } from "./http.service";
import { dropdownsEndpoint } from "../endpoints";

export interface Dropdowns {
    renewableTypes: { id: number; name: string }[];
    countryAreas: { id: number; name: string }[];
    estateTypes: { id: number; name: string }[];
    landCategories: { id: number; name: string }[];
    landTypes: { id: number; name: string }[];
    pricing: { id: number; name: string }[];
    capacityTypes: { id: number; name: string }[];
    voltage: { id: number; name: string }[];
    areaUnits: { id: number; name: string; description: string }[];
    municipality: { id: number; name: string; country_area_id?: number }[];
    currency: { id: number; name: string; description: string }[];
    years: { id: number; name: string; description: string }[];
    offerTypes: { id: number; name: string; description: string }[];
    invertorCapacityTypes: { id: number; name: string }[];
    windCapacityTypes: { id: number; name: string }[];
}

const getYearsDropdown = (): { id: number; name: string; description: string }[] => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 2000; i--) {
        years.push({ id: i, name: i.toString(), description: i.toString() });
    }
    return years;
};

export class DropdownService {
    constructor(private readonly httpService: HttpService) {}

    public getAll(): Promise<Dropdowns> {
        return this.httpService.get(dropdownsEndpoint).then((dropdowns) => {
            return {
                ...dropdowns,
                years: getYearsDropdown(),
            };
        });
    }
}

export const dropdownService = new DropdownService(httpService);
