import { httpService, HttpService } from "./http.service";
import {
    estateEndpoint,
    exportEstatesEndpoint,
    getByUserIdEndpoint,
    getPurchasedEstatesByUserIdEndpoint,
    getUnderReviewEndpoint,
    approveEstateEndpoint,
    unpublishEstateEndpoint,
} from "../endpoints";
import { SellerProfileState } from "../Seller/SellerProfile/CreateEstate/utils";
import { IEstate, IEstateResult } from "../interfaces/estate";
import { IAttachment } from "../interfaces/attachment";
import { IEstateFilters } from "../interfaces/filters";
import { cleanEstate } from "./estate-service.utils";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const numberFilters = ["size_start", "size_end", "price_start", "price_end"];

export const defaultEstatePageSize = 9;

export const defaultOrder = { sort: "createdAt", direction: "DESC" };

export const getEstateSearchUrl = (endpoint: string, filters: IEstateFilters) => {
    const url = new URL(endpoint);
    Object.keys(filters)
        .filter((key) => {
            if (numberFilters.includes(key)) {
                if (key === "size_end" && !filters["size_end"]) {
                    return false;
                }
                return true;
            }
            return (filters as any)[key];
        })
        .forEach((key) => url.searchParams.append(key, (filters as any)[key]));
    return url.toString();
};

export class EstateService {
    constructor(private readonly httpService: HttpService) {}

    public async save(estate: SellerProfileState, location: string): Promise<IEstate> {
        // TODO: Move to backend
        if (!estate.chosen_location) {
            const results = await geocodeByAddress(location);
            const latLng = await getLatLng(results[0]);
            estate.coordinates_lat = latLng.lat;
            estate.coordinates_lng = latLng.lng;
        }

        // TODO: Move to backend
        const cleanedEstate = cleanEstate(estate);

        return this.httpService.post(`${estateEndpoint}`, cleanedEstate).then((result: any) => {
            return result.data;
        });
    }

    public async update(estate: SellerProfileState, location: string): Promise<IEstate> {
        // TODO: Move to backend
        if (!estate.chosen_location) {
            const results = await geocodeByAddress(location);
            const latLng = await getLatLng(results[0]);
            estate.coordinates_lat = latLng.lat;
            estate.coordinates_lng = latLng.lng;
        }

        // TODO: Move to backend
        const cleanedEstate = cleanEstate(estate);

        return this.httpService.put(`${estateEndpoint}/${estate.id}`, cleanedEstate).then((result: any) => {
            return result.data;
        });
    }

    public getId(id: string): Promise<{ estate: IEstate; attachments: IAttachment[] }> {
        return this.httpService.get(`${estateEndpoint}/${id}`);
    }

    public getIdAsGuest(id: string): Promise<{ estate: IEstate; attachments: IAttachment[] }> {
        return this.httpService.get(`${estateEndpoint}/${id}/guest`);
    }

    public getAll(filters: IEstateFilters): Promise<IEstateResult> {
        const url = getEstateSearchUrl(estateEndpoint, filters);
        return this.httpService.get(url.toString());
    }

    public getLatest(): Promise<IEstate[]> {
        return this.httpService.get(`${estateEndpoint}/custom/latest`);
    }

    public delete(id: string): Promise<void> {
        return this.httpService.delete(`${estateEndpoint}/${id}`);
    }

    public exportEstates(): Promise<any> {
        return this.httpService.post(exportEstatesEndpoint);
    }

    public unpublish(id: number, reason: string, description: string): Promise<void> {
        return this.httpService.put(`${unpublishEstateEndpoint}/${id}`, { reason, description });
    }

    public getByUserId(id: string): Promise<{ count: number; rows: IEstate[] }> {
        return this.httpService.get(`${getByUserIdEndpoint}/${id}`);
    }

    public getPurchasedEstatesByUserId(id: string): Promise<{ count: number; rows: IEstate[] }> {
        return this.httpService.get(`${getPurchasedEstatesByUserIdEndpoint}/${id}`);
    }
    public getUnderReviewId(id: string): Promise<{ count: number; rows: IEstate[] }> {
        return this.httpService.get(`${getUnderReviewEndpoint}/${id}`);
    }

    public approve(id: string): Promise<void> {
        return this.httpService.put(`${approveEstateEndpoint}/${id}`);
    }

    public promote(id: string): Promise<void> {
        return this.httpService.put(`${estateEndpoint}/promote/${id}`);
    }

    public demote(id: string): Promise<void> {
        return this.httpService.put(`${estateEndpoint}/demote/${id}`);
    }
}

export const estateService = new EstateService(httpService);
