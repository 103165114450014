import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Pagination,
    Typography,
} from "@mui/material";
import { StyledViewEstates, viewEstatesClasses as classes } from "./StyledViewEstates";
import image from "./assets/bg.png";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../routes";
import { formatDate } from "../../common/hooks";
import { useContext, useEffect, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { IEstate } from "../../interfaces/estate";
import { GlobalContext } from "../../App";
import { greyLight } from "../../common/Theme/theme";
import { FullHearth } from "../../common/EstateCard/FullHearth";
import { buyerService } from "../../services/buyer.service";
import { defaultEstatePageSize } from "../../services/estate.service";
import { getImageLink } from "../../endpoints";

const getStart = (page: number) => (page - 1) * defaultEstatePageSize;
const getEnd = (page: number) => page * defaultEstatePageSize;

export const ViewEstates = ({
    id,
    rows,
    onToggleFavourite,
}: {
    id: string | undefined;
    rows: IEstate[];
    onToggleFavourite: () => void;
}) => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(rows.length);
    const { dropdownsMap } = useContext(GlobalContext);
    const [estates, setEstates] = useState<IEstate[]>(rows.slice(getStart(page), getEnd(page)));

    useEffect(() => {
        let start = getStart(page);
        let end = getEnd(page);
        let newRows = rows.slice(start, end);
        if (page >= 2 && newRows.length === 0) {
            const newPage = page - 1;
            if (newPage === 0) {
                return;
            }
            setPage(page - 1);
            start = getStart(newPage);
            end = getEnd(newPage);
            newRows = rows.slice(start, end);
            setEstates(newRows);
        } else {
            setEstates(newRows);
        }
    }, [page, rows]);

    const makeUnFavourite = async (estateId: number) => {
        await buyerService.makeUnFavourite(estateId);
        if (onToggleFavourite) {
            onToggleFavourite();
        }
    };

    useEffect(() => {
        setCount(Math.ceil(rows.length / defaultEstatePageSize));
    }, [rows]);

    return (
        <StyledViewEstates
            container
            flexDirection={"column"}
            width={"fit-content"}
            marginLeft={"auto"}
            marginRight={"auto"}
            justifyContent={"center"}>
            {estates.map((estate: any) => (
                <Card key={estate.id} className={classes.cardContainer}>
                    <CardActionArea className={classes.cardActionArea}>
                        <Grid container flexDirection={"row"}>
                            <Grid item>
                                <CardMedia
                                    component="img"
                                    image={estate.attachments[0] ? getImageLink(estate.attachments[0].link) : image}
                                    alt={estate.title}
                                    className={classes.cardImage}
                                />
                                <Grid container width={"fit-content"} className={classes.cardLike}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            makeUnFavourite(estate.id);
                                        }}>
                                        <FullHearth />
                                    </IconButton>
                                </Grid>
                                {estate.promoted && (
                                    <Grid className={classes.cardPromotedContainer}>
                                        <Typography variant="h4" color={"secondary"}>
                                            Промотирана
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container className={classes.cardContentGrid}>
                                <CardContent className={classes.cardContent}>
                                    <Typography className={classes.cardHeading} gutterBottom variant="h2">
                                        {estate.title}
                                    </Typography>
                                    <Grid container flexDirection={"row"} marginBottom={"20px"} alignItems="center">
                                        <Typography className={classes.cardProperty}>
                                            {estate.area}
                                            {estate.area_unit_id
                                                ? dropdownsMap!.areaUnits[estate.area_unit_id].name
                                                : ""}
                                        </Typography>
                                        <Typography className={classes.cardProperty}>
                                            {estate.estate_type_id
                                                ? dropdownsMap!.estateTypes[estate.estate_type_id].name
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid container marginBottom={"30px"} className={classes.cardInfo}>
                                        <LocationOnOutlinedIcon className={classes.cardPropertyIcon} />
                                        <Typography className={classes.cardLocation}>
                                            {dropdownsMap!.countryAreas[estate.country_area_id].name}
                                        </Typography>
                                        <AccessTimeOutlinedIcon
                                            className={classes.cardPropertyIcon}
                                            htmlColor={greyLight}
                                        />
                                        <Typography className={classes.cardDateTime}>
                                            {estate.createdAt ? formatDate(new Date(estate.createdAt)) : ""}
                                        </Typography>
                                        <Typography className={classes.cardPrice}>
                                            {estate.price}
                                            {estate.currency_id
                                                ? dropdownsMap!.currency[estate.currency_id].description
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    <Button
                                        variant="outlined"
                                        className={classes.cardButton}
                                        onClick={() => {
                                            window.open(`/${NavigationRoutes.EstateDetails}/${estate.id}`, "_blank");
                                        }}>
                                        Прегледай
                                    </Button>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
            ))}

            {estates.length === 0 ? (
                <Grid container justifyContent={"center"} className={classes.estatesResultsGrid}>
                    <Typography variant={"body1"}>Няма обяви</Typography>
                </Grid>
            ) : (
                <Grid container justifyContent={"center"} className={classes.estatesResultsGrid}>
                    <Pagination
                        count={count}
                        color="secondary"
                        page={page}
                        onChange={(_event, innerPage) => {
                            setPage(innerPage);
                        }}
                    />
                </Grid>
            )}
        </StyledViewEstates>
    );
};
