import { httpService, HttpService } from "./http.service";
import { loginEndpoint, logoutEndpoint, userEndpoint } from "../endpoints";
import { ILogged } from "../interfaces/user";
import { RegisterState } from "../Authentication/Login/Login";

export class LoginService {
    constructor(private readonly httpService: HttpService) {}

    public async login(email: string, password: string): Promise<ILogged> {
        return this.httpService
            .post(
                `${loginEndpoint}/password`,
                {
                    email,
                    password,
                },
                {
                    "Content-type": "application/json",
                }
            )
            .then((result: any) => result.data);
    }

    public async logout(): Promise<void> {
        return this.httpService.post(logoutEndpoint).then((result: any) => result.data);
    }

    public async register(data: RegisterState): Promise<void> {
        return this.httpService.post(`${userEndpoint}`, data);
    }
}

export const loginService = new LoginService(httpService);
