export enum WizardProgressItemStatus {
    Done = "done",
    InProgress = "in-progress",
    Pending = "pending",
}

export interface WizardProgressItem {
    status: WizardProgressItemStatus;
    nextStatus?: WizardProgressItemStatus;
    subTitle: string;
    stepNumber: number;
}
