import { Alert, Button, Grid, IconButton, Paper, Snackbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { invoiceModalClasses, StyledInvoiceModal } from "./StyledInvoiceModal";
import Dropdown from "../Dropdown/Dropdown";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomTextInput from "../CustomTextInput/CustomerTextInput";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { paymentService } from "../../services/payment.service";

interface InvoiceModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose?: () => void;
    order: any;
    email: string | undefined;
}

interface InvoiceFormLegalEntity {
    company: string;
    mol: string;
    eik: string;
    dds: boolean;
    ddsNumber: string;
    city: string;
    address: string;
}

interface InvoiceFormIndividual {
    city: string;
    address: string;
    name: string;
    egn: string;
}

const defultInvoiceFormLegalEntity: InvoiceFormLegalEntity = {
    company: "",
    mol: "",
    eik: "",
    dds: false,
    ddsNumber: "",
    city: "",
    address: "",
};

const defultInvoiceFormIndividual: InvoiceFormIndividual = {
    city: "",
    address: "",
    name: "",
    egn: "",
};

const invoiceLegalEntitySchema = yup.object({
    company: yup.string().required("Името на фирмата е задължително"),
    mol: yup.string().required("МОЛ е задължителен"),
    eik: yup.string().required("ЕИК е задължителен"),
    dds: yup.string().required("ДДС номер е задължителен"),
    ddsNumber: yup.string(),
    city: yup.string().required("Градът е задължителен"),
    address: yup.string().required("Адресът е задължителен"),
});

const invoiceIndividualSchema = yup.object({
    city: yup.string().required("Градът е задължителен"),
    address: yup.string().required("Адресът е задължителен"),
    name: yup.string().required("Името е задължително"),
    egn: yup.string().required("ЕГН е задължително"),
});

export const InvoiceModal = (props: InvoiceModalProps) => {
    const typeOptions = [
        { id: "0", name: "Юридическо лице" },
        { id: "1", name: "Физическо лице" },
    ];
    const [type, setType] = useState(typeOptions[0].id);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const submitForm = (values: any) => {
        setDisableButton(true);
        paymentService
            .requestInvoice({ type: type, email: props.email, ...values, ...props.order })
            .then((response) => {
                if (response.status === 200) {
                    setSuccess(true);
                    setTimeout(() => {
                        props.setOpen(false);
                        if (props.onClose) {
                            props.onClose();
                        }
                    }, 2000);
                } else {
                    setError(true);
                }
            });
    };

    const formLegalEntity = useFormik<InvoiceFormLegalEntity>({
        initialValues: {
            ...defultInvoiceFormLegalEntity,
        },
        validationSchema: invoiceLegalEntitySchema,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    const formIndividual = useFormik<InvoiceFormIndividual>({
        initialValues: {
            ...defultInvoiceFormIndividual,
        },
        validationSchema: invoiceIndividualSchema,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    useEffect(() => {
        formLegalEntity.validateForm();
        formIndividual.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledInvoiceModal
            open={props.open}
            className={invoiceModalClasses.root}
            onClose={() => {
                if (props.onClose) {
                    props.onClose();
                }
            }}>
            <Paper className={invoiceModalClasses.paper}>
                <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>Заявяване на фактура</Typography>
                    <IconButton
                        onClick={() => {
                            props.setOpen(false);
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Dropdown
                    label={"Тип фактура"}
                    name={"type"}
                    value={type}
                    onChange={(event) => {
                        setType(event.target.value);
                    }}
                    options={typeOptions}
                    className={invoiceModalClasses.dropdown}
                />
                {type === "0" && (
                    <>
                        <CustomTextInput
                            label="Фирма"
                            name={"company"}
                            placeholder={""}
                            value={formLegalEntity.values.company}
                            onChange={formLegalEntity.handleChange}
                            error={formLegalEntity.touched?.company && !!formLegalEntity.errors?.company}
                            helperText={formLegalEntity.errors.company}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="МОЛ"
                            name={"mol"}
                            placeholder={""}
                            value={formLegalEntity.values.mol}
                            onChange={formLegalEntity.handleChange}
                            error={formLegalEntity.touched?.mol && !!formLegalEntity.errors?.mol}
                            helperText={formLegalEntity.errors.mol}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="ЕИК"
                            name={"eik"}
                            placeholder={""}
                            value={formLegalEntity.values.eik}
                            onChange={formLegalEntity.handleChange}
                            error={formLegalEntity.touched?.eik && !!formLegalEntity.errors?.eik}
                            helperText={formLegalEntity.errors.eik}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomCheckbox
                            className={invoiceModalClasses.checkbox}
                            value={formLegalEntity.values.dds}
                            checked={formLegalEntity.values.dds}
                            onChange={formLegalEntity.handleChange}
                            title={"Регистрирана по ДДС"}
                            label={"Регистрирана по ДДС"}
                            name={"dds"}
                        />
                        {formLegalEntity.values.dds && (
                            <CustomTextInput
                                label="ДДС номер"
                                name={"ddsNumber"}
                                placeholder={""}
                                value={formLegalEntity.values.ddsNumber}
                                onChange={formLegalEntity.handleChange}
                                error={formLegalEntity.touched?.ddsNumber && !!formLegalEntity.errors?.ddsNumber}
                                helperText={formLegalEntity.errors.ddsNumber}
                                className={invoiceModalClasses.textInput}
                            />
                        )}
                        <CustomTextInput
                            label="Град"
                            name={"city"}
                            placeholder={""}
                            value={formLegalEntity.values.city}
                            onChange={formLegalEntity.handleChange}
                            error={formLegalEntity.touched?.city && !!formLegalEntity.errors?.city}
                            helperText={formLegalEntity.errors.city}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="Адрес"
                            name={"address"}
                            placeholder={""}
                            value={formLegalEntity.values.address}
                            onChange={formLegalEntity.handleChange}
                            error={formLegalEntity.touched?.address && !!formLegalEntity.errors?.address}
                            helperText={formLegalEntity.errors.address}
                            className={invoiceModalClasses.textInput}
                        />
                    </>
                )}
                {type === "1" && (
                    <>
                        <CustomTextInput
                            label="Име"
                            name={"name"}
                            placeholder={""}
                            value={formIndividual.values.name}
                            onChange={formIndividual.handleChange}
                            error={formIndividual.touched?.name && !!formIndividual.errors?.name}
                            helperText={formIndividual.errors.name}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="ЕГН"
                            name={"egn"}
                            placeholder={""}
                            value={formIndividual.values.egn}
                            onChange={formIndividual.handleChange}
                            error={formIndividual.touched?.egn && !!formIndividual.errors?.egn}
                            helperText={formIndividual.errors.egn}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="Град"
                            name={"city"}
                            placeholder={""}
                            value={formIndividual.values.city}
                            onChange={formIndividual.handleChange}
                            error={formIndividual.touched?.city && !!formIndividual.errors?.city}
                            helperText={formIndividual.errors.city}
                            className={invoiceModalClasses.textInput}
                        />
                        <CustomTextInput
                            label="Адрес"
                            name={"address"}
                            placeholder={""}
                            value={formIndividual.values.address}
                            onChange={formIndividual.handleChange}
                            error={formIndividual.touched?.address && !!formIndividual.errors?.address}
                            helperText={formIndividual.errors.address}
                            className={invoiceModalClasses.textInput}
                        />
                    </>
                )}
                <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        className={invoiceModalClasses.acceptButton}
                        disabled={disableButton}
                        onClick={() => {
                            if (type === "0") {
                                formLegalEntity.submitForm();
                            } else {
                                formIndividual.submitForm();
                            }
                        }}>
                        Изпрати
                    </Button>
                </Grid>
                <Snackbar
                    open={error}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={() => setError(false)}>
                    <Alert severity="error" onClose={() => setError(false)}>
                        Възникна грешка при изпращането на фактурата. Моля опитайте отново.
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={success}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClose={() => setSuccess(false)}>
                    <Alert severity="success" onClose={() => setSuccess(false)}>
                        Успешно е изпратена фактура.
                    </Alert>
                </Snackbar>
            </Paper>
        </StyledInvoiceModal>
    );
};
