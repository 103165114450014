import { httpService, HttpService } from "./http.service";
import { userEndpoint } from "../endpoints";
import { ILogged, IProfile, IUser } from "../interfaces/user";
import { IUserFilters } from "../interfaces/filters";

export const defaultUserPageSize = 10;

export class UserService {
    constructor(private readonly httpService: HttpService) {}

    public save(user: IUser): Promise<IUser> {
        return this.httpService.post(`${userEndpoint}`, user).then((result: any) => {
            return result.data;
        });
    }

    public getById(id: string): Promise<IProfile> {
        return this.httpService.get(`${userEndpoint}/${id}`);
    }

    public getCurrentUser(): Promise<ILogged> {
        return this.httpService.get(`${userEndpoint}/current/logged`);
    }

    public getAll(filters: IUserFilters): Promise<{ count: number; rows: IUser[] }> {
        const url = new URL(userEndpoint);
        Object.keys(filters)
            .filter((key) => {
                return (filters as any)[key];
            })
            .forEach((key) => url.searchParams.append(key, (filters as any)[key]));
        return this.httpService.get(url.toString());
    }

    public activate(id: number): Promise<void> {
        return this.httpService.put(`${userEndpoint}/${id}/activate`, {});
    }

    public deactivate(id: number) {
        return this.httpService.put(`${userEndpoint}/${id}/deactivate`, {});
    }

    public updateInfo(id: string, info: { name: string; phone: string }): Promise<void> {
        return httpService.put(`${userEndpoint}/${id}/`, { phone: info.phone, name: info.name });
    }

    public validate(token: string) {
        return this.httpService.get(`${userEndpoint}/validate/${token}`);
    }

    public unlockEstate(userId: string, estateId: string) {
        return this.httpService.put(`${userEndpoint}/unlock/estate`, { userId: userId, estateId: estateId });
    }

    public unlockContact(userId: string, estateId: string) {
        return this.httpService.put(`${userEndpoint}/unlock/contact`, { userId: userId, estateId: estateId });
    }

    public unlockEstateDiscount(userId: string, estateId: string) {
        return this.httpService.put(`${userEndpoint}/unlock/estate/discount`, { userId: userId, estateId: estateId });
    }
}

export const userService = new UserService(httpService);
