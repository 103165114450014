import { httpService, HttpService } from "./http.service";
import { attachmentEndpoint, documentsEndpoint } from "../endpoints";
import { Dropdowns } from "./dropdown.service";

export class AttachmentService {
    constructor(private readonly httpService: HttpService) {}

    public createAttachments(photos: any[], estateId: number): Promise<Dropdowns> {
        const form = new FormData();
        for (const photo of photos) {
            form.append("photos", photo);
        }
        form.set("estateId", estateId.toString());
        return this.httpService.post(attachmentEndpoint, form, {
            "Content-Type": "multipart/form-data",
        });
    }

    public delete(ids: number[]): Promise<void> {
        return this.httpService.delete(`${attachmentEndpoint}/${ids.join(",")}`);
    }

    public uploadDocuments(documents: any[]): Promise<any> {
        const form = new FormData();
        for (const document of documents) {
            form.append("photos", document);
        }
        return this.httpService.post(documentsEndpoint, form, {
            "Content-Type": "multipart/form-data",
        });
    }

    public deleteDocuments(estateId: number, ids: number[]): Promise<void> {
        return this.httpService.delete(`${documentsEndpoint}/${estateId}/${ids.join(",")}`);
    }
}

export const attachmentService = new AttachmentService(httpService);
