import React, { useContext, useEffect, useState } from "react";
import { authenticationClasses as classes, ColorButton, CssTextField, StyledLogin } from "./StyledLogin";
import { Alert, Grid, Link, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import solarEstatesLight from "./assets/solar-estates-light.svg";
import solarEstatesDark from "./assets/solar-estates-dark.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { loginService } from "../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import { UserType } from "../../interfaces/user";
import { NavigationRoutes } from "../../routes";
import { GlobalContext } from "../../App";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Dropdown from "../../common/Dropdown/Dropdown";
import CustomCheckbox from "../../common/CustomCheckbox/CustomCheckbox";
import { useGA } from "../../common/hooks";
import { userService } from "../../services/user.service";

const SIGN_IN = "signIn";
export const SIGN_UP = "signUp";
const emailAlreadyExistErrorStatus = 400;

export interface RegisterState {
    email: string;
    password: string;
    confirmPassword: string;
    type: string;
    name: string;
    phone: string;
    terms: boolean;
}

const registerDefaultState = {
    email: "",
    password: "",
    confirmPassword: "",
    type: UserType.Seller,
    name: "",
    phone: "",
    terms: false,
};

const registerSchema = yup.object({
    email: yup.string().required("Email e задължителен").email("Email е невалиден"),
    password: yup.string().required("Паролата е задължителна"),
    confirmPassword: yup
        .string()
        .required("Потвърждение на паролата е задължително")
        .oneOf([yup.ref("password"), null], "Паролите трябва да съвпадат"),
    type: yup.string().required("Роля e задължително поле"),
    name: yup.string(),
    phone: yup.string().required("Телефона е задължителен"),
    terms: yup
        .boolean()
        .oneOf([true], "Трябва да се съгласите с условията")
        .required("Трябва да се съгласите с условията"),
});

export const Login = (props: { defaultTab?: string }) => {
    const { hash, redirect } = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    useGA();
    const { setUser } = useContext(GlobalContext);
    const [validated, setValidated] = useState(false);
    const [tab, setTab] = useState(props.defaultTab || SIGN_IN);
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
        setSuccess(false);
        setErrorMessage("");
        registerForm.setFieldValue("terms", false);
    };
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        if (hash) {
            userService.validate(hash).then(() => {
                setValidated(true);
            });
        }
    }, [hash]);

    const form = useFormik<{
        email: string;
        password: string;
    }>({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: yup.object({
            email: yup.string().required("Email e задължителен").email("Email е невалиден"),
            password: yup.string().required("Паролата е задължителна"),
        }),
        onSubmit: (values) => {
            loginService
                .login(values.email, values.password)
                .then((result) => {
                    localStorage.setItem("token", result?.token);
                    if (redirect) {
                        setUser(result);
                        navigate(`/${redirect}`);
                        setSuccess(true);
                    } else if (result.role === UserType.Seller) {
                        setUser(result);
                        navigate(`/${NavigationRoutes.Home}`);
                        setSuccess(true);
                    } else if (result.role === UserType.Buyer) {
                        setUser(result);
                        navigate(`/${NavigationRoutes.Home}/`);
                        setSuccess(true);
                    } else if (result.role === UserType.Admin) {
                        setUser(result);
                        navigate(`/${NavigationRoutes.AdminUser}`);
                        setSuccess(true);
                    } else {
                        setUser(null);
                        setErrorMessage("Входът е неуспешен. Моля проверете email и паролата си.");
                    }
                })
                .catch(() => {
                    setUser(null);
                    setErrorMessage("Входът е неуспешен. Моля проверете email и паролата си.");
                });
        },
    });

    const registerForm = useFormik<RegisterState>({
        initialValues: {
            ...registerDefaultState,
        },
        validationSchema: registerSchema,
        onSubmit: (values) => {
            setDisableButton(true);
            loginService
                .register({ ...values })
                .then(() => {
                    window.scrollTo(0, 0);
                    setSuccess(true);
                    setDisableButton(false);
                })
                .catch((error) => {
                    error = error.toJSON ? error.toJSON() : error;
                    setDisableButton(false);
                    if (error.status === emailAlreadyExistErrorStatus) {
                        setErrorMessage("Този имейл вече е регистриран");
                    } else {
                        setErrorMessage("Грешка при регистрацията");
                    }
                });
        },
    });

    const handleTermsChange = (_event: any, value: boolean) => {
        registerForm.setFieldValue("terms", value);
    };

    return (
        <StyledLogin container display={"flex"}>
            <Grid item lg={5} md={5} xs={12} display={"flex"} flexDirection={"column"} className={classes.leftPanel}>
                <Grid container className={classes.logoContainer}>
                    <img src={solarEstatesLight} className={classes.logoLight} alt="Solar Estates" />
                    <img src={solarEstatesDark} className={classes.logoDark} alt="Solar Estates" />
                </Grid>
            </Grid>
            <Grid
                item
                lg={7}
                md={7}
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                className={tab === SIGN_IN ? classes.rightPanel.signIn : classes.rightPanel.signUp}>
                <TabContext value={tab}>
                    <Tabs value={tab} onChange={handleTabChange} className={classes.tabs}>
                        <Tab value={SIGN_IN} label="Вход" />
                        <Tab value={SIGN_UP} label="Регистрация" />
                    </Tabs>
                    <TabPanel value={SIGN_IN}>
                        <Typography variant={"caption"} color="primary" className={classes.heading}>
                            Влез
                        </Typography>
                        <Typography className={classes.welcome}>Добре дошъл в SolarEstates</Typography>
                        <Grid item>
                            <CssTextField
                                variant={"outlined"}
                                placeholder="Email"
                                focused
                                InputProps={{
                                    startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                                }}
                                value={form.values.email}
                                onChange={form.handleChange}
                                error={form.touched?.email && !!form.errors?.email}
                                helperText={form.errors?.email}
                                name="email"
                            />
                        </Grid>
                        <Grid item>
                            <CssTextField
                                variant={"outlined"}
                                placeholder="Парола"
                                type="password"
                                focused
                                InputProps={{
                                    startAdornment: <LockOutlinedIcon htmlColor={"#BABDC3"} />,
                                }}
                                value={form.values.password}
                                onChange={form.handleChange}
                                error={form.touched?.password && !!form.errors?.password}
                                helperText={form.errors?.password}
                                name="password"
                            />
                        </Grid>
                        <Link href={`/${NavigationRoutes.ForgotPassword}/`} className={classes.link}>
                            <Typography className={classes.forgotPassword}>Забравена парола?</Typography>
                        </Link>
                        <ColorButton variant="contained" color="secondary" onClick={form.submitForm}>
                            <PersonOutlineOutlinedIcon className={classes.buttonIcon} />
                            Вход
                        </ColorButton>
                    </TabPanel>
                    <TabPanel value={SIGN_UP}>
                        {!success && (
                            <>
                                <Typography variant={"caption"} color="primary" className={classes.heading}>
                                    Създай Акаунт
                                </Typography>
                                <Grid container flexDirection={"row"}>
                                    <Grid item xs={12} lg={6} md={6}>
                                        <CssTextField
                                            variant={"outlined"}
                                            placeholder="Email"
                                            sx={{ height: "90px" }}
                                            InputProps={{
                                                startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                                            }}
                                            name={"email"}
                                            value={registerForm.values.email}
                                            onChange={registerForm.handleChange}
                                            error={registerForm.touched?.email && !!registerForm.errors?.email}
                                            helperText={registerForm.errors?.email}
                                        />
                                        <CssTextField
                                            variant={"outlined"}
                                            placeholder="Парола"
                                            type={"password"}
                                            sx={{ height: "90px" }}
                                            InputProps={{
                                                startAdornment: <LockOutlinedIcon htmlColor={"#BABDC3"} />,
                                            }}
                                            name={"password"}
                                            value={registerForm.values.password}
                                            onChange={registerForm.handleChange}
                                            error={registerForm.touched?.password && !!registerForm.errors?.password}
                                            helperText={registerForm.errors?.password}
                                        />
                                        <CssTextField
                                            variant={"outlined"}
                                            placeholder="Име и Фамилия"
                                            sx={{ height: "90px" }}
                                            InputProps={{
                                                startAdornment: <PersonOutlineOutlinedIcon htmlColor={"#BABDC3"} />,
                                            }}
                                            name={"name"}
                                            value={registerForm.values.name}
                                            onChange={registerForm.handleChange}
                                            error={registerForm.touched?.name && !!registerForm.errors?.name}
                                            helperText={registerForm.errors?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6}>
                                        <Dropdown
                                            className={""}
                                            name={"type"}
                                            label={"За какво сте тук?"}
                                            sx={{
                                                height: "130px",
                                                justifyContent: "center",
                                                paddingBottom: "87px",
                                                width: "87%",
                                            }}
                                            value={registerForm.values.type}
                                            onChange={registerForm.handleChange}
                                            error={registerForm.touched?.type && !!registerForm.errors?.type}
                                            helperText={registerForm.errors?.type}
                                            options={[
                                                { id: UserType.Seller, name: "Продавам" },
                                                { id: UserType.Buyer, name: "Търся" },
                                            ]}
                                        />
                                        <CssTextField
                                            variant={"outlined"}
                                            placeholder="Повтори парола"
                                            type={"password"}
                                            sx={{ height: "90px" }}
                                            InputProps={{
                                                startAdornment: <LockOutlinedIcon htmlColor={"#BABDC3"} />,
                                            }}
                                            name={"confirmPassword"}
                                            value={registerForm.values.confirmPassword}
                                            onChange={registerForm.handleChange}
                                            error={
                                                registerForm.touched?.confirmPassword &&
                                                !!registerForm.errors?.confirmPassword
                                            }
                                            helperText={registerForm.errors?.confirmPassword}
                                        />
                                        <CssTextField
                                            variant={"outlined"}
                                            placeholder="Телефон"
                                            sx={{ height: "90px" }}
                                            InputProps={{
                                                startAdornment: <LocalPhoneOutlinedIcon htmlColor={"#BABDC3"} />,
                                            }}
                                            name={"phone"}
                                            value={registerForm.values.phone}
                                            onChange={registerForm.handleChange}
                                            error={registerForm.touched?.phone && !!registerForm.errors?.phone}
                                            helperText={registerForm.errors?.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <CustomCheckbox
                                        className={classes.agreement}
                                        value={registerForm.values.terms}
                                        checked={registerForm.values.terms}
                                        onChange={handleTermsChange as any}
                                        name={"terms"}
                                        error={registerForm.touched?.terms && !!registerForm.errors?.terms}
                                        helperText={registerForm.errors?.terms}
                                        title={"Съгласен съм с правилата и условията"}
                                        label={
                                            <Link
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = "/Общи условия-3.pdf";
                                                }}>
                                                Съгласен съм с правилата и условията
                                            </Link>
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <ColorButton
                                        variant="contained"
                                        color="secondary"
                                        disabled={disableButton}
                                        onClick={() => {
                                            registerForm.submitForm();
                                        }}>
                                        <PersonAddOutlinedIcon className={classes.buttonIcon} />
                                        Създай
                                    </ColorButton>
                                </Grid>
                            </>
                        )}
                        {success && (
                            <Typography color="primary" className={classes.heading}>
                                На предоставения email е изпратено съобщение за потвърждение. След потвърджение ще
                                активирате профила си.
                            </Typography>
                        )}
                    </TabPanel>
                </TabContext>
            </Grid>
            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => setErrorMessage("")}>
                <Alert severity="error" onClose={() => setErrorMessage("")}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={validated}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => setValidated(false)}>
                <Alert severity="success" onClose={() => setValidated(false)}>
                    Акаунтът Ви е активиран.
                </Alert>
            </Snackbar>
        </StyledLogin>
    );
};
