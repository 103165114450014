import { Modal, styled } from "@mui/material";
import { grey, greyDark, greyLight, primary } from "../../../common/Theme/theme";

const DOWNLOAD_DOCUMENTS_MODAL_PREFIX = "downloadDocumentsModal";

export const downloadDocumentsModalClasses = {
    root: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-root`,
    paper: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-paper`,
    acceptButton: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-acceptButton`,
    declineButton: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-declineButton`,
    checkIcon: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-checkIcon`,
    documentText: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-documentText`,
    estateTitle: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-estateTitle`,
    estatePrice: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-estatePrice`,
    modalTitle: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-modalTitle`,
    modalDescription: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-modalDescription`,
    modalTextException: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-modalTextException`,
    documentName: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-documentName`,
    widthOfButton: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-widthOfButton`,
    dividerLine: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-dividerLine`,
    modalCloseIcon: `${DOWNLOAD_DOCUMENTS_MODAL_PREFIX}-modalCloseIcon`,
};

export const StyledDownloadDocumentsModal = styled(Modal)(({ theme }) => ({
    [`&.${downloadDocumentsModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        overflowY: "scroll",
    },
    [`& .${downloadDocumentsModalClasses.paper}`]: {
        padding: "20px",
        width: "552px",
        height: "auto",
        borderRadius: "8px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        [theme.breakpoints.only("xs")]: {
            width: "300px",
            margin: "auto",
        },
    },
    [`& .${downloadDocumentsModalClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${downloadDocumentsModalClasses.acceptButton}`]: {
        borderRadius: "100px",
        color: "white",
    },
    [`& .${downloadDocumentsModalClasses.checkIcon}`]: {
        color: primary,
        marginRight: "10px",
    },
    [`& .${downloadDocumentsModalClasses.modalTitle}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        paddingRight: "240px",
        marginBottom: "5px",
        [theme.breakpoints.only("xs")]: {
            paddingRight: "0px",
        },
    },
    [`& .${downloadDocumentsModalClasses.modalDescription}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        marginBottom: "20px",
    },
    [`& .${downloadDocumentsModalClasses.modalTextException}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "14px",
    },
    [`& .${downloadDocumentsModalClasses.estateTitle}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        width: "300px",
        [theme.breakpoints.only("xs")]: {
            width: "280px",
        },
    },
    [`& .${downloadDocumentsModalClasses.estatePrice}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
    },
    [`& .${downloadDocumentsModalClasses.documentName}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
    [`& .${downloadDocumentsModalClasses.widthOfButton}`]: {
        width: "248px",
        height: "40px",
    },
    [`& .${downloadDocumentsModalClasses.documentText}`]: {
        color: grey,
        marginBottom: "15px",
        textOverflow: "ellipsis",
        width: "80%",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    [`& .${downloadDocumentsModalClasses.dividerLine}`]: {
        width: "auto",
        marginTop: "20px",
    },
    [`& .${downloadDocumentsModalClasses.modalCloseIcon}`]: {
        marginBottom: "5px",
    },
}));
