import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Container, Tab } from "@mui/material";
import React, { useState } from "react";
import { adminUserClasses as classes, StyledAdminUser } from "./StyledAdminUser";
import { ViewContainer } from "../../Core/ViewContainer/ViewContainer";
import { NavigationRoutes } from "../../routes";
import { FeatureBoundary } from "../../common/FeatureBoundary/FeatureBoundary";
import { useGA } from "../../common/hooks";
import { UsersTabPanel } from "./UsersTabPanel";
import { EstateTabPanel } from "./EstateTabPanel";
import { httpService } from "../../services/http.service";
import { investCSVEndpoint } from "../../endpoints";

enum Tabs {
    USERS = "Потребители",
    ESTATES = "Имоти",
    INVEST = "Invest",
}

const AdminUser = () => {
    useGA();
    const [tab, setTab] = useState(Tabs.USERS);
    const [disableDownloadInvest, setDisableDownloadInvest] = useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: Tabs) => {
        setTab(newValue);
    };

    const handleDownloadInvestData = async () => {
        setDisableDownloadInvest(true);
        const response = await httpService.post(investCSVEndpoint);
        const data = (response as any).data;
        const date = new Date();
        let element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
        element.setAttribute("download", `invest-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.csv`);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setDisableDownloadInvest(false);
    };

    return (
        <ViewContainer>
            <StyledAdminUser className={classes.root}>
                <Container className={classes.container}>
                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleTabChange}>
                                <Tab label={Tabs.USERS} value={Tabs.USERS} />
                                <Tab label={Tabs.ESTATES} value={Tabs.ESTATES} />
                                <Tab label={Tabs.INVEST} value={Tabs.INVEST} />
                            </TabList>
                        </Box>
                        <TabPanel value={Tabs.USERS}>
                            <UsersTabPanel />
                        </TabPanel>
                        <TabPanel value={Tabs.ESTATES}>
                            <EstateTabPanel />
                        </TabPanel>
                        <TabPanel value={Tabs.INVEST}>
                            <Button
                                variant="outlined"
                                disabled={disableDownloadInvest}
                                onClick={handleDownloadInvestData}>
                                Изтегли данни
                            </Button>
                        </TabPanel>
                    </TabContext>
                </Container>
            </StyledAdminUser>
        </ViewContainer>
    );
};

export default () => (
    <FeatureBoundary route={NavigationRoutes.AdminUser}>
        <AdminUser />
    </FeatureBoundary>
);
