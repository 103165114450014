import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledSuccessModal, successModalClasses } from "./StyledEstateDetailsSuccessModal";
import { IEstate } from "../../../interfaces/estate";
import { NavigationRoutes } from "../../../routes";

interface EstateDetailsSuccessModalProps {
    estate: IEstate;
    openSuccessModal: boolean;
    openErrorModal: boolean;
    setOpenSuccessModal: (value: boolean) => void;
    setOpenErrorModal: (value: boolean) => void;
}

const EstateDetailsSuccessModal = ({
    estate,
    openSuccessModal,
    openErrorModal,
    setOpenSuccessModal,
    setOpenErrorModal,
}: EstateDetailsSuccessModalProps) => {
    return (
        <>
            <StyledSuccessModal
                open={openSuccessModal}
                className={successModalClasses.root}
                onClose={() => {
                    window.location.href = `/${NavigationRoutes.EstateDetails}/${estate.id}`;
                }}>
                <Paper className={successModalClasses.paper}>
                    <Grid container flexDirection={"row-reverse"} alignItems={"center"}>
                        <IconButton
                            onClick={() => {
                                setOpenSuccessModal(false);
                                window.location.href = `/${NavigationRoutes.EstateDetails}/${estate.id}`;
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Typography>Успешно отключване на обява</Typography>
                    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"} marginTop={"50px"}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            className={successModalClasses.acceptButton}
                            onClick={() => {
                                setOpenSuccessModal(false);
                                window.location.href = `/${NavigationRoutes.EstateDetails}/${estate.id}`;
                            }}>
                            Добре
                        </Button>
                    </Grid>
                </Paper>
            </StyledSuccessModal>
            <StyledSuccessModal
                open={openErrorModal}
                className={successModalClasses.root}
                onClose={() => {
                    window.location.href = `/${NavigationRoutes.EstateDetails}/${estate.id}`;
                }}>
                <Paper className={successModalClasses.paper}>
                    <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Възникна грешка при закупуването на кредити</Typography>
                        <IconButton
                            onClick={() => {
                                setOpenErrorModal(false);
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid container display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"error"}
                            className={successModalClasses.acceptButton}
                            onClick={() => {
                                setOpenErrorModal(false);
                            }}>
                            Добре
                        </Button>
                    </Grid>
                </Paper>
            </StyledSuccessModal>
        </>
    );
};

export default EstateDetailsSuccessModal;
