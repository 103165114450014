import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { viewEstatesClasses as classes, StyledViewEstates } from "./StyledViewEstates";
import image from "./assets/bg.png";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../routes";
import { formatDate } from "../../common/hooks";
import { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { defaultEstatePageSize, estateService } from "../../services/estate.service";
import { EstateUnpublishReason, IEstate, IEstateResult } from "../../interfaces/estate";
import { GlobalContext } from "../../App";
import { greyLight } from "../../common/Theme/theme";
import { getImageLink } from "../../endpoints";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const getStart = (page: number) => (page - 1) * defaultEstatePageSize;
const getEnd = (page: number) => page * defaultEstatePageSize;

export const ViewUnderReviewEstates = ({ id }: { id: string | undefined }) => {
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const { dropdownsMap } = useContext(GlobalContext);
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [description, setDescription] = useState("");
    const [currentEstateId, setCurrentEstateId] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [estatesResult, setEstatesResult] = useState<IEstateResult>({
        count: 0,
        rows: [],
    });
    const [currentEstates, setCurrentEstates] = useState<IEstate[]>([]);

    const fetchEstates = () => {
        if (id) {
            estateService.getUnderReviewId(id).then((innerResult) => {
                setEstatesResult(innerResult);
                setCount(Math.ceil(innerResult.count / defaultEstatePageSize));
                setPage(1);
                const estates = innerResult.rows.slice(getStart(page), getEnd(page));
                setCurrentEstates(estates);
            });
        }
    };
    useEffect(() => {
        fetchEstates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        const estates = estatesResult.rows.slice((page - 1) * defaultEstatePageSize, page * defaultEstatePageSize);
        setCurrentEstates(estates);
    }, [estatesResult.rows, page]);

    const unpublishEstate = () => {
        setDisableButton(true);
        estateService.unpublish(currentEstateId, reason, description).then(() => {
            fetchEstates();
            setOpen(false);
        });
    };

    return (
        <StyledViewEstates
            container
            flexDirection={"column"}
            width={"fit-content"}
            marginLeft={"auto"}
            marginRight={"auto"}
            justifyContent={"center"}>
            {currentEstates.map((estate: IEstate) => (
                <Card key={estate.id} className={classes.cardContainer}>
                    <CardActionArea className={classes.cardActionArea}>
                        <Grid container flexDirection={"row"}>
                            <Grid item>
                                <CardMedia
                                    component="img"
                                    image={estate.attachments[0] ? getImageLink(estate.attachments[0].link) : image}
                                    alt={estate.title}
                                    className={classes.cardImage}
                                />
                                {estate.promoted && (
                                    <Grid className={classes.cardPromotedContainer}>
                                        <Typography variant="h4" color={"secondary"}>
                                            Промотирана
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid container width={"fit-content"} className={classes.cardLike}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigate(`/${NavigationRoutes.EditEstate}/${estate.id}`);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                                <Grid container width={"fit-content"} className={classes.cardDelete}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setOpen(true);
                                            setCurrentEstateId(estate.id);
                                            setDisableButton(false);
                                        }}>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.cardContentGrid}>
                                <CardContent className={classes.cardContent}>
                                    <Typography className={classes.cardHeading} gutterBottom variant="h2">
                                        {estate.title}
                                    </Typography>
                                    <Grid container flexDirection={"row"} marginBottom={"20px"} alignItems="center">
                                        <Typography className={classes.cardProperty}>
                                            {estate.area}
                                            {estate.area_unit_id
                                                ? dropdownsMap!.areaUnits[estate.area_unit_id].name
                                                : ""}
                                        </Typography>
                                        <Typography className={classes.cardProperty}>
                                            {estate.estate_type_id
                                                ? dropdownsMap!.estateTypes[estate.estate_type_id].name
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid container marginBottom={"30px"} className={classes.cardInfo}>
                                        <LocationOnOutlinedIcon className={classes.cardPropertyIcon} />
                                        <Typography className={classes.cardLocation}>
                                            {dropdownsMap!.countryAreas[estate.country_area_id].name}
                                        </Typography>
                                        <AccessTimeOutlinedIcon
                                            className={classes.cardPropertyIcon}
                                            htmlColor={greyLight}
                                        />
                                        <Typography className={classes.cardDateTime}>
                                            {estate.createdAt ? formatDate(new Date(estate.createdAt)) : ""}
                                        </Typography>
                                        <Typography className={classes.cardPrice}>
                                            {estate.price}
                                            {estate.currency_id
                                                ? dropdownsMap!.currency[estate.currency_id].description
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    <Button
                                        variant="outlined"
                                        className={classes.cardButton}
                                        onClick={() => {
                                            window.open(`/${NavigationRoutes.EstateDetails}/${estate.id}`, "_blank");
                                        }}>
                                        Прегледай
                                    </Button>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
            ))}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className={classes.archiveEstateModalBox}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"20px"}>
                        Архивирай обява
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Причина</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reason}
                            label="Причина"
                            className={classes.archiveEstateModalSelect}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}>
                            <MenuItem value={EstateUnpublishReason.Sold}>Продадено</MenuItem>
                            <MenuItem value={EstateUnpublishReason.StopSell}>Спряна продажба</MenuItem>
                            <MenuItem value={EstateUnpublishReason.Other}>Друго</MenuItem>
                        </Select>
                        {reason === EstateUnpublishReason.Other && (
                            <TextField
                                value={description}
                                multiline
                                minRows={10}
                                maxRows={20}
                                fullWidth={true}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                                label="Описание"
                                className={classes.archiveEstateModalTextField}
                            />
                        )}
                        <Button
                            variant="outlined"
                            disabled={disableButton}
                            className={classes.archiveEstateModalButton}
                            onClick={unpublishEstate}>
                            Архивирай
                        </Button>
                    </FormControl>
                </Box>
            </Modal>
            {estatesResult.rows.length === 0 ? (
                <Grid container justifyContent={"center"} className={classes.estatesResultsGrid}>
                    <Typography variant={"body1"}>Няма обяви</Typography>
                </Grid>
            ) : (
                <Grid container justifyContent={"center"} className={classes.estatesResultsGrid}>
                    <Pagination
                        count={count}
                        color="secondary"
                        page={page}
                        onChange={(_event, innerPage) => {
                            setPage(innerPage);
                        }}
                    />
                </Grid>
            )}
        </StyledViewEstates>
    );
};
