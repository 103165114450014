import { Grid, styled } from "@mui/material";
import { grey, greyDark, greyLight, theme } from "../../../common/Theme/theme";

const ESTATE_DETAILS_MAP_PREFIX = "EstateDetailsMap";

export const estateDetailsClasses = {
    heading: `${ESTATE_DETAILS_MAP_PREFIX}-heading`,
    label: `${ESTATE_DETAILS_MAP_PREFIX}-label`,
    value: `${ESTATE_DETAILS_MAP_PREFIX}-value`,
    mapContainer: `${ESTATE_DETAILS_MAP_PREFIX}-mapContainer`,
    paperContainer: `${ESTATE_DETAILS_MAP_PREFIX}-paperContainer`,
};

export const StyledEstateDetailsMap = styled(Grid)(({ theme }) => ({
    [`& .${estateDetailsClasses.heading}`]: {
        color: grey,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.label}`]: {
        color: greyLight,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.value}`]: {
        color: greyDark,
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20x",
    },
    [`& .${estateDetailsClasses.mapContainer}`]: {
        marginBottom: "10px",
        height: "400px",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            marginLeft: "10px",
        },
    },
    [`& .${estateDetailsClasses.paperContainer}`]: {
        padding: "20px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "100%",
    },
}));
