import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { GlobalContext } from "../../../App";
import { IForm } from "../../../interfaces/form";
import { SellerProfileState } from "./utils";
import { Status } from "@googlemaps/react-wrapper";
import { IAttachment } from "../../../interfaces/attachment";
import CreateEstateFormDetails from "./CreateEstateForm/CreateEstateFormDetails/CreateEstateFormDetails";
import CreateEstateFormEstateType from "./CreateEstateForm/CreateEstateFormEstateType/CreateEstateFormEstateType";
import CreateEstateFormAddDocuments from "./CreateEstateForm/CreateEstateFormAddDocuments/CreateEstateFormAddDocuments";
import CreateEstateFormOthers from "./CreateEstateForm/CreateEstateFormOthers/CreateEstateFormOthers";
import CreateEstateFormUploadImages from "./CreateEstateForm/CreateEstateFormUploadImages/CreateEstateFormUploadImages";
import CreateEstateFormLocationMap from "./CreateEstateForm/CreateEstateFormLocaionMap/CreateEstateFormLocationMap";
import CreateEstateFormAddress from "./CreateEstateForm/CreateEstateFormAddress/CreateEstateFormAddress";
import CreateEstateFormContacts from "./CreateEstateForm/CreateEstateFormContacts/CreateEstateFormContacts";
import CreateEstateFormPreview from "./CreateEstateForm/CreateEstateFormPreview/CreateEstateFormPreview";
import CreateEstateFormConfirmModal from "./CreateEstateForm/CreateEstateFormConfirmModal/CreateEstateFormConfirmModal";

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

export const maxFileSize = 10 * 1000 * 1024; // 10MB
export const imageFormats = [".png", ".jpg", ".jpeg"];

export const CreateEstateForm = ({
    form,
    next,
    attachments,
    setAttachments,
    oldAttachments,
    deletedOldAttachment,
    highlightDocumentUploads,
}: {
    form: IForm<SellerProfileState>;
    next: () => void;
    attachments: File[];
    setAttachments: (attachments: File[]) => void;
    oldAttachments: IAttachment[];
    deletedOldAttachment: (index: number) => void;
    highlightDocumentUploads: boolean;
}) => {
    const { dropdowns } = useContext(GlobalContext);
    const [selectCoordinates, setSelectCoordinates] = useState(form.values.chosen_location);
    const [location, setLocation] = useState<google.maps.LatLng>();

    const [zoom, setZoom] = React.useState(11);
    const { dropdownsMap } = useContext(GlobalContext);
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: form.values.coordinates_lat ? parseFloat(form.values.coordinates_lat as any) : 42.6953468,
        lng: form.values.coordinates_lng ? parseFloat(form.values.coordinates_lng as any) : 23.3314917,
    });

    const [keysToDisable, setKeysToDisable] = useState<any>(null);

    const onClick = (e: google.maps.MapMouseEvent) => {
        // avoid directly mutating state
        setLocation(e.latLng!);
        form.setFieldValue("coordinates_lat", e.latLng!.lat());
        form.setFieldValue("coordinates_lng", e.latLng!.lng());
        form.setFieldValue("chosen_location", true);
    };

    const onIdle = (m: google.maps.Map) => {
        setZoom(m.getZoom()!);
        setCenter(m.getCenter()!.toJSON());
    };

    const fileInputField = useRef(null);

    const handleDragEnter = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        saveAttachmentAndUpdateLoad(event.dataTransfer!.files);
        event.preventDefault();
        event.stopPropagation();
    };

    const saveAttachmentAndUpdateLoad = (fileList: FileList) => {
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const lastDotIndex = file.name.lastIndexOf(".");
            const extension = file.name.slice(lastDotIndex).toLowerCase();
            if (file.size >= maxFileSize) {
                return;
            } else if (!imageFormats.includes(extension)) {
                return;
            }
        }

        const newAttachments = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            newAttachments.push(file);
        }
        setAttachments([...attachments, ...newAttachments]);
    };

    const handleSwitchToggle = useCallback(
        (
            availabilityKey: keyof SellerProfileState,
            fileKey: keyof SellerProfileState,
            attachmentObjectKey: keyof SellerProfileState
        ) => {
            if (form.values[availabilityKey] && (!!form.values[attachmentObjectKey] || !!form.values[fileKey])) {
                setKeysToDisable({
                    availabilityKey,
                    fileKey,
                    attachmentObjectKey,
                } as any);
            } else {
                form.setFieldValue(availabilityKey, !form.values[availabilityKey]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form.values]
    );

    useEffect(() => {
        if (!form.values.other_documents) {
            form.setFieldValue("other_documents_attachment_description", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.other_documents]);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <CreateEstateFormDetails form={form} dropdowns={dropdowns} isDisabled={false} />
                <CreateEstateFormEstateType form={form} dropdowns={dropdowns} isDisabled={false} />
                <CreateEstateFormAddDocuments
                    form={form}
                    handleSwitchToggle={handleSwitchToggle}
                    highlightDocumentUploads={highlightDocumentUploads}
                    isDisabled={false}
                />
                <CreateEstateFormOthers form={form} dropdowns={dropdowns} isDisabled={false} />
                <CreateEstateFormUploadImages
                    attachments={attachments}
                    fileInputField={fileInputField}
                    oldAttachments={oldAttachments}
                    saveAttachmentAndUpdateLoad={saveAttachmentAndUpdateLoad}
                    handleDrop={handleDrop}
                    handleDragOver={handleDragOver}
                    handleDragEnter={handleDragEnter}
                    handleDragLeave={handleDragLeave}
                    deletedOldAttachment={deletedOldAttachment}
                    setAttachments={setAttachments}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <CreateEstateFormLocationMap
                    form={form}
                    dropdownsMap={dropdownsMap}
                    selectCoordinates={selectCoordinates}
                    zoom={zoom}
                    location={location}
                    render={render}
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    setSelectCoordinates={setSelectCoordinates}
                    setCenter={setCenter}
                />
                <CreateEstateFormAddress form={form} isDisabled={false} />
                <CreateEstateFormContacts form={form} isDisabled={false} />
                <CreateEstateFormPreview next={next} />
                <CreateEstateFormConfirmModal
                    form={form}
                    keysToDisable={keysToDisable}
                    setKeysToDisable={setKeysToDisable}
                />
            </Grid>
        </Grid>
    );
};
