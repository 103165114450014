import { Modal, styled } from "@mui/material";
import { grey, greyLighter, primary, secondary } from "../../../common/Theme/theme";

const BUY_CREDITS_MODAL_PREFIX = "buyCreditsModal";

export const buyCreditsModalClasses = {
    root: `${BUY_CREDITS_MODAL_PREFIX}-root`,
    paper: `${BUY_CREDITS_MODAL_PREFIX}-paper`,
    acceptButton: `${BUY_CREDITS_MODAL_PREFIX}-acceptButton`,
    declineButton: `${BUY_CREDITS_MODAL_PREFIX}-declineButton`,
    checkIcon: `${BUY_CREDITS_MODAL_PREFIX}-checkIcon`,
    secondaryText: `${BUY_CREDITS_MODAL_PREFIX}-secondaryText`,
    planCard: `${BUY_CREDITS_MODAL_PREFIX}-planCard`,
    planCardContent: `${BUY_CREDITS_MODAL_PREFIX}-planCardContent`,
    visibilityOfPayment: `${BUY_CREDITS_MODAL_PREFIX}-visibilityOfPayment`,
    planOfQuantity: `${BUY_CREDITS_MODAL_PREFIX}-planOfQuantity`,
    description: `${BUY_CREDITS_MODAL_PREFIX}-description`,
    planTitle: `${BUY_CREDITS_MODAL_PREFIX}-planTitle`,
    planTitlePrice: `${BUY_CREDITS_MODAL_PREFIX}-planTitlePrice`,
    highlightBorder: `${BUY_CREDITS_MODAL_PREFIX}-highlightBorder`,
    planDiscountTextContainer: `${BUY_CREDITS_MODAL_PREFIX}-planDiscountTextContainer`,
    planDiscountText: `${BUY_CREDITS_MODAL_PREFIX}-planDiscountText`,
    title: `${BUY_CREDITS_MODAL_PREFIX}-title`,
    creditsIcon: `${BUY_CREDITS_MODAL_PREFIX}-creditsIcon`,
    cardAction: `${BUY_CREDITS_MODAL_PREFIX}-cardAction`,
    crossOut: `${BUY_CREDITS_MODAL_PREFIX}-crossOut`,
    closeIcon: `${BUY_CREDITS_MODAL_PREFIX}-closeIcon`,
    customCheckBox: `${BUY_CREDITS_MODAL_PREFIX}-customCheckBox`,
    buttonContainer: `${BUY_CREDITS_MODAL_PREFIX}-buttonContainer`,
};

export const StyledBuyCreditsModal = styled(Modal)(({ theme }) => ({
    [`&.${buyCreditsModalClasses.root}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
    },
    [`& .${buyCreditsModalClasses.paper}`]: {
        padding: "20px",
        width: "700px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.up("xs")]: {
            margin: "auto",
        },
    },
    [`& .${buyCreditsModalClasses.declineButton}`]: {
        marginRight: "10px",
    },
    [`& .${buyCreditsModalClasses.acceptButton}`]: {
        width: "248px",
        height: "40px",
        marginTop: "90px",
        marginBottom: "30px",
        left: "20px",
        gap: "8px",
        borderRadius: "100px",
        color: "white",
    },
    [`& .${buyCreditsModalClasses.checkIcon}`]: {
        color: primary,
        marginRight: "10px",
    },
    [`& .${buyCreditsModalClasses.secondaryText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
    },
    [`& .${buyCreditsModalClasses.planCard}`]: {
        width: "295px",
        marginBottom: "20px",
    },
    [`& .${buyCreditsModalClasses.planCardContent}`]: {
        height: "102px",
    },
    [`& .${buyCreditsModalClasses.visibilityOfPayment}`]: {
        visibility: "hidden",
    },
    [`& .${buyCreditsModalClasses.planOfQuantity}`]: {
        textDecoration: "line-through",
    },
    [`& .${buyCreditsModalClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "italic",
        fontWeight: "500",
        fontSize: "14px",
    },
    [`& .${buyCreditsModalClasses.planTitle}`]: {
        fontFamily: "Rubik",
        fontWeight: "500",
        fontSize: "24px",
        marginBottom: "7px",
        color: "#515661",
        lineHeight: "28px",
        textAlign: "center",
    },
    [`& .${buyCreditsModalClasses.planTitlePrice}`]: {
        fontFamily: "Rubik",
        fontWeight: "700",
        fontSize: "16px",
        color: "#515661",
        lineHeight: "19px",
        textAlign: "center",
    },
    [`& .${buyCreditsModalClasses.highlightBorder}`]: {
        border: "solid 3px",
        borderColor: secondary,
        borderRadius: "10px",
    },
    [`& .${buyCreditsModalClasses.planDiscountTextContainer}`]: {
        width: "103px",
        height: "20px",
        gap: "10px",
        marginLeft: "70px",
        borderRadius: "4px",
        backgroundColor: secondary,
    },
    [`& .${buyCreditsModalClasses.planDiscountText}`]: {
        top: "3px",
        textAlign: "center",
        fontWeight: "500",
        fontSize: "12px",
        color: greyLighter,
        lineHeight: "14px",
    },
    [`& .${buyCreditsModalClasses.title}`]: {
        color: "#515661",
        display: "inline-block",
        paddingLeft: "10px",
    },
    [`& .${buyCreditsModalClasses.creditsIcon}`]: {
        paddingLeft: "30px",
    },
    [`& .${buyCreditsModalClasses.crossOut}`]: {
        textDecoration: "line-through",
    },
    [`& .${buyCreditsModalClasses.closeIcon}`]: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.up("xs")]: {
            padding: "0px",
        },
    },
    [`& .${buyCreditsModalClasses.customCheckBox}`]: {
        lineHeight: "40px",
        color: grey,
        left: "28px",
    },
    [`& .${buyCreditsModalClasses.buttonContainer}`]: {
        width: "284px",
        margin: "auto",
    },
}));
