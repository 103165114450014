import { Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import {
    estateDetailsContactInfoModalClasses as classes,
    StyledEstateDetailsContactInfoModal,
} from "./StyledEstateDetailsContactInfoModal";
import { IEstate } from "../../../interfaces/estate";
import { DropdownMap } from "../../../App";
import CloseIcon from "@mui/icons-material/Close";
import creditsIcon from "../assets/creditsIcon.svg";

interface EstateDetailsDownloadDocumentsModalProps {
    estate: IEstate;
    dropdownsMap: DropdownMap | null;
    openContactPreviewModal: boolean;
    setOpenContactPreviewModal: (value: boolean) => void;
    handleContactUnlocked: () => void;
}

const EstateDetailsContactInfoModal = ({
    estate,
    dropdownsMap,
    openContactPreviewModal,
    setOpenContactPreviewModal,
    handleContactUnlocked,
}: EstateDetailsDownloadDocumentsModalProps) => {
    if (estate && dropdownsMap) {
        return (
            <StyledEstateDetailsContactInfoModal
                open={openContactPreviewModal}
                className={classes.root}
                onClose={() => {
                    setOpenContactPreviewModal(false);
                }}>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom={"10px"}>
                        <Grid item>
                            <img className={classes.creditsIcon} src={creditsIcon} alt="Credits Icon" />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.modalTitle}>Отключване на контакт за</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.ModalCloseIcon}
                                onClick={() => {
                                    setOpenContactPreviewModal(false);
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"row"}>
                        <Typography className={classes.estateTitle} marginRight={"20px"}>
                            {estate.title}
                        </Typography>
                        <Typography className={classes.estatePrice}>
                            {estate.price}{" "}
                            {estate.currency_id ? dropdownsMap!.currency[estate.currency_id].description : ""}
                        </Typography>
                    </Grid>
                    <Divider className={classes.dividerLine} />
                    <Grid container marginTop={"20px"} marginBottom={"20px"}>
                        <Typography className={classes.modalDescription} marginBottom={"10px"}>
                            С натискането на бутона ще получите възможност срещу заплащане да видите контактите за
                            връзкa.
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={"row"} justifyContent={"center"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            className={`${classes.acceptButton} ${classes.widthOfButton}`}
                            onClick={() => {
                                handleContactUnlocked();
                            }}>
                            Отключване
                        </Button>
                    </Grid>
                </Paper>
            </StyledEstateDetailsContactInfoModal>
        );
    }
    return null;
};

export default EstateDetailsContactInfoModal;
